import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';

import { isInRange } from '@/utils/number';

import { EventItem, StatisticsItem } from './types';
import { MILLISECOND_DAY, isInPromotion } from './utils';

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(24)} ${rem(16)} ${rem(20)};
`;

const Summary = styled.h2`
  ${vars.$semantic.typography.title2Bold}
  color: ${vars.$scale.color.gray900};
`;

const Description = styled.p`
  ${vars.$semantic.typography.bodyL2Regular}
  margin-top: ${rem(4)};
  color: ${vars.$scale.color.gray700};
  text-align: center;

  & > b {
    color: ${vars.$semantic.color.primary};
  }
`;

type Props = {
  count: number;
  events: EventItem[];
  expectedIncrease: number;
  statistics: StatisticsItem[];
};

const PromotionDescription: React.FC<React.PropsWithChildren<Props>> = ({
  count,
  expectedIncrease,
  statistics,
  events,
}) => {
  const now = Date.now();
  const ongoingPromotions = events.filter((event) =>
    isInRange(now, event.t, event.t + MILLISECOND_DAY)
  );
  const isPromotionOngoing = ongoingPromotions.length > 0;
  const promotedStatistics = statistics.filter(isInPromotion(ongoingPromotions.slice(0, 1)));

  const getDescription = () => {
    if (!isPromotionOngoing) {
      if (expectedIncrease > 0) {
        return (
          <>
            광고하면 지금보다 <b>약 {expectedIncrease}회</b> 더 조회될 거예요.
          </>
        );
      }
      return `광고하면 조회될 확률이 올라가요`;
    }
    if (isPromotionOngoing && promotedStatistics.length <= 0) {
      return <b>곧 광고 효과를 확인할 수 있어요</b>;
    }
    return (
      <>
        광고하는 동안 <b>{count}회</b> 더 조회됐어요
      </>
    );
  };

  return <Description>{getDescription()}</Description>;
};

const Base = {
  TextWrapper,
  Summary,
  Description,
  PromotionDescription,
};

export default Base;
