import pRetry from 'p-retry';
import pTimeout from 'p-timeout';

import { Unpack } from '@/types/global';
import { getActiveTestUser } from '@/utils/dev';
import { handleError } from '@/utils/error';

import { environment, karrotBridge } from '.';

const retryBridgeRequest = async <T>(fn: () => Promise<T>) => {
  const MIN_TIMEOUT_MS = 16;
  const TIMEOUT_MS = 500;
  const RETRY_COUNT = 3;

  return pRetry(() => pTimeout<T>(fn(), { milliseconds: TIMEOUT_MS }), {
    minTimeout: MIN_TIMEOUT_MS,
    retries: RETRY_COUNT,
  });
};

export type User = Unpack<ReturnType<typeof karrotBridge.getUserInfo>>['info']['user'];

const isWeb = environment === 'Web';

export function getDefaultUser() {
  const activeTestUser = getActiveTestUser();
  return {
    ...activeTestUser,
    displayRegionCheckinsCount: 1,
    phone: '01000000000',
    nickname: '',
    profileImage: '',
  };
}

export async function getUser() {
  const defaultUser = getDefaultUser();

  if (isWeb) {
    return defaultUser as User;
  }

  try {
    const { info } = await retryBridgeRequest(() => karrotBridge.getUserInfo({}));
    return info.user;
  } catch (e) {
    handleError(new Error('karrotBridge.getUserInfo failed'), { reportToSentry: true });
    return {} as User;
  }
}

export type Region = Unpack<ReturnType<typeof karrotBridge.getRegionInfo>>['info']['region'];

export async function getRegion() {
  const defaultRegion = {
    id: 366,
    name1: '서울특별시',
    name2: '서초구',
    name3: '서초4동',
    name1Id: 1,
    name2Id: 362,
    name3Id: 366,
    parentId: 362,
    name: '서초4동',
    fullname: '서울 서초구 서초4동',
    centerCoordinates: {
      latitude: 37.502675,
      longitude: 127.022388,
    },
  };

  if (isWeb) {
    return defaultRegion;
  }

  try {
    const { info } = await retryBridgeRequest(() => karrotBridge.getRegionInfo({}));
    return info.region;
  } catch (e) {
    handleError(new Error('karrotBridge.getRegionInfo failed'), { reportToSentry: true });
    return {} as Region;
  }
}

export type App = Unpack<ReturnType<typeof karrotBridge.getAppInfo>>['info']['app'];

export async function getApp() {
  const defaultApp = {
    appVersion: '00.00.0',
    appVersionCode: '000000',
    locale: 'ko-KR',
    appHost: 'webapp.alpha.kr.karrotmarket.com',
    country: 'KR' as const,
    deviceIdentity: '00000000-0000-0000-0000-000000000000',
    userAgent: 'TowneersApp/22.5.0/68503 iOS/15.6.0/1866 iPhone14,5',
  };

  if (isWeb) {
    return defaultApp;
  }

  try {
    const { info } = await retryBridgeRequest(() => karrotBridge.getAppInfo({}));
    return info.app;
  } catch (e) {
    handleError(new Error('karrotBridge.getAppInfo failed'), { reportToSentry: true });
    return {} as App;
  }
}

export async function getInfo() {
  const [user, region, app] = await Promise.all([getUser(), getRegion(), getApp()]);

  return {
    user,
    region,
    app,
  };
}
