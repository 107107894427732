import styled from '@emotion/styled';

import { SeedColor, getSeedColor } from '@/components/Base/Box';

export const ColoredBold = styled.b<{ color?: SeedColor }>((props) => ({
  color: getSeedColor(props.color ?? 'accent'),
}));

export const ColoredSpan = styled.span<{ color?: SeedColor }>((props) => ({
  color: getSeedColor(props.color ?? 'accent'),
}));
