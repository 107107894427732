/**
 * @generated SignedSource<<3853441161e4e81dcb8f9b40fd16840f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type TransactionHistoryItem_article$data = {
  readonly carData: {
    readonly autoupModel: {
      readonly grade: {
        readonly name: string;
      } | null | undefined;
      readonly subgrade: {
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly modelYear: number;
  };
  readonly driveDistance: number;
  readonly events: ReadonlyArray<{
    readonly t: number;
    readonly type: ArticleEventType | null | undefined;
  }>;
  readonly id: string;
  readonly minActualPrice: number | null | undefined;
  readonly price: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"getTradeDurationFromArticle_article">;
  readonly " $fragmentType": "TransactionHistoryItem_article";
};
export type TransactionHistoryItem_article$key = {
  readonly " $data"?: TransactionHistoryItem_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionHistoryItem_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ArticleEvent",
  "kind": "LinkedField",
  "name": "events",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "t",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionHistoryItem_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minActualPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "driveDistance",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modelYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupModel",
          "kind": "LinkedField",
          "name": "autoupModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupGrade",
              "kind": "LinkedField",
              "name": "grade",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubgrade",
              "kind": "LinkedField",
              "name": "subgrade",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getTradeDurationFromArticle_article",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "eb5888d20ec8a665c8cb397aefb583d1";

export default node;
