/**
 * @generated SignedSource<<760e9fb847323559695f3734feaec8d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FloatingCreateNotificationButton_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateNotificationRenderer_query">;
  readonly " $fragmentType": "FloatingCreateNotificationButton_query";
};
export type FloatingCreateNotificationButton_query$key = {
  readonly " $data"?: FloatingCreateNotificationButton_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"FloatingCreateNotificationButton_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FloatingCreateNotificationButton_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateNotificationRenderer_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "60773f6e96b9969c394fe941dc304a80";

export default node;
