import React from 'react';

import { ColoredSpan } from '@/components/Base/ColoredText';
import HStack from '@/components/Base/HStack';
import { Caption1Regular, Subtitle1Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import PositivityBooster from '@/components/icon/PositivityBooster';
import BoldDot from '@/pages/ArticleProxyInspectionGuide/components/BoldDot';

type Props = {
  onClick: () => void;
};

const InspectorTraineeBanner: React.FC<Props> = ({ onClick }) => {
  return (
    <HStack
      align="center"
      bg="gray100"
      br={10}
      justify="space-between"
      maxH={84}
      onClick={onClick}
      pl={16}
      pr={10}
      py={6}
    >
      <VStack gap={2}>
        <Subtitle1Bold>[3기] 자동차 진단평가사 교육생 모집</Subtitle1Bold>
        <Caption1Regular color="gray600">
          <ColoredSpan color="accent">월 수입 400만원</ColoredSpan> <BoldDot /> 선착순 모집
        </Caption1Regular>
      </VStack>
      <PositivityBooster />
    </HStack>
  );
};

export default InspectorTraineeBanner;
