import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const OneOwner: React.FC<React.PropsWithChildren<Props>> = ({
  width = 16,
  height = 16,
  ...props
}) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 16 16"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00016 2.26001C6.15016 2.26001 4.66016 3.76001 4.66016 5.60001C4.66016 7.44001 6.16016 8.94001 8.00016 8.94001C9.84016 8.94001 11.3402 7.44001 11.3402 5.60001C11.3402 3.76001 9.84016 2.26001 8.00016 2.26001Z"
        fill="#329BE1"
      />
      <path
        d="M7.99938 9.43994C4.89938 9.43994 2.85938 11.1299 2.85938 13.3799C2.85938 13.5799 3.02938 13.7399 3.21938 13.7399H12.7894C12.9894 13.7399 13.1494 13.5699 13.1494 13.3799C13.1494 11.1299 11.1094 9.43994 8.00938 9.43994H7.98938H7.99938Z"
        fill="#329BE1"
      />
    </svg>
  );
};

export default OneOwner;
