import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleCarInfoSection_article$key } from '@/__generated__/ArticleCarInfoSection_article.graphql';
import List from '@/components/Base/List';
import { CarType, CarTypeText } from '@/types/CarData/CarType';
import { FuelType, FuelTypeText } from '@/types/CarData/FuelType';
import { GearType, GearTypeText } from '@/types/CarData/GearType';
import { getRegistrationText } from '@/utils/article/getRegistrationText';
import { getCommaFormat } from '@/utils/number';

import SectionTitle from './SectionTitle';

type Props = {
  articleRef: ArticleCarInfoSection_article$key;
};

const ArticleCarInfoSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment ArticleCarInfoSection_article on Article {
        id
        carData {
          carType
          carRegistrationYear
          carRegistrationMonth
          modelYear
          displacement
          fuelType
          gear
          autoupModel {
            company {
              name
            }
            subseries {
              name
            }
            grade {
              name
            }
            subgrade {
              name
            }
          }
        }
      }
    `,
    articleRef
  );
  const { carData } = article;
  const {
    autoupModel,
    carRegistrationYear,
    carRegistrationMonth,
    carType,
    displacement,
    fuelType,
    gear,
  } = carData;
  const { company, subseries, grade, subgrade } = autoupModel ?? {};

  const isRegistrationAvailable = carRegistrationYear && carRegistrationMonth;

  return (
    <Container>
      <SectionTitle>상세 정보</SectionTitle>
      <List
        gap={20}
        items={[
          { title: '차종', value: CarTypeText[carType as CarType] },
          {
            title: '제조사',
            value: company?.name,
          },
          {
            title: '모델',
            value: subseries?.name,
          },
          {
            title: '등급',
            value: grade?.name,
          },
          {
            title: '세부 등급',
            value: !subgrade?.name || subgrade?.name === '없음' ? '-' : subgrade?.name,
          },
          {
            title: `연식${isRegistrationAvailable ? ' / 등록일' : ''}`,
            value: getRegistrationText(carData),
          },
          {
            title: '배기량',
            value: `${getCommaFormat(displacement ?? 0)}cc`,
          },
          {
            title: '연료',
            value: FuelTypeText[fuelType as FuelType],
          },
          {
            title: '변속기',
            value: GearTypeText[gear as GearType],
          },
        ].filter(({ value }) => !!value)}
        titleCss={css`
          color: ${vars.$scale.color.gray700};
        `}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ArticleCarInfoSection;
