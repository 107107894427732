import { useActivity } from '@stackflow/react';

import { useEffectOnce } from '@/hooks/useEffectOnce';
import useIncreaseViewCount from '@/hooks/useIncreaseViewCount';
import { sessionStorage } from '@/utils/storage';
import { useCamelCaseActivityParams } from '@/utils/url';

const IncreaseArticleViewCount = () => {
  const { isRoot } = useActivity();
  const { articleId } = useCamelCaseActivityParams<'article'>();
  const [increaseViewCount] = useIncreaseViewCount();

  useEffectOnce(() => {
    const referrer = sessionStorage.getReferrer();
    const from = isRoot && referrer === 'feed' ? 'FEED' : 'UNKNOWN';

    // 어드민에 HOT 타입도 조회수 필드에 노출되고 있으나 큰 의미가 없어서 사용 안하고 있음
    increaseViewCount({ variables: { id: articleId, from } });
  });
  return null;
};

export default IncreaseArticleViewCount;
