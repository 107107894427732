import { ReactNode, Suspense, lazy } from 'react';

import { Merge } from '@/types/global';
import { PropsOf } from '@/types/React/PropsOf';

const LazyLottiePlayer = lazy(() => import('./LottiePlayer'));
type Props = Merge<
  PropsOf<typeof LazyLottiePlayer>,
  {
    fallback?: ReactNode;
  }
>;
const Lottie = ({ fallback = <div />, ...props }: Props) => {
  return (
    <Suspense fallback={fallback}>
      <LazyLottiePlayer {...props} />
    </Suspense>
  );
};

export default Lottie;
