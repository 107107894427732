import { useActivity } from '@stackflow/react';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

import { carTracker } from '@/sdk/tracker/CarTracker';
import { type PvEvent } from '@/sdk/tracker/PvEvent';
import { type ActivityName } from '@/stackflow';
import { assignedGroupMapState } from '@/store/experiment';

import usePrevActivity from './usePrevActivity';

export const usePvEvent = <TActivityName extends ActivityName>(
  name: TActivityName,
  params: Extract<PvEvent, { name: `${TActivityName}_pageview` }>['params']
) => {
  const flag = useRef(true);
  const activity = useActivity();
  const prevActivity = usePrevActivity();
  const experimentGroupMap = useAtomValue(assignedGroupMapState);

  const track = () => {
    carTracker.trackPageView(`${name}_pageview` as PvEvent['name'], params as PvEvent['params'], {
      prevActivity,
      experimentGroupMap,
      currentActivity: activity,
    });
  };
  useEffect(() => {
    if (activity.isTop && flag.current) {
      track();
      flag.current = false;
    } else {
      flag.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity.isTop, flag]);
};
