import { Activity } from '@stackflow/core';

import routes from '@/bootstrap/routes';

import { actions } from './Stack';

export function getActivityDescription(activity?: Activity) {
  if (!activity) {
    return;
  }
  const route = routes.find((r) => r.name === activity.name);

  return route?.description;
}

export function getActiveActivity() {
  const stack = actions.getStack();
  return stack.activities.find((activity) => activity.isActive);
}
