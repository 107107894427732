import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

import MoreButton from '@/components/Base/MoreButton';

type Props = {
  onClickMore?: () => void;
};

const SectionTitle: React.FC<PropsWithChildren<Props>> = ({ children, onClickMore, ...props }) => {
  return (
    <Container {...props}>
      <Title>{children}</Title>
      {onClickMore && <MoreButton onClick={onClickMore} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${rem(20)};
`;

const Title = styled.h3`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title3Bold}
  align-self: center;
`;

export default SectionTitle;
