/**
 * @generated SignedSource<<07d478979dc89064ca54a9d41838eb1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsuranceBottomSheet_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getSortedInsuranceList_article">;
  readonly " $fragmentType": "InsuranceBottomSheet_article";
};
export type InsuranceBottomSheet_article$key = {
  readonly " $data"?: InsuranceBottomSheet_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsuranceBottomSheet_article">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "order",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsuranceBottomSheet_article",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getSortedInsuranceList_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceTrackingData",
          "kind": "LinkedField",
          "name": "insuranceTrackingData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InsuranceTrackingUrl2",
              "kind": "LinkedField",
              "name": "samsung",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InsuranceTrackingUrl2",
              "kind": "LinkedField",
              "name": "kb",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InsuranceTrackingUrl2",
              "kind": "LinkedField",
              "name": "db",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InsuranceTrackingUrl2",
              "kind": "LinkedField",
              "name": "carrot",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "d7d7adb51017f93b362ca9ee8c1b8476";

export default node;
