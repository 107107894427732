/**
 * @generated SignedSource<<e88800dcf2eb107443be8a3691f35702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type SameCarTransactionHistoryItem_article$data = {
  readonly carData: {
    readonly autoupModel: {
      readonly subseries: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  };
  readonly resaleOriginArticle: {
    readonly carData: {
      readonly autoupModel: {
        readonly grade: {
          readonly name: string;
        } | null | undefined;
        readonly subgrade: {
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly modelYear: number;
    };
    readonly driveDistance: number;
    readonly events: ReadonlyArray<{
      readonly t: number;
      readonly type: ArticleEventType | null | undefined;
    }>;
    readonly id: string;
    readonly minActualPrice: number | null | undefined;
    readonly price: number;
    readonly publishedAt: any;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionHistoryItem_article">;
  readonly " $fragmentType": "SameCarTransactionHistoryItem_article";
};
export type SameCarTransactionHistoryItem_article$key = {
  readonly " $data"?: SameCarTransactionHistoryItem_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"SameCarTransactionHistoryItem_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SameCarTransactionHistoryItem_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupModel",
          "kind": "LinkedField",
          "name": "autoupModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubseries",
              "kind": "LinkedField",
              "name": "subseries",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SanitizedArticle",
      "kind": "LinkedField",
      "name": "resaleOriginArticle",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minActualPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleEvent",
          "kind": "LinkedField",
          "name": "events",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "t",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "driveDistance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SanitizedCarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modelYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupModel",
              "kind": "LinkedField",
              "name": "autoupModel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupGrade",
                  "kind": "LinkedField",
                  "name": "grade",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupSubgrade",
                  "kind": "LinkedField",
                  "name": "subgrade",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransactionHistoryItem_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "c639991e0514876e75cd9c70fa6bad80";

export default node;
