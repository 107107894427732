/**
 * @generated SignedSource<<6e73be3609884d05f0dda300d61dfddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type VerticalArticleCard_article$data = {
  readonly carData: {
    readonly " $fragmentSpreads": FragmentRefs<"carData_getCarNameByCompanyOrigin_carData">;
  };
  readonly id: string;
  readonly status: ArticleStatus;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleActionCount_article" | "ArticleBadges_article" | "ArticleStatusBadge_article" | "ArticleThumbnailBadges_article" | "LikeButton_article" | "getArticleEventProperties_article" | "getCarSpec_article" | "getMergedImages_article" | "getPriceText_article">;
  readonly " $fragmentType": "VerticalArticleCard_article";
};
export type VerticalArticleCard_article$key = {
  readonly " $data"?: VerticalArticleCard_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerticalArticleCard_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manufacturer",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoupCompany",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "driveDistance",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelYear",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyFee",
    "storageKey": null
  }
],
v10 = {
  "kind": "InlineDataFragmentSpread",
  "name": "getPriceText_article",
  "selections": [
    (v0/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseInfo",
      "kind": "LinkedField",
      "name": "leaseInfo",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerticalArticleCard_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "carData_getCarNameByCompanyOrigin_carData",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_assembleCarName_carData",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupModel",
                  "kind": "LinkedField",
                  "name": "autoupModel",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupSeries",
                      "kind": "LinkedField",
                      "name": "series",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupSubseries",
                      "kind": "LinkedField",
                      "name": "subseries",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupGrade",
                      "kind": "LinkedField",
                      "name": "grade",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupSubgrade",
                      "kind": "LinkedField",
                      "name": "subgrade",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupModel",
              "kind": "LinkedField",
              "name": "autoupModel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupCompany",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyOrigin",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getCarSpec_article",
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarFullName_carData",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupModel",
                  "kind": "LinkedField",
                  "name": "autoupModel",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        (v10/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getMergedImages_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleActionCount_article"
    },
    (v10/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleStatusBadge_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getArticleEventProperties_article",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voteCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chatRoomCount",
          "storageKey": null
        },
        (v6/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarDataEventProperties",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displacement",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carType",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fuelType",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "options",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accidentType",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isWarrantable",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "isInspectedCar_article",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CarData",
              "kind": "LinkedField",
              "name": "carData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Inspection",
                  "kind": "LinkedField",
                  "name": "inspection",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isMechanicReportVisible",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleThumbnailBadges_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleBadges_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LikeButton_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "35d65941a4e5b83adb6a8c0a7c85a8e8";

export default node;
