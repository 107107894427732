import { noop } from 'lodash-es';
import { createContext, useContext } from 'react';
import { FetchPolicy } from 'react-relay';

type ArticleContextState = {
  isChartSectionVisible: boolean;
  isMyArticle: boolean;
  refetch: (fetchPolicy?: FetchPolicy) => void;
};

const ArticleContext = createContext<ArticleContextState>({
  isMyArticle: false,
  isChartSectionVisible: false,
  refetch: noop,
});

export const useArticleContext = () => {
  return useContext(ArticleContext);
};

export default ArticleContext;
