import { StackflowActions, StackflowPlugin } from '@stackflow/core';

import CONFIG from '@/constants/config';

import { getActiveActivity } from './utils';

const titleSyncPlugin = (): StackflowPlugin => {
  function setDocumentTitle({ actions }: { actions: StackflowActions }) {
    const stack = actions.getStack();
    const activity = getActiveActivity(stack);
    document.title = [activity?.name, CONFIG.SERVICE_NAME].filter(Boolean).join(' | ');
  }

  return () => ({
    key: 'titleSync',
    onInit: ({ actions }) => {
      setDocumentTitle({ actions });
    },
    onChanged: ({ actions }) => {
      setDocumentTitle({ actions });
    },
  });
};

export default titleSyncPlugin;
