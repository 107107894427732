import { objectKeys } from '@toss/utils';
import { isEqual } from 'lodash-es';
import { graphql, readInlineData } from 'relay-runtime';
import { P, match } from 'ts-pattern';

import type {
  CreateNewArticleNotificationConditionV2Input,
  H3IndexListInput,
} from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';

import { notification_newArticleNotificationCondition$key } from '@/__generated__/notification_newArticleNotificationCondition.graphql';
import { getNewArticleNotificationConditionInput } from '@/pages/Notification/utils/getNewArticleNotificationConditionInput';
import { mutable, nullify, nullsToUndefined } from '@/utils/misc';

type Key = keyof CreateNewArticleNotificationConditionV2Input;
const record: Record<Key, true> = {
  baseDistanceKm: true,
  baseRegionId: true,
  carTypes: true,
  companyIds: true,
  companyOrigin: true,
  driveDistanceMax: true,
  driveDistanceMin: true,
  fuelTypes: true,
  gears: true,
  h3IndexList: true,
  modelYearMax: true,
  modelYearMin: true,
  priceMax: true,
  priceMin: true,
  saleTypes: true,
  seriesIds: true,
  subseriesIds: true,
  warrantyScope: true,
};

const allKeys = objectKeys(record);

const isH3IndexListInput = (v: any): v is H3IndexListInput => {
  return v && typeof v.resolution === 'string' && Array.isArray(v.h3Index);
};

const compareStringArray = (c1: string[], c2: string[]) => {
  if (c1.length !== c2.length) {
    return false;
  }
  return isEqual(c1.toSorted(), c2.toSorted());
};

export const isSameNotificationCondition = (
  newArticleNotificationConditionRef: notification_newArticleNotificationCondition$key,
  condition: CreateNewArticleNotificationConditionV2Input
) => {
  const fragment = readInlineData(
    graphql`
      fragment notification_newArticleNotificationCondition on NewArticleNotificationCondition
      @inline {
        ...getNewArticleNotificationConditionInput_newArticleNotificationCondition
      }
    `,
    newArticleNotificationConditionRef
  );
  const existingCondition = getNewArticleNotificationConditionInput(fragment);

  for (const key of allKeys) {
    const values = nullsToUndefined(
      [condition[key], existingCondition[key]].map((v) => (Array.isArray(v) ? nullify(v) : v))
    );

    const isSame = match(values)
      .with(
        P.array(P.when(isH3IndexListInput)),
        ([v1, v2]) =>
          v1.resolution === v2.resolution &&
          compareStringArray(mutable(v1.h3Index), mutable(v2.h3Index))
      )
      .with(P.array(P.array(P.string)), ([v1, v2]) => compareStringArray(mutable(v1), mutable(v2)))
      .with(P.array(P._), ([v1, v2]) => v1 === v2)
      .exhaustive();

    if (!isSame) {
      return false;
    }
  }

  return true;
};
