import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const Handle: React.FC<Props> = ({ width = 18, height = 18, ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 18 19"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 9.5V15.5" stroke="#B9B9B9" strokeWidth="1.875" />
      <path d="M9 9.5L15.75 7.25" stroke="#B9B9B9" strokeWidth="1.875" />
      <path d="M9 9.5L2.25 7.25" stroke="#B9B9B9" strokeWidth="1.875" />
      <path
        d="M8.99988 16.4646C12.8474 16.4646 15.9636 13.3484 15.9636 9.50086C15.9636 5.65336 12.8474 2.53711 8.99988 2.53711C5.15238 2.53711 2.03613 5.65336 2.03613 9.50086C2.03613 13.3484 5.15238 16.4646 8.99988 16.4646Z"
        stroke="#868B94"
        strokeMiterlimit="10"
        strokeWidth="1.87"
      />
      <circle cx="8.99976" cy="9.5" fill="#767676" r="2.25" />
    </svg>
  );
};

export default Handle;
