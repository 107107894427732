import { RefObject, useEffect, useState } from 'react';

const DefaultOptions = { root: null, rootMargin: '0px', threshold: 0 };

const useIntersectionObserver = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit = DefaultOptions
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);
    element && observer.observe(element);
    return () => {
      element && observer.unobserve(element);
    };
  }, [options, ref]);

  return isIntersecting;
};

export default useIntersectionObserver;
