import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

import { SquareBadge } from '@/components/Base/Badge';
import Text from '@/components/Base/Text';

const CountBadge: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Badge size="small" style="normal" variant="basic">
      <Text color="onPrimary" variant="caption2Bold">
        {children}
      </Text>
    </Badge>
  );
};

const Badge = styled(SquareBadge)({
  backgroundColor: vars.$scale.color.gray500,
  height: rem(16),
  borderRadius: rem(1000),
});

export default CountBadge;
