import { BoxButton } from '@daangn/sprout-components-button';
import { MultilineTextField } from '@daangn/sprout-components-text-field';
import { useBooleanState } from '@toss/react';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { Fragment, type PropsWithChildren } from 'react';
import { graphql } from 'relay-runtime';
import { match } from 'ts-pattern';

import type { UserFeedback_sendUserFeedbackMutation } from '@/__generated__/UserFeedback_sendUserFeedbackMutation.graphql';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import Center from '@/components/Base/Center';
import { Subtitle1Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import StarRatings from '@/components/StarRatings';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import useMutationAsync from '@/hooks/useMutationAsync';
import { useOverlayState } from '@/hooks/useOverlayState';
import { useToast } from '@/hooks/useToast';
import { karrotBridge } from '@/sdk/bridge';
import {
  type UserFeedbackValue,
  userFeedbackSentStatusSelector,
  userFeedbackState,
} from '@/store/userFeedback';
import { handleError } from '@/utils/error';

const UserFeedback = ({ ...props }: PropsWithChildren) => {
  const [isOpened, setIsOpenedTrue, setIsOpenedFalse] = useOverlayState('userFeedback');
  const userFeedbackSentStatus = useAtomValue(userFeedbackSentStatusSelector);
  const resetUserFeedback = useResetAtom(userFeedbackState);
  const [userFeedback, setUserFeedback] = useAtom(userFeedbackState);
  const [isInvalid, setIsInvalidTrue, setIsInvalidFalse] = useBooleanState(false);
  const { setToast } = useToast();

  const [sendFeedback, isFlightSendFeedback] =
    useMutationAsync<UserFeedback_sendUserFeedbackMutation>(graphql`
      mutation UserFeedback_sendUserFeedbackMutation($input: VoiceOfUserInput!) {
        createVoiceOfUser(input: $input) {
          id
        }
      }
    `);

  useEffectOnce(() => {
    match(userFeedbackSentStatus)
      .with('sent_month_ago', () => resetUserFeedback())
      .with('not_sent', () =>
        setUserFeedback((pv) =>
          pv.type === 'idle'
            ? {
                ...pv,
                value: {
                  ...pv.value,
                  rating: 3,
                },
              }
            : pv
        )
      )
      .with('sent', () => undefined)
      .exhaustive();
  });

  const handleConfirm = async () => {
    if (userFeedback.type !== 'idle') {
      return;
    }
    if (!userFeedback.value.rating || !userFeedback.value.content) {
      karrotBridge.setHapticError({});
      return setIsInvalidTrue();
    }
    setIsInvalidFalse();
    setIsOpenedFalse();
    try {
      await sendFeedback({
        variables: {
          input: {
            campaignId: '홈_피드_7번구좌',
            message: userFeedback.value.content,
            score: userFeedback.value.rating,
          },
        },
      });
      setUserFeedback({
        type: 'completed',
        sentAt: new Date().toISOString(),
      });
      setToast('서비스 개선에 도움을 주셔서 감사해요.');
    } catch (e) {
      const { message } = handleError(e, { reportToSentry: true });
      setToast(message);
    }
  };
  const validate = (key: keyof UserFeedbackValue) => {
    return userFeedback.type === 'idle' && !!userFeedback.value[key];
  };
  const valueSetter =
    <TKey extends keyof UserFeedbackValue>(key: TKey) =>
    (value: UserFeedbackValue[TKey]) => {
      setUserFeedback((pv) => ({
        type: 'idle',
        value: {
          ...(pv.type === 'idle' ? pv.value : {}),
          [key]: value,
        } as UserFeedbackValue,
      }));
    };

  const handleClickStarRating = (rating: number) => {
    valueSetter('rating')(rating);
    setIsOpenedTrue();
  };

  return (
    <Fragment>
      <VStack align="center" bg="paperContents" br={8} gap={16} pa={24} {...props}>
        <Subtitle1Bold>당근 중고차에 얼마나 만족하시나요?</Subtitle1Bold>
        <StarRatings onChange={handleClickStarRating} value={0} />
      </VStack>
      <BottomSheet
        active={isOpened}
        justifyHeader="center"
        onClickClose={setIsOpenedFalse}
        showCloseButton
        title="의견 보내기"
      >
        <VStack gap={24} pb={8} pt={16} px={16}>
          {userFeedback.type === 'idle' && (
            <VStack gap={44}>
              <Center>
                <StarRatings
                  onChange={valueSetter('rating')}
                  value={userFeedback.type === 'idle' ? userFeedback.value.rating : 0}
                />
              </Center>
              <MultilineTextField
                errorMessage="의견을 남겨주세요."
                isInvalid={isInvalid && !validate('content')}
                label="당근 중고차에 대한 의견을 남겨주세요."
                maxLength={1000}
                onChange={valueSetter('content')}
                placeholder="좋았던 점 혹은 불편했던 점을 솔직하게 적어주세요. 서비스 개선에 큰 도움이 돼요."
                value={userFeedback.type === 'idle' ? userFeedback.value.content : ''}
              />
            </VStack>
          )}
          <BoxButton
            isLoading={isFlightSendFeedback}
            onClick={handleConfirm}
            size="xlarge"
            variant="primary"
          >
            확인
          </BoxButton>
        </VStack>
      </BottomSheet>
    </Fragment>
  );
};

export default UserFeedback;
