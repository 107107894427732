import { Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';
import {
  type PaymentSuccessData,
  RequestPaymentWebviewOptions,
  requestPaymentWebview,
} from '@karrotpay/payment-sdk';
import { useBooleanState, useDebounce } from '@toss/react';
import { useCallback } from 'react';

import BaseDim from '@/components/Base/Dim';
import { IS_PROD } from '@/constants/config';
import { karrotBridge } from '@/sdk/bridge';

const useKarrotPayWebview = () => {
  const [isInFlight, setTrue, setFalse] = useBooleanState(false);

  const _openKarrotPayWebview = useCallback(
    ({
      payId,
      partnerAppSecret,
      requiredAuthSteps,
      authType,
      cashReceiptSupportMethod,
    }: Pick<
      RequestPaymentWebviewOptions,
      'authType' | 'cashReceiptSupportMethod' | 'partnerAppSecret' | 'payId' | 'requiredAuthSteps'
    >): Promise<PaymentSuccessData> =>
      new Promise((resolve, reject) => {
        requestPaymentWebview({
          viewType: 'FULL_VIEW',
          bridge: {
            karrotBridge,
          },
          stage: IS_PROD ? 'production' : 'alpha',
          partnerAppSecret,
          payId,
          requiredAuthSteps,
          cashReceiptSupportMethod,
          authType,
          onSuccess(data) {
            setTrue();
            resolve(data);
          },
          onFailure(data) {
            setFalse();
            reject(data);
          },
        });
      }),
    [setTrue, setFalse]
  );

  const openKarrotPayWebview = useDebounce(_openKarrotPayWebview, 500, {
    leading: true,
    trailing: false,
  });

  const loadingNode = isInFlight ? (
    <Dim visible={isInFlight} zIndex={100}>
      <Spinner variant="white" />
    </Dim>
  ) : null;

  return [openKarrotPayWebview, loadingNode, setFalse] as const;
};

const Dim = styled(BaseDim)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default useKarrotPayWebview;
