import { SeedTextButtonProps, TextButton } from '@daangn/sprout-components-button';
import { IconChevronRightFill } from '@seed-design/icon';
import React from 'react';

import HStack from '@/components/Base/HStack';
import { getSizeProps } from '@/utils/style';

type Props = Omit<SeedTextButtonProps, 'children'>;

const MoreButton: React.FC<Props> = ({ size = 'small', onClick, ...props }) => {
  return (
    <TextButton onClick={onClick} resetPadding size={size} variant="secondaryLow" {...props}>
      <HStack align="center" gap={2}>
        <span>더보기</span>
        <IconChevronRightFill {...getSizeProps(14)} />
      </HStack>
    </TextButton>
  );
};

export default MoreButton;
