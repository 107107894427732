import { IS_PROD } from '@/constants/config';
import { karrotBridge } from '@/sdk/bridge';
import { brazeCustomEventRegex } from '@/sdk/tracker/CustomEvent';
import { brazePvEventRegex } from '@/sdk/tracker/PvEvent';
import { TrackerImpl } from '@/sdk/tracker/TrackerImpl';
import { BaseEventParams, formatParams } from '@/utils/track';

function logBrazeEvent(
  type: 'click' | 'impression' | 'show',
  name: string,
  params?: BaseEventParams
) {
  if (!IS_PROD) {
    return;
  }
  const mergedParams = formatParams({ name, ...params });
  karrotBridge.logEvent({
    analytics: { target: 'BRAZE', name: `${type}_car`, params: mergedParams },
  });
}

const BrazeTracker = (): TrackerImpl => {
  let initialized = false;
  return {
    initialized,
    init: () => {
      initialized = true;
    },
    trackEvent: (name, params) => {
      if (!initialized) {
        throw new Error('BrazeTracker is not initialized');
      }
      if (brazeCustomEventRegex.test(name)) {
        logBrazeEvent(name.includes('impression_') ? 'impression' : 'click', name, params);
      }
    },
    trackPageView: (name, params) => {
      if (!initialized) {
        throw new Error('BrazeTracker is not initialized');
      }
      if (brazePvEventRegex.test(name)) {
        logBrazeEvent('show', name, params);
      }
    },
  };
};

export const brazeTracker = BrazeTracker();
