/**
 * @generated SignedSource<<63e9d7e34e98637d21a8e8e231d561f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteArticleInput = {
  articleId: string;
};
export type useDeleteArticle_DeleteArticleMutation$variables = {
  input: DeleteArticleInput;
};
export type useDeleteArticle_DeleteArticleMutation$data = {
  readonly result: boolean;
};
export type useDeleteArticle_DeleteArticleMutation = {
  response: useDeleteArticle_DeleteArticleMutation$data;
  variables: useDeleteArticle_DeleteArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "result",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteArticle",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteArticle_DeleteArticleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteArticle_DeleteArticleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "28c569b092989b11098617a5feb68077",
    "id": null,
    "metadata": {},
    "name": "useDeleteArticle_DeleteArticleMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteArticle_DeleteArticleMutation(\n  $input: DeleteArticleInput!\n) {\n  result: deleteArticle(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "c6a3dc9ec96d510e216cf775efeb25bb";

export default node;
