/**
 * @generated SignedSource<<c80062d52fca3391f23b17bb289cb30b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type carData_getStandardDriveDistance_CarData$data = {
  readonly carRegistrationMonth: number | null | undefined;
  readonly carRegistrationYear: number | null | undefined;
  readonly " $fragmentType": "carData_getStandardDriveDistance_CarData";
};
export type carData_getStandardDriveDistance_CarData$key = {
  readonly " $data"?: carData_getStandardDriveDistance_CarData$data;
  readonly " $fragmentSpreads": FragmentRefs<"carData_getStandardDriveDistance_CarData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carData_getStandardDriveDistance_CarData"
};

(node as any).hash = "9cc5be2d9a8c93e4755e5b59df4b9156";

export default node;
