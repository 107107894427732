import { captureException, withScope } from '@sentry/core';
import constate from 'constate';
import { useCallback, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import type { Image } from '@/types/Image/Image';

import { useEventCallback } from '@/hooks/useEventCallback';
import { useToast } from '@/hooks/useToast';
import { handleError } from '@/utils/error';
import { imageToBlob, readImage, uploadImage } from '@/utils/image-upload';

export type ImageAction = {
  payload: {
    files: File[];
  };
  type: 'upload-image';
};

export type ImageCallbackParams =
  | {
      payload: {
        e: Error;
        file: File;
      };
      type: 'failed';
    }
  | {
      payload: {
        image: Image;
      };
      type: 'success';
    }
  | {
      type: 'completed';
    };

const useUploadImage = () => {
  const callbacksRef = useRef<Record<string, ((p: ImageCallbackParams) => void) | undefined>>({});
  const [uploadingCount, setUploadingCount] = useState(0);
  const { setToast } = useToast();

  const uploadImages = useEventCallback(async (id: string, files: File[]) => {
    setUploadingCount((pv) => pv + files.length);
    for (const file of files) {
      try {
        const { image } = await readImage(file);
        const compressed = await imageToBlob({
          image,
          file,
        });
        const uploadedImage = await uploadImage({
          blob: compressed,
          mimeType: file.type,
          size: { width: image.width, height: image.height },
        });
        callbacksRef?.current?.[id]?.({
          type: 'success',
          payload: {
            image: {
              id: uploadedImage.id,
              url: uploadedImage.url,
            },
          },
        });
      } catch (e) {
        const { message } = handleError(e);
        withScope((scope) => {
          scope.setTag('imageType', file.type);
          scope.setTag('imageSize', file.size);
          scope.setFingerprint(['ImageUploadError', file.type, message.slice(0, 50)]);
          scope.setLevel('warning');
          captureException(e);
        });
        setToast(message);
        callbacksRef?.current?.[id]?.({
          type: 'failed',
          payload: {
            e: e as Error,
            file,
          },
        });
      } finally {
        setUploadingCount((pv) => --pv);
      }
    }
    callbacksRef?.current?.[id]?.({
      type: 'completed',
    });
  });

  const subscribeImageUploads = useCallback(
    (id: string, callback: (p: ImageCallbackParams) => void) => {
      callbacksRef.current = {
        ...callbacksRef.current,
        [id]: callback,
      };
      return () => {
        callbacksRef.current = {
          ...callbacksRef.current,
          [id]: undefined,
        };
      };
    },
    []
  );

  const dispatch = useCallback(
    (id: string, action: ImageAction) => {
      match(action)
        .with({ type: 'upload-image' }, (v) => uploadImages(id, v.payload.files))
        .exhaustive();
    },
    [uploadImages]
  );

  return {
    isUploading: uploadingCount > 0,
    dispatch,
    subscribeImageUploads,
  };
};

export const [UploadImageContextProvider, useUploadImageContext] = constate(useUploadImage);
