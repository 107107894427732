/**
 * @generated SignedSource<<665ac7d7142f9b567bf219a8c1e933f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticleSpec_article$data = {
  readonly region: {
    readonly displayName: string;
    readonly id: string;
  };
  readonly status: ArticleStatus;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleActionCount_article" | "ArticleBadges_article" | "getCarSpec_article" | "getPriceText_article" | "getPublishedAtText_article">;
  readonly " $fragmentType": "ArticleSpec_article";
};
export type ArticleSpec_article$key = {
  readonly " $data"?: ArticleSpec_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleSpec_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyFee",
    "storageKey": null
  }
],
v2 = {
  "kind": "InlineDataFragmentSpread",
  "name": "getPriceText_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseInfo",
      "kind": "LinkedField",
      "name": "leaseInfo",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleSpec_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getCarSpec_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "driveDistance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modelYear",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarFullName_carData",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupModel",
                  "kind": "LinkedField",
                  "name": "autoupModel",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupCompany",
                      "kind": "LinkedField",
                      "name": "company",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v2/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getPublishedAtText_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pullUpCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedAt",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleBadges_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleActionCount_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "007cb56b0e1b156b3e3c024efec3524d";

export default node;
