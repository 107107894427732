import { Avatar, AvatarGroup } from '@daangn/sprout-components-avatar';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { TradedCarsButton_query$key } from '@/__generated__/TradedCarsButton_query.graphql';
import { pressed } from '@/styles/mixins';
import { getCommaFormat } from '@/utils/number';

const icons = [
  { value: 'hyundai', icon: IconHyundai },
  { value: 'genesis', icon: IconGenesis },
  { value: 'bmw', icon: IconBmw },
];

import IconBmw from '@/assets/images/company/bmw.png';
import IconGenesis from '@/assets/images/company/genesis.png';
import IconHyundai from '@/assets/images/company/hyundai.png';
import HStack from '@/components/Base/HStack';
import { BodyM2Regular, Subtitle1Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
type Props = {
  onClick: () => void;
  queryRef: TradedCarsButton_query$key;
};

const TradedCarsButton: React.FC<Props> = ({ queryRef, onClick }) => {
  const { tradedArticleCount } = useFragment(
    graphql`
      fragment TradedCarsButton_query on Query {
        tradedArticleCount: tradedArticleCountWithin7Days
      }
    `,
    queryRef
  );

  return (
    <HStack
      align="center"
      aria-label="거래 완료된 차량"
      bg="gray100"
      br={10}
      css={pressed('gray100', 'gray200')}
      gap={6}
      justify="space-between"
      maxH={84}
      onClick={onClick}
      px={16}
      py={20}
      role="button"
      tabIndex={0}
    >
      <VStack gap={2}>
        <Subtitle1Bold>{getCommaFormat(tradedArticleCount)}대 당근 완료!</Subtitle1Bold>
        <BodyM2Regular color="gray800">지난 7일 간 직거래로 거래되었어요</BodyM2Regular>
      </VStack>
      <AvatarGroup limit={3} size="small">
        {icons.map(({ value, icon }) => (
          <Avatar alt={value} fallback={<></>} key={value} size="small" src={icon} />
        ))}
      </AvatarGroup>
    </HStack>
  );
};

export default TradedCarsButton;
