import styled, { CSSObject } from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import { CSSProperties } from 'react';
export type SeedColor =
  | keyof typeof vars.$scale.color
  | keyof typeof vars.$semantic.color
  | keyof typeof vars.$static.color;

export type Props = {
  bc?: SeedColor;
  bg?: SeedColor;
  br?: CSSProperties['borderRadius'];
  f?: CSSProperties['flex'];
  fr?: CSSProperties['flexWrap'];
  h?: CSSProperties['height'];
  ma?: number;
  maxH?: CSSProperties['maxHeight'];
  maxW?: CSSProperties['maxWidth'];
  mb?: number;
  minH?: CSSProperties['minHeight'];
  minW?: CSSProperties['minWidth'];
  ml?: number;
  mr?: number;
  mt?: number;
  mx?: number;
  my?: number;
  opacity?: CSSProperties['opacity'];
  ox?: CSSProperties['overflowX'];
  oy?: CSSProperties['overflowY'];
  pa?: number;
  pb?: number;
  pl?: number;
  pos?: CSSProperties['position'];
  pr?: number;
  pt?: number;
  px?: number;
  py?: number;
  ta?: CSSProperties['textAlign'];
  td?: CSSProperties['textDecoration'];
  w?: CSSProperties['width'];
  wb?: CSSProperties['wordBreak'];
  z?: CSSProperties['zIndex'];
};

export function getSeedColor(color: SeedColor): string {
  const merged = { ...vars.$scale.color, ...vars.$semantic.color, ...vars.$static.color };

  return merged[color];
}

function convertToRem<T>(value: T) {
  return typeof value === 'number' ? rem(value) : value;
}

const propsToStyle = ({
  pa,
  px,
  py,
  pt,
  pb,
  pr,
  pl,
  ma,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
  bg,
  w,
  pos,
  minW,
  maxW,
  h,
  minH,
  maxH,
  br,
  bc,
  f,
  ox,
  oy,
  wb,
  ta,
  td,
  z,
  fr,
  opacity,
}: Props): CSSObject[] => {
  return [
    pa ? { padding: rem(pa) } : {},
    py ? { paddingTop: rem(py), paddingBottom: rem(py) } : {},
    px ? { paddingRight: rem(px), paddingLeft: rem(px) } : {},
    pt ? { paddingTop: rem(pt) } : {},
    pb ? { paddingBottom: rem(pb) } : {},
    pr ? { paddingRight: rem(pr) } : {},
    pl ? { paddingLeft: rem(pl) } : {},
    ma ? { margin: rem(ma) } : {},
    my ? { marginTop: rem(my), marginBottom: rem(my) } : {},
    mx ? { marginRight: rem(mx), marginLeft: rem(mx) } : {},
    mt ? { marginTop: rem(mt) } : {},
    mb ? { marginBottom: rem(mb) } : {},
    mr ? { marginRight: rem(mr) } : {},
    ml ? { marginLeft: rem(ml) } : {},
    bg ? { background: getSeedColor(bg) } : {},
    pos ? { position: pos } : {},
    w ? { width: convertToRem(w) } : {},
    minW ? { minWidth: convertToRem(minW) } : {},
    maxW ? { maxWidth: convertToRem(maxW) } : {},
    h ? { height: convertToRem(h) } : {},
    minH ? { minHeight: convertToRem(minH) } : {},
    maxH ? { maxHeight: convertToRem(maxH) } : {},
    br ? { borderRadius: rem(br) } : {},
    bc ? { border: `1px solid ${getSeedColor(bc)}` } : {},
    f ? { flex: f } : {},
    ox ? { overflowX: ox } : {},
    oy ? { overflowY: oy } : {},
    wb ? { wordBreak: wb } : {},
    ta ? { textAlign: ta } : {},
    td ? { textDecoration: td } : {},
    z ? { zIndex: z } : {},
    fr ? { flexWrap: fr } : {},
    opacity ? { opacity } : {},
  ];
};

const Box = styled.div<Props>(propsToStyle);

export default Box;
