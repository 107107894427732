/**
 * @generated SignedSource<<24b42a70cb98ba1f0a8fee9b4eae0d28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getAllowedLoanList_article$data = {
  readonly loanTrackingUrl: {
    readonly hcs: string | null | undefined;
  };
  readonly " $fragmentType": "getAllowedLoanList_article";
};
export type getAllowedLoanList_article$key = {
  readonly " $data"?: getAllowedLoanList_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getAllowedLoanList_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getAllowedLoanList_article"
};

(node as any).hash = "9f6ac1ee13f9cc51878a0a8bcba47829";

export default node;
