import { josa } from '@toss/hangul';
import { ZodErrorMap, ZodIssueCode, ZodParsedType, setErrorMap, util } from 'zod';

const errorMap: ZodErrorMap = (issue, _ctx) => {
  let message: string;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined || issue.received === ZodParsedType.null) {
        message = '값을 입력해 주세요';
      } else {
        message = `${josa(issue.received, '이/가')} 아닌 ${josa(
          issue.expected,
          '이/가'
        )} 필요해요.`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `올바르지 않은 값이에요 ${JSON.stringify(
        issue.expected,
        util.jsonStringifyReplacer
      )}.`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `추가로 들어온 데이터예요: ${util.joinValues(issue.keys, ', ')}.`;
      break;
    case ZodIssueCode.invalid_union:
      message = `올바르지 않은 판별 값이에요.`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `올바르지 않은 판별 값이에요. 예상: ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `올바르지 않은 판별 값이에요. 예상: ${util.joinValues(issue.options)}, 받음: '${
        issue.received
      }'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `올바르지 않은 값으로 호출했어요.`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `함수 반환 값이 올바르지 않아요.`;
      break;
    case ZodIssueCode.invalid_date:
      message = `날짜 표기가 정확하지 않아요.`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('includes' in issue.validation) {
          message = `"${issue.validation.includes}"이 없어요.`;

          if (typeof issue.validation.position === 'number') {
            message = `${message} 위치는 ${issue.validation.position} 이상이어야 해요.`;
          }
        } else if ('startsWith' in issue.validation) {
          message = `"${issue.validation.startsWith}"로 시작해야 해요.`;
        } else if ('endsWith' in issue.validation) {
          message = `"${issue.validation.endsWith}"로 끝나야 해요.`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== 'regex') {
        message = `잘못된 ${issue.validation}이에요.`;
      } else {
        message = '잘못된 입력이에요';
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'array') {
        message = issue.inclusive
          ? `${issue.minimum}개 이상 필요해요.`
          : `${issue.minimum}개 초과 필요해요.`;
      } else if (issue.type === 'string') {
        message = `${issue.minimum}자 ${issue.inclusive ? `이상` : `초과`} 작성해 주세요.`;
      } else if (issue.type === 'number') {
        if (issue.minimum === 0 && issue.inclusive === false) {
          message = '0은 입력할 수 없어요';
        } else {
          message = `${issue.minimum} ${issue.inclusive ? `이상 이어야` : `초과해야`}해요.`;
        }
      } else if (issue.type === 'date') {
        message = `${new Date(Number(issue.minimum))}보다 ${
          issue.inclusive ? `이상이어야` : `초과해야`
        } 해요.`;
      } else {
        message = '잘못된 입력입니다';
      }
      break;
    case ZodIssueCode.too_big:
      if (issue.type === 'array') {
        message = issue.inclusive
          ? `${issue.maximum}개 이하의 항목만 들어갈 수 있어요.`
          : `${issue.maximum}개 미만의 항목만 들어갈 수 있어요.`;
      } else if (issue.type === 'string') {
        message = `${issue.maximum}자 ${issue.inclusive ? `이하로` : `미만으로`} 작성해 주세요.`;
      } else if (issue.type === 'number') {
        message = `${issue.maximum} ${issue.inclusive ? `이하여야` : `미만이어야`}해요.`;
      } else if (issue.type === 'bigint') {
        message = `${issue.maximum} ${issue.inclusive ? `이하여야` : `미만이어야`}해요.`;
      } else if (issue.type === 'date') {
        message = `${new Date(Number(issue.maximum))}보다 ${
          issue.inclusive ? `이하여야` : `미만이어야`
        } 해요.`;
      } else {
        message = '잘못된 입력입니다.';
      }
      break;
    case ZodIssueCode.custom:
      message = `잘못된 입력입니다.`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `교집합 결과를 병합할 수 없어요.`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `숫자는 ${issue.multipleOf}의 배수여야 해요.`;
      break;
    case ZodIssueCode.not_finite:
      message = '숫자는 유한해야 해요';
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};

setErrorMap(errorMap);
