import { graphql, readInlineData } from 'relay-runtime';

import { getSortedInsuranceList_article$key } from '@/__generated__/getSortedInsuranceList_article.graphql';
import { insuranceList } from '@/constants/insurance';

export const getSortedInsuranceList = (articleRef: getSortedInsuranceList_article$key) => {
  const { insuranceTrackingData } = readInlineData(
    graphql`
      fragment getSortedInsuranceList_article on Article @inline {
        insuranceTrackingData {
          samsung {
            url
            order
          }
          kb {
            url
            order
          }
          db {
            url
            order
          }
          carrot {
            url
            order
          }
        }
      }
    `,
    articleRef
  );

  const allowedInsuranceList = insuranceList
    .filter((insurance) => !!insuranceTrackingData[insurance.id])
    .map((insurance) => ({
      ...insurance,
      url: insuranceTrackingData[insurance.id]!.url,
    }));

  const sortedInsuranceList = allowedInsuranceList.sort((a, b) => {
    const aOrder = insuranceTrackingData[a.id]?.order;
    const bOrder = insuranceTrackingData[b.id]?.order;

    if (aOrder != null && bOrder != null) {
      return aOrder - bOrder;
    }

    return 0;
  });

  return sortedInsuranceList;
};
