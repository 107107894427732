import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { IconConfirmationFill } from '@seed-design/icon';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import { getSeedColor } from '@/components/Base/Box';
import { ColoredSpan } from '@/components/Base/ColoredText';
import HStack from '@/components/Base/HStack';
import {
  Label3Bold,
  Label3Regular,
  Subtitle2Bold,
  Subtitle2Regular,
  Title2Bold,
  Title3Bold,
} from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import withDefaultProps from '@/hocs/withDefaultProps';
import { useCanViewOnce } from '@/hooks/useCanViewOnce';
import BoldDot from '@/pages/ArticleProxyInspectionGuide/components/BoldDot';
import { exactSize } from '@/styles/mixins';
import { getSizeProps } from '@/utils/style';

const PROFILE_IMAGE_URL =
  'https://assetstorage.krrt.io/1030025633538651406/3eaefb44-8e2e-42d3-b5fd-6a32eb8dbc4f/width=64,height=64.png';

const NudgeWarrantyBottomSheet = () => {
  const [activate, , setActivateFalse] = useCanViewOnce('isViewNudgeWarranty', true);

  return (
    <BottomSheet
      active={activate}
      onClickClose={setActivateFalse}
      title={
        <HStack align="center" gap={6} pb={8} pt={4}>
          <IconConfirmationFill {...getSizeProps(24)} color={getSeedColor('primary')} />
          <Title2Bold>
            <ColoredSpan color="primary">진단보증 패키지</ColoredSpan> 대상 차량이에요
          </Title2Bold>
        </HStack>
      }
    >
      <VStack gap={16}>
        <VStack gap={24} px={16}>
          <VStack bg="paperContents" br={12} gap={2} justify="center" px={16} py={14} w="100%">
            <Label3Regular>
              <BoldDot /> 고장 시 <Label3Bold inline>최대 500만원</Label3Bold>의 수리비를 지원해요
            </Label3Regular>
            <Label3Regular>
              <BoldDot /> 가입 비용은 구매자가 부담해요
            </Label3Regular>
          </VStack>
          <VStack gap={24}>
            <Title3Bold>
              채팅에서 권유해 보세요
              <br />
              판매 후 분쟁을 미리 막을 수 있어요
            </Title3Bold>
            <VStack gap={16}>
              <HStack align="flex-start" gap={8}>
                <Image alt="프로필 사진" src={PROFILE_IMAGE_URL} />
                <ChatBubble>
                  <Subtitle2Regular>
                    진단은 괜찮다고 하는데
                    <br />
                    그래도 고장 생길까봐 불안하네요..
                  </Subtitle2Regular>
                </ChatBubble>
              </HStack>
              <MyChatWrapper>
                <ChatBubble bg="primary">
                  <Subtitle2Regular color="staticWhite">
                    <Subtitle2Bold color="staticWhite" inline>
                      진단보증 패키지
                    </Subtitle2Bold>{' '}
                    하시는 거 어때요?
                    <br />
                    고장나면 수리비를 지원해 준대요.
                  </Subtitle2Regular>
                </ChatBubble>
              </MyChatWrapper>
              <HStack gap={8}>
                <Image alt="프로필 사진" src={PROFILE_IMAGE_URL} />
                <ChatBubble>
                  <Subtitle2Regular>오 좋네요 감사합니다!</Subtitle2Regular>
                </ChatBubble>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <VStack pb={8} pt={12} px={16}>
          <BoxButton onClick={setActivateFalse} size="xlarge" variant="primary" width="100%">
            확인
          </BoxButton>
        </VStack>
      </VStack>
    </BottomSheet>
  );
};

const ChatBubble = withDefaultProps(VStack, {
  px: 14,
  py: 8,
  bg: 'gray100',
  br: 20,
  w: 'max-content',
});

const MyChatWrapper = styled(HStack)({
  alignSelf: 'flex-end',
});

const Image = styled.img({
  ...exactSize('both', 32),
});

export default NudgeWarrantyBottomSheet;
