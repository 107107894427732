import { Tab, TabList, TabPanel, TabPanelGroup, Tabs } from '@daangn/sprout-components-tabs';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleChartSection_article$key } from '@/__generated__/ArticleChartSection_article.graphql';
import useTrack from '@/hooks/useTrack';

import ArticleChart from './ArticleChart';
import { ChartPeriod } from './types';

type Props = {
  articleRef: ArticleChartSection_article$key;
};

const ArticleChartSection: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  ...props
}) => {
  const { track } = useTrack();
  const [selectedPeriod, setSelectedPeriod] = useState<ChartPeriod>('24h');
  const article = useFragment(
    graphql`
      fragment ArticleChartSection_article on Article {
        id
        ...ArticleChart_article
      }
    `,
    articleRef
  );

  return (
    <Container {...props}>
      <Tabs
        isLazy
        isSwipeable={false}
        layout="fill"
        onChange={(key: null | string) => {
          if (key) {
            setSelectedPeriod(key as ChartPeriod);
            track('article_click_chart_tab', { period: key });
          }
        }}
        value={selectedPeriod}
      >
        <TabList>
          <Tab value="24h">24시간</Tab>
          <Tab value="all">7일</Tab>
        </TabList>
        <TabPanelGroup>
          <TabPanel value="24h">
            <ArticleChart articleRef={article} period={'24h'} />
          </TabPanel>
          <TabPanel value="all">
            <ArticleChart articleRef={article} period={'all'} />
          </TabPanel>
        </TabPanelGroup>
      </Tabs>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default ArticleChartSection;
