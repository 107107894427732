/**
 * @generated SignedSource<<38d16dcfe437a0a7a7649ea2d6e7d574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getPriceText_article$data = {
  readonly id: string;
  readonly leaseInfo: {
    readonly monthlyFee: number;
  } | null | undefined;
  readonly price: number | null | undefined;
  readonly rentalInfo: {
    readonly monthlyFee: number;
  } | null | undefined;
  readonly " $fragmentType": "getPriceText_article";
};
export type getPriceText_article$key = {
  readonly " $data"?: getPriceText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getPriceText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getPriceText_article"
};

(node as any).hash = "40a43f50a19af5e554320c5e596b5d3f";

export default node;
