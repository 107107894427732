import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React, { memo } from 'react';

import VStack from '@/components/Base/VStack';
import SkeletonVerticalCArticleCard from '@/components/Skeleton/SkeletonVerticalCArticleCard';

const SkeletonPageInspectedArticles: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ArticlesContainer>
      <VStack gap={12} px={8} py={16}>
        {[...Array(20)].map((_, i: number) => (
          <SkeletonVerticalCArticleCard key={i} />
        ))}
      </VStack>
    </ArticlesContainer>
  );
};

export default memo(SkeletonPageInspectedArticles);

const ArticlesContainer = styled.div`
  background-color: ${vars.$semantic.color.paperDefault};
`;
