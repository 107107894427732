import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { FetchPolicy, useLazyLoadQuery } from 'react-relay';

export const useRefetchQuery = (fetchPolicy: FetchPolicy = 'store-or-network') => {
  const [queryOptions, setQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2] | undefined
  >({ fetchPolicy });

  const refetch = useCallback(
    (currentFetchPolicy?: FetchPolicy) => {
      setQueryOptions((prev) => ({
        fetchKey: ((prev?.fetchKey as number) || 0) + 1,
        fetchPolicy: currentFetchPolicy || fetchPolicy,
      }));
    },
    [fetchPolicy]
  );

  return useMemo(() => ({ refetch, queryOptions }), [queryOptions, refetch]);
};
type QueryOption = Parameters<typeof useLazyLoadQuery>[2];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const refetchQueryOptionAtomFamily = atomFamily((_: string) =>
  atom({ fetchPolicy: 'store-or-network' as FetchPolicy, count: 0 })
);

export const useInvalidateQueryOption = (
  fetchKey:
    | 'article_safe_trade'
    | 'inspections_for_review'
    | 'my_inspections'
    | 'my_time_table'
    | 'register_warranty'
): QueryOption => {
  const { fetchPolicy, count } = useAtomValue(refetchQueryOptionAtomFamily(fetchKey));
  return {
    fetchPolicy,
    fetchKey: `${fetchKey}-${count}`,
  };
};

export const useInvalidateQuery = (
  fetchKey:
    | 'article_safe_trade'
    | 'inspections_for_review'
    | 'my_inspections'
    | 'my_time_table'
    | 'register_warranty'
) => {
  const setter = useSetAtom(refetchQueryOptionAtomFamily(fetchKey));
  const [isPending, startTransition] = useTransition();
  type Dispose = () => void;
  const disposeRef = useRef(null) as MutableRefObject<Dispose | null>;

  const invalidate = useCallback(
    (fetchPolicy?: FetchPolicy, dispose?: Dispose) => {
      startTransition(() => {
        setter((pv) => ({
          fetchPolicy: fetchPolicy ?? pv.fetchPolicy,
          count: pv.count + 1,
        }));
        disposeRef.current = dispose ?? null;
      });
    },
    [setter]
  );

  useEffect(() => {
    if (isPending === false && disposeRef.current) {
      disposeRef.current();
      disposeRef.current = null;
    }
  }, [isPending]);

  return [invalidate, isPending] as const;
};
