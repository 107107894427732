import { atom } from 'jotai';
import { atomWithRefresh } from 'jotai/utils';

import { getRegion, getUser } from '@/sdk/bridge/info';

export const userState = atomWithRefresh(async () => {
  const user = await getUser();
  return user;
});

export const regionState = atom(async () => {
  const region = await getRegion();
  return region;
});
