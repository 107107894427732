/**
 * @generated SignedSource<<41669d581ce1bbe12a4aca022ed1236c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getWarrantyDeadline_inspection$data = {
  readonly fixedDate: any | null | undefined;
  readonly " $fragmentType": "getWarrantyDeadline_inspection";
};
export type getWarrantyDeadline_inspection$key = {
  readonly " $data"?: getWarrantyDeadline_inspection$data;
  readonly " $fragmentSpreads": FragmentRefs<"getWarrantyDeadline_inspection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getWarrantyDeadline_inspection"
};

(node as any).hash = "74c9c5f61c8b45c972e2b4ded8ba398b";

export default node;
