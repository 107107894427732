import React, { PropsWithChildren } from 'react';

import VStack, { Props as VStackProps } from './VStack';

type Props = Omit<VStackProps, 'align' | 'justify'>;

const Center: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return (
    <VStack align="center" h="100%" justify="center" {...props}>
      {children}
    </VStack>
  );
};

export default Center;
