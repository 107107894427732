import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';

import Box from '@/components/Base/Box';
import HScroll from '@/components/Base/HScroll';
import VStack from '@/components/Base/VStack';
import SkeletonVerticalArticleCard from '@/components/Skeleton/SkeletonVerticalArticleCard';
import SkeletonVerticalCArticleCard from '@/components/Skeleton/SkeletonVerticalCArticleCard';

import Placeholder from './Placeholder';

const SkeletonPageMain: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BoxPaper data-testid="page-loading">
      <Placeholder br={6} h={36} mb={6} w="100%" />
      <VStack gap={48} mx={-8}>
        <HScroll gap={8} outsideGap={8}>
          <Box f="1 0 auto" w={312}>
            <SkeletonVerticalCArticleCard />
          </Box>
          <Box f="1 0 auto" w={312}>
            <SkeletonVerticalCArticleCard />
          </Box>
        </HScroll>
        <HScroll gap={8} outsideGap={8}>
          <Box f="1 0 auto" w={156}>
            <SkeletonVerticalArticleCard />
          </Box>
          <Box f="1 0 auto" w={156}>
            <SkeletonVerticalArticleCard />
          </Box>
        </HScroll>
      </VStack>
    </BoxPaper>
  );
};

export default SkeletonPageMain;

const BoxPaper = styled.div`
  padding: ${rem(4)} ${rem(16)} ${rem(16)};
  background: ${vars.$semantic.color.paperDefault};
`;
