/**
 * @generated SignedSource<<e3fafc9e1e9c289be550e86222fefc8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getPublishedAtText_article$data = {
  readonly publishedAt: any;
  readonly pullUpCount: number;
  readonly " $fragmentType": "getPublishedAtText_article";
};
export type getPublishedAtText_article$key = {
  readonly " $data"?: getPublishedAtText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getPublishedAtText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getPublishedAtText_article"
};

(node as any).hash = "07b233a003650bb4826f2c693c89543a";

export default node;
