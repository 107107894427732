import { StackflowPlugin } from '@stackflow/core';

import { karrotBridge } from '@/sdk/bridge';

import { getActiveActivity } from './utils';

const popPlugin = (): StackflowPlugin => {
  return () => ({
    key: 'plugin-pop',
    onBeforePop({ actions }) {
      const stack = actions.getStack();
      const activeActivity = getActiveActivity(stack);

      if (activeActivity?.isRoot) {
        actions.preventDefault();
        karrotBridge.closeRouter({});
      }
    },
  });
};

export default popPlugin;
