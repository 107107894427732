import { type SeedSnackbarType, useSnackbarAdapter } from '@daangn/sprout-components-snackbar';
import { useCallback, useRef } from 'react';

type ToastOption = {
  actionLabel?: string;
  message: React.ReactNode;
  type?: SeedSnackbarType;
};

export const useToast = () => {
  const answer = useRef<(value: PromiseLike<boolean> | boolean) => undefined | void>();
  const snackbarAdapter = useSnackbarAdapter();

  const handleClickAction = useCallback(() => {
    answer.current?.(true);
  }, []);
  const handleClose = useCallback(() => {
    answer.current?.(false);
  }, []);
  const setToast = useCallback(
    (nextToastOption: ToastOption | string) => {
      const partialOptions =
        typeof nextToastOption === 'string'
          ? ({ message: nextToastOption, type: 'default' } as const)
          : ({
              type: nextToastOption.type ?? 'default',
              actionLabel: nextToastOption.actionLabel,
              message: () => nextToastOption.message,
            } as const);
      snackbarAdapter.create({
        timeout: 3000,
        onAction: handleClickAction,
        shouldCloseOnAction: true,
        onClose: handleClose,
        ...partialOptions,
      });
      return new Promise<boolean>((resolve) => {
        answer.current = resolve;
      });
    },
    [handleClickAction, handleClose, snackbarAdapter]
  );
  return { setToast };
};
