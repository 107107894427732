import { objectEntries } from '@toss/utils';

import type { FullReport } from '@/utils/report/getFullReport';

export enum AccidentType {
  Accident = 'ACCIDENT',
  NoAccident = 'NO_ACCIDENT',
  AbsoluteNoAccident = 'ABSOLUTE_NO_ACCIDENT',
}

export const summaryAccidentHistory = (
  accidentHistory: FullReport['accidentHistory'],
  carHistoryExpenseTotal: number
) => {
  const panelWeldingsCount = Object.values(accidentHistory.externalPanel).filter(
    (v) => v === 'Welding'
  ).length;
  const panelBendingsCount = Object.values(accidentHistory.externalPanel).filter(
    (v) => v === 'Bending'
  ).length;
  const panelReplacementCount = Object.values(accidentHistory.externalPanel).filter(
    (v) => v === 'Replacement'
  ).length;
  const hasPanelWelding = panelWeldingsCount > 0;
  const hasPanelBending = panelBendingsCount > 0;
  const hasPanelReplacement = panelReplacementCount > 0;
  const hasCriticalPanelReplacement = objectEntries(accidentHistory.externalPanel).some(
    ([k, v]) => {
      const isCriticalPanel =
        k === 'leftQuarterPanel' ||
        k === 'rightQuarterPanel' ||
        k === 'roofPanel' ||
        k === 'leftSideSealPanel' ||
        k === 'rightSideSealPanel';
      return isCriticalPanel && v === 'Replacement';
    }
  );
  const hasPanelIssue = hasPanelWelding || hasCriticalPanelReplacement;

  const frameIssueCount = Object.values(accidentHistory.mainFrame).filter(
    (v) => v === 'Welding' || v === 'Replacement'
  ).length;
  const hasFrameIssue = frameIssueCount > 0;
  const issueCount = {
    panelIssueCount: panelWeldingsCount + panelBendingsCount + panelReplacementCount,
    frameIssueCount,
  };

  if (hasFrameIssue && hasPanelIssue) {
    return {
      ...issueCount,
      accidentType: AccidentType.Accident,
      message: '주요외판과 주요골격에 수리 이력이 있는 사고 차량이에요.',
    };
  }
  if (hasFrameIssue) {
    return {
      ...issueCount,
      accidentType: AccidentType.Accident,
      message: '주요골격에 수리 이력이 있는 사고 차량이에요.',
    };
  }
  if (hasPanelIssue) {
    return {
      ...issueCount,
      accidentType: AccidentType.Accident,
      message: '주요외판에 수리 이력이 있는 사고 차량이에요.',
    };
  }
  if (hasPanelReplacement || hasPanelBending || carHistoryExpenseTotal > 0) {
    return {
      ...issueCount,
      accidentType: AccidentType.NoAccident,
      message: '무사고 차량이에요.',
    };
  }
  return {
    ...issueCount,
    accidentType: AccidentType.AbsoluteNoAccident,
    message: '사고 이력 없는 완전 무사고 차량이에요.',
  };
};
