import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { P, match } from 'ts-pattern';

import { VerticalCArticleCard_article$key } from '@/__generated__/VerticalCArticleCard_article.graphql';
import defaultThumbnail from '@/assets/images/empty_img_car.png';
import ArticleThumbnailBadges from '@/components/Article/ArticleThumbnailBadges';
import AspectRatio from '@/components/Base/AspectRatio';
import LowScaleImage from '@/components/Base/OptimizedImage/LowScaleImage';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import { fullCoverImageCss, thumbnailBorderCss } from '@/components/Base/OptimizedImage/utils';
import Spacer from '@/components/Base/Spacer';
import VStack from '@/components/Base/VStack';
import LikeButton from '@/components/LikeButton';
import { useFlow } from '@/stackflow';
import { pressed } from '@/styles/mixins';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getMergedImages } from '@/utils/article/getMergedImages';

import ArticleSpec from './ArticleSpec';

type Props = {
  articleRef: VerticalCArticleCard_article$key;
  onClick?: (data: ArticleEventProperties) => void;
};

const VerticalCArticleCard: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  onClick,
  ...props
}) => {
  const { push } = useFlow();
  const article = useFragment(
    graphql`
      fragment VerticalCArticleCard_article on Article {
        id
        ...ArticleSpec_article
        ...getMergedImages_article
        ...ArticleActionCount_article
        ...getArticleEventProperties_article
        ...LikeButton_article
        ...ArticleThumbnailBadges_article
      }
    `,
    articleRef
  );
  const { id } = article;
  const mergedImages = getMergedImages(article);
  const [exteriorImages] = [mergedImages.filter((v) => v.type === 'EXTERIOR')];
  const data = getArticleEventProperties(article);

  const handleClick = () => {
    push('article', { articleId: id });
    onClick?.(data);
  };

  const [firstImage, secondImage, thirdImage] = match(exteriorImages)
    .with(
      P.when((v) => v.length > 3),
      (v) => [v[0].url, v[2].url, v[3].url]
    )
    .otherwise((v) => [
      v[0]?.url ?? defaultThumbnail,
      v[1]?.url ?? defaultThumbnail,
      v[2]?.url ?? defaultThumbnail,
    ]);

  return (
    <Container br={10} pa={8} pos="relative" {...props} onClick={handleClick}>
      <AspectRatio css={thumbnailBorderCss} ratio={'16/9'}>
        <ImageContainer>
          <ImageItem>
            <LowScaleImage css={fullCoverImageCss} src={firstImage} />
            <NormalImage css={fullCoverImageCss} src={firstImage} />
          </ImageItem>
          <ImageItem>
            <LowScaleImage css={fullCoverImageCss} src={secondImage} />
            <NormalImage css={fullCoverImageCss} src={secondImage} />
          </ImageItem>
          <ImageItem>
            <LowScaleImage css={fullCoverImageCss} src={thirdImage} />
            <NormalImage css={fullCoverImageCss} src={thirdImage} />
          </ImageItem>
        </ImageContainer>
        <ArticleThumbnailBadges articleRef={article} />
        <ArticleLikeButton articleRef={article} pos="absolute" />
      </AspectRatio>
      <Spacer axis="vertical" size={12} />
      <ArticleSpec
        articleRef={article}
        badgeSize="medium"
        priceTextVariant="label2Regular"
        titleEllipsis={1}
        titleVariant="label3Regular"
        withActionCount={true}
      />
    </Container>
  );
};

const Container = styled(VStack)`
  ${pressed()}
`;

const ImageContainer = styled.div([
  {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '5fr 4fr',
    gridGap: rem(1),
    position: 'relative',
  },
  fullCoverImageCss,
]);

const ImageItem = styled.div({
  position: 'relative',
  '&:first-of-type': {
    gridRow: 'span 2',
  },
});

const ArticleLikeButton = styled(LikeButton)({
  right: 0,
  top: 0,
});

export default memo(VerticalCArticleCard);
