import { vars } from '@seed-design/design-token';
import React from 'react';
import { Text } from 'recharts';

type Props = {
  highlighted?: boolean;
  text: string;
} & any;

const CustomizedAxisTick: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  highlighted,
  ...props
}) => {
  if (!text) {
    return <></>;
  }

  return (
    <Text
      {...props}
      style={{
        fill: highlighted ? vars.$scale.color.gray900 : vars.$scale.color.gray500,
        fontWeight: highlighted ? 'bold' : 'normal',
      }}
    >
      {text}
    </Text>
  );
};

export default CustomizedAxisTick;
