import { withErrorBoundary } from '@sentry/react';
import React from 'react';

import ActivityErrorScreen from '@/components/Error/ActivityErrorScreen';

const withActivityErrorScreen = <Props extends Record<string, unknown>>(
  Component: React.ComponentType<React.PropsWithChildren<Props>>
) =>
  withErrorBoundary(Component, {
    beforeCapture: (scope) => {
      scope.setLevel('fatal');
    },
    fallback: ({ error }) => <ActivityErrorScreen error={error} />,
  });

export default withActivityErrorScreen;
