import styled from '@emotion/styled';
import React from 'react';

import FilterLayer from '@/components/Filter/FilterLayer';
import useCheckVisited from '@/hooks/useCheckVisited';
import { MainContextProvider } from '@/pages/Main/MainContext';

import MainHelmet from './components/MainHelmet';
import MainQueryContainer from './components/MainQueryContainer';

const PageMain: React.FC<React.PropsWithChildren<unknown>> = () => {
  useCheckVisited();

  return (
    <MainContextProvider>
      <PageContainer>
        <MainHelmet />
        <MainQueryContainer />
      </PageContainer>
      <FilterLayer />
    </MainContextProvider>
  );
};

const PageContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export default PageMain;
