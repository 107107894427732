/**
 * @generated SignedSource<<7d1765fbca46729441d2aa26a708c6d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarHistoryStatus = "EXPIRED" | "NOT_REQUESTED" | "OWNED" | "REQUESTED" | "UNABLE_TO_FETCH";
export type useGetCarHistoryQuery$variables = {
  id: string;
};
export type useGetCarHistoryQuery$data = {
  readonly article: {
    readonly article: {
      readonly carData: {
        readonly carHistory: {
          readonly collectedAt: any;
          readonly " $fragmentSpreads": FragmentRefs<"AccidentSection_carHistory" | "CarDamageSection_carHistory" | "CarNumberChangeSection_carHistory" | "InsuranceSection_carHistory" | "OwnerChangeSection_carHistory" | "SpecialUseSection_carHistory">;
        } | null | undefined;
        readonly carHistoryStatus: CarHistoryStatus;
      };
    } | null | undefined;
  };
};
export type useGetCarHistoryQuery = {
  response: useGetCarHistoryQuery$data;
  variables: useGetCarHistoryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carHistoryStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGetCarHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArticleResponse",
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "article",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CarData",
                "kind": "LinkedField",
                "name": "carData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CarHistory",
                    "kind": "LinkedField",
                    "name": "carHistory",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "InsuranceSection_carHistory"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AccidentSection_carHistory"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CarDamageSection_carHistory"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpecialUseSection_carHistory"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OwnerChangeSection_carHistory"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CarNumberChangeSection_carHistory"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGetCarHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArticleResponse",
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "article",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CarData",
                "kind": "LinkedField",
                "name": "carData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CarHistory",
                    "kind": "LinkedField",
                    "name": "carHistory",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CarHistoryDateSpan",
                        "kind": "LinkedField",
                        "name": "uninsured",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "from",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "to",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uninsuredTotalYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uninsuredTotalMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOfTotalAccidents",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOfTheftAccidents",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOfSubmergedAccidents",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CarHistoryAccident",
                        "kind": "LinkedField",
                        "name": "accidents",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accidentAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expenseEstimate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "claim",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expensePart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expenseWage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expensePainting",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reserve",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "repaired",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gov",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "generalBusiness",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rent",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CarHistoryChange",
                        "kind": "LinkedField",
                        "name": "changes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "carNo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd6b20bdccd94acfc0540bb8be181533",
    "id": null,
    "metadata": {},
    "name": "useGetCarHistoryQuery",
    "operationKind": "query",
    "text": "query useGetCarHistoryQuery(\n  $id: ID!\n) {\n  article(id: $id) {\n    article {\n      carData {\n        carHistoryStatus\n        carHistory {\n          collectedAt\n          ...InsuranceSection_carHistory\n          ...AccidentSection_carHistory\n          ...CarDamageSection_carHistory\n          ...SpecialUseSection_carHistory\n          ...OwnerChangeSection_carHistory\n          ...CarNumberChangeSection_carHistory\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AccidentSection_carHistory on CarHistory {\n  numOfTotalAccidents\n  numOfTheftAccidents\n  numOfSubmergedAccidents\n}\n\nfragment CarDamageSection_carHistory on CarHistory {\n  ...carHistory_summaryCarHistoryAccidents\n}\n\nfragment CarNumberChangeSection_carHistory on CarHistory {\n  changes {\n    type\n    date\n    carNo\n  }\n}\n\nfragment InsuranceSection_carHistory on CarHistory {\n  uninsured {\n    from\n    to\n  }\n  uninsuredTotalYear\n  uninsuredTotalMonth\n}\n\nfragment OwnerChangeSection_carHistory on CarHistory {\n  ...carHistory_summaryCarHistoryOwnerChanges\n}\n\nfragment SpecialUseSection_carHistory on CarHistory {\n  gov\n  generalBusiness\n  rent\n}\n\nfragment carHistory_summaryCarHistoryAccident on CarHistoryAccident {\n  accidentAt\n  expensePart\n  expenseWage\n  expensePainting\n  expenseEstimate\n  claim\n  reserve\n  repaired\n}\n\nfragment carHistory_summaryCarHistoryAccidents on CarHistory {\n  accidents {\n    accidentAt\n    expenseEstimate\n    claim\n    type\n    ...carHistory_summaryCarHistoryAccident\n  }\n}\n\nfragment carHistory_summaryCarHistoryOwnerChanges on CarHistory {\n  changes {\n    date\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "98fd0c8aa80606d59c5146495a0ef285";

export default node;
