/**
 * @generated SignedSource<<1e8a1ae6130b206f5968cfa9df123e32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Manufacturer = "ACURA" | "ASTON_MARTIN" | "AUDI" | "BAIC" | "BENTLEY" | "BENZ" | "BMW" | "CADILLAC" | "CAMMSYS" | "CHEVROLET" | "CHEVROLET_GM_DAEWOO" | "CHRYSLER" | "CITROEN" | "CYAN" | "DAECHANG_MOTORS" | "DAEWOO_BUS" | "DFSK" | "DODGE" | "DS" | "ETC" | "FERRARI" | "FIAT" | "FORD" | "FOTON" | "FOX" | "GENESIS" | "GMC" | "HONDA" | "HUMMER" | "HYUNDAI" | "INFINITI" | "ISUZU" | "JAGUAR" | "JEEP" | "KIA" | "LAMBORGHINI" | "LAND_ROVER" | "LEXUS" | "LINCOLN" | "LOTUS" | "MAIV" | "MASERATI" | "MAYBACH" | "MAZDA" | "MC_LAREN" | "MINI" | "MITSUBISHI" | "NISSAN" | "OULLIM" | "PEUGEOT" | "PORSCHE" | "POWER_PLAZA" | "RENAULT_SAMSUNG" | "ROLLS" | "SAAB" | "SMART" | "SSANGYOUNG" | "SUBARU" | "SUZUKI" | "TESLA" | "TOYOTA" | "VOLKSWAGEN" | "VOLVO" | "ZHIDOU";
import { FragmentRefs } from "relay-runtime";
export type carData_assembleCarName_carData$data = {
  readonly autoupModel: {
    readonly company: {
      readonly name: string;
    };
    readonly grade: {
      readonly name: string;
    } | null | undefined;
    readonly series: {
      readonly name: string;
    } | null | undefined;
    readonly subgrade: {
      readonly name: string;
    } | null | undefined;
    readonly subseries: {
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly carName: string;
  readonly manufacturer: Manufacturer;
  readonly " $fragmentType": "carData_assembleCarName_carData";
};
export type carData_assembleCarName_carData$key = {
  readonly " $data"?: carData_assembleCarName_carData$data;
  readonly " $fragmentSpreads": FragmentRefs<"carData_assembleCarName_carData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carData_assembleCarName_carData"
};

(node as any).hash = "60e9a512c7148d231bf390385b1d38de";

export default node;
