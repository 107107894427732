export const nonNullish = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

type NonNullishTuple<T> = { [K in keyof T]-?: NonNullable<T[K]> };

export function nonNullishTuple<T extends any[]>(values: T): values is NonNullishTuple<T> {
  const everyValueExists = values.every((value) => value !== undefined && value !== null);
  return everyValueExists;
}
