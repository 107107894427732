/**
 * @generated SignedSource<<551c5effd53c467821cb9cde3b04783d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type carHistory_getCarHistoriesExpenseTotalAmount$data = {
  readonly accidents: ReadonlyArray<{
    readonly claim: number;
    readonly expenseEstimate: number;
  }>;
  readonly " $fragmentType": "carHistory_getCarHistoriesExpenseTotalAmount";
};
export type carHistory_getCarHistoriesExpenseTotalAmount$key = {
  readonly " $data"?: carHistory_getCarHistoriesExpenseTotalAmount$data;
  readonly " $fragmentSpreads": FragmentRefs<"carHistory_getCarHistoriesExpenseTotalAmount">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carHistory_getCarHistoriesExpenseTotalAmount"
};

(node as any).hash = "c8e4ab940cff655732ab54a307ca3fa2";

export default node;
