import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { useToast } from '@/hooks/useToast';
import { regionState, userState } from '@/store/user';

const useUser = () => {
  const user = useAtomValue(userState);
  const region = useAtomValue(regionState);
  const { setToast } = useToast();
  const isLogin = useMemo(() => !!user?.id, [user]);
  const isCheckedIn = useMemo(() => Number(user?.displayRegionCheckinsCount ?? 0) > 0, [user]);

  const checkLogin = useCallback(() => {
    if (!isLogin) {
      setToast('로그인 후에 이용 가능해요.');
    }
    return isLogin;
  }, [isLogin, setToast]);

  return { user, region, isLogin, isCheckedIn, checkLogin };
};

export default useUser;
