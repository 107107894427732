import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import type { CreateNewArticleNotificationConditionV2Input } from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';

import { CreateNotificationRenderer_query$key } from '@/__generated__/CreateNotificationRenderer_query.graphql';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';
import useCreateNotification from '@/hooks/useCreateNotification';
import { isSameNotificationCondition } from '@/utils/notification';

type CreateNotificationRendererState = {
  createNotification: () => Promise<void>;
  enabled: boolean;
  hasNoDuplicatedNotification: boolean;
  isInFlight: boolean;
  isNotReachedNotificationLimit: boolean;
  isSelectedConditionAvailable: boolean;
};

export type CreateNotificationRendererProps = {
  queryRef: CreateNotificationRenderer_query$key;
  render: (state: CreateNotificationRendererState) => ReactNode;
  selectedCondition: CreateNewArticleNotificationConditionV2Input;
};

const CreateNotificationRenderer: React.FC<
  React.PropsWithChildren<CreateNotificationRendererProps>
> = ({ queryRef, selectedCondition, render }) => {
  const { conditions } = useFragment(
    graphql`
      fragment CreateNotificationRenderer_query on Query {
        conditions: newArticleNotificationConditions {
          id
          ...notification_newArticleNotificationCondition
        }
      }
    `,
    queryRef
  );
  const { handleClickCreate, isInFlight } = useCreateNotification();

  const isSelectedConditionAvailable = Object.values(selectedCondition).some((value) => !!value);
  const hasNoDuplicatedNotification =
    !conditions ||
    !conditions.some((condition) => isSameNotificationCondition(condition, selectedCondition));
  const isNotReachedNotificationLimit = (conditions?.length ?? 0) < MAX_NOTIFICATION_COUNT;
  const enabled =
    isSelectedConditionAvailable && hasNoDuplicatedNotification && isNotReachedNotificationLimit;

  const createNotification = async () => {
    await handleClickCreate(selectedCondition);
  };

  return render({
    createNotification,
    isInFlight,
    hasNoDuplicatedNotification,
    isSelectedConditionAvailable,
    isNotReachedNotificationLimit,
    enabled,
  });
};

export default CreateNotificationRenderer;
