import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

type Props = {
  width?: number;
} & PropsOf<typeof ReactTooltip>;

const Tooltip: React.FC<Props> = ({ children, width, ...props }) => {
  return (
    <Wrapper>
      <ReactTooltip events={['click']} {...props} style={getWrapperStyle({ width })}>
        {children}
      </ReactTooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const getWrapperStyle = ({ width }: { width?: number }) => ({
  width: width ? rem(width) : 'max-content',
  backgroundColor: vars.$scale.color.gray900,
  padding: `${rem(8)} ${rem(12)}`,
  borderRadius: rem(6),
  color: vars.$scale.color.gray00,
  '--rt-opacity': 1,
  zIndex: 1000,
  ...vars.$semantic.typography.caption1Bold,
});

export default Tooltip;
