import { Divider } from '@daangn/sprout-components-divider';
import { IconConfirmationFill, IconInfoRegular } from '@seed-design/icon';
import { graphql, useFragment } from 'react-relay';
import { match } from 'ts-pattern';

import { ArticleMetaInfoSection_article$key } from '@/__generated__/ArticleMetaInfoSection_article.graphql';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { Label3Bold, Label3Regular } from '@/components/Base/Text';
import Tooltip from '@/components/Base/Tooltip';
import VStack from '@/components/Base/VStack';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import { getCarHistoriesExpenseTotalAmount } from '@/utils/carHistory';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: ArticleMetaInfoSection_article$key;
};

const ArticleMetaInfoSection: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment ArticleMetaInfoSection_article on Article {
        user {
          selfVerified
        }
        ownerNameVerified
        carData {
          accidentType
          carHistory {
            ...carHistory_getCarHistoriesExpenseTotalAmount
          }
        }
        ...useIsWarrantable_article
      }
    `,
    articleRef
  );
  const { user, ownerNameVerified, carData } = article;
  const { count } = getCarHistoriesExpenseTotalAmount(carData.carHistory);
  const { isWarrantable } = useIsWarrantable(article);

  return (
    <>
      <VStack divider={<Divider />} gap={28}>
        {(user.selfVerified || ownerNameVerified) && (
          <HStack>
            <Label3Bold color="gray600" w={50}>
              인증
            </Label3Bold>
            <Label3Regular data-tooltip-id="verified_explanation">
              <HStack align="center" gap={2}>
                <span>{ownerNameVerified ? '소유주 인증' : '본인인증'}</span>
                <IconInfoRegular {...getSizeProps(14)} />
              </HStack>
            </Label3Regular>
          </HStack>
        )}
        {carData.accidentType ? (
          <HStack>
            <Label3Bold color="gray600" w={50}>
              진단
            </Label3Bold>
            <Label3Regular>
              {match(carData.accidentType)
                .with('ABSOLUTE_NO_ACCIDENT', () => '완전 무사고')
                .with('NO_ACCIDENT', () => '무사고')
                .with('ACCIDENT', () => '사고')
                .otherwise(() => '')}
            </Label3Regular>
          </HStack>
        ) : (
          <HStack>
            <Label3Bold color="gray600" w={50}>
              보험
            </Label3Bold>
            <Label3Regular>사고이력 {count > 0 ? `${count}건` : '없음'}</Label3Regular>
          </HStack>
        )}
        {isWarrantable && (
          <HStack>
            <Label3Bold color="gray600" w={50}>
              포함
            </Label3Bold>
            <Label3Bold>
              <HStack align="center" gap={2}>
                <IconConfirmationFill color={getSeedColor('primary')} {...getSizeProps(14)} />
                <span>진단보증 패키지</span>
              </HStack>
            </Label3Bold>
          </HStack>
        )}
      </VStack>
      <Tooltip id="verified_explanation" place="bottom" width={ownerNameVerified ? 215 : 191}>
        {ownerNameVerified
          ? '차량 소유주와 글 작성자가 일치해요'
          : '당근 중고차는 안전한 직거래를 위해 판매자 본인인증을 하고 있어요'}
      </Tooltip>
    </>
  );
};

export default ArticleMetaInfoSection;
