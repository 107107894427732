import { formatDistanceStrict } from 'date-fns';

export const formatMonthDistance = (end: Date, start: Date) => {
  try {
    return Number(
      formatDistanceStrict(end, start, {
        unit: 'month',
        roundingMethod: 'floor',
      }).replace(/ month(s)?/, '')
    );
  } catch (e) {
    return 0;
  }
};
