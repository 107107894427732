import { SeedSpinnerProps, Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  variant?: SeedSpinnerProps['variant'];
};

const GlobalLoading: React.FC<React.PropsWithChildren<Props>> = ({ variant = 'gray' }) => {
  return (
    <Container data-testid="page-loading">
      <Spinner variant={variant} />
    </Container>
  );
};

export default GlobalLoading;

const Container = styled.div`
  position: absolute;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
