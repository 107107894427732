import { useEffectOnce } from '@/hooks/useEffectOnce';
import { useFlow } from '@/stackflow';
import { useCamelCaseActivityParams } from '@/utils/url';

const StackOnArticle = () => {
  const { push } = useFlow();
  const { articleId, action } = useCamelCaseActivityParams<'article'>();

  useEffectOnce(() => {
    if (
      action === 'article_lease_rental' ||
      action === 'article_car_history' ||
      action === 'article_options_list' ||
      action === 'pullup_article'
    ) {
      push(action, { articleId });
    }
  });

  return null;
};

export default StackOnArticle;
