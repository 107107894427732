import { useActivity } from '@stackflow/react';
import { useMemo } from 'react';

import { useEventCallback } from '@/hooks/useEventCallback';
import { ActivityName, useStepFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';
type Params = {
  overlay?: string;
};

export const useOverlayState = (id: string) => {
  const activity = useActivity();
  const { overlay, ...params } = useCamelCaseParams<Params>();
  const activeIds = useMemo(() => {
    return overlay?.split(',') ?? [];
  }, [overlay]);
  const value = useMemo(
    () => activeIds.includes(id) && activity.isActive,
    [activeIds, id, activity.isActive]
  );
  const { stepPush, stepPop } = useStepFlow(activity?.name as ActivityName);
  const setTrue = useEventCallback(() => {
    if (!id) {
      return undefined;
    }
    if (!value) {
      stepPush({ ...params, overlay: [...activeIds, id].join(',') });
    }
  });
  const setFalse = useEventCallback(() => {
    if (!id) {
      return undefined;
    }
    if (value) {
      stepPop();
    }
  });
  return [value, setTrue, setFalse] as const;
};
