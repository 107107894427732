import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { differenceInHours } from 'date-fns';
import React from 'react';
import {
  CartesianGrid,
  Dot,
  LabelList,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { formatFloat, isInRange, sum } from '@/utils/number';

import Base from './Base';
import CustomizedAxisTick from './CustomizedAxisTick';
import PromotionEstimationLabel from './PromotionEstimationLabel';
import PromotionLabel from './PromotionLabel';
import { EventItem, ExtendedEventItem, StatisticsItem } from './types';
import { MILLISECOND_DAY, getAdjustedTicks, getDiff, getSummaryText, getTicks } from './utils';

type Props = {
  estimations: StatisticsItem[];
  events: EventItem[];
  expectedIncrease: number;
  futureStatistics: StatisticsItem[];
  statistics: StatisticsItem[];
};

const DAILY_STATS_COUNT = 24;

const ArticleOneDayChart: React.FC<React.PropsWithChildren<Props>> = ({
  statistics,
  futureStatistics,
  estimations,
  expectedIncrease,
  events,
}) => {
  const totalCount = Math.max(
    getDiff(statistics) + (statistics.length >= DAILY_STATS_COUNT ? 0 : statistics[0]?.view ?? 0),
    0
  );
  const lastCountedHour = [...statistics].reverse().find((stat) => stat.view > 0);
  const countsInDayByHour = estimations.map((estimation, idx) => {
    const date = new Date(estimation.t);
    const now = new Date();
    const diff = differenceInHours(now, date);
    const isNow = diff === 0 && now.getTime() >= estimation.t;

    const getLabel = () => {
      switch (true) {
        // 겹쳐 보이지 않기 위한 통계의 최소 갯수
        case idx === 0 && statistics.length >= 5:
          return `${diff}시간 전`;
        case isNow:
          return '지금';
        default:
          return '';
      }
    };
    const stats = statistics.find((stat) => stat.t === estimation.t);
    const futureStats = futureStatistics.find((stat) => stat.t === estimation.t);

    return {
      ...stats,
      t: estimation.t,
      estimatedView: futureStats?.view,
      estimatedPromotedView: estimation.view,
      label: getLabel(),
      isNow,
      isLastCounted: lastCountedHour?.t === estimation.t,
      isLast: idx === estimations.length - 1,
    };
  });

  const timestamps = countsInDayByHour.map((stat) => stat.t);
  const maxTimestampInDay = Math.max(...timestamps);
  const minTimestampInDay = Math.min(...timestamps);

  const extendedEvents = events.reduce(
    (acc, event) => [
      ...acc,
      event,
      { t: event.t + MILLISECOND_DAY, type: 'PROMOTION_ENDED' } as ExtendedEventItem,
    ],
    [] as ExtendedEventItem[]
  );
  const eventsIn24Hours = events.filter((event) => {
    const promotionStart = event.t;
    const promotionEnd = event.t + MILLISECOND_DAY;

    return (
      isInRange(promotionStart, minTimestampInDay, maxTimestampInDay) ||
      isInRange(promotionEnd, minTimestampInDay, maxTimestampInDay)
    );
  });

  const isPromotionOngoing = eventsIn24Hours.length > 0;
  const counts = statistics.map((stat) => stat.view);
  const estimatedPromotedCounts = estimations.map((estimation) => estimation.view);
  const mergedCounts = isPromotionOngoing ? counts : [...counts, ...estimatedPromotedCounts];
  const maxCount = Math.max(...mergedCounts);
  const minCount = Math.min(...mergedCounts);
  const ticks = getTicks(minCount, maxCount);
  const digits = `${maxCount}`.length;
  const { adjustedTicks, adjustedAlternativeTicks } = getAdjustedTicks(ticks);

  const promotedTimeRanges = eventsIn24Hours
    .map((event) => [
      Math.max(event.t, minTimestampInDay),
      Math.min(event.t + MILLISECOND_DAY, maxTimestampInDay),
    ])
    .sort(([startA], [startB]) => startA - startB);
  const promotedStatistics = promotedTimeRanges.map(([start, end]) =>
    statistics.filter((stat) => isInRange(stat.t, start, end))
  );
  const totalPromotedCount = Math.max(
    sum(
      promotedStatistics.map((promotedRangeStats) => {
        if (!promotedRangeStats.length) {
          return 0;
        }
        const [first] = promotedRangeStats;
        const startIndex = statistics.findIndex((stat) => stat.t === first.t);
        return (
          promotedRangeStats[promotedRangeStats.length - 1]?.view -
          (statistics?.[startIndex - 1]?.view ?? promotedRangeStats[0]?.view)
        );
      })
    ),
    0
  );
  const lastCountedTimestamp = lastCountedHour?.t ?? maxTimestampInDay;
  const getChartOffset = (timestamp: number) => {
    if (minTimestampInDay === maxTimestampInDay) {
      return 0;
    }
    return ((timestamp - minTimestampInDay) / (lastCountedTimestamp - minTimestampInDay)) * 100;
  };
  const chartRanges = promotedTimeRanges.reduce<{ active: boolean; end: number; start: number }[]>(
    (acc, range, idx) => {
      const [start, end] = range;
      const currentRangeOffset = {
        start: getChartOffset(start),
        end: getChartOffset(end),
        active: true,
      };

      if (idx === 0) {
        return start > minTimestampInDay
          ? [{ start: 0, end: getChartOffset(start), active: false }, currentRangeOffset]
          : [currentRangeOffset];
      }
      const [, prevRangeEnd] = promotedTimeRanges?.[idx - 1] ?? [];

      return !!prevRangeEnd
        ? [
            ...acc,
            {
              start: getChartOffset(prevRangeEnd),
              end: getChartOffset(start),
              active: false,
            },
            currentRangeOffset,
          ]
        : [...acc, currentRangeOffset];
    },
    []
  );
  const [, lastTimeRangeEnd] = promotedTimeRanges?.[promotedTimeRanges.length - 1] ?? [];

  if (lastTimeRangeEnd && lastTimeRangeEnd !== maxTimestampInDay) {
    chartRanges.push({ start: getChartOffset(lastTimeRangeEnd), end: 100, active: false });
  }

  return (
    <>
      <Base.TextWrapper>
        <Base.Summary>
          {Math.min(statistics.length, DAILY_STATS_COUNT)}시간동안 {getSummaryText(totalCount)}
        </Base.Summary>
        <Base.PromotionDescription
          count={totalPromotedCount}
          events={events}
          expectedIncrease={expectedIncrease}
          statistics={statistics}
        />
      </Base.TextWrapper>
      <ChartContainer>
        <ResponsiveContainer aspect={1.363} width="100%">
          <LineChart
            data={countsInDayByHour}
            margin={{
              top: 33,
              left: 16,
              right: -8 + (digits >= 5 ? (digits - 4) * 6 : 0),
              bottom: 10,
            }}
          >
            {isPromotionOngoing && (
              <defs>
                <linearGradient id="lineColor" x1="0%" x2="100%" y1="0" y2="0">
                  {chartRanges.map(({ start, end, active }, idx) => {
                    const color = active ? vars.$semantic.color.primary : vars.$scale.color.gray800;

                    return (
                      <React.Fragment key={`${start}_${idx}`}>
                        <stop offset={`${formatFloat(start)}%`} stopColor={color} />
                        <stop offset={`${formatFloat(end)}%`} stopColor={color} />
                      </React.Fragment>
                    );
                  })}
                </linearGradient>
              </defs>
            )}
            <CartesianGrid vertical={false} />
            {!isPromotionOngoing && (
              <>
                <Line
                  dataKey="estimatedPromotedView"
                  dot={(props) => {
                    if (!props?.payload?.isLast) {
                      return <React.Fragment key={props.key} />;
                    }
                    return (
                      <Dot
                        {...props}
                        fill={vars.$semantic.color.paperDefault}
                        fillOpacity={1}
                        r={5}
                        stroke={vars.$semantic.color.primary}
                        strokeDasharray=""
                        strokeWidth={3}
                      />
                    );
                  }}
                  isAnimationActive={false}
                  stroke={vars.$semantic.color.primary}
                  strokeDasharray="8 4"
                  strokeWidth={2}
                  type="monotone"
                >
                  <LabelList
                    content={(props) => {
                      if (props.index ?? 0 < countsInDayByHour.length - 1) {
                        return <React.Fragment key={`${props.value}-${props.index}`} />;
                      }
                      return <PromotionEstimationLabel {...props} />;
                    }}
                  />
                </Line>
                {statistics.length > 0 && futureStatistics.length > 1 && (
                  <Line
                    dataKey="estimatedView"
                    dot={(props) => {
                      if (!props?.payload?.isLast) {
                        return <React.Fragment key={props.key} />;
                      }
                      return (
                        <Dot
                          {...props}
                          fill={vars.$semantic.color.paperDefault}
                          fillOpacity={1}
                          r={5}
                          stroke={vars.$scale.color.gray400}
                          strokeDasharray=""
                          strokeWidth={3}
                        />
                      );
                    }}
                    isAnimationActive={false}
                    stroke={vars.$scale.color.gray400}
                    strokeDasharray="8 4"
                    strokeWidth={2}
                    type="monotone"
                  />
                )}
              </>
            )}
            <Line
              dataKey="view"
              dot={(props) => {
                if (
                  !props?.payload?.isLastCounted ||
                  (!isPromotionOngoing && (statistics.length <= 0 || futureStatistics.length > 1))
                ) {
                  return <React.Fragment key={props.key} />;
                }
                return (
                  <Dot
                    {...props}
                    fill={vars.$semantic.color.paperDefault}
                    fillOpacity={1}
                    r={5}
                    stroke={
                      isPromotionOngoing
                        ? chartRanges?.[chartRanges.length - 1]?.active
                          ? vars.$semantic.color.primary
                          : vars.$scale.color.gray800
                        : vars.$scale.color.gray800
                    }
                  />
                );
              }}
              isAnimationActive={false}
              stroke={isPromotionOngoing ? 'url(#lineColor)' : vars.$scale.color.gray800}
              strokeWidth={totalCount > 0 ? 3 : 0}
              type="monotone"
            />
            <XAxis
              axisLine={false}
              dataKey="t"
              domain={['dataMin', 'dataMax']}
              height={18}
              interval={0}
              minTickGap={0}
              padding={{ left: 16 }}
              scale="time"
              tick={(props) => {
                const index = props?.payload?.index;

                if (index == null) {
                  return <></>;
                }
                const item = countsInDayByHour[props.payload.index];

                return <CustomizedAxisTick {...props} highlighted={item.isNow} text={item.label} />;
              }}
              tickFormatter={(_, index) => countsInDayByHour[index].label}
              tickLine={false}
              tickMargin={12}
              type="number"
            />
            <YAxis
              axisLine={false}
              domain={['dataMin', 'dataMax']}
              orientation="right"
              tickFormatter={(value: number, index) => {
                if (maxCount - minCount >= 30) {
                  return `${Math.floor(Math.round(value) / 10) * 10}`;
                }
                if (new Set(adjustedAlternativeTicks).size <= 2 && index === 1) {
                  return '';
                }
                return `${Math.floor(value)}`;
              }}
              tickLine={false}
              tickMargin={4}
              ticks={adjustedTicks}
              type="number"
            />
            {totalCount > 0 &&
              extendedEvents
                .filter((event, index) => {
                  const nextEvent = extendedEvents?.[index + 1];

                  return (
                    isInRange(event.t, minTimestampInDay, maxTimestampInDay) &&
                    !(event.type === 'PROMOTION_ENDED' && nextEvent?.type === 'PROMOTED')
                  );
                })
                .map((event) => (
                  <ReferenceLine
                    ifOverflow="discard"
                    key={event.t}
                    label={{
                      position: 'top',
                      content: (props) => <PromotionLabel {...props} type={event.type} />,
                    }}
                    stroke={vars.$scale.color.carrot200}
                    strokeDasharray="4 4"
                    x={event.t}
                  />
                ))}
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .recharts-cartesian-axis-tick text {
    ${vars.$semantic.typography.label5Regular};
  }

  & .recharts-cartesian-axis-tick-value {
    fill: ${vars.$scale.color.gray500};
  }

  & .recharts-cartesian-grid-horizontal line {
    stroke: ${vars.$scale.color.gray100};
  }
`;

export default ArticleOneDayChart;
