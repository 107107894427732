import copy from 'copy-to-clipboard';

export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    return Promise.resolve();
  } catch (e) {
    if (copy(text)) {
      return Promise.resolve();
    }
    return Promise.reject();
  }
}
