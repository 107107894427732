import { memo } from 'react';

import AspectRatio from '@/components/Base/AspectRatio';
import { thumbnailRoundCss } from '@/components/Base/OptimizedImage/utils';
import VStack from '@/components/Base/VStack';

import Placeholder from './Placeholder';

const SkeletonVerticalCArticleCard = () => {
  return (
    <VStack gap={12} pa={8}>
      <AspectRatio css={thumbnailRoundCss} ratio="16/9">
        <Placeholder h="100%" w="100%" />
      </AspectRatio>
      <VStack gap={8}>
        <Placeholder br={6} h={20} w={200} />
        <Placeholder br={6} h={20} w={120} />
      </VStack>
    </VStack>
  );
};

export default memo(SkeletonVerticalCArticleCard);
