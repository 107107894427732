import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { IconCheckFlowerFill } from '@seed-design/icon';
import { rem } from 'polished';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import Box, { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import NumberedSection from '@/components/Base/NumberedSection';
import RatioImage from '@/components/Base/OptimizedImage/RatioImage';
import { Label2Bold, Title2Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import InspectionBadge from '@/components/InspectionBadge';
import { inspectionAdvantages } from '@/constants/inspection';
import { useOverlayState } from '@/hooks/useOverlayState';
import { getSizeProps } from '@/utils/style';

const images = [
  'https://dnvefa72aowie.cloudfront.net/car/articles/9d29a25d98d549ee4f8f4e921b1309f534bec92ee9ef3943f7c5d99373adc98b_1702002953458.png',
  'https://dnvefa72aowie.cloudfront.net/car/articles/cd0690195b3d8fc6814748500b8dc367545401ae7002316fe6d56923249b20bd_1702002953503.png',
];

const useInspectionAdvantages = () => {
  const [isOpened, open, close] = useOverlayState('inspection_advantages');
  const node = (
    <BottomSheet
      active={isOpened}
      justifyHeader="center"
      onClickClose={close}
      title={
        <VStack align="center" gap={12} justify="center" pt={12} w="100%">
          <InspectionBadge hasBg={false} size="large">
            <HStack align="center" gap={4}>
              <IconCheckFlowerFill {...getSizeProps(18)} color={getSeedColor('accent')} />
              <Label2Bold color="blue700">진단 완료</Label2Bold>
            </HStack>
          </InspectionBadge>
          <Title2Bold ta="center">
            진단으로 사고 · 수리 내역을 <br />
            모두 확인했어요
          </Title2Bold>
        </VStack>
      }
    >
      <VStack gap={24} pt={16} px={16}>
        <HStack br={8} gap={2} w="100%">
          <LeftImage ratio="16/9" src={images[0]} width="100%" />
          <RightImage ratio="16/9" src={images[1]} width="100%" />
        </HStack>
        <VStack gap={24}>
          {inspectionAdvantages.map(({ title, description }, index) => (
            <NumberedSection key={index} number={index + 1} title={title}>
              {description}
            </NumberedSection>
          ))}
        </VStack>
        <Box py={8}>
          <BoxButton onClick={close} size="xlarge" variant="primary" width="100%">
            확인
          </BoxButton>
        </Box>
      </VStack>
    </BottomSheet>
  );

  return [open, node] as const;
};

const LeftImage = styled(RatioImage)({
  '.contents': {
    borderTopLeftRadius: rem(8),
    borderBottomLeftRadius: rem(8),
  },
});

const RightImage = styled(RatioImage)({
  '.contents': {
    borderTopRightRadius: rem(8),
    borderBottomRightRadius: rem(8),
  },
});

export default useInspectionAdvantages;
