import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

import { exactSize } from '@/styles/mixins';

import Box from './Box';
import HStack from './HStack';

const Quote: React.FC<PropsWithChildren<unknown>> = ({ children, ...props }) => {
  return (
    <HStack gap={8} h="fit-content" w="100%" {...props}>
      <Bar bg="gray200" br={11} />
      <Box>{children}</Box>
    </HStack>
  );
};

const Bar = styled(Box)(() => [exactSize('width', 4)]);

export default Quote;
