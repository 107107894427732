import { graphql, readInlineData } from 'relay-runtime';

import { parseOptions_article$key } from '@/__generated__/parseOptions_article.graphql';
import { Option } from '@/types/Article/Option';

import { getSplittedOptions } from './getSplittedOptions';

export const parseOptions = (articleRef: parseOptions_article$key) => {
  const {
    carData: { options },
  } = readInlineData(
    graphql`
      fragment parseOptions_article on Article @inline {
        carData {
          options
        }
      }
    `,
    articleRef
  );
  return getSplittedOptions(options as Option[]);
};
