import { differenceInHours, formatDistanceToNow, formatDuration, getYear } from 'date-fns';
import ko from 'date-fns/locale/ko';

import './dateStr';
import { padLeft } from './number';
export const formatDistanceKo = (timeString: string): string => {
  if (!timeString) {
    return '';
  }

  const timeAgoInWords = formatDistanceToNow(new Date(timeString), { locale: ko });
  return timeAgoInWords.replace('약 ', '') + ' 전';
};

export const formatDistanceDayHourKo = (timeString: string): string => {
  const hours = differenceInHours(new Date(timeString), new Date());
  const dayHourKo = formatDuration(
    {
      days: Math.floor(hours / 24),
      hours: hours % 24,
    },
    { format: ['days', 'hours'], locale: ko }
  );
  return dayHourKo;
};

export const formatDurationInHour = (minute: number): string => {
  return formatDuration(
    { hours: Math.floor(minute / 60), minutes: minute % 60 },
    { format: ['hours', 'minutes'], locale: ko }
  );
};

export const YEAR_RANGE_SIZE = 30;
export const getYearRange = (max = getYear(new Date()), count = YEAR_RANGE_SIZE) => {
  return [
    ...Array(count)
      .fill(max)
      .map((_, delta) => max - delta),
  ].reverse();
};

export const getYearRangeBeforeAndAfter = (year = getYear(new Date()), count = YEAR_RANGE_SIZE) => {
  const max = year + Math.floor(count / 2);
  const min = year - Math.floor(count / 2);
  return getYearRange(max, max - min + 1);
};

export const getMonthList = () => {
  const monthList = Array(12)
    .fill(0)
    .map((_, idx) => idx + 1);

  return monthList;
};

export type Time = [number, number];

export const isTimeAfter = (comparisonTarget: Time, toCompare: Time) => {
  if (comparisonTarget[0] > toCompare[0]) {
    return true;
  } else if (comparisonTarget[0] === toCompare[0]) {
    return comparisonTarget[1] > toCompare[1];
  }
  return false;
};

export const timeTypeTextMap = {
  AM: '오전',
  PM: '오후',
} as const;

export type TimeType = keyof typeof timeTypeTextMap;

export const getTimeType = (hour: number) => (hour <= 12 ? 'AM' : 'PM') as TimeType;

export const isAM = (hour: number) => hour <= 12;

export const change24HourToDisplayHour = (hour: number) => {
  return isAM(hour) ? hour : hour - 12;
};

export const changeDisplayHourTo24Hour = (hour: number, timeType: TimeType) => {
  return timeType === 'AM' ? hour : hour + 12;
};

export const defaultTimeFormat = ([hour, minute]: Time) =>
  `${timeTypeTextMap[isAM(hour) ? 'AM' : 'PM']} ${padLeft(isAM(hour) ? hour : hour - 12)}:${padLeft(
    minute
  )}`;
