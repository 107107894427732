import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { InsuranceBottomSheet_article$key } from '@/__generated__/InsuranceBottomSheet_article.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import useArticleStep from '@/hooks/useArticleStep';
import useTrack from '@/hooks/useTrack';
import { pressed } from '@/styles/mixins';
import { getSortedInsuranceList } from '@/utils/article/getSortedInsuranceList';
import { getSizeProps } from '@/utils/style';
import { openInAppBrowser, useCamelCaseParams } from '@/utils/url';

import { type DisplayState } from '../types';

type Props = {
  articleRef: InsuranceBottomSheet_article$key;
};

const InsuranceBottomSheet: React.FC<Props> = ({ articleRef }) => {
  const { track } = useTrack();
  const { state } = useCamelCaseParams<{ state?: DisplayState }>();
  const { stepPop } = useArticleStep();
  const article = useFragment(
    graphql`
      fragment InsuranceBottomSheet_article on Article {
        ...getSortedInsuranceList_article
      }
    `,
    articleRef
  );
  const sortedInsuranceList = getSortedInsuranceList(article);

  return (
    <BottomSheet
      active={state === 'insurance'}
      onClickClose={stepPop}
      showCloseButton
      title="내게 맞는 보험을 찾아보세요"
    >
      {sortedInsuranceList.map(({ id, url, title, reward, description, icon }) => {
        const handleClick = () => {
          openInAppBrowser(url);
          track('article_click_insurance_item', { company: id });
        };

        return (
          <InsuranceItem key={id} onClick={handleClick}>
            <Wrapper>
              <Image
                src={icon}
                style={{
                  border: id === 'db' ? `1px solid ${vars.$semantic.color.divider1}` : undefined,
                }}
              />
              <TextWrapper>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <Reward>{reward}</Reward>
              </TextWrapper>
            </Wrapper>
            <Icon {...getSizeProps(16)} color={vars.$scale.color.gray600} />
          </InsuranceItem>
        );
      })}
    </BottomSheet>
  );
};

const InsuranceItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(14)} ${rem(16)};

  ${pressed()}
`;

const Wrapper = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: ${rem(44)};
  height: ${rem(44)};
  margin-right: ${rem(12)};
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label2Bold}
`;

const Description = styled.p`
  margin-top: ${rem(4)};
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.label4Regular}
`;

const Reward = styled.p`
  margin-top: ${rem(8)};
  color: ${vars.$semantic.color.accent};
  ${vars.$semantic.typography.label4Bold}
`;

const Icon = styled(IconChevronRightRegular)`
  margin-top: ${rem(13)};
`;

export default InsuranceBottomSheet;
