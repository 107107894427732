import styled from '@emotion/styled';
import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { match } from 'ts-pattern';

import { VerticalArticleCard_article$key } from '@/__generated__/VerticalArticleCard_article.graphql';
import defaultThumbnail from '@/assets/images/empty_img_car.png';
import ArticleActionCount from '@/components/Article/ArticleActionCount';
import ArticleBadges from '@/components/Article/ArticleBadges';
import ArticleThumbnailBadges from '@/components/Article/ArticleThumbnailBadges';
import Box from '@/components/Base/Box';
import { ColoredSpan } from '@/components/Base/ColoredText';
import RatioImage from '@/components/Base/OptimizedImage/RatioImage';
import { thumbnailBorderCss } from '@/components/Base/OptimizedImage/utils';
import { BodyL2Regular, Caption1Regular, Label2Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import LikeButton from '@/components/LikeButton';
import { useFlow } from '@/stackflow';
import { pressed } from '@/styles/mixins';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getCarSpec } from '@/utils/article/getCarSpec';
import { getMergedImages } from '@/utils/article/getMergedImages';
import { getPriceText } from '@/utils/article/getPriceText';
import { getCarNameByCompanyOrigin } from '@/utils/carData';

type Props = {
  articleRef: VerticalArticleCard_article$key;
  onClick?: (data: ArticleEventProperties) => void;
};

const VerticalArticleCard = memo(({ articleRef, onClick, ...props }: Props) => {
  const { push } = useFlow();
  const article = useFragment<VerticalArticleCard_article$key>(
    graphql`
      fragment VerticalArticleCard_article on Article {
        id
        carData {
          ...carData_getCarNameByCompanyOrigin_carData
        }
        status
        ...getCarSpec_article
        ...getMergedImages_article
        ...ArticleActionCount_article
        ...getPriceText_article
        ...ArticleStatusBadge_article
        ...getArticleEventProperties_article
        ...ArticleThumbnailBadges_article
        ...ArticleBadges_article
        ...LikeButton_article
      }
    `,
    articleRef
  );
  const { id, carData, status } = article;

  const { specWithoutPrice } = getCarSpec(article);
  const priceText = getPriceText(article);

  const mergedImages = getMergedImages(article);

  const handleClick = () => {
    push('article', { articleId: id });
    onClick?.(getArticleEventProperties(article));
  };
  const carName = getCarNameByCompanyOrigin(carData);

  return (
    <Container
      br={10}
      minW={140 + 16}
      onClick={handleClick}
      px={8}
      py={8}
      w="100%"
      {...props}
      gap={8}
    >
      <RatioImage
        alt="매물 이미지"
        css={thumbnailBorderCss}
        fallback={defaultThumbnail}
        ratio="4/3"
        sizes={'300px'}
        src={mergedImages?.[0]?.url}
      >
        <ArticleThumbnailBadges articleRef={article} size="small" />
        <ArticleLikeButton articleRef={article} pa={6} pos="absolute" size={20} />
      </RatioImage>
      <VStack gap={4}>
        <BodyL2Regular ellipsis={1} w="100%">
          {carName}
        </BodyL2Regular>
        <Label2Bold>
          {match(status)
            .with('RESERVED', () => <ColoredSpan color={'success'}>예약중 </ColoredSpan>)
            .with('TRADED', () => <ColoredSpan color={'gray600'}>거래완료 </ColoredSpan>)
            .otherwise(() => null)}
          {priceText}
        </Label2Bold>
        <Caption1Regular wordBreak="keep-all">{specWithoutPrice}</Caption1Regular>
      </VStack>
      <ArticleBadges articleRef={article} fallback={<Box mt={-8} />} size="small" />
      <ArticleActionCount articleRef={article} />
    </Container>
  );
});

const Container = styled(VStack)`
  ${pressed()}
`;

const ArticleLikeButton = styled(LikeButton)({
  top: 0,
  right: 0,
});

export default VerticalArticleCard;
VerticalArticleCard.displayName = 'VerticalArticleCard';
