/**
 * @generated SignedSource<<a51b5018ab3a7e06a3b9813d5273c0d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ArticleTitleSection_article$data = {
  readonly carData: {
    readonly fuelType: FuelType;
    readonly modelYear: number;
  };
  readonly driveDistance: number;
  readonly status: ArticleStatus;
  readonly " $fragmentSpreads": FragmentRefs<"getCarSpec_article" | "getPriceText_article" | "isInspectedCar_article" | "useIsWarrantable_article">;
  readonly " $fragmentType": "ArticleTitleSection_article";
};
export type ArticleTitleSection_article$key = {
  readonly " $data"?: ArticleTitleSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTitleSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "driveDistance",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelYear",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyFee",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineDataFragmentSpread",
  "name": "getPriceText_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseInfo",
      "kind": "LinkedField",
      "name": "leaseInfo",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTitleSection_article",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fuelType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getCarSpec_article",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarFullName_carData",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupModel",
                  "kind": "LinkedField",
                  "name": "autoupModel",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutoupCompany",
                      "kind": "LinkedField",
                      "name": "company",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v4/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isInspectedCar_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Inspection",
              "kind": "LinkedField",
              "name": "inspection",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMechanicReportVisible",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "c01d50ecaa8f29cb73ca1c82aa623c48";

export default node;
