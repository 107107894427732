/**
 * @generated SignedSource<<29e5e841a970fde306a6293d2b0ab2ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleWarrantySection_article$data = {
  readonly carData: {
    readonly inspection: {
      readonly " $fragmentSpreads": FragmentRefs<"getWarrantyDeadline_inspection">;
    } | null | undefined;
  };
  readonly id: string;
  readonly " $fragmentType": "ArticleWarrantySection_article";
};
export type ArticleWarrantySection_article$key = {
  readonly " $data"?: ArticleWarrantySection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleWarrantySection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleWarrantySection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Inspection",
          "kind": "LinkedField",
          "name": "inspection",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getWarrantyDeadline_inspection",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fixedDate",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "a490f0607a7ad478ac94a21344cd8f4d";

export default node;
