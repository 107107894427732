import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleThumbnailBadges_article$key } from '@/__generated__/ArticleThumbnailBadges_article.graphql';
import Box from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import { isInspectedCar } from '@/utils/article/isInspectedCar';

type Size = 'large' | 'small';
type Props = {
  articleRef: ArticleThumbnailBadges_article$key;
  size?: Size;
};

const InspectionBadge: Record<Size, ReactNode> = {
  small: (
    <svg fill="none" height="21" viewBox="0 0 40 21" width="40" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2769_92209)">
        <circle cx="7" cy="10.5" fill="white" r="5" />
        <path
          clipRule="evenodd"
          d="M4.85972 5.33371C5.28083 4.58751 6.0809 4.08325 6.99967 4.08325C7.91842 4.08325 8.71848 4.58748 9.13961 5.33359C9.96499 5.1038 10.8873 5.31306 11.5369 5.96262C12.1865 6.6123 12.3957 7.53456 12.1659 8.35996C12.9121 8.78108 13.4163 9.58115 13.4163 10.4999C13.4163 11.4187 12.9121 12.2187 12.166 12.6398C12.3958 13.4652 12.1865 14.3875 11.537 15.0371C10.8873 15.6868 9.96504 15.8959 9.13963 15.6661C8.71852 16.4123 7.91845 16.9166 6.99967 16.9166C6.08093 16.9166 5.28087 16.4124 4.85975 15.6662C4.03436 15.896 3.11205 15.6868 2.46249 15.0372C1.8128 14.3875 1.60365 13.4653 1.83346 12.6399C1.08727 12.2188 0.583008 11.4187 0.583008 10.4999C0.583008 9.58117 1.08724 8.78111 1.83334 8.35999C1.60356 7.5346 1.81282 6.61229 2.46237 5.96274C3.11206 5.31305 4.03431 5.1039 4.85972 5.33371ZM9.33885 8.35763C9.09339 8.18063 8.75093 8.23612 8.57392 8.48158L6.498 11.3604L5.38419 10.1497C5.1793 9.92701 4.83267 9.91257 4.60996 10.1175C4.38726 10.3223 4.37281 10.669 4.5777 10.8917L6.1463 12.5967C6.25777 12.7178 6.4178 12.7825 6.58213 12.7727C6.74647 12.7629 6.8977 12.6797 6.99399 12.5462L9.4628 9.12255C9.6398 8.8771 9.5843 8.53463 9.33885 8.35763Z"
          fill="#009CEB"
          fillRule="evenodd"
        />
        <path
          d="M25.702 13.342V4.944H27.21V13.342H25.702ZM18.617 12.094L17.707 10.885C18.552 10.573 19.228 10.079 19.735 9.403C20.242 8.727 20.489 7.947 20.489 7.076H18.162V5.88H24.272V7.076H22.049C22.062 7.921 22.309 8.649 22.816 9.273C23.31 9.897 23.908 10.326 24.61 10.547L23.778 11.743C23.323 11.574 22.855 11.262 22.374 10.82C21.893 10.391 21.529 9.91 21.282 9.377C21.113 9.949 20.762 10.495 20.242 11.015C19.722 11.535 19.176 11.899 18.617 12.094ZM19.488 16.215V12.549H21.009V15.019H27.47V16.215H19.488ZM36.404 13.303V4.944H37.938V8.103H39.589V9.351H37.938V13.303H36.404ZM29.267 5.893H34.428V7.089H30.775V10.144C32.712 10.144 34.22 10.027 35.312 9.806C35.351 10.092 35.416 10.482 35.52 10.963C34.389 11.236 32.686 11.366 30.398 11.366H29.267V5.893ZM30.515 16.189V12.497H32.036V14.967H38.211V16.189H30.515Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2769_92209">
          <rect fill="white" height="20" rx="4" width="40" y="0.5" />
        </clipPath>
      </defs>
    </svg>
  ),
  large: (
    <svg fill="none" height="19" viewBox="0 0 45 19" width="45" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2769_92181)">
        <circle cx="10" cy="11" fill="white" r="5" />
        <path
          clipRule="evenodd"
          d="M6.24863 2.85773C6.79006 1.89833 7.81872 1.25 9 1.25C10.1812 1.25 11.2099 1.8983 11.7513 2.85758C12.8126 2.56213 13.9984 2.83118 14.8335 3.66632C15.6688 4.50164 15.9377 5.68739 15.6423 6.74863C16.6017 7.29006 17.25 8.31872 17.25 9.5C17.25 10.6812 16.6017 11.7099 15.6424 12.2513C15.9379 13.3126 15.6688 14.4984 14.8337 15.3335C13.9984 16.1688 12.8126 16.4377 11.7514 16.1423C11.2099 17.1017 10.1813 17.75 9 17.75C7.81875 17.75 6.7901 17.1017 6.24866 16.1424C5.18745 16.4379 4.00162 16.1688 3.16648 15.3337C2.33117 14.4984 2.06226 13.3126 2.35773 12.2514C1.39833 11.7099 0.75 10.6813 0.75 9.5C0.75 8.31875 1.3983 7.2901 2.35757 6.74866C2.06213 5.68745 2.33118 4.50162 3.16632 3.66648C4.00164 2.83117 5.18739 2.56226 6.24863 2.85773ZM12.0075 6.74563C11.6919 6.51805 11.2516 6.5894 11.024 6.90499L8.35499 10.6063L6.92295 9.04974C6.65952 8.76341 6.21385 8.74484 5.92752 9.00827C5.64118 9.2717 5.62261 9.71737 5.88604 10.0037L7.90281 12.1958C8.04612 12.3516 8.25187 12.4347 8.46316 12.4221C8.67445 12.4095 8.86889 12.3026 8.99269 12.1309L12.1669 7.7291C12.3944 7.41351 12.3231 6.9732 12.0075 6.74563Z"
          fill="#009CEB"
          fillRule="evenodd"
        />
        <path
          d="M29.4867 12.676V3.632H31.1107V12.676H29.4867ZM21.8567 11.332L20.8767 10.03C21.7867 9.694 22.5147 9.162 23.0607 8.434C23.6067 7.706 23.8727 6.866 23.8727 5.928H21.3667V4.64H27.9467V5.928H25.5527C25.5667 6.838 25.8327 7.622 26.3787 8.294C26.9107 8.966 27.5547 9.428 28.3107 9.666L27.4147 10.954C26.9247 10.772 26.4207 10.436 25.9027 9.96C25.3847 9.498 24.9927 8.98 24.7267 8.406C24.5447 9.022 24.1667 9.61 23.6067 10.17C23.0467 10.73 22.4587 11.122 21.8567 11.332ZM22.7947 15.77V11.822H24.4327V14.482H31.3907V15.77H22.7947ZM41.012 12.634V3.632H42.664V7.034H44.442V8.378H42.664V12.634H41.012ZM33.326 4.654H38.884V5.942H34.95V9.232C37.036 9.232 38.66 9.106 39.836 8.868C39.878 9.176 39.948 9.596 40.06 10.114C38.842 10.408 37.008 10.548 34.544 10.548H33.326V4.654ZM34.67 15.742V11.766H36.308V14.426H42.958V15.742H34.67Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2769_92181">
          <rect fill="white" height="19" rx="4" width="45" />
        </clipPath>
      </defs>
    </svg>
  ),
};

const WarrantyBadge = {
  small: (
    <svg fill="none" height="21" viewBox="0 0 40 21" width="40" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2769_92213)">
        <circle cx="7" cy="10.5" fill="white" r="5" />
        <path
          clipRule="evenodd"
          d="M7.46882 4.19185C7.17356 4.04705 6.82618 4.04705 6.53092 4.19185L1.86426 6.48034C1.50819 6.65495 1.2832 7.01232 1.2832 7.40329V8.6958C1.2832 10.806 1.74061 12.4717 2.65385 13.804C3.56591 15.1345 4.90217 16.088 6.57877 16.828C6.84588 16.9459 7.15383 16.9466 7.42236 16.8268C9.1125 16.0731 10.4436 15.0294 11.3497 13.6641C12.2561 12.2983 12.7165 10.6417 12.7165 8.6958V7.40329C12.7165 7.01232 12.4916 6.65495 12.1355 6.48034L7.46882 4.19185ZM9.67851 9.08102C9.85188 8.89366 9.83782 8.60371 9.64712 8.43338C9.45641 8.26306 9.16127 8.27687 8.9879 8.46422L6.40045 11.2604L4.99652 9.88114C4.81427 9.70209 4.5188 9.70209 4.33655 9.88114C4.15431 10.0602 4.15431 10.3505 4.33655 10.5295L6.08655 12.2487C6.17679 12.3374 6.30005 12.3859 6.42764 12.3829C6.55522 12.3799 6.676 12.3257 6.76184 12.233L9.67851 9.08102Z"
          fill="#FF6F0F"
          fillRule="evenodd"
        />
        <path
          d="M22.088 13.875V11.626H18.903V5.737H20.411V7.531H25.338V5.711H26.859V11.626H23.609V13.875H28.107V15.084H17.642V13.875H22.088ZM20.411 10.417H25.338V8.701H20.411V10.417ZM30.073 9.832L29.449 8.662C30.372 8.623 31.191 8.415 31.893 8.051C32.582 7.7 33.037 7.232 33.232 6.66H29.969V5.451H38.315V6.66H35.065C35.286 7.219 35.754 7.687 36.495 8.038C37.223 8.389 38.003 8.597 38.822 8.636L38.172 9.832C37.327 9.767 36.521 9.52 35.767 9.117C35 8.714 34.454 8.233 34.129 7.674C33.843 8.246 33.323 8.727 32.582 9.117C31.841 9.52 30.996 9.754 30.073 9.832ZM28.89 11.678V10.482H39.355V11.678H28.89ZM31.139 15.955C30.424 15.578 30.073 15.045 30.073 14.369C30.073 13.056 31.568 12.237 34.116 12.237C36.664 12.237 38.172 13.056 38.172 14.369C38.172 15.721 36.677 16.527 34.129 16.527C32.842 16.527 31.854 16.332 31.139 15.955ZM31.646 14.382C31.646 14.681 31.867 14.902 32.322 15.071C32.764 15.24 33.362 15.331 34.116 15.331C34.883 15.331 35.481 15.24 35.936 15.071C36.391 14.902 36.612 14.681 36.612 14.382C36.612 14.083 36.391 13.849 35.936 13.68C35.481 13.511 34.883 13.433 34.116 13.433C33.362 13.433 32.764 13.511 32.322 13.68C31.867 13.849 31.646 14.083 31.646 14.382Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2769_92213">
          <rect fill="white" height="20" rx="4" width="40" y="0.5" />
        </clipPath>
      </defs>
    </svg>
  ),
  large: (
    <svg fill="none" height="19" viewBox="0 0 45 19" width="45" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2769_92185)">
        <circle cx="9" cy="10" fill="white" r="5" />
        <path
          clipRule="evenodd"
          d="M9.60332 1.38962C9.2237 1.20346 8.77708 1.20346 8.39746 1.38962L2.39746 4.33197C1.93966 4.55647 1.65039 5.01594 1.65039 5.51862V7.18042C1.65039 9.89356 2.23848 12.0351 3.41265 13.7481C4.5853 15.4588 6.30334 16.6847 8.45897 17.6361C8.80241 17.7877 9.19833 17.7886 9.54359 17.6346C11.7166 16.6655 13.428 15.3237 14.593 13.5683C15.7584 11.8122 16.3504 9.68231 16.3504 7.18042V5.51862C16.3504 5.01594 16.0611 4.55647 15.6033 4.33197L9.60332 1.38962ZM12.4444 7.6757C12.6673 7.43481 12.6492 7.06201 12.404 6.84303C12.1588 6.62404 11.7793 6.64179 11.5564 6.88268L8.22971 10.4777L6.42465 8.70442C6.19034 8.47423 5.81044 8.47423 5.57613 8.70442C5.34181 8.93462 5.34181 9.30784 5.57613 9.53803L7.82613 11.7485C7.94215 11.8625 8.10063 11.9248 8.26466 11.921C8.4287 11.9171 8.58398 11.8475 8.69436 11.7282L12.4444 7.6757Z"
          fill="#FF6F0F"
          fillRule="evenodd"
        />
        <path
          d="M25.5947 13.25V10.828H22.1647V4.486H23.7887V6.418H29.0947V4.458H30.7327V10.828H27.2327V13.25H32.0767V14.552H20.8067V13.25H25.5947ZM23.7887 9.526H29.0947V7.678H23.7887V9.526ZM34.194 8.896L33.522 7.636C34.516 7.594 35.398 7.37 36.154 6.978C36.896 6.6 37.386 6.096 37.596 5.48H34.082V4.178H43.07V5.48H39.57C39.808 6.082 40.312 6.586 41.11 6.964C41.894 7.342 42.734 7.566 43.616 7.608L42.916 8.896C42.006 8.826 41.138 8.56 40.326 8.126C39.5 7.692 38.912 7.174 38.562 6.572C38.254 7.188 37.694 7.706 36.896 8.126C36.098 8.56 35.188 8.812 34.194 8.896ZM32.92 10.884V9.596H44.19V10.884H32.92ZM35.342 15.49C34.572 15.084 34.194 14.51 34.194 13.782C34.194 12.368 35.804 11.486 38.548 11.486C41.292 11.486 42.916 12.368 42.916 13.782C42.916 15.238 41.306 16.106 38.562 16.106C37.176 16.106 36.112 15.896 35.342 15.49ZM35.888 13.796C35.888 14.118 36.126 14.356 36.616 14.538C37.092 14.72 37.736 14.818 38.548 14.818C39.374 14.818 40.018 14.72 40.508 14.538C40.998 14.356 41.236 14.118 41.236 13.796C41.236 13.474 40.998 13.222 40.508 13.04C40.018 12.858 39.374 12.774 38.548 12.774C37.736 12.774 37.092 12.858 36.616 13.04C36.126 13.222 35.888 13.474 35.888 13.796Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2769_92185">
          <rect fill="white" height="19" rx="4" width="45" />
        </clipPath>
      </defs>
    </svg>
  ),
};

const getGap = (size: Size) => (size === 'large' ? 8 : 6);

const ArticleThumbnailBadges: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  size = 'large',
  ...props
}) => {
  const article = useFragment(
    graphql`
      fragment ArticleThumbnailBadges_article on Article {
        ...isInspectedCar_article
        ...useIsWarrantable_article
      }
    `,
    articleRef
  );
  const isInspected = isInspectedCar(article);
  const { isWarrantable } = useIsWarrantable(article);

  if (!isInspected) {
    return null;
  }

  return (
    <BottomGradient h={66} pos="absolute" w="100%" {...props}>
      <BadgeContainer align="center" gap={getGap(size)} pos="absolute" size={size}>
        {InspectionBadge[size]}
        {isWarrantable && WarrantyBadge[size]}
      </BadgeContainer>
    </BottomGradient>
  );
};

export default ArticleThumbnailBadges;

const BottomGradient = styled(Box)({
  top: 0,
  content: '""',
  display: 'block',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%)',
});

const BadgeContainer = styled(HStack)<{ size: Size }>(({ size }) => {
  const gap = getGap(size);
  return {
    top: rem(gap),
    left: rem(gap),
  };
});
