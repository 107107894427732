import React from 'react';

type Props = {
  keywords: string[];
  render?: (matchedKeyword: string) => JSX.Element;
  text: string;
};

const stringToRegex = (str: string[]) => {
  return new RegExp(
    str
      .map((s) => s.replace(/[()[\]{}*+?^$|#.,/\\\s-]/g, '\\$&'))
      .sort((a, b) => b.length - a.length)
      .join('|'),
    'g'
  );
};

const KeywordHighlighter: React.FC<Props> = ({ keywords, text, ...props }) => {
  const regex = stringToRegex(keywords);
  const splitted = text.split(regex);
  const matched = [...text.matchAll(regex)];

  if (!matched.length) {
    return <span>{text}</span>;
  }

  return (
    <>
      {splitted.map((s, i) => (
        <React.Fragment key={i}>
          <span>{s}</span>
          {i < matched.length &&
            (props.render ? props.render(matched[i][0]) : <b>{matched[i][0]}</b>)}
        </React.Fragment>
      ))}
    </>
  );
};

export default KeywordHighlighter;
