/**
 * @generated SignedSource<<becd1f329aca805cd55ede8c46774fe1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CarOptionName = "AEB" | "AROUND_VIEW" | "AUTO_AIR_CONDITIONER" | "AUTO_FOLDING_SIDE_MIRROR" | "AUTO_TRUNK" | "AUTO_WIPER" | "BLUETOOTH" | "BSD" | "CRUISE_CONTROL" | "DASHBOARD_CAMERA" | "EPB" | "HEADLAMP" | "HEATED_SEAT" | "HEATED_STEERING_WHEEL" | "HIPASS" | "HUD" | "LDWS" | "LEATHER_SEAT" | "NAVIGATION" | "NUM_OF_KEY_2" | "PANORAMA_SUNROOF" | "PARK_SENSOR_FRONT" | "PARK_SENSOR_REAR" | "POWER_SEAT" | "REAR_CAMERA" | "SMART_KEY" | "SMOKING_FREE" | "SUNROOF" | "TPMS" | "VENTILATED_SEAT";
import { FragmentRefs } from "relay-runtime";
export type parseOptions_article$data = {
  readonly carData: {
    readonly options: ReadonlyArray<CarOptionName> | null | undefined;
  };
  readonly " $fragmentType": "parseOptions_article";
};
export type parseOptions_article$key = {
  readonly " $data"?: parseOptions_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"parseOptions_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "parseOptions_article"
};

(node as any).hash = "372057f91ec602231b45aea7d89669be";

export default node;
