import CONFIG, { IS_ALPHA, IS_LOCAL } from '@/constants/config';
import { filterNil } from '@/utils/misc';
import { sessionStorage } from '@/utils/storage';
import { isCarTeamUserId } from '@/utils/user';

import { ga4CustomEventRegex } from './CustomEvent';
import { ga4PvEventRegex } from './PvEvent';
import { TrackerImpl } from './TrackerImpl';

const preserveGa4Events = () => {
  // GA4 초기화전 log 도 쌓일 수 있도록 미리 선언
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };
};

preserveGa4Events();

const GA4Tracker = (): TrackerImpl => {
  let initialized = false;
  return {
    initialized,
    init: ({ user, region }) => {
      if (initialized || !CONFIG.GA_MEASUREMENT_ID) {
        return undefined;
      }
      const isCarTeamAdmin = isCarTeamUserId(String(user?.id ?? ''));
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${CONFIG.GA_MEASUREMENT_ID}`;
      document.head.appendChild(script);
      window.gtag('js', new Date());
      window.gtag('config', CONFIG.GA_MEASUREMENT_ID, {
        send_page_view: false,
        campaign_source: sessionStorage.getReferrer(),
        // false여도 debug 모드가 활성화됨. (https://support.google.com/analytics/answer/7201382?hl=en#zippy=%2Cgoogle-tag-gtagjs)
        ...((IS_LOCAL || IS_ALPHA) && {
          debug_mode: true,
        }),
      });
      window.gtag('set', {
        user_id: user.id,
        user_properties: {
          regionName2: region?.name2,
          isCarTeamAdmin,
        },
        ...filterNil({ referrer: sessionStorage.getReferrer() }),
      });
      initialized = true;
    },
    trackEvent: (name, params) => {
      if (!initialized) {
        throw Error('GA4Tracker is not initialized');
      }
      if (ga4CustomEventRegex.test(name)) {
        window.gtag('event', name, {
          ...params,
          event_category: params?.activityName ?? 'click',
        });
      }
    },
    trackPageView: (name, params) => {
      if (!initialized) {
        throw Error('GA4Tracker is not initialized');
      }
      if (ga4PvEventRegex.test(name)) {
        window.gtag('event', 'page_view', {
          ...params,
          name,
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      }
    },
  };
};

export const ga4Tracker = GA4Tracker();
