import styled from '@emotion/styled';
import { useCombinedRefs } from '@toss/react';
import { ForwardedRef, HTMLAttributes, forwardRef, memo } from 'react';

import { useRetryImage } from '@/hooks/useRetryImage';
import { getSrcSet } from '@/utils/image';

interface Props extends HTMLAttributes<HTMLImageElement> {
  alt?: string;
  fallback?: string;
  sizes?: string;
  src?: string;
  width?: number | string;
}

const NormalImage = (
  { className, src, fallback, width, sizes, alt, ...props }: Props,
  passedRef: ForwardedRef<HTMLImageElement>
) => {
  const {
    imageProps: { ref, ...imageProps },
    imageStatus,
  } = useRetryImage();
  const targetSrc = imageStatus === 'error' ? fallback : src ?? fallback;
  const combinedRef = useCombinedRefs(passedRef, ref);
  const targetSrcSet = getSrcSet(targetSrc);
  return (
    <RealImage
      alt={alt}
      className={className}
      data-image-completed={imageStatus !== 'loading'}
      loading="eager"
      ref={combinedRef}
      sizes={sizes ?? width?.toString() ?? '100vw'}
      src={targetSrc}
      srcSet={targetSrcSet}
      width={width}
      {...imageProps}
      {...props}
    />
  );
};

export default memo(forwardRef<HTMLImageElement, Props>(NormalImage));

const RealImage = styled.img({
  opacity: 0,
  transition: 'opacity 0.2s cubic-bezier(0, 0, 0.74, 1)',
  '&[data-image-completed="true"]': {
    opacity: 1,
  },
});
