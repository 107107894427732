import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { FragmentRefs } from 'relay-runtime';

import ArticleCard from '@/components/Article/ArticleCard';
import { noScrollbar } from '@/styles/mixins';
import { PropsOf } from '@/types/React/PropsOf';

type Props = {
  articles: ReadonlyArray<{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly ' $fragmentSpreads': FragmentRefs<'ArticleCard_article'>;
    readonly id: string;
  }>;
  itemSize?: 'medium' | 'small';
  onClick: PropsOf<typeof ArticleCard>['onClick'];

  supportSnap?: boolean;
  variant?: PropsOf<typeof ArticleCard>['variant'];
};

const ArticleCarousel: React.FC<React.PropsWithChildren<Props>> = ({
  articles,
  onClick,
  variant = 'vertical',
  itemSize = 'small',
  supportSnap,
  ...props
}) => {
  return (
    <Container data-support-snap={supportSnap} {...props}>
      {articles.map((article) => (
        <FixedWidthArticleCard
          articleRef={article}
          data-item-size={itemSize}
          data-support-snap={supportSnap}
          key={article.id}
          onClick={onClick}
          variant={variant}
        />
      ))}
    </Container>
  );
};

const FixedWidthArticleCard = styled(ArticleCard)`
  &[data-item-size='small'] {
    width: ${rem(140)};
  }
  &[data-item-size='medium'] {
    width: ${rem(312)};
  }
  &[data-support-snap='true'] {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  flex: 1 0 auto;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: ${vars.$semantic.color.paperDefault};
  ${noScrollbar}
  padding: 0 ${rem(8)};
  &[data-support-snap='true'] {
    scroll-snap-type: x mandatory;
    scroll-padding: 0 ${rem(8)};
  }
`;

export default ArticleCarousel;
