import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const Camera: React.FC<React.PropsWithChildren<Props>> = ({
  width = 16,
  height = 16,
  ...props
}) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 16 16"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M4.15109 4.34009L5.18109 2.63009C5.28109 2.47009 5.51109 2.34009 5.68109 2.34009H10.3611C10.5411 2.34009 10.7611 2.47009 10.8611 2.63009L11.8911 4.34009H13.3611C14.0911 4.34009 14.6911 4.93009 14.6911 5.67009V12.3301C14.6911 13.0701 14.1011 13.6601 13.3611 13.6601H2.70109C1.97109 13.6601 1.37109 13.0701 1.37109 12.3301V5.67009C1.37109 4.94009 1.96109 4.34009 2.70109 4.34009H4.15109V4.34009Z"
        fill="#898E97"
        fillRule="evenodd"
      />
      <path
        d="M8.01937 5.59009C6.13937 5.59009 4.60938 7.12009 4.60938 9.00009C4.60938 10.8801 6.13937 12.4101 8.01937 12.4101C9.89937 12.4101 11.4294 10.8801 11.4294 9.00009C11.4294 7.12009 9.89937 5.59009 8.01937 5.59009V5.59009Z"
        fill="#BABEC5"
      />
      <path
        clipRule="evenodd"
        d="M7.27187 3.28003C6.94187 3.28003 6.67188 3.55003 6.67188 3.88003C6.67188 4.21003 6.94187 4.48003 7.27187 4.48003H8.77187C9.10187 4.48003 9.37188 4.21003 9.37188 3.88003C9.37188 3.55003 9.10187 3.28003 8.77187 3.28003H7.27187V3.28003Z"
        fill="#585B63"
        fillRule="evenodd"
      />
      <path
        d="M8.02031 11.2C6.81031 11.2 5.82031 10.22 5.82031 9.00005C5.82031 7.78005 6.80031 6.80005 8.02031 6.80005C9.24031 6.80005 10.2203 7.78005 10.2203 9.00005C10.2203 10.22 9.24031 11.2 8.02031 11.2Z"
        fill="#585B63"
      />
    </svg>
  );
};

export default Camera;
