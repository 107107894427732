import authPlugin from '@daangn/plantae-plugin-auth';
import { EntryPoint, useDialogOpened } from '@daangn/transfer-identification-sdk';
import { createFetch } from 'plantae';
import { useMemo, useRef } from 'react';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import CONFIG, { IS_PROD } from '@/constants/config';
import useMe from '@/hooks/useMe';
import { useOverlayState } from '@/hooks/useOverlayState';
import { useToast } from '@/hooks/useToast';
import { karrotBridge } from '@/sdk/bridge';
import { getDefaultUser } from '@/sdk/bridge/info';
import { handleError as normalizeError } from '@/utils/error';

const fetchWithAuth = (() => {
  const defaultUser = getDefaultUser();

  return createFetch({
    plugins: [
      authPlugin({
        bridge: karrotBridge,
        options: {
          fallbackAuthToken: {
            value: defaultUser.authToken,
          },
        },
      }),
    ],
  });
})();

const IDENTIFICATION_API_URL = `${CONFIG.IDENTIFICATION_HOST}/identification/v2/karrot-users/me/kr/identification`;

type Props = {
  previousScreenName?: string;
  serviceName?: string;
};

const defaultProps = {
  serviceName: '중고차 직거래',
  previousScreenName: 'car',
};

const useIdentificationBottomSheet = ({
  serviceName = defaultProps.serviceName,
  previousScreenName = defaultProps.previousScreenName,
}: Props = defaultProps) => {
  const { setToast } = useToast();
  const [isOpened, open, close] = useOverlayState('identification');
  const resolveRef = useRef<((value: PromiseLike<boolean> | boolean) => void) | undefined>(
    undefined
  );

  const envProps = useMemo(() => {
    return IS_PROD
      ? {
          agreementUrl: 'https://terms.karrotwebview.com/pay_identity/',
          transferIdentificationScheme: `karrot://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.karrotwebview.com%2F%3Fprevious_screen_name%3D${previousScreenName}&navbar=false&scrollable=false`,
          newIdentificationScheme: `karrot://identification?type=account&referrer=${previousScreenName}&show_complete=true`,
        }
      : {
          agreementUrl: 'https://terms.alpha.karrotwebview.com/pay_identity/',
          transferIdentificationScheme: `karrot.alpha://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.alpha.karrotwebview.com%2F%3Fprevious_screen_name%3D${previousScreenName}&navbar=false&scrollable=false`,
          newIdentificationScheme: `karrot.alpha://identification?type=account&referrer=${previousScreenName}&show_complete=true`,
        };
  }, [previousScreenName]);

  const fetchMe = useMe();
  const fetchVerify = () => fetchWithAuth(IDENTIFICATION_API_URL);

  const check = () => {
    return new Promise<boolean>(async (resolve) => {
      try {
        const { me } = await fetchMe({});
        if (me?.selfVerified) {
          return resolve(true);
        }
        resolveRef.current = resolve;
        open();
      } catch (e) {
        normalizeError(e, { reportToSentry: true });
        setToast('일시적인 오류가 발생했어요. 다시 시도해 주세요.');
        return resolve(false);
      }
    });
  };

  const refetch = async () => {
    await fetchMe({}, { fetchPolicy: 'network-only' });
  };

  const handleIdentified = () => {
    resolveRef.current?.(true);
    resolveRef.current = undefined;
    refetch();
    close();
  };

  const handleNotIdentified = () => {
    resolveRef.current?.(false);
    resolveRef.current = undefined;
    refetch();
    close();
  };

  const handleCancelClick = () => {
    resolveRef.current?.(false);
    resolveRef.current = undefined;
    close();
  };

  const handleError = () => {
    setToast('일시적인 오류가 발생했어요. 다시 시도해 주세요.');
    resolveRef.current?.(false);
    resolveRef.current = undefined;
    close();
  };

  useDialogOpened(() => {
    close();
  });

  const node = (
    <BottomSheet active={isOpened} onClickClose={close}>
      <EntryPoint
        bridge={karrotBridge}
        fetchVerify={fetchVerify}
        onCancelClick={handleCancelClick}
        onError={handleError}
        onIdentified={handleIdentified}
        onNotIdentified={handleNotIdentified}
        serviceName={serviceName}
        {...envProps}
      />
    </BottomSheet>
  );

  return [check, node, open] as const;
};

export default useIdentificationBottomSheet;
