import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';

import BottomSheetItem from '@/components/Base/BottomSheet/BottomSheetItem';
import useTrack from '@/hooks/useTrack';
import useUpdateFilter from '@/hooks/useUpdateFilter';

import { useFilterContext, useFilterUpdaterContext } from './FilterContextProvider';

const statusItems = [
  { title: '판매중', value: true },
  { title: '모든 매물', value: false },
];

const StatusFilter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    filter: { onlyOnSale },
  } = useFilterContext();
  const { updateOpenedFilter, startTransition } = useFilterUpdaterContext();
  const { trackWithActivityName } = useTrack();
  const updateFilter = useUpdateFilter();

  const handleChange = (nextValue: boolean) => {
    updateOpenedFilter(null);
    startTransition(() => {
      updateFilter({ onlyOnSale: nextValue });
    });
    trackWithActivityName('click_change_filter_status', { onlyOnSale: nextValue });
  };
  return (
    <Container>
      {statusItems.map(({ title, value }) => (
        <BottomSheetItem
          active={onlyOnSale === value}
          key={title}
          onClick={() => handleChange(value)}
          value={value}
        >
          {title}
        </BottomSheetItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: ${rem(2)} ${rem(16)} ${rem(9)};
  position: relative;
`;

export default StatusFilter;
