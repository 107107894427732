import { css, keyframes } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const thumbnailRoundCss = css({
  borderRadius: rem(6),
  overflow: 'hidden',
});

export const thumbnailBorderCss = css([
  thumbnailRoundCss,
  {
    border: `1px solid ${vars.$scale.color.grayAlpha50}`,
  },
]);

export const fullCoverImageCss = css({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});
