import { graphql, readInlineData } from 'relay-runtime';

import { getCarSpec_article$key } from '@/__generated__/getCarSpec_article.graphql';
import { getCarFullName } from '@/utils/carData';
import { getDriveDistanceText } from '@/utils/string';

import { getShortenYear } from '../number';
import { getPriceText } from './getPriceText';

export const getCarSpec = (articleRef: getCarSpec_article$key) => {
  const article = readInlineData(
    graphql`
      fragment getCarSpec_article on Article @inline {
        driveDistance
        carData {
          modelYear
          ...carData_getCarFullName_carData
        }
        ...getPriceText_article
      }
    `,
    articleRef
  );
  const { carData, driveDistance } = article;
  const { modelYear } = carData;

  const name = getCarFullName(carData);

  const priceText = getPriceText(article);
  const modelYearText = `${getShortenYear(modelYear)}년식`;
  const driveDistanceText = `${getDriveDistanceText(driveDistance)}km`;

  const spec = [priceText, modelYearText, driveDistanceText].join(' ⸱ ');
  const specWithoutPrice = [modelYearText, driveDistanceText].join(' ⸱ ');

  return { name, spec, specWithoutPrice, priceText, modelYearText, driveDistanceText };
};
