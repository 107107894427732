import { IS_PROD } from '@/constants/config';
import { karrotBridge } from '@/sdk/bridge';
import { appsflyerCustomEventRegex } from '@/sdk/tracker/CustomEvent';
import { appsflyerPvEventRegex } from '@/sdk/tracker/PvEvent';
import { TrackerImpl } from '@/sdk/tracker/TrackerImpl';
import { BaseEventParams, formatParams } from '@/utils/track';

function logAppsflyerEvent(type: 'click' | 'show', name: string, params?: BaseEventParams) {
  if (!IS_PROD) {
    return;
  }
  const mergedParams = formatParams({ name, ...params });
  const eventName = `${type}_car_${name}`;
  karrotBridge.logEvent({
    analytics: { target: 'APPSFLYER', name: eventName, params: mergedParams },
  });
}

const AppsflyerTracker = (): TrackerImpl => {
  let initialized = false;
  return {
    initialized,
    init: () => {
      initialized = true;
    },
    trackEvent: (name, params) => {
      if (!initialized) {
        throw new Error('AppsflyerTracker is not initialized');
      }
      if (appsflyerCustomEventRegex.test(name)) {
        logAppsflyerEvent('click', name, params);
      }
    },
    trackPageView: (name, params) => {
      if (!initialized) {
        throw new Error('AppsflyerTracker is not initialized');
      }
      if (appsflyerPvEventRegex.test(name)) {
        logAppsflyerEvent('show', name, params);
      }
    },
  };
};

export const appsflyerTracker = AppsflyerTracker();
