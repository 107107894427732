import { useRefEffect } from '@toss/react';
import { useCallback, useRef, useState } from 'react';

export type ImageStatus = 'error' | 'loaded' | 'loading';

export const useRetryImage = (maxRetryCount = 3) => {
  const [imageStatus, setImageStatus] = useState<ImageStatus>('loading');
  const [retryCount, setRetryCount] = useState(0);
  const onceLoaded = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const imageRef = useRefEffect<HTMLImageElement>(
    (imageElement) => {
      if (retryCount > 0 && !onceLoaded.current) {
        imageElement.src = imageElement.src;
      }
    },
    [retryCount]
  );

  const onError = useCallback(() => {
    if (retryCount >= maxRetryCount) {
      setImageStatus('error');
    } else {
      timeoutRef.current = setTimeout(() => {
        setRetryCount((pv) => ++pv);
      }, 1500);
    }
  }, [maxRetryCount, retryCount]);

  const onLoad = useCallback(() => {
    if (!onceLoaded.current) {
      onceLoaded.current = true;
      setImageStatus('loaded');
    }
  }, []);

  return {
    imageStatus,
    imageProps: {
      ref: imageRef,
      onError,
      onLoad,
    },
  };
};
