import { ActivityName, useStepFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

const useArticleStep = (activityName: ActivityName = 'article') => {
  const {
    stepPush: baseStepPush,
    stepReplace: baseStepReplace,
    stepPop,
  } = useStepFlow(activityName);
  const { articleId } = useCamelCaseParams<{ articleId: string }>();

  const stepParams = { articleId };
  const [stepPush, stepReplace] = [baseStepPush, baseStepReplace].map(
    (fn) =>
      ({ state }: { state: string }) =>
        fn({ ...stepParams, state })
  );

  return { stepPush, stepReplace, stepPop };
};

export default useArticleStep;
