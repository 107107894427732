import CONFIG from '@/constants/config';

import getFetcher from '../utils/fetcher';

const API_HOST = `${CONFIG.BIZ_ACCOUNT_HOST}/api/v2`;
const fetcher = getFetcher(API_HOST);

export default {
  fetchBizAccounts: async () => {
    const {
      data: { businessAccounts },
      error,
    } = await fetcher.get(`/business_accounts`).then((res) => res.data);

    if (error) {
      throw error;
    }

    return businessAccounts || [];
  },
};
