import styled from '@emotion/styled';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

import { noScrollbar } from '@/styles/mixins';

import HStack from './HStack';

type Props = {
  gap: number;
  onEndScroll?: () => void;
  onStartScroll?: () => void;
  outsideGap?: number;
};

const HScroll = ({
  gap,
  children,
  outsideGap = 16,
  onStartScroll,
  onEndScroll,
}: PropsWithChildren<Props>) => {
  return (
    <div
      css={{ marginRight: rem(-outsideGap), marginLeft: rem(-outsideGap) }}
      onTouchEnd={onEndScroll}
      onTouchStart={onStartScroll}
    >
      <Scroll css={{ paddingLeft: rem(outsideGap) }} gap={gap}>
        {children}
        <div css={{ width: rem(outsideGap), flex: '1 0 auto' }} />
      </Scroll>
    </div>
  );
};

const Scroll = styled(HStack)`
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${noScrollbar};
`;

export default HScroll;
