import { BoxButton } from '@daangn/sprout-components-button';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import Box from '@/components/Base/Box';
import KeywordHighlighter from '@/components/Base/KeywordHighlighter';
import Text from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';

import { useOverlayState } from './useOverlayState';

const useAbsoluteNoAccident = () => {
  const [isOpened, open, close] = useOverlayState('absolute_no_accident');
  const node = (
    <BottomSheet active={isOpened} onClickClose={close} title="완전 무사고">
      <VStack pb={8} px={16}>
        <Box pb={24} pt={12}>
          <Text color="gray900" variant="bodyL1Regular">
            <KeywordHighlighter
              keywords={['한 건의 수리, 정비 이력도 없는 차량']}
              render={(matchedKeyword) => (
                <Text color="accent" inline variant="bodyL1Bold">
                  {matchedKeyword}
                </Text>
              )}
              text="교환, 판금, 도색, 사고이력을 포함해 한 건의 수리, 정비 이력도 없는 차량을 의미해요."
            />
          </Text>
        </Box>
        <BoxButton onClick={close} size="xlarge" variant="primary" width="100%">
          확인
        </BoxButton>
      </VStack>
    </BottomSheet>
  );

  return [open, node] as const;
};

export default useAbsoluteNoAccident;
