import { pick } from 'lodash-es';

import type { MechanicReportCategory } from '@/types/MechanicReport/MechanicReport';
import type { ReportItemState } from '@/types/MechanicReport/ReportItemState';

export const pickMechanicReport = <T>(report: T, categories: MechanicReportCategory[]) => {
  const result = pick(report, categories);
  return result as {
    [category: string]: {
      [group: string]: { [part: string]: ReportItemState | undefined } | undefined;
    };
  };
};
