import {
  add as _add,
  format as _format,
  isAfter as _isAfter,
  isBefore as _isBefore,
  setHours as _setHours,
  setMinutes as _setMinutes,
  setMonth as _setMonth,
  setYear as _setYear,
  startOfDay as _startOfDay,
  startOfMonth as _startOfMonth,
  startOfYear as _startOfYear,
  toDate as _toDate,
  isDate,
  parseISO,
} from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import { ko } from 'date-fns/locale';
export type DatePrimitives = Date | string;
export type DateString = string;

export const isValid = (v: any): v is DatePrimitives => {
  if (isDate(v)) {
    return v.toString() !== 'Invalid Date';
  }
  if (typeof v === 'string') {
    return parseISO(v).toString() !== 'Invalid Date';
  }
  return false;
};

export const toDate = (v: DatePrimitives) => {
  if (typeof v === 'string') {
    return parseISO(v);
  }
  return _toDate(v);
};

export const toDateStr = (v: DatePrimitives) => {
  return toDate(v).toISOString();
};

type DateAdapter<T> = {
  [P in keyof T]: T[P] extends Date | number ? DatePrimitives : T[P];
};

type DateAdapterFn<T extends (...args: any) => any> = (
  ...v: DateAdapter<Parameters<T>>
) => ReturnType<T> extends Date ? string : ReturnType<T>;

const convertDatePrimitives =
  <T extends (...args: any) => any>(fn: T): DateAdapterFn<T> =>
  (...params) => {
    const result = fn(...(params as any[]).map((item) => (isValid(item) ? toDate(item) : item)));
    return isValid(result) ? toDate(result).toISOString() : result;
  };

export const format = convertDatePrimitives(_format);
const formatKo = formatWithOptions({ locale: ko });
// https://github.com/date-fns/date-fns/blob/main/src/locale/ko/snapshot.md
export const formatTemplates = {
  '1월 11일 (수) 오후 11:00': convertDatePrimitives(formatKo('MMM do (E) aaaa h:mm')),
  '1월 11일(수) 23:00': convertDatePrimitives(formatKo('MMM do(E) HH:mm')),
  '1월 11일 (수) 23:00': convertDatePrimitives(formatKo('MMM do (E) HH:mm')),
  '1월 11일 (수) 23시 00분': convertDatePrimitives(formatKo('MMM do (E) HH시 mm분')),
  '1/11(수) 오후 11:00': convertDatePrimitives(formatKo('M/dd(E) aaaa h:mm')),
  '24년 1월 1일': convertDatePrimitives(formatKo('yy년 MMM do')),
  '24년 1월 1일 23시': convertDatePrimitives(formatKo('yy년 MMM do HH시')),
  '1월 1일': convertDatePrimitives(formatKo('MMM do')),
  '2024년 1월 1일': convertDatePrimitives(formatKo('yyyy년 MMM do')),
  '오전 10:00': convertDatePrimitives(formatKo('a	h:mm')),
};

export const isAfter = convertDatePrimitives(_isAfter);
export const isBefore = convertDatePrimitives(_isBefore);
export const add = convertDatePrimitives(_add);
export const startOfYear = convertDatePrimitives(_startOfYear);
export const startOfDay = convertDatePrimitives(_startOfDay);
export const startOfMonth = convertDatePrimitives(_startOfMonth);
export const setHours = convertDatePrimitives(_setHours);
export const setMonth = convertDatePrimitives(_setMonth);
export const setMinutes = convertDatePrimitives(_setMinutes);
export const setYear = convertDatePrimitives(_setYear);
export const isSameDate = (date1: DatePrimitives, date2: DatePrimitives) =>
  new Date(date1).toISOString() === new Date(date2).toISOString();
