import React, { useMemo } from 'react';

import AdBanner from '@/components/Banner/AdBanner';

const cache = new Map();

export type AdItem = {
  imageUrl: string;
  link: string;
};

type Props = {
  adOwner: string;
  items: AdItem[];
};

const RandomAdBanner: React.FC<Props> = ({ adOwner, items }) => {
  const type = useMemo(() => {
    const cachedType = cache.get(adOwner);

    if (cachedType) {
      return cachedType;
    }
    const randomType = Math.random() > 0.5 ? 'A' : 'B';
    cache.set(adOwner, randomType);

    return randomType;
  }, [adOwner]);

  const index = items.length <= 1 ? 0 : type === 'A' ? 0 : 1;

  return <AdBanner adOwner={adOwner} type={type} {...items[index]} />;
};

export default RandomAdBanner;
