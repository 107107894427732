import { vars } from '@seed-design/design-token';
import { AppScreen, AppScreenProps } from '@stackflow/plugin-basic-ui';
import { useAtomValue } from 'jotai';
import React, { PropsWithChildren, Suspense, createContext, useContext, useState } from 'react';

import GlobalLoading from '@/components/Base/GlobalLoading';
import SkeletonInitialApp from '@/components/Skeleton/SkeletonInitialApp';
import { ActivityName, activityDescription } from '@/stackflow';
import { globalLoadingState } from '@/store/globalLoading';

type AppScreenOptions = Omit<AppScreenProps, 'children'>;
export type AppBarProps = AppScreenProps['appBar'];

type PageContextType = {
  screenOptions?: AppScreenOptions;
  setScreenOptions: React.Dispatch<React.SetStateAction<AppScreenOptions | undefined>>;
};
const PageContext = createContext<PageContextType>({
  screenOptions: undefined,
  setScreenOptions: () => undefined,
});
export const usePageContext = () => useContext(PageContext);

type Props = PropsWithChildren<{
  activityName: string;
}>;

const PageLayout = ({ activityName, children }: Props) => {
  const [screenOptions, setScreenOptions] = useState<AppScreenOptions | undefined>(undefined);
  const globalLoadingProps = useAtomValue(globalLoadingState);

  return (
    <PageContext.Provider value={{ screenOptions, setScreenOptions }}>
      <AppScreen
        backgroundColor={vars.$semantic.color.paperDefault}
        dimBackgroundColor={vars.$static.color.staticBlackAlpha500}
        {...activityDescription[activityName as ActivityName]?.defaultAppScreenProps}
        {...screenOptions}
        appBar={{
          border: false,
          ...screenOptions?.appBar,
        }}
      >
        <Suspense fallback={<SkeletonInitialApp />}>{children}</Suspense>
        {globalLoadingProps ? <GlobalLoading {...globalLoadingProps} /> : null}
      </AppScreen>
    </PageContext.Provider>
  );
};

export default PageLayout;
