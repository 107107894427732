import type { SyncStringStorage } from 'jotai/vanilla/utils/atomWithStorage';

import { atom } from 'jotai';
import { RESET, type atomWithReset, atomWithStorage, createJSONStorage } from 'jotai/utils';

import { carStorage, commonStorage } from '@/utils/storage';

const atomWithCustomStorage =
  <T>(storage: SyncStringStorage) =>
  (key: string, params: T): ReturnType<typeof atom<T>> => {
    const baseAtom = atomWithStorage(
      key,
      params,
      createJSONStorage(() => storage)
    );
    return baseAtom as any;
  };

export const atomWithCarStorage = <T>(key: string, params: T): ReturnType<typeof atom<T>> => {
  return atomWithCustomStorage(carStorage)(key, params) as any;
};

export const atomWithCommonStorage = <T>(key: string, params: T): ReturnType<typeof atom<T>> => {
  return atomWithCustomStorage(commonStorage)(key, params) as any;
};

export const atomWithResetAndCarStorage = <T>(
  key: string,
  params: T
): ReturnType<typeof atomWithReset<T>> => {
  const baseAtom = atomWithCarStorage(key, params);
  const combinedAtom = atom(
    (get) => get(baseAtom),
    (get, set, arg) => {
      if (arg === RESET) {
        set(baseAtom, params);
      } else {
        set(baseAtom, arg as T);
      }
    }
  );

  return combinedAtom as any;
};
