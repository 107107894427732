import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconPhotoRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React, { useCallback, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Swiper } from 'swiper';

import { ArticleImageSection_article$key } from '@/__generated__/ArticleImageSection_article.graphql';
import HStack from '@/components/Base/HStack';
import ImageDetailSlider from '@/components/Image/ImageDetailSlider';
import ImageSlider from '@/components/Image/ImageSlider';
import { useOverlayState } from '@/hooks/useOverlayState';
import useTrack from '@/hooks/useTrack';
import { useArticleContext } from '@/pages/Article/ArticleContext';
import { useFlow } from '@/stackflow';
import { getMergedImages } from '@/utils/article/getMergedImages';
import { getSizeProps } from '@/utils/style';

interface Props {
  articleRef: ArticleImageSection_article$key;
}

const ArticleImageSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const article = useFragment(
    graphql`
      fragment ArticleImageSection_article on Article {
        id
        user {
          id
        }
        ...getMergedImages_article
      }
    `,
    articleRef
  );
  const { id: articleId } = article;
  const [slideIndex, setSlideIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [isOpened, open, close] = useOverlayState('article_image');
  const mergedImages = getMergedImages(article);

  const { isMyArticle } = useArticleContext();

  const handleClickDetailImage = useCallback(
    (idx: number) => {
      track('article_click_image');
      setSelectedIndex(idx);
      open();
    },
    [open, track]
  );

  const handleClickAllImages = () => {
    push('article_images', { articleId });
  };

  const handleClickEditImage = () => {
    setSelectedIndex(undefined);
    push('edit_article_images', { articleId }, { animate: false });
  };

  const handleSlide = (swiper: Swiper) => {
    setSlideIndex(swiper.activeIndex);
  };

  if (!mergedImages.length) {
    return null;
  }

  return (
    <Container>
      <ImageSlider
        images={mergedImages}
        onDetailClick={handleClickDetailImage}
        onSlideChange={handleSlide}
      />
      <ImageDetailSlider
        images={mergedImages}
        initialIndex={selectedIndex}
        isOpen={isOpened}
        onClose={close}
        renderTopRight={() =>
          isMyArticle ? (
            <EditPhotoButton onClick={handleClickEditImage}>사진 수정</EditPhotoButton>
          ) : undefined
        }
      />
      <BottomButtonBox align="flex-end" gap={8}>
        {mergedImages.length > 1 && (
          <AllImageButton onClick={handleClickAllImages}>
            {slideIndex + 1}/{mergedImages.length}
            <Splitter />
            전체보기
          </AllImageButton>
        )}
        {isMyArticle ? (
          <BoxButton
            flex="0 0 auto"
            onClick={handleClickEditImage}
            prefix={<IconPhotoRegular {...getSizeProps(14)} />}
            size="xsmall"
            variant="secondary"
          >
            사진 수정
          </BoxButton>
        ) : undefined}
      </BottomButtonBox>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const BottomButtonBox = styled(HStack)({
  position: 'absolute',
  bottom: rem(10),
  right: rem(10),
  zIndex: 1,
});

const AllImageButton = styled.button`
  display: flex;
  align-items: center;
  background: ${vars.$static.color.staticBlackAlpha500};
  padding: ${rem(6)} ${rem(8)};
  height: ${rem(28)};
  border-radius: ${rem(6)};
  color: ${vars.$static.color.staticWhite};
  ${vars.$semantic.typography.label4Regular};
  z-index: 1;
`;

const EditPhotoButton = styled.button`
  color: ${vars.$static.color.staticWhite};
  ${vars.$semantic.typography.label2Regular};
`;

const Splitter = styled.div`
  width: ${rem(1)};
  height: ${rem(12)};
  background: ${vars.$static.color.staticWhiteAlpha200};
  margin: 0 ${rem(6)};
`;

export default ArticleImageSection;
