import { captureException, setContext, setTag, setUser } from '@sentry/core';
import React, { useEffect } from 'react';

import { preloadPageChunks } from '@/bootstrap/pages';
import useUser from '@/hooks/useUser';
import { sessionStorage } from '@/utils/storage';

const InitApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, region } = useUser();

  useEffect(() => {
    const id = String(user?.id ?? 'unknown');
    const username = user?.nickname;
    setUser({ id, username });
    setTag('userId', id);
    setTag('userNickname', username);
    setTag('region', region.id);
    setContext('region', region);

    setTimeout(() => {
      try {
        window.clarity?.('identify', String(user?.id));
        window.clarity?.('set', 'referrer', sessionStorage.getReferrer());
        window.clarity?.('set', 'region', String(region.id));
      } catch (err: any) {
        if (err) {
          err.message = `[clarity 초기화 error]: ${err.message}`;
          captureException(err);
        }
      }
    }, 500);
  }, [user, region]);

  useEffect(() => {
    setTimeout(() => {
      preloadPageChunks();
    }, 5000);
  }, []);
  return <></>;
};

export default InitApp;
