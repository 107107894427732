import { useMotionValueEvent, useScroll } from 'framer-motion';
import React, { useState } from 'react';

type Option = {
  scrollContainerRef: React.RefObject<HTMLElement>;
  targetRef: React.RefObject<HTMLElement>;
};

export const useScrollPass = ({
  scrollContainerRef,
  targetRef,
  onPassChange,
}: { onPassChange: (isPassed: boolean) => void } & Option) => {
  const { scrollY } = useScroll({ container: scrollContainerRef });

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const $target = targetRef.current;
    if (!$target) {
      return;
    }
    const isPassed = latest >= $target.offsetTop + $target.clientHeight;
    onPassChange(isPassed);
  });
};

export const useScrollPassState = (option: Option) => {
  const [isPassed, setIsPassed] = useState(false);

  useScrollPass({ ...option, onPassChange: setIsPassed });

  return isPassed;
};
