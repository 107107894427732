import { differenceInMonths, setMonth, setYear, startOfMonth } from 'date-fns';
import { graphql, readInlineData } from 'relay-runtime';
import { match } from 'ts-pattern';

import { carData_assembleCarName_carData$key } from '@/__generated__/carData_assembleCarName_carData.graphql';
import { carData_getCarFullName_carData$key } from '@/__generated__/carData_getCarFullName_carData.graphql';
import { carData_getCarNameByCompanyOrigin_carData$key } from '@/__generated__/carData_getCarNameByCompanyOrigin_carData.graphql';
import { carData_getStandardDriveDistance_CarData$key } from '@/__generated__/carData_getStandardDriveDistance_CarData.graphql';
import { Manufacturer, ManufacturerText } from '@/types/CarData/Manufacturer';

export const getCarFullName = (carDataRef: carData_getCarFullName_carData$key) => {
  if (!carDataRef) {
    return '';
  }
  const { carName, manufacturer, autoupModel } = readInlineData(
    graphql`
      fragment carData_getCarFullName_carData on CarData @inline {
        carName
        manufacturer
        autoupModel {
          company {
            name
          }
        }
      }
    `,
    carDataRef
  );
  const { company } = autoupModel ?? {};

  return [company?.name ?? ManufacturerText[manufacturer as Manufacturer] ?? '', carName]
    .filter(Boolean)
    .join(' ');
};

type AssembleCarNameOption = Partial<{
  company: boolean;
  grade: boolean;
  series: boolean;
  subgrade: boolean;
  subseries: boolean;
}>;

export const assembleCarName = (
  carDataRef: carData_assembleCarName_carData$key,
  options: AssembleCarNameOption
) => {
  if (!carDataRef) {
    return '';
  }
  const { manufacturer, autoupModel } = readInlineData(
    graphql`
      fragment carData_assembleCarName_carData on CarData @inline {
        carName
        manufacturer
        autoupModel {
          company {
            name
          }
          series {
            name
          }
          subseries {
            name
          }
          grade {
            name
          }
          subgrade {
            name
          }
        }
      }
    `,
    carDataRef
  );
  const { company, grade, series, subgrade, subseries } = autoupModel ?? {};
  const result: string[] = [];
  if (options.company) {
    const companyName = company?.name ?? ManufacturerText[manufacturer as Manufacturer];
    if (companyName) {
      result.push(companyName);
    }
  }
  if (options.series) {
    const seriesName = series?.name;
    if (seriesName) {
      result.push(seriesName);
    }
  }
  if (options.subseries) {
    const subseriesName = subseries?.name;
    if (subseriesName && subseriesName !== '없음') {
      result.push(subseriesName);
    } else if (!options.series) {
      // 엄청 레어케이스로 subseries가 없는 데이터가 있음.
      const seriesName = series?.name;
      if (seriesName) {
        result.push(seriesName);
      }
    }
  }
  if (options.grade) {
    const gradeName = grade?.name;
    if (gradeName && gradeName !== '없음') {
      result.push(gradeName);
    }
  }
  if (options.subgrade) {
    const subgradeName = subgrade?.name;
    if (subgradeName && subgradeName !== '없음') {
      result.push(subgradeName);
    }
  }
  return result.join(' ');
};

export const getCarNameByCompanyOrigin = (
  carDataRef: carData_getCarNameByCompanyOrigin_carData$key
) => {
  if (!carDataRef) {
    return '';
  }
  const carData = readInlineData(
    graphql`
      fragment carData_getCarNameByCompanyOrigin_carData on CarData @inline {
        ...carData_assembleCarName_carData
        autoupModel {
          company {
            companyOrigin
          }
        }
      }
    `,
    carDataRef
  );
  const companyOrigin = carData?.autoupModel?.company?.companyOrigin;

  return match(companyOrigin)
    .with('FOREIGN', () => assembleCarName(carData, { company: true, subseries: true }))
    .otherwise(() => assembleCarName(carData, { subseries: true }));
};

/**
 * 표준 주행거리.
 * 차 출고부터 진단 시점까지를 기준으로 1개월 1660km, 1년 기준 19920km
 */
export const getStandardDriveDistance = (
  carDataRef: carData_getStandardDriveDistance_CarData$key,
  endDate: Date
) => {
  const { carRegistrationMonth, carRegistrationYear } = readInlineData(
    graphql`
      fragment carData_getStandardDriveDistance_CarData on CarData @inline {
        carRegistrationYear
        carRegistrationMonth
      }
    `,
    carDataRef
  );
  if (!carRegistrationYear || !carRegistrationMonth) {
    return undefined;
  }
  const registration = startOfMonth(
    setMonth(setYear(Date.now(), carRegistrationYear), carRegistrationMonth)
  );
  const monthDiff = differenceInMonths(endDate, registration);
  return 1660 * monthDiff;
};

export type CarDataEventProperties = {
  accidentType?: 'ABSOLUTE_NO_ACCIDENT' | 'ACCIDENT' | 'NO_ACCIDENT';
  carName: string;
  carType: string;
  displacement?: number;
  driveDistance: number;
  fuelType: string;
  manufacturer: string;
  modelYear: number;
  optionCount: number;
};

export const getCarDataEventProperties = (carDataRef: any): CarDataEventProperties => {
  const carData = readInlineData(
    graphql`
      fragment carData_getCarDataEventProperties on CarData @inline {
        displacement
        carType
        modelYear
        fuelType
        manufacturer
        options
        accidentType
        carName
      }
    `,
    carDataRef
  );
  return {
    carName: carData.carName,
    displacement: carData.displacement ?? undefined,
    optionCount: carData.options?.length ?? 0,
    modelYear: carData.modelYear,
    carType: carData.carType,
    fuelType: carData.fuelType,
    manufacturer: carData.manufacturer,
    driveDistance: carData.driveDistance,
    accidentType: carData.accidentType,
  };
};
