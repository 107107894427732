/**
 * @generated SignedSource<<d3a42bff9f4ffdf0ece1a1026f6e1747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useIsInspectableRegionQuery$variables = {
  regionId: string;
  skip: boolean;
};
export type useIsInspectableRegionQuery$data = {
  readonly isInspectableRegion?: boolean;
};
export type useIsInspectableRegionQuery = {
  response: useIsInspectableRegionQuery$data;
  variables: useIsInspectableRegionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "regionId",
            "variableName": "regionId"
          }
        ],
        "kind": "ScalarField",
        "name": "isInspectableRegion",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useIsInspectableRegionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useIsInspectableRegionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "40e5743c672684cd899375867fcf466c",
    "id": null,
    "metadata": {},
    "name": "useIsInspectableRegionQuery",
    "operationKind": "query",
    "text": "query useIsInspectableRegionQuery(\n  $regionId: ID!\n  $skip: Boolean!\n) {\n  isInspectableRegion(regionId: $regionId) @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "2dcd525c62fef0784d024ca8db879a88";

export default node;
