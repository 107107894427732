import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticlePromotionTimer_article$key } from '@/__generated__/ArticlePromotionTimer_article.graphql';
import CountdownTimer from '@/components/Base/CountdownTimer';
import HStack from '@/components/Base/HStack';
import { Label3Bold } from '@/components/Base/Text';
import { PropsOf } from '@/types/React/PropsOf';

type Props = {
  articleRef: ArticlePromotionTimer_article$key;
} & PropsOf<typeof HStack>;

const ArticlePromotionTimer: React.FC<Props> = ({ articleRef, ...props }) => {
  const { promotionEndsAt } = useFragment(
    graphql`
      fragment ArticlePromotionTimer_article on Article {
        promotionEndsAt
      }
    `,
    articleRef
  );

  if (!promotionEndsAt) {
    return null;
  }

  return (
    <HStack align="center" bg="carrot50" br={6} justify="space-between" px={16} py={12} {...props}>
      <Label3Bold>지금 광고중이에요!</Label3Bold>
      <CountdownTimer
        format={['hours', 'minutes', 'seconds']}
        initialEndsAt={new Date(promotionEndsAt).getTime()}
      />
    </HStack>
  );
};

export default ArticlePromotionTimer;
