import { CarType } from '@/types/CarData/CarType';
import { FuelType } from '@/types/CarData/FuelType';

import { NUM_1만 } from '../number';
import { isElectronic } from './isElectronic';
import { isHybrid } from './isHybrid';

// https://www.notion.so/daangn/A-to-Z-1685f451d2054b22b415fd6c761d85de#472b94b84ad142d88e87ffad52ba2d7e

export const getAcquisitionTax = ({
  price,
  carType,
  fuelType,
  displacement,
}: {
  carType: CarType;
  displacement: number;
  fuelType: FuelType;
  price: number;
}) => {
  const is배기량1000미만 = displacement > 0 && displacement < 1000;
  const is전기차 = isElectronic(fuelType);
  const is전기경차 = is전기차 && carType === CarType.Small;
  const is내연경차 = !is전기차 && is배기량1000미만;
  const is경차 = is내연경차 || is전기경차;
  const isTruck = carType === CarType.Business;
  const taxRate = is경차 ? 0.04 : isTruck ? 0.05 : 0.07;
  const taxDeductions = [0];

  if (is경차) {
    taxDeductions.push(75 * NUM_1만);
  }
  if (is전기차) {
    taxDeductions.push(140 * NUM_1만);
  }
  if (isHybrid(fuelType)) {
    taxDeductions.push(40 * NUM_1만);
  }
  const deduction = Math.max(...taxDeductions);
  const originalAmount = Math.floor(price * taxRate);

  return {
    originalAmount,
    deduction: Math.min(deduction, originalAmount),
    amount: Math.floor(Math.max(0, price * taxRate - deduction)),
    taxRate,
  };
};
