/**
 * @generated SignedSource<<db423e6dafc3268bc6e2b16ed1abd446>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleProxyInspectionSection_article$data = {
  readonly id: string;
  readonly " $fragmentType": "ArticleProxyInspectionSection_article";
};
export type ArticleProxyInspectionSection_article$key = {
  readonly " $data"?: ArticleProxyInspectionSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleProxyInspectionSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleProxyInspectionSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "2a49e8a8210ef880df32ec76a0c22393";

export default node;
