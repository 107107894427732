import { graphql, readInlineData } from 'relay-runtime';

import { getPublishedAtText_article$key } from '@/__generated__/getPublishedAtText_article.graphql';

import { formatDistanceKo } from '../date';

export const getPublishedAtText = (articleRef: getPublishedAtText_article$key) => {
  const article = readInlineData(
    graphql`
      fragment getPublishedAtText_article on Article @inline {
        pullUpCount
        publishedAt
      }
    `,
    articleRef
  );
  const { pullUpCount, publishedAt } = article;

  return `${pullUpCount > 0 ? '끌올 ' : ''}${formatDistanceKo(publishedAt)}`;
};
