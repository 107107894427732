import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React from 'react';

import Spacer from './Spacer';

type ListItem = {
  disabled?: boolean;
  title: React.ReactNode | string;
  value: React.ReactNode | string;
};

type Props = {
  gap?: number;
  items: ListItem[];
  titleCss?: SerializedStyles;
  valueCss?: SerializedStyles;
};

const List: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  titleCss = '',
  valueCss = '',
  gap = 16,
  ...props
}) => {
  if (!items.length) {
    return null;
  }

  return (
    <Container {...props}>
      {items.map(({ title, value, disabled }, index, list) => (
        <React.Fragment key={`${title}_${index}`}>
          <Item disabled={disabled}>
            <Block>
              <Text css={titleCss}>{title}</Text>
            </Block>
            <Block>
              <Text css={valueCss}>{value}</Text>
            </Block>
          </Item>
          {index < list.length - 1 && <Spacer axis="vertical" size={gap} />}
        </React.Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
`;

const Block = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  ${vars.$semantic.typography.bodyL1Regular}
  color: ${vars.$scale.color.gray900};

  & > b {
    color: ${vars.$semantic.color.warning};
    ${vars.$semantic.typography.bodyL1Bold}
  }
`;

export default List;
