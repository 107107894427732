import type { Feature, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson';

import { Map, type MapInstance, Marker, Pin } from '@daangn/maps-react';
import React, { memo, useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';

import type { ShortCoordinates } from '@/utils/map/Coordinates';

import { polygonToMultiPolygon } from '@/utils/map/polygonToMultiPolygon';
import { toGeoJsonSourceRaw } from '@/utils/map/toGeoJsonSourceRaw';

export type KarrotMapResourceProps = {
  coordinates?: ShortCoordinates;
  polygon?: Feature<MultiPolygon | Polygon, GeoJsonProperties>;
};

const KarrotMapResource = ({ coordinates, polygon }: KarrotMapResourceProps) => {
  const [map, setMap] = useState<MapInstance | undefined>(undefined);

  useEffect(() => {
    match([map, polygon])
      .with([P.not(undefined), P.not(undefined)], ([map, v]) => {
        map.addPolygonLayer(
          toGeoJsonSourceRaw(
            v.geometry.type === 'Polygon'
              ? polygonToMultiPolygon(v as Feature<Polygon, GeoJsonProperties>)
              : v
          ),
          {
            areaMarginPixel: { bottom: 30, left: 30, right: 30, top: 30 },
            moveToCenter: true,
            removeOthers: true,
          }
        );
      })
      .otherwise(() => undefined);
  }, [map, polygon]);

  return (
    <Map
      initialMapState={{
        center: coordinates,
      }}
      interactive={false}
      module={false}
      onLoad={({ target }) => {
        setMap(target);
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {coordinates && (
        <Marker anchor="bottom" position={coordinates}>
          <Pin type="pin-single-type" />
        </Marker>
      )}
    </Map>
  );
};

export default memo(KarrotMapResource);
