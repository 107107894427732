import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { LoanBottomSheet_article$key } from '@/__generated__/LoanBottomSheet_article.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import useArticleStep from '@/hooks/useArticleStep';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { pressed } from '@/styles/mixins';
import { getAllowedLoanList } from '@/utils/article/getAllowedLoanList';
import { sessionStorage } from '@/utils/storage';
import { getSizeProps } from '@/utils/style';
import { openInAppBrowser, useCamelCaseParams } from '@/utils/url';

import { type DisplayState } from '../types';

type Props = {
  articleRef: LoanBottomSheet_article$key;
};

const LoanBottomSheet: React.FC<Props> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const { state } = useCamelCaseParams<{ state?: DisplayState }>();
  const { stepPop } = useArticleStep();
  const article = useFragment(
    graphql`
      fragment LoanBottomSheet_article on Article {
        loanTrackingUrl {
          hcs
        }
        ...getAllowedLoanList_article
      }
    `,
    articleRef
  );
  const { loanTrackingUrl } = article;
  const allowedLoanList = getAllowedLoanList(article);

  return (
    <BottomSheet
      active={state === 'loan'}
      onClickClose={stepPop}
      showCloseButton
      title="대출한도를 조회해보세요"
    >
      {allowedLoanList.map(({ id, title, interestRate, description, limit, icon }) => {
        const handleClick = () => {
          track('article_click_loan_item', { company: id });

          if (id === 'hcs') {
            sessionStorage.setItem('hyundai_capital_article_id', id);
            stepPop();
            push('hyundai_capital', {});
            return;
          }
          const trackingUrl = loanTrackingUrl[id];

          if (trackingUrl) {
            openInAppBrowser(trackingUrl);
          }
        };

        return (
          <LoanItem key={id} onClick={handleClick}>
            <Wrapper>
              <Image src={icon} />
              <TextWrapper>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <ExplanationWrapper>
                  <InterestRate>금리 연 {interestRate}</InterestRate>
                  <Explanation>한도 최대 {limit}</Explanation>
                </ExplanationWrapper>
              </TextWrapper>
            </Wrapper>
            <Icon {...getSizeProps(16)} color={vars.$scale.color.gray600} />
          </LoanItem>
        );
      })}
    </BottomSheet>
  );
};

const LoanItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(14)} ${rem(16)};

  ${pressed()}
`;

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Image = styled.img`
  width: ${rem(44)};
  height: ${rem(44)};
  margin-right: ${rem(12)};
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.p`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label2Bold}
`;

const Description = styled.p`
  margin-top: ${rem(4)};
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.label4Regular}
`;

const ExplanationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${rem(8)};
  margin-top: ${rem(8)};
`;

const Explanation = styled.p`
  ${vars.$semantic.typography.label4Bold}
  color: ${vars.$scale.color.gray700};
`;

const InterestRate = styled(Explanation)`
  color: ${vars.$semantic.color.accent};
`;

const Icon = styled(IconChevronRightRegular)`
  margin-top: ${rem(13)};
`;

export default LoanBottomSheet;
