import { BoxButton, type SeedBoxButtonProps } from '@daangn/sprout-components-button';
import { convertNewlineToJSX } from '@toss/react';

import { Subtitle1Regular, Subtitle2Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';

type Props = {
  buttonProps: Pick<SeedBoxButtonProps, 'children' | 'isLoading' | 'onClick'>;
  description: string;
  title: string;
};

const NoResult = ({ buttonProps, description, title, ...props }: Props) => {
  return (
    <VStack align="center" gap={38} {...props}>
      <VStack align="center" gap={4}>
        <Subtitle1Regular>{convertNewlineToJSX(title)}</Subtitle1Regular>
        <Subtitle2Regular color="gray600">{description}</Subtitle2Regular>
      </VStack>
      <BoxButton variant="secondary" {...buttonProps} />
    </VStack>
  );
};

export default NoResult;
