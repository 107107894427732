import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';

type Variant = 'accent' | 'basic' | 'error' | 'primary' | 'success';
type Size = 'large' | 'medium' | 'small';
type Style = 'bold' | 'normal' | 'outline';

type Props = {
  size?: Size;
  style?: Style;
  variant?: Variant;
};

const SquareBadge: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  variant = 'basic',
  size = 'medium',
  style = 'normal',
  ...props
}) => {
  return (
    <SquareBadgeContainer size={size} styleName={style} variant={variant} {...props}>
      {children}
    </SquareBadgeContainer>
  );
};

const PillBadge: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  variant = 'basic',
  size = 'medium',
  style = 'normal',
  ...props
}) => {
  return (
    <PillBadgeContainer size={size} styleName={style} variant={variant} {...props}>
      {children}
    </PillBadgeContainer>
  );
};

const getColorSet = (variant: Variant, styleName: Style) => {
  switch (variant) {
    case 'basic':
      return {
        mainColor: vars.$scale.color.gray700,
        borderColor: vars.$scale.color.gray900,
        subColor: vars.$scale.color.gray100,
      };
    case 'primary':
      return {
        mainColor: vars.$semantic.color.primary,
        borderColor: vars.$semantic.color.primary,
        subColor: vars.$scale.color.carrotAlpha100,
      };
    case 'success':
      return {
        mainColor:
          styleName === 'normal' ? vars.$scale.color.green700 : vars.$semantic.color.success,
        borderColor: vars.$semantic.color.success,
        subColor: vars.$semantic.color.successLow,
      };
    case 'accent':
      return {
        mainColor: styleName !== 'bold' ? vars.$scale.color.blue800 : vars.$semantic.color.accent,
        borderColor: vars.$scale.color.blue700,
        subColor: vars.$scale.color.blue50,
      };
    case 'error':
      return {
        mainColor: vars.$semantic.color.danger,
        borderColor: vars.$semantic.color.danger,
        subColor: vars.$semantic.color.dangerLow,
      };
  }
};

type ContainerProps = { styleName: Style } & Required<Pick<Props, 'size' | 'variant'>>;

const Container = styled.div<ContainerProps>`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          border-radius: ${rem(2)};
          padding: ${rem(2)} ${rem(4)};
          ${vars.$semantic.typography.label6Regular}
        `;
      case 'medium':
        return css`
          border-radius: ${rem(2)};
          padding: ${rem(2)} ${rem(6)};
          ${vars.$semantic.typography.label5Regular}
        `;
      case 'large':
        return css`
          border-radius: ${rem(4)};
          padding: ${rem(4)} ${rem(8)};
          ${vars.$semantic.typography.caption2Regular}
        `;
    }
  }}

  ${({ variant, styleName }) => {
    const { mainColor, borderColor, subColor } = getColorSet(variant, styleName);

    switch (styleName) {
      case 'normal':
        return css`
          background-color: ${subColor};
          color: ${mainColor};
        `;
      case 'outline':
        return css`
          border: 1px solid ${borderColor};
          color: ${borderColor};
          font-weight: bold;
        `;
      case 'bold':
        return css`
          background-color: ${mainColor};
          color: ${variant === 'basic' ? vars.$scale.color.gray00 : vars.$static.color.staticWhite};
          font-weight: bold;
        `;
    }
  }}
`;

const SquareBadgeContainer = styled(Container)(({ size }) => {
  switch (size) {
    case 'small':
    case 'medium':
      return {
        borderRadius: rem(2),
      };
    case 'large':
      return {
        borderRadius: rem(4),
      };
  }
});

const PillBadgeContainer = styled(Container)({
  borderRadius: rem(1000),
});

export { PillBadge, SquareBadge };
