import { graphql, useLazyLoadQuery } from 'react-relay';

import { useTradedArticlesQuery } from '@/__generated__/useTradedArticlesQuery.graphql';

const LOAD_COUNT = 10;

type Options = {
  count?: number;
  gradeId?: string;
  subgradeId?: string;
  subseriesId: string;
};

const useTradedArticles = ({ subseriesId, gradeId, subgradeId, count = LOAD_COUNT }: Options) => {
  const { tradedArticles } = useLazyLoadQuery<useTradedArticlesQuery>(
    graphql`
      query useTradedArticlesQuery(
        $subseriesId: Int!
        $gradeId: Int
        $subgradeId: Int
        $count: Int!
      ) {
        tradedArticles(
          subseriesId: $subseriesId
          gradeId: $gradeId
          subgradeId: $subgradeId
          num: $count
        ) {
          id
          events {
            type
            t
          }
          ...TransactionHistoryItem_article
        }
      }
    `,
    {
      subseriesId: Number(subseriesId),
      gradeId: gradeId ? Number(gradeId) : undefined,
      subgradeId: subgradeId ? Number(subgradeId) : undefined,
      count,
    }
  );

  return tradedArticles;
};

export default useTradedArticles;
