import { Identify, Types, identify, init, setDeviceId, track } from '@amplitude/analytics-browser';
import { Md5 } from 'ts-md5';

import CONFIG, { IS_PROD } from '@/constants/config';
import { carStorage } from '@/utils/storage';
import { isCarTeamUserId } from '@/utils/user';

import { amplitudeCustomEventRegex } from './CustomEvent';
import { amplitudePvEventRegex } from './PvEvent';
import { TrackerImpl } from './TrackerImpl';

const md5 = (txt: string) => {
  const md5Instance = new Md5();
  md5Instance.appendStr(txt);
  return md5Instance.end() as string;
};

const defaultSampleRate = IS_PROD ? 0.05 : 1;

const isUserSampled = (userId: number, samplingRate = defaultSampleRate) => {
  const hash = md5(userId.toString());
  const intVal = parseInt(hash.substring(0, 8), 16);
  const decimalVal = intVal / 0xffffffff;
  return decimalVal < samplingRate;
};

const defaultOptions: Types.BrowserOptions = {
  defaultTracking: false,
};

const AmplitudeTracker = (): TrackerImpl => {
  let initialized = false;
  let sampled = false;
  return {
    initialized,
    init: ({ user, app, region }) => {
      if (initialized || !CONFIG.AMPLITUDE_API_KEY) {
        return undefined;
      }
      const identifyEvent = new Identify();
      identifyEvent.setOnce('isCarTeamAdmin', isCarTeamUserId(String(user?.id ?? '')));
      if (region && region.name2) {
        identifyEvent.set('regionName2', region.name2);
      }
      // 사용자 ID 최소 길이 제한
      // ref: https://www.docs.developers.amplitude.com/analytics/apis/http-v2-api/#device-ids-and-user-ids-minimum-length
      init(CONFIG.AMPLITUDE_API_KEY, user?.id ? `karrot-${user.id}` : undefined, defaultOptions);
      identify(identifyEvent);
      setDeviceId(app?.deviceIdentity ?? carStorage.getGaClientId());
      initialized = true;
      sampled = isUserSampled(user.id);
    },
    trackEvent: (name, params) => {
      if (!initialized) {
        throw new Error('AmplitudeTracker is not initialized');
      }
      if (!sampled) {
        return undefined;
      }
      if (amplitudeCustomEventRegex.test(name)) {
        track(name, params);
      }
    },
    trackPageView: (name, params) => {
      if (!initialized) {
        throw new Error('AmplitudeTracker is not initialized');
      }
      if (!sampled) {
        return undefined;
      }
      if (amplitudePvEventRegex.test(name)) {
        track(name, params);
      }
    },
  };
};

export const amplitudeTracker = AmplitudeTracker();
