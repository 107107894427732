import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { noop } from 'lodash-es';
import { rem } from 'polished';
import { createContext, useCallback, useContext, useState } from 'react';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';
import useTrack from '@/hooks/useTrack';
import { StorageKey, carStorage } from '@/utils/storage';

type NotificationContextState = {
  isBottomSheetOpened: boolean;
  openNotificationBottomSheet: () => void;
};

const NotificationContext = createContext<NotificationContextState>({
  isBottomSheetOpened: false,
  openNotificationBottomSheet: noop,
});

export const NotificationContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { trackWithActivityName } = useTrack();
  const [isBottomSheetOpened, setBottomSheetOpened] = useState(false);

  const openNotificationBottomSheet = useCallback(() => {
    setBottomSheetOpened(true);
    carStorage.setItem(StorageKey.NotificationMaxReached, 'true');
  }, []);

  const handleClickConfirm = () => {
    setBottomSheetOpened(false);
    trackWithActivityName('click_confirm_notification_bottom_sheet');
  };

  const handleClickClose = () => {
    setBottomSheetOpened(false);
    trackWithActivityName('click_close_notification_bottom_sheet');
  };

  return (
    <NotificationContext.Provider value={{ isBottomSheetOpened, openNotificationBottomSheet }}>
      {children}
      <BottomSheet
        active={isBottomSheetOpened}
        onClickClose={handleClickClose}
        title="알림을 5개 등록하셨네요!"
      >
        <Wrapper>
          <Body>
            알림은 최대 {MAX_NOTIFICATION_COUNT}개까지만 등록할 수 있어요. 알림 관리 화면에서 기존
            알림을 삭제하시면 새로운 알림을 등록할 수 있어요.
          </Body>
          <Image src="https://assetstorage.krrt.io/1030025633538651406/49ba719c-5278-40ad-9691-ccb3e4b33559/width=1005,height=588.png" />
          <BoxButton onClick={handleClickConfirm} size="xlarge" variant="primary" width="100%">
            확인
          </BoxButton>
        </Wrapper>
      </BottomSheet>
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

const Wrapper = styled.div`
  padding: 0 ${rem(16)};
`;

const Body = styled.p`
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.bodyL1Regular};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: ${rem(24)} 0;
  border: 1px solid ${vars.$semantic.color.divider1};
`;
