import React from 'react';

import KeywordHighlighter from '@/components/Base/KeywordHighlighter';

type Props = {
  keywords?: string[];
  render?: (matchedKeyword: string) => JSX.Element;
  text: string;
};

const MultilineText: React.FC<React.PropsWithChildren<Props>> = ({ text, render, keywords }) => {
  const splitted = text.split('\n');
  return (
    <>
      {splitted.map((line, idx) => (
        <React.Fragment key={idx}>
          {keywords ? (
            <KeywordHighlighter keywords={keywords} render={render} text={line} />
          ) : (
            <span>{line}</span>
          )}
          {idx !== splitted.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export default MultilineText;
