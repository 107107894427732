import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { MainContentsSection_query$key } from '@/__generated__/MainContentsSection_query.graphql';
import InspectorTraineeBanner from '@/components/Banner/InspectorTraineeBanner';
import Box from '@/components/Base/Box';
import RollingCarousel from '@/components/RollingCarousel';
import { INSPECTOR_TRAINEE_APPLY_LINK } from '@/constants/scheme';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { openInAppBrowser } from '@/utils/url';

import TradedCarsButton from './TradedCarsButton';

type Props = {
  queryRef: MainContentsSection_query$key;
};

const random = Math.random() <= 0.5;

const MainContentsSection: React.FC<Props> = ({ queryRef }) => {
  const { track } = useTrack();
  const query = useFragment(
    graphql`
      fragment MainContentsSection_query on Query {
        ...TradedCarsButton_query
        ...CurationSection_query
      }
    `,
    queryRef
  );
  const { push } = useFlow();

  const handleClickTradedCars = () => {
    push('traded_cars', {});
    track('main_click_traded_cars');
  };

  const handleClickInspectorTrainee = () => {
    openInAppBrowser(INSPECTOR_TRAINEE_APPLY_LINK);
    track('main_click_inspector_trainee_banner');
  };

  return (
    <Box pb={8} pt={4}>
      {random ? (
        <RollingCarousel px={16}>
          <InspectorTraineeBanner onClick={handleClickInspectorTrainee} />
          <TradedCarsButton onClick={handleClickTradedCars} queryRef={query} />
        </RollingCarousel>
      ) : (
        <RollingCarousel px={16}>
          <TradedCarsButton onClick={handleClickTradedCars} queryRef={query} />
          <InspectorTraineeBanner onClick={handleClickInspectorTrainee} />
        </RollingCarousel>
      )}
    </Box>
  );
};

export default MainContentsSection;
