/**
 * @generated SignedSource<<05e651b19330e3f16fc4d9a376528405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccidentType = "ABSOLUTE_NO_ACCIDENT" | "ACCIDENT" | "NO_ACCIDENT";
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
export type CarOptionName = "AEB" | "AROUND_VIEW" | "AUTO_AIR_CONDITIONER" | "AUTO_FOLDING_SIDE_MIRROR" | "AUTO_TRUNK" | "AUTO_WIPER" | "BLUETOOTH" | "BSD" | "CRUISE_CONTROL" | "DASHBOARD_CAMERA" | "EPB" | "HEADLAMP" | "HEATED_SEAT" | "HEATED_STEERING_WHEEL" | "HIPASS" | "HUD" | "LDWS" | "LEATHER_SEAT" | "NAVIGATION" | "NUM_OF_KEY_2" | "PANORAMA_SUNROOF" | "PARK_SENSOR_FRONT" | "PARK_SENSOR_REAR" | "POWER_SEAT" | "REAR_CAMERA" | "SMART_KEY" | "SMOKING_FREE" | "SUNROOF" | "TPMS" | "VENTILATED_SEAT";
import { FragmentRefs } from "relay-runtime";
export type ArticleBadges_article$data = {
  readonly carData: {
    readonly accidentType: AccidentType | null | undefined;
    readonly options: ReadonlyArray<CarOptionName> | null | undefined;
  };
  readonly status: ArticleStatus;
  readonly " $fragmentSpreads": FragmentRefs<"getLastTransferInspection_article" | "isInspectedCar_article">;
  readonly " $fragmentType": "ArticleBadges_article";
};
export type ArticleBadges_article$key = {
  readonly " $data"?: ArticleBadges_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleBadges_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleBadges_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "options",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accidentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getLastTransferInspection_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NiceDnrHistory",
              "kind": "LinkedField",
              "name": "niceDnrHistory",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "driveDistance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isInspectedCar_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Inspection",
              "kind": "LinkedField",
              "name": "inspection",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMechanicReportVisible",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "25c1839f7de4dece7fd827c0e27d21b1";

export default node;
