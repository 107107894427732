import { Divider } from '@daangn/sprout-components-divider';
import {
  IconBillRegular,
  IconHeartRegular,
  IconNotificationRegular,
  IconWriteRegular,
} from '@seed-design/icon';
import { useHistoryTick } from '@stackflow/plugin-history-sync';
import { rem } from 'polished';
import React from 'react';

import HStack from '@/components/Base/HStack';
import Text from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import Drawer from '@/components/Drawer';
import { IS_PROD } from '@/constants/config';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';
import { CsCenterScheme, INSPECTOR_TRAINEE_APPLY_LINK } from '@/constants/scheme';
import { useOverlayState } from '@/hooks/useOverlayState';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import useTrack from '@/hooks/useTrack';
import useUser from '@/hooks/useUser';
import { useFlow } from '@/stackflow';
import { openInAppBrowser } from '@/utils/url';
import { isCarTeamUserId } from '@/utils/user';

import CountBadge from './CountBadge';
import DrawerIconButton from './DrawerIconButton';
import NotificationCountBadge from './NotificationCountBadge';
import TouchResponsiveButton from './TouchResponsiveButton';

type Props = {
  onClickWrite: () => void;
};

const DrawerMenu: React.FC<Props> = ({ onClickWrite }) => {
  const { track } = useTrack();
  const { user } = useUser();
  const [drawerOpened, , closeDrawer] = useOverlayState('drawer');
  const { push } = useFlow();
  const redirect = useRedirectAppScheme();
  const { requestHistoryTick } = useHistoryTick();
  const handleClickWrite = () => {
    closeDrawer();
    onClickWrite();
    track('main_click_drawer_item', { kind: 'write' });
  };

  const handleClickMy = () => {
    closeDrawer();
    push('liked_articles', {});
    track('main_click_drawer_item', { kind: 'liked_articles' });
  };

  const handleClickSalesHistory = () => {
    closeDrawer();
    push('sales_history', {});
    track('main_click_drawer_item', { kind: 'sales_history' });
  };

  const handleClickNotification = () => {
    closeDrawer();
    push('notification', {});
    track('main_click_drawer_item', { kind: 'notification' });
  };

  const handleClickInquiry = () => {
    closeDrawer();
    track('main_click_drawer_item', { kind: 'inquiry' });
    redirect(CsCenterScheme.Home);
  };

  const handleClickGuide = () => {
    closeDrawer();
    push('trade_guide', {});
    track('main_click_drawer_item', { kind: 'trade_guide' });
  };

  const handleClickDevLaboratory = () => {
    closeDrawer();
    push('dev_laboratory', {});
  };

  const handleClickInspector = () => {
    closeDrawer();
    requestHistoryTick((next) => {
      openInAppBrowser(INSPECTOR_TRAINEE_APPLY_LINK);
      return next();
    });
  };

  return (
    <Drawer isOpened={drawerOpened} onClickClose={closeDrawer}>
      <VStack>
        <DrawerIconButton icon={IconWriteRegular} onClick={handleClickWrite}>
          판매글 쓰기
        </DrawerIconButton>
        <DrawerIconButton icon={IconBillRegular} onClick={handleClickSalesHistory}>
          판매내역
        </DrawerIconButton>
        <DrawerIconButton icon={IconHeartRegular} onClick={handleClickMy}>
          관심 중고차
        </DrawerIconButton>
        <DrawerIconButton icon={IconNotificationRegular} onClick={handleClickNotification}>
          <HStack align="center" gap={4}>
            <Text color="gray900" variant="label2Regular">
              알림 관리
            </Text>
            <React.Suspense fallback={<CountBadge>0/{MAX_NOTIFICATION_COUNT}</CountBadge>}>
              <NotificationCountBadge />
            </React.Suspense>
          </HStack>
        </DrawerIconButton>
      </VStack>
      <Divider marginY={rem(16)} />
      <VStack>
        {!IS_PROD && isCarTeamUserId(user.id.toString()) && (
          <TouchResponsiveButton onClick={handleClickDevLaboratory} py={12}>
            <Text color="gray600" variant="subtitle2Regular">
              개발 실험실
            </Text>
          </TouchResponsiveButton>
        )}
        <TouchResponsiveButton onClick={handleClickInquiry} py={12}>
          <Text color="gray600" variant="subtitle2Regular">
            문의하기
          </Text>
        </TouchResponsiveButton>
        <TouchResponsiveButton onClick={handleClickGuide} py={12}>
          <Text color="gray600" variant="subtitle2Regular">
            중고차 직거래 가이드
          </Text>
        </TouchResponsiveButton>
        <TouchResponsiveButton onClick={handleClickInspector} py={12}>
          <Text color="gray600" variant="subtitle2Regular">
            자동차 진단평가사 교육생 모집
          </Text>
        </TouchResponsiveButton>
      </VStack>
    </Drawer>
  );
};

export default DrawerMenu;
