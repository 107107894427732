import { graphql, readInlineData } from 'relay-runtime';

import { getAllowedLoanList_article$key } from '@/__generated__/getAllowedLoanList_article.graphql';
import { loanList } from '@/constants/loan';

export const getAllowedLoanList = (articleRef: getAllowedLoanList_article$key) => {
  const { loanTrackingUrl } = readInlineData(
    graphql`
      fragment getAllowedLoanList_article on Article @inline {
        loanTrackingUrl {
          hcs
        }
      }
    `,
    articleRef
  );

  const allowedLoanList = loanList.filter((loan) => {
    const trackingUrl = loanTrackingUrl[loan.id];

    // 현대캐피탈은 주소가 비어서 옴
    return !!trackingUrl || trackingUrl === '';
  });

  return allowedLoanList;
};
