import { Error as _ErrorScreen } from '@daangn/error-component';

import { IS_ALPHA, IS_LOCAL } from '@/constants/config';
import { handleError } from '@/utils/error';

const isDebug = IS_ALPHA || IS_LOCAL;

type Props = {
  buttonText: string;
  error: Error;
  onClick: () => void;
};

const ErrorScreen = ({ error, onClick, buttonText = '뒤로 가기' }: Props) => {
  const title = isDebug ? error.name : '에러가 발생했어요.';
  const { message: _message } = handleError(error);
  const message = isDebug ? _message : '잠시 후 다시 시도해 주세요.';

  return (
    <_ErrorScreen
      buttonText={buttonText}
      description={message}
      onClickButton={onClick}
      title={title}
    />
  );
};

export default ErrorScreen;
