import { ChipToggleButton } from '@daangn/sprout-components-chips';
import { memo } from 'react';

import { SaleType, SaleTypeText } from '@/types/CarData/SaleType';

import Section from './Section';

type Props = {
  fixedSaleTypes?: SaleType[];
  handleChangeSaleType: (saleType: SaleType) => void;
  saleTypes: SaleType[] | null;
};
const SaleTypeSection: React.FC<Props> = memo(
  ({ saleTypes, fixedSaleTypes, handleChangeSaleType }) => {
    return (
      <Section.Container>
        <Section.Header>
          <Section.Title>판매 방식</Section.Title>
        </Section.Header>
        <Section.ButtonWrapper>
          {[SaleType.General, SaleType.Lease, SaleType.Rental].map((saleType) => {
            const isActive = (saleTypes ?? []).includes(saleType);
            const isDisabled = (fixedSaleTypes ?? []).includes(saleType);

            return (
              <ChipToggleButton
                isDisabled={isDisabled}
                isSelected={isActive}
                key={saleType}
                onClick={() => handleChangeSaleType(saleType)}
                size="small"
              >
                {SaleTypeText[saleType]}
              </ChipToggleButton>
            );
          })}
        </Section.ButtonWrapper>
      </Section.Container>
    );
  }
);

SaleTypeSection.displayName = 'SaleTypeSection';

export default SaleTypeSection;
