import { BrowserTracing, addIntegration, getClient, getCurrentScope, init } from '@sentry/react';

import CONFIG, { IS_ALPHA, IS_PROD } from '@/constants/config';
import { RelayNetworkError } from '@/types/global';

const isSentryEnabled = IS_PROD || IS_ALPHA;

if (isSentryEnabled) {
  init({
    dsn: CONFIG.SENTRY_DSN,
    // https://www.notion.so/daangn/1-5-a8f95c7002f54b9c8d8e9a5cbd68a70d?pvs=4
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      // https://daangn.slack.com/archives/CUV91G2MT/p1671546088048909?thread_ts=1671501341.168479&cid=CUV91G2MT
      'Java exception was raised during method invocation',
      '동일한 매물을 같은 동네에 올릴 수 없어요',
      '차량 정보를 찾을 수 없어요, 소유자 명이 다르거나 조회 결과가 없어요',
    ],
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_STAGE,
    release: process.env.REACT_APP_SENTRY_RELEASE_TARGET,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    normalizeDepth: 6,
    beforeSend(event, hint) {
      const originalError = hint.originalException as Error;
      const isRelayNetworkError = !!originalError?.name?.match('RelayNetwork');

      if (
        isRelayNetworkError &&
        (originalError as RelayNetworkError)?.source?.errors?.[0]?.extensions?.isReportedException
      ) {
        return null;
      }

      return event;
    },
  });
}

export const startReplay = async (name: string) => {
  if (!isSentryEnabled) {
    return;
  }
  const { Replay } = await import('@sentry/react');
  const replayId = getClient()?.getIntegration(Replay)?.getReplayId();

  // Already started
  if (replayId) {
    return;
  }
  const replay = new Replay({ maskAllText: false, maskAllInputs: false });
  const scope = getCurrentScope();

  scope.setTag('replayName', name);
  addIntegration(replay);
  replay.start();
};
