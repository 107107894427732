/**
 * @generated SignedSource<<8f4225f2a857c759c5f1f5d1df6a3ab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
export type useTradedArticlesQuery$variables = {
  count: number;
  gradeId?: number | null | undefined;
  subgradeId?: number | null | undefined;
  subseriesId: number;
};
export type useTradedArticlesQuery$data = {
  readonly tradedArticles: ReadonlyArray<{
    readonly events: ReadonlyArray<{
      readonly t: number;
      readonly type: ArticleEventType | null | undefined;
    }>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TransactionHistoryItem_article">;
  }>;
};
export type useTradedArticlesQuery = {
  response: useTradedArticlesQuery$data;
  variables: useTradedArticlesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gradeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subgradeId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subseriesId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "gradeId",
    "variableName": "gradeId"
  },
  {
    "kind": "Variable",
    "name": "num",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "subgradeId",
    "variableName": "subgradeId"
  },
  {
    "kind": "Variable",
    "name": "subseriesId",
    "variableName": "subseriesId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ArticleEvent",
  "kind": "LinkedField",
  "name": "events",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "t",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTradedArticlesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "tradedArticles",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TransactionHistoryItem_article"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useTradedArticlesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "tradedArticles",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minActualPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "driveDistance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CarData",
            "kind": "LinkedField",
            "name": "carData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modelYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoupModel",
                "kind": "LinkedField",
                "name": "autoupModel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AutoupGrade",
                    "kind": "LinkedField",
                    "name": "grade",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AutoupSubgrade",
                    "kind": "LinkedField",
                    "name": "subgrade",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f026525d087979fae66591005a3fc257",
    "id": null,
    "metadata": {},
    "name": "useTradedArticlesQuery",
    "operationKind": "query",
    "text": "query useTradedArticlesQuery(\n  $subseriesId: Int!\n  $gradeId: Int\n  $subgradeId: Int\n  $count: Int!\n) {\n  tradedArticles(subseriesId: $subseriesId, gradeId: $gradeId, subgradeId: $subgradeId, num: $count) {\n    id\n    events {\n      type\n      t\n    }\n    ...TransactionHistoryItem_article\n  }\n}\n\nfragment TransactionHistoryItem_article on Article {\n  id\n  price\n  minActualPrice\n  driveDistance\n  events {\n    type\n    t\n  }\n  carData {\n    modelYear\n    autoupModel {\n      grade {\n        name\n        id\n      }\n      subgrade {\n        name\n        id\n      }\n    }\n  }\n  ...getTradeDurationFromArticle_article\n}\n\nfragment getTradeDurationFromArticle_article on Article {\n  events {\n    type\n    t\n  }\n}\n"
  }
};
})();

(node as any).hash = "1313207290f7edb0d98e9a3d8ff1538f";

export default node;
