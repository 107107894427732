/**
 * @generated SignedSource<<f66ba90cea8acd10ca98990b4b87c607>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UndoLikeArticleInput = {
  articleId: string;
};
export type LikeButton_UnlikeArticleMutation$variables = {
  input: UndoLikeArticleInput;
};
export type LikeButton_UnlikeArticleMutation$data = {
  readonly undoLikeArticle: {
    readonly id: string;
    readonly isUserVoted: boolean;
    readonly voteCount: number;
  };
};
export type LikeButton_UnlikeArticleMutation = {
  response: LikeButton_UnlikeArticleMutation$data;
  variables: LikeButton_UnlikeArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Article",
    "kind": "LinkedField",
    "name": "undoLikeArticle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUserVoted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "voteCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LikeButton_UnlikeArticleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LikeButton_UnlikeArticleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e5c8dc37291f981e51a7e4ceb320209",
    "id": null,
    "metadata": {},
    "name": "LikeButton_UnlikeArticleMutation",
    "operationKind": "mutation",
    "text": "mutation LikeButton_UnlikeArticleMutation(\n  $input: UndoLikeArticleInput!\n) {\n  undoLikeArticle(input: $input) {\n    id\n    isUserVoted\n    voteCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a539734aa47fcd52372cca17a4b204d";

export default node;
