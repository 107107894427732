import { getYear } from 'date-fns';

import { YEAR_RANGE_SIZE } from '@/utils/date';
import { getInvertedMap } from '@/utils/misc';

import { getKeys } from './utils';

export const PriceMap: Record<number, null | number> = {
  0: null,
  1: 100,
  2: 200,
  3: 300,
  4: 500,
  5: 700,
  6: 1000,
  7: 2000,
  8: 3000,
  9: 5000,
  10: 7000,
  11: 10000,
  12: null,
};
export const priceKeys = getKeys(PriceMap);

const getCarYearMap = (yearRange = YEAR_RANGE_SIZE) => {
  const thisYear = getYear(new Date());
  const deltas = [-1, ...Array(yearRange + 2).keys()];

  return Object.fromEntries(
    deltas.map((delta: number) => {
      const key = thisYear - (yearRange - delta);

      return [key, delta >= 0 && delta <= yearRange ? key : null];
    })
  );
};

export const CarYearMap = getCarYearMap();

const DISTANCE_STEP = 20000;
const DISTANCE_MAX = 200000;

const getDriveDistanceMap = (step = DISTANCE_STEP, max = DISTANCE_MAX) => {
  const map: Record<number, null | number> = { 0: null };
  let idx = 1;

  for (let distance = step; distance <= max; idx++, distance += step) {
    map[idx] = distance;
  }
  map[idx] = null;
  return map;
};

export const DriveDistanceMap = getDriveDistanceMap();
export const InvertedDriveDistanceMap = getInvertedMap(DriveDistanceMap);
