/**
 * @generated SignedSource<<af32a3e315a1e1da6790ff222a9a4d99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionContext_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfettiLayer_article" | "PromotionExampleLayer_article" | "PromotionPaymentLayer_article">;
  readonly " $fragmentType": "PromotionContext_article";
};
export type PromotionContext_article$key = {
  readonly " $data"?: PromotionContext_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionContext_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionContext_article",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionExampleLayer_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfettiLayer_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionPaymentLayer_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "89a15fc2e32466ea1b77b5e0ee0a5754";

export default node;
