import { memo } from 'react';

import AspectRatio from '@/components/Base/AspectRatio';
import { thumbnailRoundCss } from '@/components/Base/OptimizedImage/utils';
import VStack from '@/components/Base/VStack';

import Placeholder from './Placeholder';

const SkeletonVerticalArticleCard = () => {
  return (
    <VStack bg="paperDefault" gap={8} pa={8}>
      <AspectRatio css={thumbnailRoundCss} ratio="4/3">
        <Placeholder h="100%" w="100%" />
      </AspectRatio>
      <VStack gap={4}>
        <Placeholder br={6} h={20} w={128} />
        <Placeholder br={6} h={20} w={67} />
      </VStack>
    </VStack>
  );
};

export default memo(SkeletonVerticalArticleCard);
