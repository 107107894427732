/**
 * @generated SignedSource<<991666f6754f5b1f9eda06cb2216f9d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccidentHistorySelection = "Bending" | "NoIssues" | "Replacement" | "Welding";
export type PowerSteeringType = "Electric" | "Hydraulic";
export type PowertrainLayout = "AWD" | "FF" | "FR" | "MR" | "RR";
export type ReportBaseInfoFuelType = "Diesel" | "DieselHybrid" | "Electric" | "Gasoline" | "GasolineHybrid" | "HydrogenElectric" | "LPG" | "LPGHybrid";
export type ReportBaseInfoGearType = "Auto" | "Manual";
export type StateSelection = "BendingRequired" | "CheckRequired" | "Corrosion" | "Damaged" | "Defect" | "DoorDing" | "Hardened" | "Impact" | "InspectionRequired" | "Issues" | "Lack" | "MaintenanceRequired" | "MinorImpact" | "MinorOilLeak" | "MinorWaterLeak" | "Moisture" | "NoIssues" | "Noise" | "None" | "OilLeak" | "PaintRequired" | "RepairRequired" | "ReplacementRecommended" | "ReplacementRequired" | "Scratch" | "StoneChip" | "TireOneSidedWear" | "TuningEquipment" | "TuningIllegalEquipment" | "TuningIllegalStructure" | "TuningStructure" | "Used" | "WaterLeak";
import { FragmentRefs } from "relay-runtime";
export type getFullReport_MechanicReport$data = {
  readonly accidentHistory: {
    readonly externalPanel: {
      readonly hood: AccidentHistorySelection;
      readonly leftFender: AccidentHistorySelection;
      readonly leftFrontDoor: AccidentHistorySelection;
      readonly leftQuarterPanel: AccidentHistorySelection;
      readonly leftRearDoor: AccidentHistorySelection;
      readonly leftSideSealPanel: AccidentHistorySelection;
      readonly radiatorSupport: AccidentHistorySelection;
      readonly rightFender: AccidentHistorySelection;
      readonly rightFrontDoor: AccidentHistorySelection;
      readonly rightQuarterPanel: AccidentHistorySelection;
      readonly rightRearDoor: AccidentHistorySelection;
      readonly rightSideSealPanel: AccidentHistorySelection;
      readonly roofPanel: AccidentHistorySelection;
      readonly trunkLid: AccidentHistorySelection;
    };
    readonly mainFrame: {
      readonly crossMember: AccidentHistorySelection;
      readonly dashPanel: AccidentHistorySelection;
      readonly floorPanel: AccidentHistorySelection;
      readonly frontPanel: AccidentHistorySelection;
      readonly leftFrontSideMember: AccidentHistorySelection;
      readonly leftFrontWheelHouse: AccidentHistorySelection;
      readonly leftInsidePanel: AccidentHistorySelection;
      readonly leftPillarPanelA: AccidentHistorySelection;
      readonly leftPillarPanelB: AccidentHistorySelection;
      readonly leftPillarPanelC: AccidentHistorySelection;
      readonly leftPillarPanelD: AccidentHistorySelection;
      readonly leftRearSideMember: AccidentHistorySelection;
      readonly leftRearWheelHouse: AccidentHistorySelection;
      readonly packageTray: AccidentHistorySelection;
      readonly rearPanel: AccidentHistorySelection | null | undefined;
      readonly rightFrontSideMember: AccidentHistorySelection;
      readonly rightFrontWheelHouse: AccidentHistorySelection;
      readonly rightInsidePanel: AccidentHistorySelection;
      readonly rightPillarPanelA: AccidentHistorySelection;
      readonly rightPillarPanelB: AccidentHistorySelection;
      readonly rightPillarPanelC: AccidentHistorySelection;
      readonly rightPillarPanelD: AccidentHistorySelection;
      readonly rightRearSideMember: AccidentHistorySelection;
      readonly rightRearWheelHouse: AccidentHistorySelection;
      readonly trunkFloor: AccidentHistorySelection;
    };
  };
  readonly autoTransmission: {
    readonly missionOil: {
      readonly condition: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly level: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly oilLeak: {
      readonly missionCase: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly oilPan: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly pump: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly operation: {
      readonly DNRP: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly PRND: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly baseInfo: {
    readonly driveDistance: number;
    readonly fuelType: ReportBaseInfoFuelType;
    readonly gearType: ReportBaseInfoGearType;
    readonly powerSteeringType: PowerSteeringType;
    readonly powertrainLayout: PowertrainLayout;
    readonly tuningHistory: {
      readonly comment: string | null | undefined;
      readonly imgs: ReadonlyArray<{
        readonly id: string;
        readonly url: string;
      }>;
      readonly selections: ReadonlyArray<StateSelection>;
    };
  };
  readonly brakingSystem: {
    readonly brakeFluid: {
      readonly brakeFluid: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly oilLeak: {
      readonly caliper: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly masterCylinder: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly operation: {
      readonly vacuumBrakeBooster: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly suspension: {
      readonly suspension: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
  };
  readonly diagnosis: {
    readonly engine: ReadonlyArray<string>;
    readonly etc: ReadonlyArray<string>;
    readonly imgs: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }> | null | undefined;
    readonly transmission: ReadonlyArray<string>;
  };
  readonly electricalSystem: {
    readonly generator: {
      readonly noise: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly outputVoltage: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly radiatorFanMotor: {
      readonly radiatorFanMotor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly starterMotor: {
      readonly operation: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly engine: {
    readonly coolant: {
      readonly condition: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly level: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly coolantLeak: {
      readonly cylinderHead: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly radiator: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly waterPump: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly engineOil: {
      readonly condition: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly flow: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly highPressurePump: {
      readonly highPressurePump: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly oilLeak: {
      readonly cylinderHead: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly engineBlock: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontCover: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      } | null | undefined;
      readonly oilPan: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rockerArmCover: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly operation: {
      readonly acceleration: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly idle: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly ignition: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly exterior: {
    readonly front: {
      readonly frontBumper: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontBumperMolding: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontWindshield: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly grille: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly headlamp: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly hood: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly tinting: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly turnSignal: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly left: {
      readonly aPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly bPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly cPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly dPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly doorGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly doorMolding: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly fender: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly fenderGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontDoor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontWheel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly quarterPanel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly quarterPanelGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearDoor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearWheel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly sideMirror: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly sideSealPanel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly muffler: {
      readonly muffler: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly rear: {
      readonly rearBumper: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearBumperMolding: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearWindowTinting: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly tailLamp: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly trunkLid: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly right: {
      readonly aPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly bPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly cPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly dPillar: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly doorGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly doorMolding: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly fender: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly fenderGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontDoor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly frontWheel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly quarterPanel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly quarterPanelGarnish: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearDoor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rearWheel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly sideMirror: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly sideSealPanel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly roof: {
      readonly roof: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly fuelSystem: {
    readonly fuelLeak: {
      readonly dieselLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly gasolineLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly lpgLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly highVoltageElectricalSystem: {
    readonly batteryIsolation: {
      readonly isolation: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly chargingPortIsolation: {
      readonly isolation: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly wire: {
      readonly wire: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly id: string;
  readonly interiorCondition: {
    readonly centerConsole: {
      readonly centerConsole: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly centerFascia: {
      readonly centerFascia: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly dashboard: {
      readonly dashboard: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly doorTrim: {
      readonly doorTrim: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly driverSeatMat: {
      readonly driverSeatMat: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly handle: {
      readonly handle: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly headliner: {
      readonly pillarCover: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly roof: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly seat: {
      readonly row1: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly row2: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly row3: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly manualTransmission: {
    readonly gearShift: {
      readonly gearShift: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly oilLeak: {
      readonly oilLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly operation: {
      readonly operation: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly notice: {
    readonly comment: string | null | undefined;
    readonly imgs: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
  } | null | undefined;
  readonly opinion: {
    readonly comment: string | null | undefined;
    readonly imgs: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
  };
  readonly options: {
    readonly aeb: {
      readonly aeb: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly autoFoldSideMirror: {
      readonly autoFoldSideMirror: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly autoTrunk: {
      readonly autoTrunk: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly autoWiper: {
      readonly autoWiper: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly avSystem: {
      readonly monitor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly radio: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly bluetooth: {
      readonly bluetooth: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly bsd: {
      readonly bsd: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly camera: {
      readonly around: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rear: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly climateControlSystem: {
      readonly airConditioner: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly autoAirConditioner: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      } | null | undefined;
      readonly blowerDirectionController: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly blowerMotor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly heater: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly cruiseControl: {
      readonly cruiseControl: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly dashboardCamera: {
      readonly dashboardCamera: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly epb: {
      readonly epb: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly headlamp: {
      readonly headlamp: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly heatedSeat: {
      readonly heatedSeat: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly heatedSteeringWheel: {
      readonly heatedSteeringWheel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly hipass: {
      readonly hipass: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly hud: {
      readonly hud: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly indoorLight: {
      readonly indoorLight: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly ldws: {
      readonly ldws: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly leatherSeat: {
      readonly leatherSeat: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly navigation: {
      readonly navigation: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly touchPanel: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly panoramicSunroof: {
      readonly panoramicSunroof: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly parkingSensor: {
      readonly front: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rear: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly powerSeat: {
      readonly powerSeat: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly powerWindow: {
      readonly powerWindow: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly smartKey: {
      readonly smartKey: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly steeringHandle: {
      readonly electricalSteeringHandle: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      } | null | undefined;
    };
    readonly sunroof: {
      readonly sunroof: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly tpms: {
      readonly tpms: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    } | null | undefined;
    readonly ventilatedSeat: {
      readonly ventilatedSeat: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly wiper: {
      readonly wiper: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly paint: {
    readonly paint: {
      readonly differentColor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly ppf: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly sameColor: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly wrapping: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly photos: {
    readonly carRegistration: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly exterior: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly interior: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly maintenanceReceipts: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }> | null | undefined;
    readonly paperReport: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly wheel: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }> | null | undefined;
  };
  readonly powertrain: {
    readonly clutchAssembly: {
      readonly clutchAssembly: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly differential: {
      readonly caseLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly oilSealLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly driveShaft: {
      readonly greaseLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rubberBootDamage: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly propellerShaft: {
      readonly bearingDamage: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly discDamage: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly shaftLooseness: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly transferCase: {
      readonly caseLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly oilSealLeak: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly pros: string | null | undefined;
  readonly steering: {
    readonly oilLeak: {
      readonly gearBox: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly highPressureHose: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly pump: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly operation: {
      readonly steeringLooseness: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly powerOil: {
      readonly level: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly tieRodAndBallJoint: {
      readonly tieRodAndBallJoint: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
  };
  readonly tire: {
    readonly brakePadWear: {
      readonly front: number;
      readonly rear: number | null | undefined;
    };
    readonly productionDate: {
      readonly front: any;
    };
    readonly tireCondition: {
      readonly front: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
      readonly rear: {
        readonly comment: string | null | undefined;
        readonly imgs: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly selections: ReadonlyArray<StateSelection>;
      };
    };
    readonly tireTreadDepth: {
      readonly frontDriverSeat: number;
      readonly frontPassengerSeat: number;
      readonly rearDriverSeat: number;
      readonly rearPassengerSeat: number;
    };
  };
  readonly " $fragmentType": "getFullReport_MechanicReport";
};
export type getFullReport_MechanicReport$key = {
  readonly " $data"?: getFullReport_MechanicReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"getFullReport_MechanicReport">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getFullReport_MechanicReport"
};

(node as any).hash = "d5ab621aa951be32eb975d3490f6f9e8";

export default node;
