import { Portal } from '@daangn/karrot-clothes';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import { AnimatePresence, Transition, motion } from 'framer-motion';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

import { getSizeProps } from '@/utils/style';

import HStack from './Base/HStack';
import Text from './Base/Text';
import Car from './icon/Car';

type Props = {
  isOpened: boolean;
  onClickClose: () => void;
  zIndex?: number;
};

const transitionProps: Transition = {
  ease: [0.19, 0, 0.51, 1],
  duration: 0.25,
};

const defaultZIndex = 1000;

const Drawer: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClickClose,
  isOpened,
  zIndex = defaultZIndex,
}) => {
  return (
    <Portal>
      <AnimatePresence>
        {isOpened && (
          <>
            <Overlay
              animate={{ opacity: 1 }}
              css={{ zIndex }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={onClickClose}
              transition={transitionProps}
            />
            <Menu
              animate={{ translateX: '0%' }}
              css={{ zIndex: zIndex + 1 }}
              exit={{ translateX: '100%' }}
              initial={{ translateX: '100%' }}
              transition={transitionProps}
            >
              <Header justify="space-between" pb={22} pl={20} pr={16} pt={10}>
                <HStack gap={6}>
                  <Car height={20} width={20} />
                  <Text color="gray900" variant="label2Bold">
                    중고차 직거래
                  </Text>
                </HStack>
                <IconCloseRegular onClick={onClickClose} {...getSizeProps(20)} />
              </Header>
              {children}
            </Menu>
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const Overlay = styled(motion.div)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  backgroundColor: vars.$semantic.color.overlayDim,
});

const Menu = styled(motion.div)({
  position: 'fixed',
  top: 0,
  right: 0,
  width: `calc(100% - ${rem(100)})`,
  height: '100%',
  backgroundColor: vars.$semantic.color.paperDefault,
  paddingTop: 'env(safe-area-inset-top)',
});

const Header = styled(HStack)({
  width: '100%',
});

export default Drawer;
