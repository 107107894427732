import { css } from '@emotion/react';
import { useCallback } from 'react';

import type { MainQueryContainerQuery$data } from '@/__generated__/MainQueryContainerQuery.graphql';

import Box from '@/components/Base/Box';
import useTrack from '@/hooks/useTrack';
import SearchButton from '@/pages/Main/components/SearchButton';
import { useFlow } from '@/stackflow';

import CarouselSection from './CarouselSection';
import MainContentsSection from './MainContentsSection';
type Props = {
  query: MainQueryContainerQuery$data;
};
const Home2QueryContainer = ({ query }: Props) => {
  const { track } = useTrack();
  const { push } = useFlow();
  const handleClickSearch = useCallback(() => {
    push('search', {});
    track('main_click_search');
  }, [push, track]);

  return (
    <>
      <Box
        bg="paperDefault"
        css={css`
          top: 0;
          z-index: 2;
        `}
        pb={8}
        pos="sticky"
        pt={4}
        px={16}
      >
        <SearchButton onClick={handleClickSearch} />
      </Box>
      <MainContentsSection queryRef={query} />
      <CarouselSection queryRef={query} />
    </>
  );
};

export default Home2QueryContainer;
