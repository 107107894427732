import {
  AlertDialog,
  DialogContainer,
  SeedAlertDialogProps,
} from '@daangn/sprout-components-dialog';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { Navigation_article$key } from '@/__generated__/Navigation_article.graphql';
import RenewedBottomSheet from '@/components/Base/BottomSheet/RenewedBottomSheet';
import RenewedBottomSheetItem from '@/components/Base/BottomSheet/RenewedBottomSheetItem';
import ScreenHelmet from '@/components/Base/ScreenHelmet';
import CONFIG from '@/constants/config';
import useArticleStep from '@/hooks/useArticleStep';
import useDeleteArticle from '@/hooks/useDeleteArticle';
import useHideArticle from '@/hooks/useHideArticle';
import useResetArticleForm from '@/hooks/useResetArticleForm';
import useTrack from '@/hooks/useTrack';
import useUpdateArticleStatus from '@/hooks/useUpdateArticleStatus';
import { useFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

import { useArticleContext } from '../ArticleContext';
import { type DisplayState } from '../types';
import HeaderMenu from './HeaderMenu';

interface Props {
  articleRef: Navigation_article$key;
}

const Navigation: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const { pop } = useFlow();
  const { stepPush, stepReplace, stepPop } = useArticleStep();
  const { state } = useCamelCaseParams<{ state?: DisplayState }>();
  const resetArticleForm = useResetArticleForm();
  const { isChartSectionVisible } = useArticleContext();

  const article = useFragment(
    graphql`
      fragment Navigation_article on Article {
        id
        shareUrl
        isHide
        promotionEndsAt
        carData {
          carNo
          ownerVerified
        }
        ...HeaderMenu_article
      }
    `,
    articleRef
  );

  const { hideArticle, undoHideArticle } = useHideArticle();
  const { deleteArticle } = useDeleteArticle();
  const { updateArticleStatus } = useUpdateArticleStatus();

  const { id, promotionEndsAt, isHide, carData } = article;
  const { carNo, ownerVerified } = carData;

  const hasOngoingPromotion = isChartSectionVisible && promotionEndsAt;

  const toggleHide = async () => {
    const expectedNextHideState = !isHide;
    const operation = expectedNextHideState ? hideArticle : undoHideArticle;
    const nextHideState = await operation(id);

    if (nextHideState) {
      pop();
    }
    stepPop();

    const eventName = nextHideState ? 'hide' : 'unhide';

    track(`article_click_${eventName}`);
  };

  const handleClickDeleteConfirm = async () => {
    const result = await deleteArticle(id);

    if (result) {
      pop();
    }
    stepPop();

    track('article_click_delete');
  };

  const handleClickPullUp = () => {
    stepPop();
    push('pullup_article', { articleId: id });
    track('article_click_pullup');
  };

  const handleClickEdit = () => {
    if (carNo) {
      stepPop();
      push('edit_article', { articleId: id });
    } else {
      stepReplace({ state: 'fetch' });
    }
    track('article_click_edit');
  };

  const handleClickFetchConfirm = () => {
    resetArticleForm();
    stepPop();
    push('fetch_car_info', { articleId: id });
  };

  const handleClickHide = () => {
    const nextHideState = !isHide;

    if (hasOngoingPromotion && nextHideState) {
      stepReplace({ state: 'hide_while_ad' });
      return;
    }
    if (ownerVerified === false && nextHideState) {
      stepReplace({ state: 'hide_after_trade' });
      return;
    }
    toggleHide();
  };

  const handleClickDelete = () => {
    if (hasOngoingPromotion) {
      stepReplace({ state: 'delete_while_ad' });
      return;
    }
    if (ownerVerified === false) {
      stepReplace({ state: 'delete_after_trade' });
      return;
    }
    stepReplace({ state: 'delete' });
  };

  const handleClickTraded = async () => {
    const changedStatus = await updateArticleStatus({ id, status: 'TRADED' });

    stepPop();
    if (changedStatus === 'TRADED') {
      push('article_select_buyer', { articleId: id });
    }
  };

  const getDialogProps = (): SeedAlertDialogProps => {
    switch (state) {
      case 'fetch':
        return {
          description: '앞으로 중고차 직거래 게시글에는 차량 조회가 필요해요.',
          secondaryActionIntent: 'nonpreferred',
          primaryActionLabel: '차량 조회하고 수정하기',
          secondaryActionLabel: '아니요, 다음에 할래요.',
          onPrimaryAction: handleClickFetchConfirm,
        };
      case 'delete':
        return {
          description: '게시글을 정말 삭제하시겠어요?',
          secondaryActionIntent: 'neutral',
          secondaryActionLabel: '취소',
          primaryActionLabel: '삭제',
          onPrimaryAction: handleClickDeleteConfirm,
        };
      case 'hide_while_ad':
        return {
          title: '광고가 진행중이에요',
          description: '게시글을 숨기면 광고가 중단되고 광고비는 환불되지 않아요.',
          secondaryActionIntent: 'neutral',
          primaryActionLabel: '숨기기',
          onPrimaryAction: toggleHide,
        };
      case 'delete_while_ad':
        return {
          title: '광고가 진행중이에요',
          description: '게시글을 삭제하면 광고가 중단되고 광고비는 환불되지 않아요.',
          secondaryActionIntent: 'neutral',
          primaryActionLabel: '삭제',
          onPrimaryAction: handleClickDeleteConfirm,
        };
      case 'hide_after_trade':
      case 'delete_after_trade':
        return {
          description: `중고차를 판매하셨다면 ${
            state === 'hide_after_trade' ? '숨기기' : '삭제'
          } 대신 거래완료로 변경해보세요.`,
          secondaryActionIntent: 'alternative',
          title: '중고차를 판매하셨나요?',
          primaryActionLabel: '거래완료',
          secondaryActionLabel: state === 'hide_after_trade' ? '숨기기' : '삭제',
          onPrimaryAction: handleClickTraded,
          onSecondaryAction: state === 'hide_after_trade' ? toggleHide : handleClickDeleteConfirm,
        };
      default:
        return { description: '', primaryActionLabel: '' };
    }
  };

  return (
    <>
      <ScreenHelmet
        border
        renderRight={() => (
          <HeaderMenu
            articleRef={article}
            onClickMore={() => stepPush({ state: 'more_menu' })}
            onClickReport={stepPop}
            onClickShare={stepPop}
          />
        )}
        title={CONFIG.SERVICE_NAME}
      />
      <RenewedBottomSheet active={state === 'more_menu'} onClickClose={stepPop}>
        <RenewedBottomSheetItem onClick={handleClickEdit}>수정하기</RenewedBottomSheetItem>
        <RenewedBottomSheetItem onClick={handleClickPullUp}>끌어올리기</RenewedBottomSheetItem>
        <RenewedBottomSheetItem onClick={handleClickHide}>
          {isHide ? '숨기기 해제' : '숨기기'}
        </RenewedBottomSheetItem>
        <RenewedBottomSheetItem onClick={handleClickDelete}>
          <DangerWord>삭제하기</DangerWord>
        </RenewedBottomSheetItem>
      </RenewedBottomSheet>
      {state &&
        [
          'delete',
          'delete_after_trade',
          'delete_while_ad',
          'fetch',
          'hide_after_trade',
          'hide_while_ad',
        ].includes(state) && (
          <DialogContainer>
            <AlertDialog
              maxWidth={272}
              onSecondaryAction={stepPop}
              secondaryActionLabel="취소"
              {...getDialogProps()}
            />
          </DialogContainer>
        )}
    </>
  );
};

export default Navigation;

const DangerWord = styled.span({
  color: vars.$semantic.color.danger,
});
