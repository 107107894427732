/**
 * @generated SignedSource<<bd23657d0d10c139f838317e70775fd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PostContractOption = "BUY" | "BUY_OR_RETURN" | "RETURN";
import { FragmentRefs } from "relay-runtime";
export type ArticleLeaseRentalSection_article$data = {
  readonly id: string;
  readonly leaseInfo: {
    readonly postContractOption: PostContractOption;
  } | null | undefined;
  readonly rentalInfo: {
    readonly postContractOption: PostContractOption;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"getArticleLeaseRentalPriceInfo_article">;
  readonly " $fragmentType": "ArticleLeaseRentalSection_article";
};
export type ArticleLeaseRentalSection_article$key = {
  readonly " $data"?: ArticleLeaseRentalSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleLeaseRentalSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postContractOption",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contractStartedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contractEndedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyFee",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "acquisitionPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "residualValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deposit",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleLeaseRentalSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseInfo",
      "kind": "LinkedField",
      "name": "leaseInfo",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getArticleLeaseRentalPriceInfo_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LeaseInfo",
          "kind": "LinkedField",
          "name": "leaseInfo",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RentalInfo",
          "kind": "LinkedField",
          "name": "rentalInfo",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "44455b53714d42b228c40b416e017eae";

export default node;
