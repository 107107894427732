import { StackflowReactPlugin } from '@stackflow/react';

import PageLayout from '@/components/Base/PageLayout';
import DevLaboratoryButton from '@/components/DevLaboratoryButton';
import { IS_ALPHA, IS_LOCAL } from '@/constants/config';

const routeWrapperPlugin = (): StackflowReactPlugin => () => {
  return {
    key: 'hoc',
    wrapActivity({ activity }) {
      const isLaboratoryButtonVisible = (IS_ALPHA || IS_LOCAL) && activity.isTop;
      return (
        <>
          <PageLayout activityName={activity.name}>{activity.render()}</PageLayout>
          {isLaboratoryButtonVisible && <DevLaboratoryButton />}
        </>
      );
    },
  };
};

export default routeWrapperPlugin;
