/**
 * @generated SignedSource<<dced5d32266075a757fd782b1dbe6d6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticleChart_article$data = {
  readonly estimationsPromoted24HoursAgo: ReadonlyArray<{
    readonly t: number;
    readonly view: number;
  }>;
  readonly estimationsPromotedFromNowOn: ReadonlyArray<{
    readonly t: number;
    readonly view: number;
  }>;
  readonly events: ReadonlyArray<{
    readonly t: number;
    readonly type: ArticleEventType | null | undefined;
  }>;
  readonly statistics: ReadonlyArray<{
    readonly t: number;
    readonly view: number;
  }>;
  readonly statisticsIn48Hours: ReadonlyArray<{
    readonly t: number;
    readonly view: number;
  }>;
  readonly " $fragmentType": "ArticleChart_article";
};
export type ArticleChart_article$key = {
  readonly " $data"?: ArticleChart_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleChart_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "t",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "view",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleChart_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleStatistics",
      "kind": "LinkedField",
      "name": "statistics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleStatistics",
      "kind": "LinkedField",
      "name": "statisticsIn48Hours",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "estimationsPromoted24HoursAgo",
      "args": null,
      "concreteType": "ArticleStatistics",
      "kind": "LinkedField",
      "name": "statisticsPromoted24HoursAgo",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "estimationsPromotedFromNowOn",
      "args": null,
      "concreteType": "ArticleStatistics",
      "kind": "LinkedField",
      "name": "statisticsPromotedFromNowOn",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "dbc1ad7e61d852225bae53e44046e259";

export default node;
