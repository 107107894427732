import React, { memo, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { match } from 'ts-pattern';

import { ArticleCard_article$key } from '@/__generated__/ArticleCard_article.graphql';
import HorizontalArticleCard from '@/components/Article/HorizontalArticleCard';
import MinimalArticleCard from '@/components/Article/MinimalArticleCard';
import VerticalArticleCard from '@/components/Article/VerticalArticleCard';
import VerticalCArticleCard from '@/components/Article/VerticalCArticleCard';
import { karrotBridge } from '@/sdk/bridge';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';

type ArticleCardVariant = 'horizontal' | 'minimal' | 'vertical' | 'verticalC';

interface Props {
  articleRef: ArticleCard_article$key;
  onClick?: (data: ArticleEventProperties) => void;
  variant?: ArticleCardVariant;
}

const ArticleCard: React.FC<React.PropsWithChildren<Props>> = ({
  variant = 'horizontal',
  articleRef,
  onClick,
  ...props
}) => {
  const article = useFragment<ArticleCard_article$key>(
    graphql`
      fragment ArticleCard_article on Article {
        id
        ...HorizontalArticleCard_article
        ...VerticalArticleCard_article
        ...VerticalCArticleCard_article
        ...MinimalArticleCard_article
      }
    `,
    articleRef
  );
  const handleClick = useCallback(
    (params: ArticleEventProperties) => {
      onClick?.(params);
      karrotBridge.setHapticSelect({});
    },
    [onClick]
  );

  return match(variant)
    .with('horizontal', () => (
      <HorizontalArticleCard articleRef={article} {...props} onClick={handleClick} />
    ))
    .with('vertical', () => (
      <VerticalArticleCard articleRef={article} {...props} onClick={handleClick} />
    ))

    .with('verticalC', () => (
      <VerticalCArticleCard articleRef={article} {...props} onClick={handleClick} />
    ))
    .with('minimal', () => (
      <MinimalArticleCard articleRef={article} {...props} onClick={handleClick} />
    ))
    .exhaustive();
};

export default memo(ArticleCard);
