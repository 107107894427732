import { Identify, identify } from '@amplitude/analytics-browser';
import { getCurrentScope } from '@sentry/core';
import { objectEntries } from '@toss/utils';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import mixpanel from 'mixpanel-browser';

import { carTracker } from '@/sdk/tracker/CarTracker';
import { atomWithCarStorage } from '@/utils/jotai';
import { sum } from '@/utils/number';

export type TreatmentGroup = `TREATMENT_${number}`;
export type ExperimentGroup = 'CONTROL' | TreatmentGroup;

export const Experiment = {
  // 0번 인덱스는 항상 CONTROL (대조군) 그룹으로 고정
  /* 종료된 실험들 */
  // 판매자의_시세_판단_돕기: [0.5, 0.5],
  // 본인_인증_소유주_고정: [0.5, 0.5],
  // 판매자의_시세_판단_돕기_v2: [0.5, 0.5],
  // 본인_인증_개선_v2: [0.5, 0.5],
  // 뷰타입_타이틀_옵션제거: [0.5, 0.5],
  // 진단_판매자_가격_설정: [0.2, 0.4, 0.4],
  // 진단_BM_실험_1차_v2: [0.1, 0.45, 0.45],
  // 홈_뷰타입_실험: [0.5, 0.5],
  // 임시저장_버튼_실험_v2: [0.5, 0.5],
  // 찜_뷰타입_실험: [0.5, 0.5],
  // 구매동행_실험: [0.5, 0.5],
} as Record<string, number[]>;
export type ExperimentName = keyof typeof Experiment;

/** https://docs.sentry.io/platforms/dotnet/guides/aws-lambda/enriching-events/tags/
 * Tag keys have a maximum length of 32 characters and can contain only letters (a-zA-Z), numbers (0-9), underscores (_), periods (.), colons (:), and dashes (-).
 * Tag values have a maximum length of 200 characters and they cannot contain the newline (\n) character.
 */
export const ExperimentEns: Record<keyof typeof Experiment, string> = {};

export type ExperimentGroupMap = Record<ExperimentName, ExperimentGroup>;

const setTags = (v: Partial<ExperimentGroupMap>) => {
  const scope = getCurrentScope();
  objectEntries(v).forEach(([name, group]) => {
    const en = ExperimentEns[name];
    // 이전에 끝났던 실험이 있을 수 있어서 en name이 없을 수 있어요.
    if (en) {
      scope.setTag(en, group);
    }
  });
};

export function getExperimentGroup(index: number): ExperimentGroup {
  if (index === 0) {
    return 'CONTROL';
  }
  return `TREATMENT_${index}`;
}

function setUserExperimentGroup(experimentName: ExperimentName, group: ExperimentGroup) {
  const propertyName = 'experimentGroup';
  const value = `${experimentName}:${group}`;
  const identifyEvent = new Identify();
  identifyEvent.postInsert(propertyName, value);
  identify(identifyEvent);

  mixpanel.people.union(propertyName, value);
}

export function getRandomGroup(experimentName: ExperimentName): ExperimentGroup {
  const ratioList = Experiment[experimentName] as number[];
  const sumOfRatio = sum(ratioList);
  let random = Math.random() * sumOfRatio;

  for (let i = 0; i < ratioList.length; i++) {
    const ratio = ratioList[i];
    if (random <= ratio) {
      return getExperimentGroup(i);
    }
    random -= ratio;
  }
  return 'CONTROL';
}
export const assignedGroupMapState = atomWithCarStorage(
  'assignedGroup',
  {} as Partial<ExperimentGroupMap>
);

export const experimentGroupSelector = atomFamily((key: ExperimentName) =>
  atom(
    (get) => {
      const assignedGroupMap = get(assignedGroupMapState);
      return assignedGroupMap[key];
    },
    (get, set, newValue: ExperimentGroup) => {
      const assignedGroupMap = get(assignedGroupMapState);
      const assignedGroup = assignedGroupMap[key];
      if (assignedGroup) {
        setTags(assignedGroupMap);
        return undefined;
      }
      if (newValue) {
        const newGroupMap = { ...assignedGroupMap, [key]: newValue };
        const treatments = Object.entries(newGroupMap).map(
          ([groupName, group]) => `${groupName}:${group}`
        );
        carTracker.trackEvent('set_user_experimental_groups', { treatments });
        window?.clarity?.('set', 'treatments', treatments);
        set(assignedGroupMapState, newGroupMap);
        setUserExperimentGroup(key, newValue);
        setTags(newGroupMap);
      }
    }
  )
);
