import { useActivity } from '@stackflow/react';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { carTracker } from '@/sdk/tracker/CarTracker';
import { type CustomEvent } from '@/sdk/tracker/CustomEvent';
import { activityDescription } from '@/stackflow';
import { getActiveActivity } from '@/stackflow/utils';
import { assignedGroupMapState } from '@/store/experiment';
import { UnionToIntersection } from '@/types/global';

import usePrevActivity from './usePrevActivity';

type AddPrefix<TPrefix extends string, TText extends string> = `${TPrefix}_${TText}`;
type RemovePrefix<
  TPrefix extends string,
  TText extends string,
> = TText extends `${TPrefix}_${infer C}` ? C : never;

type ActivityName = keyof typeof activityDescription;
const useTrack = <TActivityNamePrefix extends ActivityName = ActivityName>() => {
  const activity = useActivity() ?? getActiveActivity();
  const prevActivity = usePrevActivity();
  const experimentGroupMap = useAtomValue(assignedGroupMapState);

  /**
   * Deprecated. 더이상 ActivityName을 자동으로 Prefix 하지 않아요.
   */
  const trackWithActivityName = useCallback(
    <
      TEventName extends RemovePrefix<TActivityNamePrefix, CustomEvent['name']>,
      TEventParams extends Extract<
        CustomEvent,
        { name: AddPrefix<TActivityNamePrefix, TEventName> }
      >['params'],
    >(
      name: TEventName,
      params?: UnionToIntersection<TEventParams>
    ) => {
      const eventName = `${activity.name}_${name}`;
      return carTracker.trackEvent(eventName as CustomEvent['name'], params ?? {}, {
        currentActivity: activity,
        prevActivity,
        experimentGroupMap,
      });
    },
    [activity, experimentGroupMap, prevActivity]
  );

  const track = useCallback(
    <
      TEventName extends CustomEvent['name'],
      TEventParams extends Extract<CustomEvent, { name: TEventName }>['params'],
    >(
      name: TEventName,
      params?: UnionToIntersection<TEventParams>
    ) => {
      return carTracker.trackEvent(name, (params ?? {}) as any, {
        currentActivity: activity,
        prevActivity,
        experimentGroupMap,
      });
    },
    [activity, experimentGroupMap, prevActivity]
  );

  return { track, trackWithActivityName };
};

export default useTrack;
