import { Divider } from '@daangn/sprout-components-divider';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCheckFlowerFill, IconConfirmationFill } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { ArticleTitleSection_article$key } from '@/__generated__/ArticleTitleSection_article.graphql';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { Label3Regular, Label4Bold, Title3Regular } from '@/components/Base/Text';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import { ArticleStatusText } from '@/types/Article/ArticleStatus';
import { FuelTypeText } from '@/types/CarData/FuelType';
import { getCarSpec } from '@/utils/article/getCarSpec';
import { isInspectedCar } from '@/utils/article/isInspectedCar';
import { getShortenYear } from '@/utils/number';
import { getDriveDistanceText } from '@/utils/string';
import { getSizeProps } from '@/utils/style';

import { useArticleContext } from '../ArticleContext';

type Props = {
  articleRef: ArticleTitleSection_article$key;
};

const ArticleTitleSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment ArticleTitleSection_article on Article {
        status
        driveDistance
        carData {
          modelYear
          fuelType
        }
        ...getCarSpec_article
        ...getPriceText_article
        ...isInspectedCar_article
        ...useIsWarrantable_article
      }
    `,
    articleRef
  );
  const { isWarrantable } = useIsWarrantable(article);
  const { status, driveDistance, carData } = article;
  const { modelYear, fuelType } = carData;
  const isInspected = isInspectedCar(article);
  const { name } = getCarSpec(article);
  const { isMyArticle } = useArticleContext();
  const inspectionBadge = isInspected ? (
    <HStack align="center" gap={2}>
      <IconCheckFlowerFill color={getSeedColor('blue500')} {...getSizeProps(14)} />
      <Label4Bold color="gray700">진단</Label4Bold>
    </HStack>
  ) : undefined;
  const warrantyBadge = !!isWarrantable ? (
    <HStack align="center" gap={2}>
      <IconConfirmationFill color={getSeedColor('primary')} {...getSizeProps(14)} />
      <Label4Bold color="gray700">보증</Label4Bold>
    </HStack>
  ) : undefined;
  return (
    <Container>
      {(!!inspectionBadge || !!warrantyBadge) && (
        <HStack
          divider={
            <DividerWrapper>
              <Divider orientation="vertical" />
            </DividerWrapper>
          }
          gap={14}
          mb={6}
        >
          {inspectionBadge}
          {warrantyBadge}
        </HStack>
      )}
      <Title>
        {!isMyArticle && (status === 'RESERVED' || status === 'TRADED') && (
          <StatusText status={status}>{ArticleStatusText[status]} </StatusText>
        )}
        {name}
      </Title>
      <Label3Regular color="gray800" mt={10}>
        {[
          `${getShortenYear(modelYear)}년식`,
          `${getDriveDistanceText(driveDistance)}km`,
          FuelTypeText[fuelType],
        ].join(' ⸱ ')}
      </Label3Regular>
    </Container>
  );
};

const DividerWrapper = styled(HStack)({
  paddingTop: rem(3),
  paddingBottom: rem(3),
  height: '100%',
  '.seed-divider': {
    backgroundColor: getSeedColor('gray300'),
  },
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Title3Regular)`
  white-space: pre-wrap;
  word-break: break-word;
`;

const StatusText = styled.span<{ status: 'RESERVED' | 'TRADED' }>`
  color: ${({ status }) =>
    status === 'RESERVED' ? vars.$semantic.color.success : vars.$scale.color.gray600};
`;

export default ArticleTitleSection;
