import { useCallback } from 'react';

import { FilterState, useFilterUpdaterContext } from '@/components/Filter/FilterContextProvider';

const useUpdateFilter = () => {
  const { updateFilter: setFilter, setFilterChangedCount } = useFilterUpdaterContext();
  const updateFilter = useCallback(
    (changedState: Partial<FilterState>) => {
      setFilterChangedCount((prevValue) => prevValue + 1);
      setFilter((pv) => ({
        ...pv,
        ...changedState,
      }));
    },
    [setFilter, setFilterChangedCount]
  );

  return updateFilter;
};

export default useUpdateFilter;
