import type { Activity, StackflowPlugin } from '@stackflow/core';

import { Activity as ActivityItem } from '@stackflow/core';

import { karrotBridge } from '@/sdk/bridge';
import { type ActivityName, activityDescription } from '@/stackflow/Activity';

function getActiveActivities(activities: Activity[]) {
  return activities.filter(
    (activity) =>
      activity.transitionState === 'exit-active' || activity.transitionState === 'enter-done'
  );
}

const handleRouteStyle = ({
  depth,
  activeActivities,
}: {
  activeActivities: ActivityItem[];
  depth: number;
}) => {
  const lastActiveActivity = activeActivities[activeActivities.length - 1];
  let backSwipable = false;
  if (depth <= 1) {
    backSwipable = true;
    if (
      activityDescription[lastActiveActivity.name as ActivityName]?.defaultAppScreenProps
        .preventSwipeBack
    ) {
      backSwipable = false;
    }
  }

  karrotBridge.styleCurrentRouter({
    router: {
      backSwipable,
      navbar: false,
      scrollable: false,
      enableSafeAreaInsets: false,
    },
  });
};

const bridgeSwipeBackPlugin = (): StackflowPlugin => {
  let prevActivityIds: string[] = [];
  return () => ({
    key: 'plugin-bridge-swipe-back',
    onInit({ actions: { getStack } }) {
      const { activities } = getStack();
      const activeActivities = getActiveActivities(activities);
      prevActivityIds = activeActivities.map(({ id }) => id);
      const depth = activeActivities.length;
      handleRouteStyle({
        depth,
        activeActivities,
      });
    },
    onChanged({ actions: { getStack } }) {
      const { activities } = getStack();
      const activeActivities = getActiveActivities(activities);
      const depth = activeActivities.length;

      if (
        prevActivityIds.length !== activeActivities.length ||
        activeActivities.some(({ id }, i) => id !== prevActivityIds[i])
      ) {
        prevActivityIds = activeActivities.map(({ id }) => id);
        handleRouteStyle({
          depth,
          activeActivities,
        });
      }
    },
  });
};

export default bridgeSwipeBackPlugin;
