import { BoxButton } from '@daangn/sprout-components-button';
import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { WarrantyButton_article$key } from '@/__generated__/WarrantyButton_article.graphql';
import { useFlow } from '@/stackflow';

const Fragment = graphql`
  fragment WarrantyButton_article on Article {
    id
  }
`;

type Props = {
  articleRef: WarrantyButton_article$key;
};

const WarrantyButton: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(Fragment, articleRef);
  const { push } = useFlow();
  const handleClick = () => {
    push('warranty_guide', { articleId: article.id });
  };

  return (
    <BoxButton onClick={handleClick} size="xlarge" variant="primaryLow" width="100%">
      진단보증
    </BoxButton>
  );
};

export default WarrantyButton;
