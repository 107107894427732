import { Activity } from '@stackflow/core';
import { objectEntries } from '@toss/utils';

import { Experiment, ExperimentGroupMap } from '@/store/experiment';
import { compose, filterNil } from '@/utils/misc';

import { sessionStorage } from './storage';

export type BaseEventParams = {
  [name: string]: any;
  activityDescription?: string;
  activityName?: string;
  experiment?: string[];
  prevActivityDescription?: string;
  prevActivityName?: string;
  ref?: string;
  referrer?: string;
  sref?: string;
  url?: string;
};

export function getDefaultEventParams(
  activity?: Activity,
  prevActivity?: Activity,
  experimentGroupMap?: Partial<ExperimentGroupMap>
): BaseEventParams {
  const sref = sessionStorage.getReferrer();
  const experiment = experimentGroupMap
    ? objectEntries(experimentGroupMap)
        .filter(([name]) => !!Experiment[name])
        .map(([name, group]) => `${name}_${group}`)
    : [];
  // ref: https://karrot.atlassian.net/wiki/spaces/FE/pages/1210253523/WebView+Referrer+Standard
  return {
    experiment,
    sref: sref ?? undefined,
    referrer: sref ?? undefined,
    ref: prevActivity?.name,
    prevActivityName: prevActivity?.name,
    activityName: activity?.name,
    url: (activity?.context as any)?.path,
    ...activity?.params,
  };
}

function arrToString(params: BaseEventParams) {
  const entries = Object.entries(params).map(([key, value]) => [
    key,
    Array.isArray(value) ? value.join(',') : value,
  ]);

  return Object.fromEntries(entries) as BaseEventParams;
}

export const formatParams = compose(JSON.stringify, arrToString, filterNil);
