/**
 * @generated SignedSource<<f1558f29fc06fd5b2c4b47cfbac2a4f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoanBottomSheet_article$data = {
  readonly loanTrackingUrl: {
    readonly hcs: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"getAllowedLoanList_article">;
  readonly " $fragmentType": "LoanBottomSheet_article";
};
export type LoanBottomSheet_article$key = {
  readonly " $data"?: LoanBottomSheet_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoanBottomSheet_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LoanTrackingUrl",
  "kind": "LinkedField",
  "name": "loanTrackingUrl",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hcs",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoanBottomSheet_article",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getAllowedLoanList_article",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "b1ec015d9eb9d267d87db6c301222083";

export default node;
