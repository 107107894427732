import { PropsOf, css, keyframes } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import { IconHeartFill, IconHeartRegular } from '@seed-design/icon';
import React, { MouseEventHandler } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';

import { LikeButton_article$key } from '@/__generated__/LikeButton_article.graphql';
import { LikeButton_LikeArticleMutation } from '@/__generated__/LikeButton_LikeArticleMutation.graphql';
import { LikeButton_UnlikeArticleMutation } from '@/__generated__/LikeButton_UnlikeArticleMutation.graphql';
import { SeedColor, getSeedColor } from '@/components/Base/Box';
import Center from '@/components/Base/Center';
import Scheme from '@/constants/scheme';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import { useToast } from '@/hooks/useToast';
import useTrack from '@/hooks/useTrack';
import useUser from '@/hooks/useUser';
import getDataID from '@/relay/getDataID';
import { karrotBridge } from '@/sdk/bridge';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: LikeButton_article$key;
  color?: SeedColor;
  from?: 'article' | 'viewType';
  showToast?: boolean;
  size?: number;
} & Partial<PropsOf<typeof Center>>;

const LikeButton: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  size = 24,
  showToast = false,
  color = 'onPrimary',
  from = 'viewType',
  ...props
}) => {
  const { track } = useTrack();
  const { checkLogin } = useUser();
  const redirect = useRedirectAppScheme();
  const article = useFragment(
    graphql`
      fragment LikeButton_article on Article {
        id
        isUserVoted
        voteCount
        ...getArticleEventProperties_article
      }
    `,
    articleRef
  );
  const [likeArticle] = useMutation<LikeButton_LikeArticleMutation>(graphql`
    mutation LikeButton_LikeArticleMutation($input: LikeArticleInput!) {
      likeArticle(input: $input) {
        id
        isUserVoted
        voteCount
      }
    }
  `);
  const [unlikeArticle] = useMutation<LikeButton_UnlikeArticleMutation>(graphql`
    mutation LikeButton_UnlikeArticleMutation($input: UndoLikeArticleInput!) {
      undoLikeArticle(input: $input) {
        id
        isUserVoted
        voteCount
      }
    }
  `);
  const { setToast } = useToast();

  const handleClickVote: MouseEventHandler<HTMLDivElement> = async (e) => {
    e.stopPropagation();

    if (!checkLogin() || !article.id) {
      return;
    }
    const nextVoteType = article.isUserVoted ? 'unlike' : 'like';

    if (article.isUserVoted) {
      unlikeArticle({
        variables: { input: { articleId: article.id } },
        optimisticResponse: {
          undoLikeArticle: {
            id: article.id,
            isUserVoted: false,
            voteCount: article.voteCount - 1,
          },
        },
        updater(store) {
          const root = store.getRoot();
          const records = root.getLinkedRecords('myVotedArticles');

          if (!records) {
            return;
          }
          root.setLinkedRecords(
            records.filter((r) => r?.getValue('id') !== article.id),
            'myVotedArticles'
          );
        },
      });
    } else {
      likeArticle({
        variables: { input: { articleId: article.id } },
        optimisticResponse: {
          likeArticle: {
            id: article.id,
            isUserVoted: true,
            voteCount: article.voteCount + 1,
          },
        },
        updater(store) {
          const root = store.getRoot();
          const records = root.getLinkedRecords('myVotedArticles');
          const dataID = getDataID(article, 'Article');
          const record = store.get(dataID);

          if (!records || !record) {
            return;
          }
          root.setLinkedRecords([record, ...records], 'myVotedArticles');
        },
      });

      if (showToast) {
        const answer = await setToast({
          message: '관심목록에 추가했어요.',
          actionLabel: '관심목록 보기',
        });
        if (answer) {
          redirect(Scheme.MyWatches);
        }
      }
    }
    karrotBridge.setHapticLightFeedback({});
    track(`article_click_${nextVoteType}`, { ...getArticleEventProperties(article), from });
  };

  const iconProps = getSizeProps(size);

  return (
    <Center h="auto" onClick={handleClickVote} pa={8} {...props}>
      {article.isUserVoted ? (
        <IconHeartFill {...iconProps} color={vars.$semantic.color.primary} css={heartStyle} />
      ) : (
        <IconHeartRegular {...iconProps} color={getSeedColor(color)} />
      )}
    </Center>
  );
};

const heartBounce = keyframes`
  0% {
    transform: scale(0.05)
  }
  10% {
    transform: scale(0.1)
  }
  20% {
    transform: scale(0.2)
  }
  30% {
    transform: scale(0.3)
  }
  40% {
    transform: scale(0.4)
  }
  50% {
    transform: scale(1.1)
  }
  60% {
    transform: scale(1.2)
  }
  70% {
    transform: scale(1.3)
  }
  80% {
    transform: scale(1.2)
  }
  90% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
`;

const heartStyle = css`
  animation-name: ${heartBounce};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`;

export default LikeButton;
