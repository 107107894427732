/**
 * @generated SignedSource<<ae725e017315b0d180ce355c00037123>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccidentType = "ABSOLUTE_NO_ACCIDENT" | "ACCIDENT" | "NO_ACCIDENT";
export type CarHistoryStatus = "EXPIRED" | "NOT_REQUESTED" | "OWNED" | "REQUESTED" | "UNABLE_TO_FETCH";
import { FragmentRefs } from "relay-runtime";
export type ArticleInspectionSection_article$data = {
  readonly carData: {
    readonly accidentType: AccidentType | null | undefined;
    readonly carHistory: {
      readonly " $fragmentSpreads": FragmentRefs<"carHistory_getCarHistoriesExpenseTotalAmount">;
    } | null | undefined;
    readonly carHistoryStatus: CarHistoryStatus;
    readonly inspection: {
      readonly fixedDate: any | null | undefined;
      readonly id: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "ArticleInspectionSection_article";
};
export type ArticleInspectionSection_article$key = {
  readonly " $data"?: ArticleInspectionSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleInspectionSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleInspectionSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accidentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carHistoryStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarHistory",
          "kind": "LinkedField",
          "name": "carHistory",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carHistory_getCarHistoriesExpenseTotalAmount",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CarHistoryAccident",
                  "kind": "LinkedField",
                  "name": "accidents",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expenseEstimate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "claim",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Inspection",
          "kind": "LinkedField",
          "name": "inspection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fixedDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "7899eb246549a53ba09834e5c9da8882";

export default node;
