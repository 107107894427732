import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import type { useIsWarrantable_carData$key } from '@/__generated__/useIsWarrantable_carData.graphql';

import { useIsWarrantable_article$key } from '@/__generated__/useIsWarrantable_article.graphql';
import { useFeatureFlagContext } from '@/contexts/FeatureFlagContext';
import { useWarrantyPrice } from '@/hooks/useWarrantyPrice';
import { Nullish } from '@/types/global';

export const useIsWarrantableByPrice = (
  carDataRef: Nullish<useIsWarrantable_carData$key>,
  price: number
) => {
  const { isEnabledWarranty } = useFeatureFlagContext();
  const { isWarrantable } = useFragment(
    graphql`
      fragment useIsWarrantable_carData on CarData {
        isWarrantable
      }
    `,
    carDataRef
  ) ?? {
    price: null,
    carData: { isWarrantable: false, warrantyPurchasable: false },
  };
  const { warrantyBasePrice } = useWarrantyPrice();
  const isPriceOver500 = (price ?? 0) > warrantyBasePrice;

  return isEnabledWarranty && isWarrantable && isPriceOver500;
};

const useIsWarrantable = (articleRef: Nullish<useIsWarrantable_article$key>) => {
  const { isEnabledWarranty } = useFeatureFlagContext();
  const article = useFragment(
    graphql`
      fragment useIsWarrantable_article on Article {
        price
        warrantyPurchasable
        carData {
          ...useIsWarrantable_carData
        }
      }
    `,
    articleRef
  );
  const isWarrantable = useIsWarrantableByPrice(article?.carData, article?.price ?? 0);
  const isWarrantyPurchasable = isEnabledWarranty && !!article?.warrantyPurchasable;

  return {
    isWarrantable,
    isWarrantyPurchasable,
  };
};

export default useIsWarrantable;
