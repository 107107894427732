/**
 * @generated SignedSource<<e85a3df6257c48f2814588e0f4e177e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation$variables = {
  articleId: string;
  payId: string;
};
export type PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation$data = {
  readonly closePromotionPaymentAndPromoteArticle: {
    readonly nextPullUpAt: any;
    readonly promotionEndsAt: any | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleChart_article">;
  };
};
export type PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation = {
  response: PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation$data;
  variables: PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "articleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "articleId",
    "variableName": "articleId"
  },
  {
    "kind": "Variable",
    "name": "payId",
    "variableName": "payId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextPullUpAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promotionEndsAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "t",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "view",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "closePromotionPaymentAndPromoteArticle",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArticleChart_article"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "closePromotionPaymentAndPromoteArticle",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleEvent",
            "kind": "LinkedField",
            "name": "events",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleStatistics",
            "kind": "LinkedField",
            "name": "statistics",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleStatistics",
            "kind": "LinkedField",
            "name": "statisticsIn48Hours",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "estimationsPromoted24HoursAgo",
            "args": null,
            "concreteType": "ArticleStatistics",
            "kind": "LinkedField",
            "name": "statisticsPromoted24HoursAgo",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "estimationsPromotedFromNowOn",
            "args": null,
            "concreteType": "ArticleStatistics",
            "kind": "LinkedField",
            "name": "statisticsPromotedFromNowOn",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e431e26675ee09a32999757bceb1373",
    "id": null,
    "metadata": {},
    "name": "PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation",
    "operationKind": "mutation",
    "text": "mutation PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation(\n  $payId: ID!\n  $articleId: ID!\n) {\n  closePromotionPaymentAndPromoteArticle(payId: $payId, articleId: $articleId) {\n    nextPullUpAt\n    promotionEndsAt\n    ...ArticleChart_article\n    id\n  }\n}\n\nfragment ArticleChart_article on Article {\n  events {\n    t\n    type\n  }\n  statistics {\n    t\n    view\n  }\n  statisticsIn48Hours {\n    t\n    view\n  }\n  estimationsPromoted24HoursAgo: statisticsPromoted24HoursAgo {\n    t\n    view\n  }\n  estimationsPromotedFromNowOn: statisticsPromotedFromNowOn {\n    t\n    view\n  }\n}\n"
  }
};
})();

(node as any).hash = "75a1ceaf5b5dd710beddb8c0177d94f9";

export default node;
