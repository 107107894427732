import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';

import Box from '@/components/Base/Box';

const PlaceholderAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const placeholderCss = css`
  background-color: ${vars.$scale.color.gray100};
  position: relative;
  overflow: hidden;
  /* https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  mask-image: radial-gradient(white, black);

  &::after {
    content: '';
    display: block;
    background: linear-gradient(
      to left,
      rgba(0, 10, 20, 0),
      rgba(0, 10, 20, 0.075),
      rgba(0, 10, 20, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 2s ${PlaceholderAnimation} ease-in-out infinite;
    min-width: 10rem;
  }
`;

const Placeholder = styled(Box)`
  ${placeholderCss}
`;

export default Placeholder;
