import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

const useNavigatorHeight = () => {
  const theme = useTheme();
  const navigatorHeight = useMemo(() => {
    switch (theme.environment) {
      case 'Cupertino':
        return 44;
      case 'Android':
      default:
        return 56;
    }
  }, [theme.environment]);

  return navigatorHeight;
};

export default useNavigatorHeight;
