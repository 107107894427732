/**
 * @generated SignedSource<<b9c5ffa5235a4988f7dd0490def2a1be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterLayerQuery$variables = Record<PropertyKey, never>;
export type FilterLayerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CompanyIdsFilter_autoupCompanies">;
};
export type FilterLayerQuery = {
  response: FilterLayerQuery$data;
  variables: FilterLayerQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterLayerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompanyIdsFilter_autoupCompanies"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FilterLayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoupCompany",
        "kind": "LinkedField",
        "name": "autoupCompanies",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyOrigin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imgUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d7504f7be2e7f6d8cbaa4fcda2ab1ce",
    "id": null,
    "metadata": {},
    "name": "FilterLayerQuery",
    "operationKind": "query",
    "text": "query FilterLayerQuery {\n  ...CompanyIdsFilter_autoupCompanies\n}\n\nfragment CompanyIcon_autoupCompany on AutoupCompany {\n  imgUrl\n}\n\nfragment CompanyIdsFilter_autoupCompanies on Query {\n  autoupCompanies {\n    id\n    name\n    count\n    companyOrigin\n    ...CompanyIcon_autoupCompany\n  }\n}\n"
  }
};

(node as any).hash = "25be2a1cc2b99f3b560cec163529df10";

export default node;
