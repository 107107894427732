/**
 * @generated SignedSource<<61cb3da11aca250ce37da54c7a3436f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CarHistoryChangeType = "CAR_NO" | "OWNER" | "REGISTER";
import { FragmentRefs } from "relay-runtime";
export type carHistory_summaryCarHistoryOwnerChanges$data = {
  readonly changes: ReadonlyArray<{
    readonly date: any;
    readonly type: CarHistoryChangeType;
  }>;
  readonly " $fragmentType": "carHistory_summaryCarHistoryOwnerChanges";
};
export type carHistory_summaryCarHistoryOwnerChanges$key = {
  readonly " $data"?: carHistory_summaryCarHistoryOwnerChanges$data;
  readonly " $fragmentSpreads": FragmentRefs<"carHistory_summaryCarHistoryOwnerChanges">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carHistory_summaryCarHistoryOwnerChanges"
};

(node as any).hash = "5f18a098ca3769826167c130129490ba";

export default node;
