import { atom } from 'jotai';
import { fetchQuery, graphql } from 'relay-runtime';

import type { serviceMaintenanceQuery } from '@/__generated__/serviceMaintenanceQuery.graphql';

import environment from '@/relay/environment';

const fetchServiceMaintenance = () =>
  new Promise<boolean>((resolve) => {
    fetchQuery<serviceMaintenanceQuery>(
      environment,
      graphql`
        query serviceMaintenanceQuery {
          underInspection
        }
      `,
      {}
    ).subscribe({
      next({ underInspection }) {
        resolve(underInspection);
      },
      error() {
        resolve(false);
      },
    });
  });

export const serviceMaintenanceAtom = atom(async () => {
  const inspection = await fetchServiceMaintenance();
  return inspection;
});
