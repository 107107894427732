import mixpanel from 'mixpanel-browser';

import CONFIG from '@/constants/config';
import { carStorage } from '@/utils/storage';
import { isCarTeamUserId } from '@/utils/user';

import { mixpanelCustomEventRegex } from './CustomEvent';
import { mixpanelPvEventRegex } from './PvEvent';
import { TrackerImpl, UserIdentity } from './TrackerImpl';

const MixpanelTracker = (): TrackerImpl => {
  let initialized = false;
  let _info: UserIdentity | undefined;
  const initialize = () => {
    if (initialized || !CONFIG.MIXPANEL_TOKEN || !_info) {
      return undefined;
    }

    const { user, region, app } = _info;
    mixpanel.init(CONFIG.MIXPANEL_TOKEN);
    if (user?.id) {
      mixpanel.identify(`karrot-${user.id}`);
    }

    mixpanel.people.set({
      regionName2: region.name2,
      isCarTeamAdmin: isCarTeamUserId(String(user?.id ?? '')),
      deviceId: app?.deviceIdentity ?? carStorage.getGaClientId(),
    });
    initialized = true;
  };

  return {
    initialized,
    init: (info) => {
      _info = info;
      initialize();
    },
    trackEvent: (name, params) => {
      if (!mixpanelCustomEventRegex.test(name)) {
        return undefined;
      }
      if (!initialized) {
        initialize();
      }
      mixpanel.track(name, params);
    },
    trackPageView: (name, params) => {
      if (!mixpanelPvEventRegex.test(name)) {
        return undefined;
      }
      if (!initialized) {
        initialize();
      }
      mixpanel.track_pageview({
        page: name,
        ...params,
      });
    },
  };
};

export const mixpanelTracker = MixpanelTracker();
