import type { Stack } from '@stackflow/core';

export function getActiveActivity(stack: Stack) {
  return stack.activities.find((activity) => activity.isActive);
}

export function getPrevActivity(stack: Stack) {
  const filteredActivities = stack.activities.filter((a) => a.transitionState !== 'exit-done');
  const activeActivityIndex = filteredActivities.findIndex((a) => a.isActive);

  return activeActivityIndex >= 0 ? filteredActivities[activeActivityIndex - 1] : undefined;
}
