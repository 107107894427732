import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightThin } from '@seed-design/icon';
import { rem } from 'polished';
import { graphql, useFragment } from 'react-relay';

import { HistoryGrid_carHistory$key } from '@/__generated__/HistoryGrid_carHistory.graphql';
import Center from '@/components/Base/Center';
import HStack from '@/components/Base/HStack';
import Text from '@/components/Base/Text';
import { pressed } from '@/styles/mixins';
import { getSizeProps } from '@/utils/style';

export type CarHistoryItem = 'accident' | 'mine_damage' | 'owner_change' | 'special_use';

type Props = {
  carHistoryRef: HistoryGrid_carHistory$key;
  onClick: (name: CarHistoryItem) => void;
};

const HistoryGrid: React.FC<Props> = ({ carHistoryRef, onClick }) => {
  const {
    accidents,
    numOfTotalAccidents,
    numOfSubmergedAccidents,
    rent,
    generalBusiness,
    changes,
  } = useFragment(
    graphql`
      fragment HistoryGrid_carHistory on CarHistory {
        generalBusiness
        rent
        accidents {
          type
        }
        changes {
          type
        }
        numOfTotalAccidents
        numOfSubmergedAccidents
      }
    `,
    carHistoryRef
  );

  const insuranceAccidentsCount = (accidents ?? []).length;
  const accidentsCount = (numOfTotalAccidents ?? 0) + (numOfSubmergedAccidents ?? 0);
  const businessUsageText = [rent && '렌트', generalBusiness && '영업'].filter(Boolean).join('/');
  const ownerChangeCount = (changes ?? []).filter((change) => change.type === 'OWNER').length;

  const carHistoryItems: Array<{ label: string; name: CarHistoryItem; value: number | string }> = [
    { label: '보험사고', value: insuranceAccidentsCount, name: 'mine_damage' },
    { label: '소유자 변경', value: ownerChangeCount, name: 'owner_change' },
    { label: '렌트, 영업', value: businessUsageText, name: 'special_use' },
    { label: '전손, 침수', value: accidentsCount, name: 'accident' },
  ];

  return (
    <Container>
      {carHistoryItems.map(({ name, value, label }) => {
        return (
          <HistoryItem
            bc="divider2"
            br={10}
            key={name}
            onClick={() => onClick(name)}
            px={16}
            py={18}
          >
            <Text color="gray500" variant="label2Bold">
              {typeof value === 'number' ? (
                value > 0 ? (
                  <Text color="warning" inline variant="label2Bold">
                    {value}회
                  </Text>
                ) : (
                  '0회'
                )
              ) : typeof value === 'string' ? (
                value ? (
                  <Text color="warning" inline variant="label2Bold">
                    {value}
                  </Text>
                ) : (
                  '없음'
                )
              ) : null}
            </Text>
            <HStack align="center" mt={4}>
              <Text color="gray700" variant="caption1Regular">
                {label}
              </Text>
              <IconChevronRightThin {...getSizeProps(12)} color={vars.$scale.color.gray700} />
            </HStack>
          </HistoryItem>
        );
      })}
    </Container>
  );
};

const colorTransition = keyframes`
  from {
    color: ${vars.$semantic.color.primary};
  }
  to {
    color: ${vars.$semantic.color.warning};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(8)};
  .active & b {
    animation: ${colorTransition} 2s ease;
  }
`;

const HistoryItem = styled(Center)`
  height: auto;
  ${pressed()}
`;

export default HistoryGrid;
