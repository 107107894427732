import { addMonths, isAfter } from 'date-fns';
import { atom } from 'jotai';
import { P, match } from 'ts-pattern';

import { atomWithResetAndCarStorage } from '@/utils/jotai';

export type UserFeedbackValue = {
  content: string;
  rating: number;
};

export type UserFeedbackState =
  | {
      sentAt: string; // date string
      type: 'completed';
    }
  | {
      type: 'idle';
      value: UserFeedbackValue;
    };

export const userFeedbackState = atomWithResetAndCarStorage<UserFeedbackState>(
  'userFeedbackState',
  {
    type: 'idle',
    value: {
      rating: 3,
      content: '',
    },
  }
);

export const userFeedbackSentStatusSelector = atom((get) => {
  const state = get(userFeedbackState);
  return match(state)
    .with({ type: 'idle' }, () => 'not_sent' as const)
    .with({ type: 'completed', sentAt: P.select('sentAt') }, ({ sentAt }) =>
      isAfter(Date.now(), addMonths(new Date(sentAt), 1))
        ? ('sent_month_ago' as const)
        : ('sent' as const)
    )
    .exhaustive();
});
