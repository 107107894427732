// 사용자 향 진단 리포트에 사용

import type { MechanicReportCategory } from '@/types/MechanicReport/MechanicReport';

export const maintainableCategories: MechanicReportCategory[] = [
  'engine',
  'autoTransmission',
  'manualTransmission',
  'powertrain',
  'steering',
  'fuelSystem',
  'brakingSystem',
  'electricalSystem',
  'highVoltageElectricalSystem',
];
