/**
 * @generated SignedSource<<e5cd98f060326be768c803b6ec068445>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getCarSpec_article$data = {
  readonly carData: {
    readonly modelYear: number;
    readonly " $fragmentSpreads": FragmentRefs<"carData_getCarFullName_carData">;
  };
  readonly driveDistance: number;
  readonly " $fragmentSpreads": FragmentRefs<"getPriceText_article">;
  readonly " $fragmentType": "getCarSpec_article";
};
export type getCarSpec_article$key = {
  readonly " $data"?: getCarSpec_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getCarSpec_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getCarSpec_article"
};

(node as any).hash = "462fd7ea4bb3b883841140e2d8257d40";

export default node;
