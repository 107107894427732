import { useActivity, useStack } from '@stackflow/react';
import { useMemo } from 'react';

import { actions } from '@/stackflow';
import { getActiveActivity } from '@/stackflow/utils';

const usePrevActivity = () => {
  const activity = useActivity() ?? getActiveActivity();
  const stack = useStack() ?? actions.getStack();

  const prevActivity = useMemo(() => {
    const filteredActivities = stack.activities.filter((a) => a.transitionState !== 'exit-done');
    const activityIndex = filteredActivities.findIndex((a) => a.id === activity?.id);

    return activityIndex > 0 ? filteredActivities[activityIndex - 1] : undefined;
  }, [activity, stack]);

  return prevActivity;
};

export default usePrevActivity;
