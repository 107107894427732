export const inspectionAdvantages: Array<{ description: string; title: string }> = [
  {
    title: '20년 노하우의 차량 진단',
    description: '20년 경력의 진단평가사가 만든 시스템으로 180개 이상의 항목을 진단해요.',
  },
  {
    title: '객관적인 진단 결과',
    description: '당근은 사지도, 팔지도 않기 때문에 객관적인 차량 상태만 전달드려요.',
  },
];
