import { graphql, useLazyLoadQuery } from 'react-relay';

import { NotificationCountBadgeQuery } from '@/__generated__/NotificationCountBadgeQuery.graphql';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';

import CountBadge from './CountBadge';

const NotificationCountBadge: React.FC = () => {
  const { notifications } = useLazyLoadQuery<NotificationCountBadgeQuery>(
    graphql`
      query NotificationCountBadgeQuery {
        notifications: newArticleNotificationConditions {
          id
        }
      }
    `,
    {}
  );

  return (
    <CountBadge>
      {notifications?.length ?? 0}/{MAX_NOTIFICATION_COUNT}
    </CountBadge>
  );
};

export default NotificationCountBadge;
