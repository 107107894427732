import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React from 'react';

import { exactSize } from '@/styles/mixins';

type Props = {
  backgroundColor?: string;
  height?: number;
};

const Divider: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundColor: propBgColor,
  height = 1,
  ...props
}) => {
  const backgroundColor = propBgColor ?? vars.$semantic.color.divider1;

  return <Styled.Divider backgroundColor={backgroundColor} height={height} {...props} />;
};

const Styled = {
  Divider: styled.div<Props>`
    width: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    ${(props) => exactSize('height', props.height ?? 1)};
  `,
};

export default Divider;
