import { Divider } from '@daangn/sprout-components-divider';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import { rem } from 'polished';
import React, { Suspense, startTransition, useMemo } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { Article_article$key } from '@/__generated__/Article_article.graphql';
import { ArticleQuery } from '@/__generated__/ArticleQuery.graphql';
import ArticleError from '@/components/Article/ArticleError';
import AsyncBoundary from '@/components/AsyncBoundary';
import Box from '@/components/Base/Box';
import BaseDivider from '@/components/Base/Divider';
import DividerNav from '@/components/Base/DividerNav';
import Spacer from '@/components/Base/Spacer';
import VStack from '@/components/Base/VStack';
import MainShortcutBanner from '@/components/MainShortcutBanner';
import SkeletonPageArticle from '@/components/Skeleton/SkeletonPageArticle';
import User from '@/components/User/User';
import CONFIG from '@/constants/config';
import useCanProxyInspection from '@/hooks/useCanProxyInspection';
import useCheckVisited from '@/hooks/useCheckVisited';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import { usePvEvent } from '@/hooks/usePvEvent';
import { useReactiveEffect } from '@/hooks/useReactiveEffect';
import { useRefetchQuery } from '@/hooks/useRefetchQuery';
import useTrack from '@/hooks/useTrack';
import useUser from '@/hooks/useUser';
import ArticleImageSection from '@/pages/Article/components/ArticleImageSection';
import ArticleMetaInfoSection from '@/pages/Article/components/ArticleMetaInfoSection';
import ArticleProxyInspectionSection from '@/pages/Article/components/ArticleProxyInspectionSection';
import ArticleWarrantySection from '@/pages/Article/components/ArticleWarrantySection';
import IncreaseArticleViewCount from '@/pages/Article/components/IncreaseArticleViewCount';
import NudgeWarrantyBottomSheet from '@/pages/Article/components/NudgeWarrantyBottomSheet';
import StackOnArticle from '@/pages/Article/components/StackOnArticle';
import { ActivityParams, useFlow } from '@/stackflow';
import { safeAreaInset } from '@/styles/mixins';
import { ArticleStatus } from '@/types/Article/ArticleStatus';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getPublishedAtText } from '@/utils/article/getPublishedAtText';
import { isInspectedCar } from '@/utils/article/isInspectedCar';
import { sessionStorage } from '@/utils/storage';
import { useCamelCaseParams } from '@/utils/url';

import ArticleContext from './ArticleContext';
import ArticleNoticeSection from './ArticleNoticeSection';
import ArticleAdvertisementSection from './components/ArticleAdvertisementSection';
import ArticleCarInfoSection from './components/ArticleCarInfoSection';
import ArticleExpenseSection from './components/ArticleExpenseSection';
import ArticleLeaseRentalSection from './components/ArticleLeaseRentalSection';
import ArticleLocationSection from './components/ArticleLocationSection';
import ArticleOptionsSection from './components/ArticleOptionsSection';
import ArticlePriceSection from './components/ArticlePriceSection';
import ArticleStatusChangeSection from './components/ArticleStatusChangeSection';
import ArticleStorySection from './components/ArticleStorySection';
import ArticleTitleSection from './components/ArticleTitleSection';
import ArticleCarHistorySection from './components/CarHistory/ArticleCarHistorySection';
import Footer from './components/Footer';
import IndicateSalesHistoryBottomSheet from './components/IndicateSalesHistoryBottomSheet';
import ArticleInspectionSection from './components/Inspection/ArticleInspectionSection';
import Navigation from './components/Navigation';
import NewArticleNotificationSection from './components/NewArticleNotificationSection';
import RecommendedArticles from './components/RecommendedArticles';
import useNotificationCreatable from './hooks/useNotificationCreatable';

const PageArticle: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { push } = useFlow();
  const { isRoot } = useActivity();
  const { user } = useUser();
  const { track } = useTrack();
  const { articleId, afterWriteArticle, afterWriteWarrantyArticle } =
    useCamelCaseParams<ActivityParams<'article'>>();

  const { refetch, queryOptions } = useRefetchQuery();
  const query = useLazyLoadQuery<ArticleQuery>(
    graphql`
      query ArticleQuery($id: ID!, $userId: ID!) {
        me {
          id
          ...ArticleExpenseSection_user
        }
        article(id: $id) {
          article {
            ...Article_article
          }
          ...ArticleError_articleResponse
          errors {
            type
          }
        }
        recommendedArticles(maxCount: 6, articleId: $id) {
          id
          deletedAt
          ...ArticleCard_article
        }
        ...ArticleNoticeSection_query @arguments(userId: $userId)
        ...useNotificationCreatable_query
        ...useCanProxyInspection_query @arguments(articleId: $id)
      }
    `,
    { id: articleId!, userId: String(user.id) },
    queryOptions
  );

  const { me, article: articleResponse, recommendedArticles } = query;
  const { article: articleRef, errors } = articleResponse;

  const article = useFragment<Article_article$key>(
    graphql`
      fragment Article_article on Article {
        id
        isHide
        chatRoomCount
        voteCount
        viewCount
        user {
          id
        }
        status
        price
        leaseInfo {
          contractStartedAt
        }
        rentalInfo {
          contractStartedAt
        }
        ...ArticleImageSection_article
        ...ArticleStatusChangeSection_article
        ...ArticleNoticeSection_article
        ...ArticleTitleSection_article
        ...ArticleExpenseSection_article
        ...ArticleLeaseRentalSection_article
        ...ArticleMetaInfoSection_article
        ...ArticleAdvertisementSection_article
        ...ArticleCarInfoSection_article
        ...NewArticleNotificationSection_article
        ...ArticleProxyInspectionSection_article
        ...ArticlePriceSection_article
        ...ArticleOptionsSection_article
        ...ArticleStorySection_article
        ...ArticleInspectionSection_article
        ...ArticleWarrantySection_article
        ...ArticleCarHistorySection_article
        ...ArticleLocationSection_article
        ...Navigation_article
        ...User_article
        ...Footer_article
        ...useNotificationCreatable_article
        ...useIsWarrantable_article
        ...getPublishedAtText_article
        ...getArticleEventProperties_article
        ...isInspectedCar_article
      }
    `,
    articleRef
  );

  const isNotificationCreatable = useNotificationCreatable({
    queryRef: query,
    articleRef: article,
  });
  const { isWarrantable } = useIsWarrantable(article);
  const canProxyInspection = useCanProxyInspection(query);
  const isBannerVisible = useMemo(() => {
    const referrer = sessionStorage.getReferrer();
    return (
      isRoot ||
      referrer === 'search_more' ||
      referrer === 'featured_article' ||
      referrer === 'featured_scroll'
    );
  }, [isRoot]);
  const filteredRecommendedArticles = useMemo(
    () => (recommendedArticles ?? []).filter((item) => !item.deletedAt),
    [recommendedArticles]
  );

  useCheckVisited();
  usePvEvent('article', {
    ...((article ? getArticleEventProperties(article) : {}) as unknown as ReturnType<
      typeof getArticleEventProperties
    >),
    ...((article ? getArticleEventProperties(article) : {}) as unknown as ReturnType<
      typeof getArticleEventProperties
    >),
  });

  const isMyArticle = useMemo(() => `${user.id}` === article?.user.id, [article, user]);

  useReactiveEffect(() => {
    startTransition(() => {
      refetch('network-only');
    });
  });

  if (errors || !article) {
    return (
      <PageContainer
        css={css`
          padding-bottom: 0;
        `}
      >
        <ArticleError articleResponseRef={articleResponse} />
        {filteredRecommendedArticles.length > 0 && (
          <>
            <Margined24Divider />
            <RecommendedArticles
              items={filteredRecommendedArticles}
              title="이런 매물은 어떠세요?"
            />
          </>
        )}
      </PageContainer>
    );
  }

  const { viewCount, voteCount, chatRoomCount, price, status, isHide, leaseInfo, rentalInfo } =
    article;

  const handleClickGoMain =
    (eventName: 'article_home_shortcut_banner' | 'article_recommend_more') => () => {
      push('main', {});
      track(`article_click_${eventName}`);
    };

  const isOnSale = status === ArticleStatus.OnSale;
  const isNotTraded = ([ArticleStatus.OnSale, ArticleStatus.Reserved] as ArticleStatus[]).includes(
    status as ArticleStatus
  );
  const isLeaseRentalArticle = leaseInfo?.contractStartedAt || rentalInfo?.contractStartedAt;
  const isChartSectionVisible = isMyArticle && isOnSale && !isHide;
  const isInspected = isInspectedCar(article);

  return (
    <ArticleContext.Provider value={{ isMyArticle, isChartSectionVisible, refetch }}>
      <Navigation articleRef={article} />
      <Suspense fallback={<SkeletonPageArticle />}>
        {!!afterWriteArticle && <IndicateSalesHistoryBottomSheet />}
        {!!afterWriteWarrantyArticle && <NudgeWarrantyBottomSheet />}
        <PageContainer>
          <IncreaseArticleViewCount />
          <StackOnArticle />
          {isBannerVisible && (
            <ShortcutWrapper>
              <MainShortcutBanner onClick={handleClickGoMain('article_home_shortcut_banner')} />
              <DividerNav />
            </ShortcutWrapper>
          )}
          <ArticleImageSection articleRef={article} />
          <SectionContainer>
            <User articleRef={article} />
            <Margined24Divider
              css={css`
                margin: ${rem(16)} 0;
              `}
            />
            <ArticleStatusChangeSection articleRef={article} />
            <ArticleNoticeSection articleRef={article} queryRef={query} />
            {isMyArticle && me && <ArticleAdvertisementSection articleRef={article} />}
            <ArticleTitleSection articleRef={article} />
            <Spacer axis="vertical" size={24} />
            {(price ?? 0) > 0 ? (
              <ArticleExpenseSection articleRef={article} userRef={me} />
            ) : isLeaseRentalArticle ? (
              <ArticleLeaseRentalSection articleRef={article} />
            ) : null}
            <Spacer axis="vertical" size={20} />
            <ArticleMetaInfoSection articleRef={article} />
            <Box mb={16} mt={14}>
              <Divider />
            </Box>
            <Caption>
              {[
                getPublishedAtText(article),
                `채팅 ${chatRoomCount}`,
                `관심 ${voteCount}`,
                `조회 ${viewCount}`,
              ].join(' · ')}
            </Caption>
            <Spacer axis="vertical" size={20} />
            <Box bg="paperContents" h={12} mx={-16} />
            <Spacer axis="vertical" size={20} />
            {(isInspected || isNotTraded) && (
              <>
                {isInspected ? (
                  <AsyncBoundary>
                    <VStack gap={16}>
                      <ArticleInspectionSection articleRef={article} />
                      {isWarrantable && <ArticleWarrantySection articleRef={article} />}
                    </VStack>
                  </AsyncBoundary>
                ) : (
                  isNotTraded && <ArticleCarHistorySection articleRef={article} />
                )}
                <Spacer axis="vertical" size={52} />
              </>
            )}
            {canProxyInspection && (
              <>
                <ArticleProxyInspectionSection articleRef={article} />
                <Spacer axis="vertical" size={52} />
              </>
            )}
            <ArticlePriceSection articleRef={article} />
            <ArticleOptionsSection articleRef={article} />
            <Spacer axis="vertical" size={52} />
            <ArticleStorySection articleRef={article} />
            <Spacer axis="vertical" size={52} />
            <ArticleCarInfoSection articleRef={article} />
            <NewArticleNotificationSection articleRef={article} visible={isNotificationCreatable} />
            <Spacer axis="vertical" size={52} />
            <ArticleLocationSection articleRef={article} />
          </SectionContainer>
          {filteredRecommendedArticles?.length > 0 && (
            <>
              <Margined24Divider height={8} />
              <RecommendedArticles
                items={filteredRecommendedArticles}
                onClickMore={handleClickGoMain('article_recommend_more')}
                showMoreButton={isRoot}
                title={`${CONFIG.SERVICE_NAME} 게시글 더보기`}
              />
            </>
          )}
          {me && <Footer articleRef={article} queryRef={query} />}
        </PageContainer>
      </Suspense>
    </ArticleContext.Provider>
  );
};

const PageContainer = styled.div`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${safeAreaInset({ paddingBottom: 80 })}
`;

const SectionContainer = styled.div`
  padding: ${rem(16)} ${rem(16)} 0;
`;

const ShortcutWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Caption = styled.div`
  margin-top: ${rem(16)};
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption2Regular}
`;

const Margined24Divider = styled(BaseDivider)`
  margin: ${rem(24)} 0;
`;

export default PageArticle;
