import React, { PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import { match } from 'ts-pattern';

import { ArticleSpec_article$key } from '@/__generated__/ArticleSpec_article.graphql';
import ArticleActionCount from '@/components/Article/ArticleActionCount';
import Box from '@/components/Base/Box';
import { ColoredBold } from '@/components/Base/ColoredText';
import HStack from '@/components/Base/HStack';
import Text, { Caption1Regular, TextVariant } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { PropsOf } from '@/types/React/PropsOf';
import { getCarSpec } from '@/utils/article/getCarSpec';
import { getPriceText } from '@/utils/article/getPriceText';
import { getPublishedAtText } from '@/utils/article/getPublishedAtText';

import ArticleBadges from './ArticleBadges';

type Props = {
  articleRef: ArticleSpec_article$key;
  badgeSize?: PropsOf<typeof ArticleBadges>['size'];
  priceTextVariant?: TextVariant;
  titleEllipsis?: number;
  titleVariant?: TextVariant;
  withActionCount?: boolean;
};

const ArticleSpec: React.FC<PropsWithChildren<Props>> = ({
  articleRef,
  priceTextVariant = 'label3Regular',
  titleVariant = 'subtitle1Regular',
  badgeSize = 'small',
  titleEllipsis = 2,
  withActionCount = false,
  ...props
}) => {
  const article = useFragment(
    graphql`
      fragment ArticleSpec_article on Article {
        region {
          id
          displayName
        }
        status
        ...getCarSpec_article
        ...getPriceText_article
        ...getPublishedAtText_article
        ...ArticleBadges_article
        ...ArticleActionCount_article
      }
    `,
    articleRef
  );
  const { region, status } = article;
  const { name, specWithoutPrice } = getCarSpec(article);
  const priceText = getPriceText(article);

  return (
    <VStack {...props} gap={8}>
      <VStack gap={4}>
        <Text ellipsis={titleEllipsis} variant={titleVariant}>
          {name}
        </Text>
        <Text variant={priceTextVariant} wordBreak="keep-all">
          {match(status)
            .with('RESERVED', () => <ColoredBold color={'success'}>예약중 </ColoredBold>)
            .with('TRADED', () => <ColoredBold color={'gray600'}>거래완료 </ColoredBold>)
            .otherwise(() => null)}
          <b>{priceText}</b> · {specWithoutPrice}
        </Text>
      </VStack>
      <ArticleBadges articleRef={article} fallback={<Box mt={-8} />} size={badgeSize} />
      <HStack align="center" justify="space-between">
        <Caption1Regular color="gray600">
          {region ? `${region.displayName ?? ''} · ` : ''}
          {getPublishedAtText(article)}
        </Caption1Regular>
        {withActionCount && <ArticleActionCount articleRef={article} />}
      </HStack>
    </VStack>
  );
};

export default ArticleSpec;
