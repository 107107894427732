import { compareDesc } from 'date-fns';
import { graphql, readInlineData } from 'relay-runtime';

import { getLastTransferInspection_article$key } from '@/__generated__/getLastTransferInspection_article.graphql';

export const getLastTransferInspection = (articleRef: getLastTransferInspection_article$key) => {
  const {
    carData: { niceDnrHistory },
  } = readInlineData(
    graphql`
      fragment getLastTransferInspection_article on Article @inline {
        carData {
          niceDnrHistory {
            date
            driveDistance
            type
          }
        }
      }
    `,
    articleRef
  );

  const [lastTransferInspection] = niceDnrHistory
    .filter((inspection) => inspection.type === 'TRANSFER')
    .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));

  return lastTransferInspection;
};
