import UrlPattern from 'url-pattern';

import { useEnterDoneEffectOnce } from '@/hooks/useEnterDoneEffectOnce';
import { type ActivityName, useFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

const PageNotFound = () => {
  const { replace } = useFlow();
  const activityParams = useCamelCaseParams();
  useEnterDoneEffectOnce(() => {
    const { pathname } = window.location;
    const replaceOption = { animate: false } as const;
    type Entry = [string, ActivityName, string[]];
    const entries: Entry[] = [
      [
        '/articles/:articleId/safe_trade/:safeTradeId/safe_trade_guide/',
        'safe_trade_guide',
        ['safeTradeId'],
      ],
      ['/proxy_inspection/:inspectionId/', 'inspection_reply', ['inspectionId', 'actionType']],
      [
        '/articles/:inspectionId/accomany_guide/',
        'article_proxy_inspection_guide',
        ['inspectionId', 'articleId'],
      ],
    ];

    for (const [path, activityName, passedParameters] of entries) {
      const urlParams = new UrlPattern(path).match(pathname);
      if (!urlParams) {
        continue;
      }
      const passedActivityParams = passedParameters.reduce(
        (result, next) => {
          const val = activityParams[next];
          if (val) {
            result[next] = val;
          }
          return result;
        },
        {} as Record<string, any>
      );
      replace(
        activityName,
        {
          ...urlParams,
          ...passedActivityParams,
        },
        replaceOption
      );
      return;
    }

    replace('main', {}, replaceOption);
  });

  return null;
};

export default PageNotFound;
