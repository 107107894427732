import { Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';

import Center from '@/components/Base/Center';

const LoadingCover = () => {
  return (
    <Container>
      <Spinner size="small" variant="primary" />
    </Container>
  );
};
export default LoadingCover;
const Container = styled(Center)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
});
