import type { TooltipRefProps } from 'react-tooltip';

import { Portal } from '@daangn/karrot-clothes';
import { useActivity } from '@stackflow/react';
import { type ReactElement, cloneElement, useEffect, useRef, useState } from 'react';

import { Caption1Bold } from '@/components/Base/Text';
import Tooltip from '@/components/Base/Tooltip';
import useTriggerOnce from '@/hooks/useTriggerOnce';

type Props = {
  children: ReactElement;
};

const DistanceFilterNudgingTooltip = ({ children }: Props) => {
  const tooltipKey = 'DistanceFilterNudgingTooltip';
  const [isDisplayedOnce, setIsDisplayedOnce] = useTriggerOnce(tooltipKey);
  const [isOpened, setIsOpened] = useState(!isDisplayedOnce);

  const { isActive } = useActivity();
  const ref = useRef<TooltipRefProps>(null);

  useEffect(() => {
    const listener = () => {
      setIsOpened(false);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  });

  useEffect(() => {
    setIsDisplayedOnce();
  }, [setIsDisplayedOnce]);

  return (
    <>
      {cloneElement(children, {
        'data-tooltip-id': tooltipKey,
      })}
      {isActive && (
        <Portal selector="#root">
          <Tooltip
            afterHide={() => setIsOpened(false)}
            id={tooltipKey}
            isOpen={isOpened}
            offset={20}
            place="bottom"
            ref={ref}
          >
            <Caption1Bold color="staticWhite" ta="left">
              전국 매물을 볼 수 있어요
            </Caption1Bold>
          </Tooltip>
        </Portal>
      )}
    </>
  );
};

export default DistanceFilterNudgingTooltip;
