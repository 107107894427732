/**
 * @generated SignedSource<<daa087b627b3ed40ac4b306c009af469>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useNotificationCreatable_query$data = {
  readonly notifications: ReadonlyArray<{
    readonly id: string;
    readonly subseries: ReadonlyArray<{
      readonly id: string;
    }> | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "useNotificationCreatable_query";
};
export type useNotificationCreatable_query$key = {
  readonly " $data"?: useNotificationCreatable_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationCreatable_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useNotificationCreatable_query",
  "selections": [
    {
      "alias": "notifications",
      "args": null,
      "concreteType": "NewArticleNotificationCondition",
      "kind": "LinkedField",
      "name": "newArticleNotificationConditions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupSubseries",
          "kind": "LinkedField",
          "name": "subseries",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a24226295c1f1df0b50d67c8eaafde09";

export default node;
