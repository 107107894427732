import { Slider } from '@daangn/sprout-components-slider';
import { css } from '@emotion/react';
import { IconLocationFill } from '@seed-design/icon';
import { rem } from 'polished';
import { useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import SouthKoreaPng from '@/assets/images/southKorea.png';
import { getSeedColor } from '@/components/Base/Box';
import Center from '@/components/Base/Center';
import HStack from '@/components/Base/HStack';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import { Label3Regular, Subtitle1Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import KarrotMap from '@/components/KarrotMap/KarrotMap';
import withDefaultProps from '@/hocs/withDefaultProps';
import useTrack from '@/hooks/useTrack';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import useUser from '@/hooks/useUser';
import { type Distance, allDistances } from '@/utils/map/allDistances';
import { getMinimumBoundaryPolygonByDistance } from '@/utils/map/getMinimumBoundaryPolygonByDistance';
import { getSizeProps } from '@/utils/style';

import FilterButtonFooter from './FilterButtonFooter';
import {
  defaultFilterState,
  useFilterContext,
  useFilterUpdaterContext,
} from './FilterContextProvider';
const SouthKoreaImage = withDefaultProps(NormalImage, {
  src: SouthKoreaPng,
  css: css({
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    transition: 'opacity 0.1s cubic-bezier(0, 0, 0.74, 1)',
  }),
});

const markers = allDistances.map((v, i) => ({
  value: i,
  matchedValue: v,
  label: match(v)
    .with(999, () => '전국')
    .otherwise((v) => `${v}km`),
  align: match(i)
    .with(0, () => 'start' as const)
    .with(allDistances.length - 1, () => 'end' as const)
    .otherwise(() => undefined),
}));

const DistanceFilter = () => {
  const { region } = useUser();
  const { trackWithActivityName } = useTrack();
  const {
    openedFilter,
    filter: { distance: prevDistance },
    isPending,
  } = useFilterContext();
  const { updateOpenedFilter, startTransition } = useFilterUpdaterContext();
  const updateFilter = useUpdateFilter();
  const [distance, setDistance] = useState<Distance | null>(prevDistance);
  const selectedDistance = distance ?? allDistances[0];
  const polygon = useMemo(
    () =>
      selectedDistance === 999
        ? undefined
        : getMinimumBoundaryPolygonByDistance(region.centerCoordinates, selectedDistance),
    [region.centerCoordinates, selectedDistance]
  );

  const shortCoordinates = useMemo(
    () => ({
      lat: region.centerCoordinates.latitude,
      lng: region.centerCoordinates.longitude,
    }),
    [region.centerCoordinates.latitude, region.centerCoordinates.longitude]
  );

  const handleClickSave = () => {
    updateOpenedFilter(null);
    if (distance !== prevDistance) {
      startTransition(() => {
        updateFilter({ distance });
      });
      trackWithActivityName('click_change_filter_range', { range: distance });
    }
  };

  const handleClickRefresh = () => {
    trackWithActivityName('click_reset_filter_range');
    setDistance(defaultFilterState.distance);
  };

  useEffect(() => {
    if (openedFilter === 'distance') {
      setDistance(prevDistance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFilter]);

  return (
    <>
      <VStack gap={16} pb={8} px={16}>
        <Subtitle1Regular color="gray600">중고차 직거래에만 적용되는 범위에요.</Subtitle1Regular>
        <KarrotMap
          coordinates={distance === 999 ? undefined : shortCoordinates}
          heightRatio="100%"
          polygon={polygon}
        >
          {distance !== 999 ? (
            <BottomCaptionOverlay>
              <Label3Regular color="staticWhite">
                <HStack align="center" gap={4}>
                  <IconLocationFill {...getSizeProps(14)} />
                  <span>{region.name3}</span>
                </HStack>
              </Label3Regular>
            </BottomCaptionOverlay>
          ) : (
            <SouthKoreaImage />
          )}
        </KarrotMap>
        <Slider
          markers={markers}
          maxValue={markers.length - 1}
          minValue={0}
          onChange={(v) => setDistance(markers[v].matchedValue)}
          value={markers.findIndex(
            (v) => v.matchedValue === (distance ?? defaultFilterState.distance)
          )}
        />
      </VStack>
      <FilterButtonFooter
        isLoading={isPending}
        onClickConfirm={handleClickSave}
        onClickRefresh={handleClickRefresh}
      />
    </>
  );
};

export default DistanceFilter;

const BottomCaptionOverlay = withDefaultProps(Center, {
  pos: 'absolute',
  css: css({
    bottom: rem(8),
    left: rem(8),
    backgroundColor: getSeedColor('overlayLow'),
  }),
  w: 'auto',
  h: 'initial',
  px: 6,
  py: 4,
  br: 6,
});
