/**
 * @generated SignedSource<<7a871c31ae5fb978fb49fe1de766185f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type CompanyOrigin = "FOREIGN" | "KOREAN";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Gear = "AUTO" | "CVT" | "MANUAL" | "SEMI_AUTO" | "UNKNOWN";
export type H3Resolution = "RESOLUTION_4" | "RESOLUTION_5" | "RESOLUTION_6";
export type SaleType = "GENERAL" | "LEASE" | "RENTAL";
export type WarrantyScope = "INSPECTED" | "NONE" | "WARRANTABLE";
export type CreateNewArticleNotificationConditionV2Input = {
  baseDistanceKm?: number | null | undefined;
  baseRegionId?: number | null | undefined;
  carTypes?: ReadonlyArray<CarType> | null | undefined;
  companyIds?: ReadonlyArray<string> | null | undefined;
  companyOrigin?: CompanyOrigin | null | undefined;
  driveDistanceMax?: number | null | undefined;
  driveDistanceMin?: number | null | undefined;
  fuelTypes?: ReadonlyArray<FuelType> | null | undefined;
  gears?: ReadonlyArray<Gear> | null | undefined;
  h3IndexList?: H3IndexListInput | null | undefined;
  modelYearMax?: number | null | undefined;
  modelYearMin?: number | null | undefined;
  priceMax?: number | null | undefined;
  priceMin?: number | null | undefined;
  saleTypes?: ReadonlyArray<SaleType> | null | undefined;
  seriesIds?: ReadonlyArray<string> | null | undefined;
  subseriesIds?: ReadonlyArray<string> | null | undefined;
  warrantyScope?: WarrantyScope | null | undefined;
};
export type H3IndexListInput = {
  h3Index: ReadonlyArray<string>;
  resolution: H3Resolution;
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation$variables = {
  input: CreateNewArticleNotificationConditionV2Input;
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation$data = {
  readonly newCondition: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_newArticleNotificationCondition" | "getArticleFeedV2FilterInput_newArticleNotificationCondition">;
  };
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation = {
  response: useCreateNotification_CreateNewArticleNotificationConditionMutation$data;
  variables: useCreateNotification_CreateNewArticleNotificationConditionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carTypes",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fuelTypes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleTypes",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gears",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelYearMin",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modelYearMax",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "driveDistanceMin",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "driveDistanceMax",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceMin",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceMax",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyOrigin",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "H3IndexList",
  "kind": "LinkedField",
  "name": "h3IndexList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "h3Index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolution",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "selections": [
      {
        "alias": "newCondition",
        "args": (v1/*: any*/),
        "concreteType": "NewArticleNotificationCondition",
        "kind": "LinkedField",
        "name": "createArticleNotificationConditionV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationItem_newArticleNotificationCondition"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "getArticleFeedV2FilterInput_newArticleNotificationCondition",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoupCompany",
                "kind": "LinkedField",
                "name": "companies",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoupSeries",
                "kind": "LinkedField",
                "name": "series",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoupSubseries",
                "kind": "LinkedField",
                "name": "subseries",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "selections": [
      {
        "alias": "newCondition",
        "args": (v1/*: any*/),
        "concreteType": "NewArticleNotificationCondition",
        "kind": "LinkedField",
        "name": "createArticleNotificationConditionV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseDistanceKm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "baseRegion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name3",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupCompany",
            "kind": "LinkedField",
            "name": "companies",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupSeries",
            "kind": "LinkedField",
            "name": "series",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupSubseries",
            "kind": "LinkedField",
            "name": "subseries",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bcf641f16e05836aaf577532b5a4c50",
    "id": null,
    "metadata": {},
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateNotification_CreateNewArticleNotificationConditionMutation(\n  $input: CreateNewArticleNotificationConditionV2Input!\n) {\n  newCondition: createArticleNotificationConditionV2(input: $input) {\n    id\n    ...NotificationItem_newArticleNotificationCondition\n    ...getArticleFeedV2FilterInput_newArticleNotificationCondition\n  }\n}\n\nfragment NotificationItem_newArticleNotificationCondition on NewArticleNotificationCondition {\n  id\n  baseDistanceKm\n  baseRegion {\n    name3\n    id\n  }\n  companies {\n    name\n    id\n  }\n  series {\n    name\n    id\n  }\n  subseries {\n    name\n    id\n  }\n  carTypes\n  fuelTypes\n  saleTypes\n  gears\n  modelYearMin\n  modelYearMax\n  driveDistanceMin\n  driveDistanceMax\n  priceMin\n  priceMax\n  companyOrigin\n  ...getNewArticleNotificationConditionInput_newArticleNotificationCondition\n}\n\nfragment getArticleFeedV2FilterInput_newArticleNotificationCondition on NewArticleNotificationCondition {\n  companies {\n    id\n  }\n  series {\n    id\n  }\n  subseries {\n    id\n  }\n  carTypes\n  fuelTypes\n  saleTypes\n  gears\n  modelYearMin\n  modelYearMax\n  driveDistanceMin\n  driveDistanceMax\n  priceMin\n  priceMax\n  companyOrigin\n  h3IndexList {\n    h3Index\n    resolution\n  }\n}\n\nfragment getNewArticleNotificationConditionInput_newArticleNotificationCondition on NewArticleNotificationCondition {\n  baseDistanceKm\n  baseRegion {\n    name3\n    id\n  }\n  companies {\n    id\n    name\n  }\n  series {\n    id\n    name\n  }\n  subseries {\n    id\n    name\n  }\n  carTypes\n  fuelTypes\n  saleTypes\n  gears\n  modelYearMin\n  modelYearMax\n  driveDistanceMin\n  driveDistanceMax\n  priceMin\n  priceMax\n  companyOrigin\n  h3IndexList {\n    h3Index\n    resolution\n  }\n}\n"
  }
};
})();

(node as any).hash = "b028ec866961ea21992acd9f57a42d4c";

export default node;
