import React, { PropsWithChildren } from 'react';

import HStack from '@/components/Base/HStack';

type Props = {
  hasBg?: boolean;
  size: 'large' | 'small';
};

const InspectionBadge: React.FC<PropsWithChildren<Props>> = ({
  size,
  children,
  hasBg = true,
  ...props
}) => {
  return (
    <HStack
      align="center"
      bg={hasBg ? 'blue50' : undefined}
      br={2}
      justify="center"
      px={size === 'small' ? 4 : 6}
      py={size === 'small' ? 2 : 4}
      {...props}
    >
      {children}
    </HStack>
  );
};

export default InspectionBadge;
