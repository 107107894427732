/**
 * @generated SignedSource<<0123005b9c09535af101cf796bd8768c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UndoHideArticleInput = {
  articleId: string;
};
export type useHideArticle_UndoHideArticleMutation$variables = {
  input: UndoHideArticleInput;
};
export type useHideArticle_UndoHideArticleMutation$data = {
  readonly result: {
    readonly isHide: boolean;
    readonly promotionEndsAt: any | null | undefined;
  };
};
export type useHideArticle_UndoHideArticleMutation = {
  response: useHideArticle_UndoHideArticleMutation$data;
  variables: useHideArticle_UndoHideArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHide",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promotionEndsAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHideArticle_UndoHideArticleMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "undoHideArticle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHideArticle_UndoHideArticleMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "undoHideArticle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bf6aa9a6de27f9c3e0495bd583abd8f",
    "id": null,
    "metadata": {},
    "name": "useHideArticle_UndoHideArticleMutation",
    "operationKind": "mutation",
    "text": "mutation useHideArticle_UndoHideArticleMutation(\n  $input: UndoHideArticleInput!\n) {\n  result: undoHideArticle(input: $input) {\n    isHide\n    promotionEndsAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e286007692d1dabebb1632dbe2a6abe5";

export default node;
