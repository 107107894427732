/**
 * @generated SignedSource<<97d65436c0f721ba205463ed4fae4a26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PostContractOption = "BUY" | "BUY_OR_RETURN" | "RETURN";
import { FragmentRefs } from "relay-runtime";
export type getArticleLeaseRentalPriceInfo_article$data = {
  readonly leaseInfo: {
    readonly acquisitionPrice: number;
    readonly contractEndedAt: any;
    readonly contractStartedAt: any;
    readonly deposit: number;
    readonly monthlyFee: number;
    readonly postContractOption: PostContractOption;
    readonly residualValue: number;
  } | null | undefined;
  readonly rentalInfo: {
    readonly acquisitionPrice: number;
    readonly contractEndedAt: any;
    readonly contractStartedAt: any;
    readonly deposit: number;
    readonly monthlyFee: number;
    readonly postContractOption: PostContractOption;
    readonly residualValue: number;
  } | null | undefined;
  readonly " $fragmentType": "getArticleLeaseRentalPriceInfo_article";
};
export type getArticleLeaseRentalPriceInfo_article$key = {
  readonly " $data"?: getArticleLeaseRentalPriceInfo_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getArticleLeaseRentalPriceInfo_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getArticleLeaseRentalPriceInfo_article"
};

(node as any).hash = "9b8eb7e56f95878235eebc3461208999";

export default node;
