import CONFIG, { IS_PROD } from '@/constants/config';
import { getUrlWithQuery } from '@/utils/url';

function createScheme(path: string, queries?: Record<string, string>) {
  const url = getUrlWithQuery(`${CONFIG.APP_SCHEME}://${path}`, queries);

  return url;
}

function getSurveyPath(id: number) {
  return `web/ad/user_surveys/${id}`;
}

const Scheme = {
  Home: createScheme('tab/home'),
  User: (id: string) => createScheme(`users/${id}`),
  MyWatches: createScheme('my_watches'),
  ApplyMaintenanceGarage: createScheme(getSurveyPath(IS_PROD ? 10797 : 117)),
  ApplyMaintenanceMechanic: createScheme(getSurveyPath(IS_PROD ? 10798 : 116)),
  Checkin: createScheme('checkin'),
};

function createBizProfileScheme(path: string, queries?: Record<string, string>) {
  const url = getUrlWithQuery(`${CONFIG.BIZ_PROFILE_HOST}/${path}`, queries);

  return {
    url,
    stage: process.env.REACT_APP_STAGE,
  };
}

export const BizProfileScheme = {
  // 우리동네 중고차 목록
  Broker: ({ queries }: { queries?: Record<string, string> }) =>
    createBizProfileScheme(`posts/theme/${IS_PROD ? 85 : 58}`, queries),
  // 비즈프로필 생성
  Create: ({ queries }: { queries?: Record<string, string> }) =>
    createBizProfileScheme('onboarding', queries),
  // 비즈프로필 소식 작성
  Write: ({ bizId, queries }: { bizId: string; queries?: Record<string, string> }) =>
    createBizProfileScheme(`biz_accounts/${bizId}/manager/posts/new`, queries),
  // 비즈프로필
  BizAccount: ({ bizId, queries }: { bizId: string; queries?: Record<string, string> }) =>
    createBizProfileScheme(`biz_accounts/${bizId}/viewer/home`, queries),
};

export const CsCenterScheme = {
  Home: createScheme('web/wv/feedbacks/new', { category_id: IS_PROD ? '562' : '232' }),
  CarIsOwnedBySomeoneElse: createScheme('web/wv/feedbacks/new', {
    category_id: IS_PROD ? '2060' : '233',
  }),
  OwnerNameMissMatched: createScheme('web/wv/feedbacks/new', {
    category_id: IS_PROD ? '2027' : '234',
  }),
  VerificationCodeNotSended: createScheme('web/wv/faqs/157', {}),
};

export const INSPECTOR_TRAINEE_APPLY_LINK = 'https://car-inspector.oopy.io/';

export default Scheme;
