/**
 * @generated SignedSource<<b7fc8cb6e1dcc982d9c50a5d9c8c3fda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type getTradeDurationFromArticle_article$data = {
  readonly events: ReadonlyArray<{
    readonly t: number;
    readonly type: ArticleEventType | null | undefined;
  }>;
  readonly " $fragmentType": "getTradeDurationFromArticle_article";
};
export type getTradeDurationFromArticle_article$key = {
  readonly " $data"?: getTradeDurationFromArticle_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getTradeDurationFromArticle_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getTradeDurationFromArticle_article"
};

(node as any).hash = "dd70e5942e04dfefc002398104a41cfb";

export default node;
