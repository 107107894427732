/**
 * @generated SignedSource<<b438346d1bcc5af6d5147a3dafeaa820>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationCountBadgeQuery$variables = Record<PropertyKey, never>;
export type NotificationCountBadgeQuery$data = {
  readonly notifications: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
};
export type NotificationCountBadgeQuery = {
  response: NotificationCountBadgeQuery$data;
  variables: NotificationCountBadgeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "notifications",
    "args": null,
    "concreteType": "NewArticleNotificationCondition",
    "kind": "LinkedField",
    "name": "newArticleNotificationConditions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationCountBadgeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationCountBadgeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b1aff7bb07498003c61c9ea493e5aaab",
    "id": null,
    "metadata": {},
    "name": "NotificationCountBadgeQuery",
    "operationKind": "query",
    "text": "query NotificationCountBadgeQuery {\n  notifications: newArticleNotificationConditions {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2296751c65117139d0cbcb0dd1cac028";

export default node;
