/**
 * @generated SignedSource<<a8352a009dfdc6c2ab6d6e7871337df5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleImageSection_article$data = {
  readonly id: string;
  readonly user: {
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"getMergedImages_article">;
  readonly " $fragmentType": "ArticleImageSection_article";
};
export type ArticleImageSection_article$key = {
  readonly " $data"?: ArticleImageSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleImageSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleImageSection_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getMergedImages_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "6645763002be261627e1a69a9b332187";

export default node;
