import { useEffect, useState } from 'react';

import { carStorage } from '@/utils/storage';

/**
 * 한번만 true로 트리거될 수 있어요.
 */

export const useCanViewOnce = (key: string, defaultValue = false) => {
  const storageKey = `${key}_toggled`;
  const [value, _setValue] = useState(
    carStorage.getItem(storageKey) === 'true' ? false : defaultValue
  );
  const setValue = (nextValue: boolean) => {
    if (nextValue) {
      if (carStorage.getItem(storageKey) === 'true') {
        return;
      }
      _setValue(nextValue);
    } else {
      _setValue(nextValue);
    }
  };
  useEffect(() => {
    if (value) {
      carStorage.setItem(storageKey, 'true');
    }
  }, [storageKey, value]);
  return [value, () => setValue(true), () => setValue(false)] as const;
};
