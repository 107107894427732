import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { PromotionExampleLayer_article$key } from '@/__generated__/PromotionExampleLayer_article.graphql';
import feed from '@/assets/images/daangn_home_@2x.png';
import useTrack from '@/hooks/useTrack';

import StaticArticleCard from './StaticArticleCard';

type Props = {
  articleRef: PromotionExampleLayer_article$key;
  onClickClose: () => void;
};

const PromotionExampleLayer: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  onClickClose,
}) => {
  const { trackWithActivityName } = useTrack();
  const article = useFragment(
    graphql`
      fragment PromotionExampleLayer_article on Article {
        id
        ...StaticArticleCard_article
      }
    `,
    articleRef
  );

  const handleClickConfirm = () => {
    trackWithActivityName('click_confirm_promotion_example_layer');
    onClickClose();
  };

  return (
    <Container>
      <Description>광고하기를 하면 24시간 동안 당근 홈에 게시글이 노출돼요.</Description>
      <ExampleWrapper>
        <ExampleImage src={feed} />
        <CenteredArticleCard articleRef={article} />
      </ExampleWrapper>
      <BoxButton onClick={handleClickConfirm} size="xlarge" variant="primary" width="100%">
        확인
      </BoxButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(16)} ${rem(24)};
`;

const Description = styled.p`
  color: ${vars.$scale.color.gray700};
`;

const ExampleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(8)} 0;
  margin: ${rem(24)} 0;
  border: 1px solid ${vars.$scale.color.grayAlpha50};
  background: ${vars.$scale.color.gray100};
  border-radius: ${rem(6)};
`;

const ExampleImage = styled.img`
  width: auto;
  height: ${rem(229)};
`;

const CenteredArticleCard = styled(StaticArticleCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default PromotionExampleLayer;
