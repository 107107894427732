/**
 * @generated SignedSource<<aeba32e4331ccdf0dbe443fb7758bacb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccidentType = "ABSOLUTE_NO_ACCIDENT" | "ACCIDENT" | "NO_ACCIDENT";
export type CarOptionName = "AEB" | "AROUND_VIEW" | "AUTO_AIR_CONDITIONER" | "AUTO_FOLDING_SIDE_MIRROR" | "AUTO_TRUNK" | "AUTO_WIPER" | "BLUETOOTH" | "BSD" | "CRUISE_CONTROL" | "DASHBOARD_CAMERA" | "EPB" | "HEADLAMP" | "HEATED_SEAT" | "HEATED_STEERING_WHEEL" | "HIPASS" | "HUD" | "LDWS" | "LEATHER_SEAT" | "NAVIGATION" | "NUM_OF_KEY_2" | "PANORAMA_SUNROOF" | "PARK_SENSOR_FRONT" | "PARK_SENSOR_REAR" | "POWER_SEAT" | "REAR_CAMERA" | "SMART_KEY" | "SMOKING_FREE" | "SUNROOF" | "TPMS" | "VENTILATED_SEAT";
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Manufacturer = "ACURA" | "ASTON_MARTIN" | "AUDI" | "BAIC" | "BENTLEY" | "BENZ" | "BMW" | "CADILLAC" | "CAMMSYS" | "CHEVROLET" | "CHEVROLET_GM_DAEWOO" | "CHRYSLER" | "CITROEN" | "CYAN" | "DAECHANG_MOTORS" | "DAEWOO_BUS" | "DFSK" | "DODGE" | "DS" | "ETC" | "FERRARI" | "FIAT" | "FORD" | "FOTON" | "FOX" | "GENESIS" | "GMC" | "HONDA" | "HUMMER" | "HYUNDAI" | "INFINITI" | "ISUZU" | "JAGUAR" | "JEEP" | "KIA" | "LAMBORGHINI" | "LAND_ROVER" | "LEXUS" | "LINCOLN" | "LOTUS" | "MAIV" | "MASERATI" | "MAYBACH" | "MAZDA" | "MC_LAREN" | "MINI" | "MITSUBISHI" | "NISSAN" | "OULLIM" | "PEUGEOT" | "PORSCHE" | "POWER_PLAZA" | "RENAULT_SAMSUNG" | "ROLLS" | "SAAB" | "SMART" | "SSANGYOUNG" | "SUBARU" | "SUZUKI" | "TESLA" | "TOYOTA" | "VOLKSWAGEN" | "VOLVO" | "ZHIDOU";
import { FragmentRefs } from "relay-runtime";
export type carData_getCarDataEventProperties$data = {
  readonly accidentType: AccidentType | null | undefined;
  readonly carName: string;
  readonly carType: CarType;
  readonly displacement: number | null | undefined;
  readonly fuelType: FuelType;
  readonly manufacturer: Manufacturer;
  readonly modelYear: number;
  readonly options: ReadonlyArray<CarOptionName> | null | undefined;
  readonly " $fragmentType": "carData_getCarDataEventProperties";
};
export type carData_getCarDataEventProperties$key = {
  readonly " $data"?: carData_getCarDataEventProperties$data;
  readonly " $fragmentSpreads": FragmentRefs<"carData_getCarDataEventProperties">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carData_getCarDataEventProperties"
};

(node as any).hash = "7c81458ddf8a4a1aa84a04ea9b7867cc";

export default node;
