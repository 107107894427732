import { rem } from 'polished';
import React from 'react';

const Manner3: React.FC<React.PropsWithChildren<unknown>> = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(24)}
      viewBox="0 0 24 24"
      width={rem(24)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        d="M9.33177 15.9175L9.33045 15.9164L9.32897 15.9152C9.08969 15.723 8.72975 15.7547 8.53166 15.9899C8.33069 16.2286 8.36328 16.5832 8.60446 16.7821L8.60659 16.7839L8.60907 16.7859L8.6151 16.7907C8.63677 16.8076 8.65874 16.8241 8.68119 16.8399C8.72211 16.8689 8.77857 16.9064 8.85117 16.9495C8.99646 17.0358 9.20596 17.144 9.48434 17.25C10.0424 17.4624 10.8696 17.6625 12 17.6625C13.1305 17.6625 13.9576 17.4624 14.5157 17.25C14.7941 17.144 15.0036 17.0358 15.1489 16.9495C15.2215 16.9064 15.2779 16.8689 15.3188 16.8399C15.3434 16.8226 15.3675 16.8047 15.391 16.7859L15.3934 16.7839L15.3951 16.7825C15.6362 16.5836 15.6693 16.2285 15.4683 15.9899C15.2536 15.7348 14.9132 15.7441 14.658 15.9245C14.6409 15.9366 14.6098 15.9576 14.5639 15.9848C14.4724 16.0392 14.3219 16.1184 14.1076 16.2C13.6804 16.3626 12.9917 16.5375 12 16.5375C11.0084 16.5375 10.3196 16.3626 9.89244 16.2C9.67816 16.1184 9.52764 16.0392 9.43608 15.9848C9.43482 15.9841 9.33146 15.9172 9.33177 15.9175Z"
        fill="#895F00"
      />
      <path
        d="M7.79995 13.3499C8.54553 13.3499 9.14995 12.4097 9.14995 11.2499C9.14995 10.0901 8.54553 9.1499 7.79995 9.1499C7.05437 9.1499 6.44995 10.0901 6.44995 11.2499C6.44995 12.4097 7.05437 13.3499 7.79995 13.3499Z"
        fill="#895F00"
      />
      <path
        d="M16.2001 13.3499C16.9457 13.3499 17.5501 12.4097 17.5501 11.2499C17.5501 10.0901 16.9457 9.1499 16.2001 9.1499C15.4545 9.1499 14.8501 10.0901 14.8501 11.2499C14.8501 12.4097 15.4545 13.3499 16.2001 13.3499Z"
        fill="#895F00"
      />
      <path
        clipRule="evenodd"
        d="M5.88511 6.61815L5.88346 6.6214C5.88453 6.61945 5.88746 6.61422 5.89174 6.6072C5.90035 6.59309 5.91526 6.57013 5.93683 6.54192C5.9804 6.48494 6.04828 6.41026 6.14285 6.34271C6.32036 6.21591 6.62069 6.0918 7.10894 6.21386C7.30986 6.26409 7.51346 6.14193 7.56369 5.94101C7.61392 5.74009 7.49176 5.53649 7.29084 5.48626C6.57909 5.30832 6.05441 5.4842 5.70692 5.73241C5.53899 5.85236 5.41937 5.98393 5.34107 6.08633C5.30171 6.1378 5.27208 6.18281 5.25139 6.21675L5.21448 6.28235C5.12186 6.4676 5.19694 6.69285 5.38218 6.78547C5.5662 6.87748 5.78972 6.80398 5.88346 6.6214"
        fill="#D49D3A"
        fillRule="evenodd"
        opacity="0.85"
      />
      <path
        clipRule="evenodd"
        d="M18.4677 6.78547C18.2837 6.87748 18.0602 6.80399 17.9664 6.6214C17.9663 6.62126 17.9665 6.62161 17.9664 6.6214C17.9654 6.61946 17.9624 6.61422 17.9581 6.6072C17.9495 6.59309 17.9346 6.57013 17.913 6.54192C17.8695 6.48494 17.8016 6.41026 17.707 6.34271C17.5295 6.21591 17.2292 6.0918 16.7409 6.21386C16.54 6.26409 16.3364 6.14193 16.2862 5.94101C16.236 5.74009 16.3581 5.53649 16.559 5.48626C17.2708 5.30832 17.7955 5.4842 18.143 5.73241C18.3109 5.85236 18.4305 5.98393 18.5088 6.08633C18.5482 6.1378 18.5778 6.18282 18.5985 6.21675C18.6089 6.23375 18.617 6.24807 18.6231 6.25916L18.6345 6.28061C18.7267 6.46361 18.6512 6.6937 18.4677 6.78547Z"
        fill="#D49D3A"
        fillRule="evenodd"
        opacity="0.85"
      />
    </svg>
  );
};

export default Manner3;
