import type { FullReport } from '@/utils/report/getFullReport';

import {
  type ReportItemStateSelection,
  ReportItemStateSelectionText,
} from '@/types/MechanicReport/ReportItemState';

export const getAllTireState = (tire: FullReport['tire']) => {
  const MAX_DRIVE_DISTANCE = 60000;
  const MAX_TIRE_TREAD_DEPTH = 8;
  const { tireTreadDepth, tireCondition } = tire;

  const getTreadDepthRatio = (depth: number) => {
    return Math.min(depth / MAX_TIRE_TREAD_DEPTH, 1);
  };

  const getTireState = ({
    treadDepth,
    selections,
  }: {
    selections: ReportItemStateSelection[];
    treadDepth: { driverSeat: number; passengerSeat: number };
  }) => {
    const [driverSeatTreadDepthRatio, passengerSeatTreadDepthRatio] = [
      treadDepth.driverSeat,
      treadDepth.passengerSeat,
    ].map(getTreadDepthRatio);
    const treadDepthRatio = Math.min(driverSeatTreadDepthRatio, passengerSeatTreadDepthRatio);
    const isTreadOk = treadDepthRatio >= 0.3;
    const treadLeftKm = isTreadOk ? ((MAX_DRIVE_DISTANCE * treadDepthRatio) / 1000) * 1000 : 0;

    const [tireIssueSelectionText] = (['TireOneSidedWear', 'Damaged', 'Hardened'] as const)
      .map((s) => (selections.includes(s) ? ReportItemStateSelectionText[s] : undefined))
      .filter(Boolean);
    const isReplacementRequired = !!tireIssueSelectionText || !isTreadOk;

    return {
      isReplacementRequired,
      treadLeftKm,
      treadDepthRatio: {
        min: Math.min(driverSeatTreadDepthRatio, passengerSeatTreadDepthRatio),
        driverSeat: driverSeatTreadDepthRatio,
        passengerSeat: passengerSeatTreadDepthRatio,
      },
      particulars: tireIssueSelectionText ?? '없음',
    };
  };

  const front = getTireState({
    treadDepth: {
      driverSeat: tireTreadDepth.frontDriverSeat,
      passengerSeat: tireTreadDepth.frontPassengerSeat,
    },
    selections: tireCondition.front.selections,
  });
  const rear = getTireState({
    treadDepth: {
      driverSeat: tireTreadDepth.rearDriverSeat,
      passengerSeat: tireTreadDepth.rearPassengerSeat,
    },
    selections: tireCondition.rear.selections,
  });

  return {
    isReplacementRequired: front.isReplacementRequired || rear.isReplacementRequired,
    treadLeftKm: Math.min(front.treadLeftKm, rear.treadLeftKm),
    front,
    rear,
  };
};
