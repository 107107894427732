import { BoxButton } from '@daangn/sprout-components-button';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import Box from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import Spacer from '@/components/Base/Spacer';
import Text from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import Car from '@/components/icon/Car';
import { useCanViewOnce } from '@/hooks/useCanViewOnce';

const IndicateSalesHistoryBottomSheet = () => {
  const [activate, , setActivateFalse] = useCanViewOnce('isViewIndicateSalesHistory', true);

  return (
    <BottomSheet active={activate} onClickClose={setActivateFalse}>
      <Box pb={8} pt={24} px={16}>
        <VStack align="center">
          <HStack align="center" gap={2}>
            <Car height={24} width={24} />
            <Text variant="title2Bold">판매내역은</Text>
          </HStack>
          <Text variant="title2Bold">나의 당근에서 찾을 수 있어요</Text>
        </VStack>
        <Spacer axis="vertical" size={26} />
        <NormalImage
          src={
            'https://assetstorage.krrt.io/1232219314629866968/27a5e96e-aa80-4eec-9ee3-86c1e13fef42/width=1372,height=1130.png'
          }
          width={'100%'}
        />
        <Spacer axis="vertical" size={22} />
        <BoxButton onClick={setActivateFalse} size="large" width={'100%'}>
          확인했어요
        </BoxButton>
      </Box>
    </BottomSheet>
  );
};

export default IndicateSalesHistoryBottomSheet;
