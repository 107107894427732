import type { MultiPolygon, Polygon } from 'geojson';

import { circle, featureCollection, intersect, point } from '@turf/turf';

import { southKorea } from '@/constants/southKorea';

import type { Coordinates } from './Coordinates';

export const getMinimumBoundaryPolygonByDistance = (
  coordinates: Coordinates,
  distanceKm: number
) => {
  const turfPoint = point([Number(coordinates.longitude), Number(coordinates.latitude)]);
  const result = circle(turfPoint, distanceKm, {
    units: 'kilometers',
    steps: 12,
  });
  return intersect(featureCollection<MultiPolygon | Polygon>([result, southKorea]))!;
};
