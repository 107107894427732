import axios from 'axios';

import CONFIG from '@/constants/config';
import { getInfo } from '@/sdk/bridge/info';
import { filterNil } from '@/utils/misc';

const API_HOST = CONFIG.SHORTCUT_HOST;
const fetcher = axios.create({ baseURL: API_HOST });
const VISITED_SERVICE = 'VISITABLE_SERVICE_CARS';

async function visited() {
  const { user, app } = await getInfo();

  try {
    await fetcher.post(
      `/public/shortcut/v1/users/me/services/${VISITED_SERVICE}/visited`,
      {
        visitedAt: new Date().toISOString(),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...filterNil({
            'X-AUTH-TOKEN': user.authToken,
            'X-User-Agent': app.userAgent,
          }),
        },
      }
    );
  } catch (e) {}
}

export default {
  visited,
};
