/**
 * @generated SignedSource<<ea42948b783b1bed5978400317028d3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VerticalCArticleCard_article$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleActionCount_article" | "ArticleSpec_article" | "ArticleThumbnailBadges_article" | "LikeButton_article" | "getArticleEventProperties_article" | "getMergedImages_article">;
  readonly " $fragmentType": "VerticalCArticleCard_article";
};
export type VerticalCArticleCard_article$key = {
  readonly " $data"?: VerticalCArticleCard_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerticalCArticleCard_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerticalCArticleCard_article",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleSpec_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getMergedImages_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleActionCount_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getArticleEventProperties_article",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voteCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chatRoomCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "driveDistance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarDataEventProperties",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displacement",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "modelYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fuelType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "options",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accidentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carName",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isWarrantable",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "isInspectedCar_article",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CarData",
              "kind": "LinkedField",
              "name": "carData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Inspection",
                  "kind": "LinkedField",
                  "name": "inspection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isMechanicReportVisible",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LikeButton_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleThumbnailBadges_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "96057fb2ef4096b0b0c3b62605632427";

export default node;
