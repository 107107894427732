import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticlePriceSection_article$key } from '@/__generated__/ArticlePriceSection_article.graphql';
import GlobalLoading from '@/components/Base/GlobalLoading';
import Spacer from '@/components/Base/Spacer';
import useTradedArticles from '@/hooks/useTradedArticles';

import ArticleTransactionHistorySection from './ArticleTransactionHistorySection';

type Props = {
  articleRef: ArticlePriceSection_article$key;
};

const LOAD_COUNT = 20;

const ArticlePriceSection: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment ArticlePriceSection_article on Article {
        id
        carData {
          autoupModel {
            subseries {
              id
            }
            grade {
              id
            }
            subgrade {
              id
            }
          }
        }
        resaleOriginArticle {
          id
        }
        ...ArticleTransactionHistorySection_article
      }
    `,
    articleRef
  );
  const { id, carData, resaleOriginArticle } = article;
  const { autoupModel } = carData;
  const { subseries, grade, subgrade } = autoupModel ?? {};

  const tradedArticles = useTradedArticles({
    subseriesId: subseries?.id ?? '',
    gradeId: grade?.id,
    subgradeId: subgrade?.id,
    count: LOAD_COUNT,
  }).filter(
    (tradedArticle) => ![resaleOriginArticle?.id, id].filter(Boolean).includes(tradedArticle.id)
  );
  const isTransactionHistoryAvailable = !!subseries?.id && tradedArticles.length > 0;

  if (!isTransactionHistoryAvailable) {
    return null;
  }

  return (
    <>
      <Suspense fallback={<GlobalLoading />}>
        <ArticleTransactionHistorySection articleRef={article} />
      </Suspense>
      <Spacer axis="vertical" size={52} />
    </>
  );
};

export default ArticlePriceSection;
