import { type Distance } from './allDistances';
import { getResolutionByDistance } from './getResolutionByDistance';
import type { Feature, MultiPolygon, Polygon, GeoJsonProperties } from 'geojson';
import { polygonToCells } from 'h3-js';

export const getPolygonCells = (
  polygon: Feature<MultiPolygon | Polygon, GeoJsonProperties>,
  step: Distance
) => {
  const resolution = getResolutionByDistance(step);
  if (polygon.geometry.type === 'MultiPolygon') {
    return polygon.geometry.coordinates.flatMap((v) => polygonToCells(v, resolution, true));
  } else {
    return polygonToCells(polygon.geometry.coordinates, resolution, true);
  }
};
