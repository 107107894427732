import { useStorageState } from '@/utils/storage';

const useTriggerOnce = (key: string) => {
  const storageKey = `${key}_triggered`;
  const [isTriggered, setState] = useStorageState(storageKey, {
    defaultValue: false,
  });
  const setTriggered = () => setState(true);

  return [isTriggered, setTriggered] as const;
};

export default useTriggerOnce;
