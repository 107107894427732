import { BoxButton } from '@daangn/sprout-components-button';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import { Subtitle1Regular, Title2Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useOverlayState } from '@/hooks/useOverlayState';

const useWarrantyBottomSheet = () => {
  const [isOpened, open, close] = useOverlayState('warranty');
  const node = (
    <BottomSheet active={isOpened} onClickClose={close}>
      <VStack align="center" w="100%">
        <NormalImage src={'/images/warranty_guide_car_motion.png'} width={300} />
        <VStack gap={6} pa={16}>
          <Title2Bold ta="center">
            고장 수리비 지원받는
            <br />
            진단보증 패키지 차량이에요
          </Title2Bold>
          <Subtitle1Regular color="gray700" ta="center">
            구매 후 1개월 이내 고장 시<br />
            최대 500만원까지 수리비를 지원받아요.
          </Subtitle1Regular>
        </VStack>
        <VStack pb={16} pt={20} px={16} w="100%">
          <BoxButton onClick={close} size="xlarge" variant="primary" width="100%">
            확인
          </BoxButton>
        </VStack>
      </VStack>
    </BottomSheet>
  );

  return [open, node, isOpened] as const;
};

export default useWarrantyBottomSheet;
