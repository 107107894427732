import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/pagination';

import RatioImage from '@/components/Base/OptimizedImage/RatioImage';
import { Image } from '@/types/Image/Image';

type Props = {
  images: Image[];
  onDetailClick: (index: number) => void;
  onSlideChange?: SwiperProps['onSlideChange'];
};

const ImageSlider: React.FC<React.PropsWithChildren<Props>> = ({
  images,
  onDetailClick,
  onSlideChange,
}) => {
  return (
    <Container>
      <Swiper onSlideChange={onSlideChange} pagination={{ clickable: true }} speed={500}>
        {images.map((image, i) => {
          return (
            <SwiperSlide key={image.id} onClick={() => onDetailClick(i)}>
              <RatioImage lazy={false} ratio="1/1" src={image.url} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default ImageSlider;

const Container = styled.div`
  & .swiper-pagination-bullets {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${rem(38)};
  }

  & .swiper-pagination-bullet {
    width: ${rem(6)};
    height: ${rem(6)};
    background: ${vars.$static.color.staticWhite};
    opacity: 0.3;
  }

  & .swiper-pagination-bullet-active {
    opacity: 1;
  }
`;
