/**
 * @generated SignedSource<<da92982e4129141bb4d5f26605869429>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCanProxyInspection_query$data = {
  readonly isProxyInspectableArticle: {
    readonly result: boolean;
  };
  readonly " $fragmentType": "useCanProxyInspection_query";
};
export type useCanProxyInspection_query$key = {
  readonly " $data"?: useCanProxyInspection_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCanProxyInspection_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articleId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCanProxyInspection_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "articleId",
          "variableName": "articleId"
        }
      ],
      "concreteType": "IsInspectableCarResponse",
      "kind": "LinkedField",
      "name": "isProxyInspectableArticle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "result",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "09589a6fac402d719406a44298fd56f2";

export default node;
