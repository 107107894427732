import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChattingThin, IconHeartRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { StaticArticleCard_article$key } from '@/__generated__/StaticArticleCard_article.graphql';
import defaultThumbnail from '@/assets/images/empty_img_car.png';
import { ellipsis } from '@/styles/mixins';
import { getMergedImages } from '@/utils/article/getMergedImages';
import { getCarFullName } from '@/utils/carData';
import { getImageUrl } from '@/utils/image';
import { getBillionFormatFromPrice, getShortenYear } from '@/utils/number';
import { getDriveDistanceText } from '@/utils/string';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: StaticArticleCard_article$key;
};

const StaticArticleCard: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, ...props }) => {
  const article = useFragment(
    graphql`
      fragment StaticArticleCard_article on Article {
        driveDistance
        voteCount
        chatRoomCount
        price
        carData {
          modelYear
          ...carData_getCarFullName_carData
        }
        ...getMergedImages_article
      }
    `,
    articleRef
  );
  const { carData, driveDistance } = article;
  const { modelYear } = carData;
  const mergedImages = getMergedImages(article);

  return (
    <Container {...props}>
      <Image
        src={
          mergedImages?.[0]
            ? getImageUrl(mergedImages[0].url, { width: 300, height: 300 })
            : defaultThumbnail
        }
      />
      <Wrapper>
        <Title>{getCarFullName(article.carData)}</Title>
        <Title>
          {getShortenYear(modelYear)}년식, {getDriveDistanceText(driveDistance)}km
        </Title>
        <Price>{getBillionFormatFromPrice(article.price ?? 0)}원</Price>
        <CountWrapper>
          {article.chatRoomCount > 0 && (
            <>
              <IconChattingThin {...iconSizeProps} css={iconStyle} />
              <Count
                css={css`
                  margin-right: ${article.voteCount > 0 ? rem(3.33) : 0};
                `}
              >
                {article.chatRoomCount}
              </Count>
            </>
          )}
          {article.voteCount > 0 && (
            <>
              <IconHeartRegular {...iconSizeProps} css={iconStyle} />
              <Count>{article.voteCount}</Count>
            </>
          )}
        </CountWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: ${rem(169)};
  height: ${rem(78)};
  padding: ${rem(8)};
  background-color: ${vars.$static.color.staticWhite};
  box-shadow: 0px 2.16755px 13.0053px rgba(0, 0, 0, 0.1);
  border-radius: ${rem(4.3351)};
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  border: ${rem(0.572887)} solid rgba(0, 0, 0, 0.05);
  border-radius: ${rem(3.43732)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${rem(6)};
`;

const Title = styled.p`
  ${ellipsis()}
  color: ${vars.$static.color.staticGray900};
  font-size: ${rem(9)};
  line-height: ${rem(13)};
`;

const Price = styled.p`
  color: ${vars.$static.color.staticGray900};
  font-weight: bold;
  font-size: ${rem(8)};
  line-height: ${rem(11)};
  margin-top: ${rem(2.22)};
`;

const CountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  align-items: center;
`;

const Count = styled.p`
  font-size: ${rem(8)};
  line-height: 150%;
  color: ${vars.$static.color.staticBlackAlpha500};
`;

const iconStyle = css`
  color: ${vars.$static.color.staticBlackAlpha500};
  margin-right: ${rem(1.11)};
`;

const iconSizeProps = getSizeProps(10);

export default StaticArticleCard;
