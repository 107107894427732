import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';

import CarrotRight from '@/components/icon/CarrotRight';

const DEFAULT_Z_INDEX = 12;
const DEFAULT_MAX_WIDTH = 180;

type Props = {
  maxWidth?: number;
  tipStyle?: SerializedStyles;
  visible: boolean;
};

const ManuallyPositionedTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  tipStyle,
  maxWidth = DEFAULT_MAX_WIDTH,
  children,
  ...props
}) => {
  return (
    <Container visible={visible}>
      <ContentWrapper maxWidth={maxWidth} {...props}>
        {children}
      </ContentWrapper>
      <CarrotRight
        css={css`
          ${tipStyle}
          transform: rotate(0.75turn);
          position: absolute;
        `}
        fill={vars.$scale.color.gray900}
      />
    </Container>
  );
};

const Container = styled.div<{ visible: boolean }>`
  position: relative;
  transition: all 0.6s ease;
  z-index: ${DEFAULT_Z_INDEX};
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const ContentWrapper = styled.div<{ maxWidth: number }>`
  position: absolute;
  padding: ${rem(8)} ${rem(12)};
  border-radius: ${rem(6)};
  max-width: ${({ maxWidth }) => rem(maxWidth ?? DEFAULT_MAX_WIDTH)};
  background: ${vars.$scale.color.gray900};
  color: ${vars.$scale.color.gray00};
  ${vars.$semantic.typography.caption1Bold}
`;

export default ManuallyPositionedTooltip;
