/**
 * @generated SignedSource<<0f5b40e0f48326a971820e388fd908bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleThumbnailBadges_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"isInspectedCar_article" | "useIsWarrantable_article">;
  readonly " $fragmentType": "ArticleThumbnailBadges_article";
};
export type ArticleThumbnailBadges_article$key = {
  readonly " $data"?: ArticleThumbnailBadges_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleThumbnailBadges_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleThumbnailBadges_article",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isInspectedCar_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Inspection",
              "kind": "LinkedField",
              "name": "inspection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMechanicReportVisible",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "7e8496ff9f1c62e65f28d8be795c7fd8";

export default node;
