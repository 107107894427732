import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const TwoKeys: React.FC<React.PropsWithChildren<Props>> = ({
  width = 16,
  height = 16,
  ...props
}) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 16 16"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.34883 4.17001L0.678826 10.81C0.508826 12.49 1.82883 13.94 3.50883 13.94H7.52882C9.21882 13.94 10.5288 12.49 10.3588 10.81L9.68882 4.18001C9.57882 3.09001 8.65883 2.26001 7.56883 2.26001H3.48883C2.38883 2.26001 1.47883 3.09001 1.36883 4.18001L1.34883 4.17001Z"
        fill="#00B493"
      />
      <path
        d="M7.84906 9.86011H3.18906C2.96906 9.86011 2.78906 10.0401 2.78906 10.2601V11.5001C2.78906 11.7201 2.96906 11.9001 3.18906 11.9001H7.84906C8.06906 11.9001 8.24906 11.7201 8.24906 11.5001V10.2601C8.24906 10.0401 8.06906 9.86011 7.84906 9.86011Z"
        fill="#029D82"
      />
      <path
        d="M3.71922 5.75997C4.27922 5.75997 4.73922 5.29997 4.73922 4.73997C4.73922 4.17997 4.27922 3.71997 3.71922 3.71997C3.15922 3.71997 2.69922 4.17997 2.69922 4.73997C2.69922 5.29997 3.15922 5.75997 3.71922 5.75997Z"
        fill="#029D82"
      />
      <path d="M12.7972 13.46V8.98999H11.3672V13.46L12.0872 14.06L12.7972 13.46Z" fill="#F7BB4E" />
      <path d="M13.2989 10.4V4.26001H10.8789V10.4L12.0889 11.84L13.2989 10.4Z" fill="#F7BB4E" />
      <path
        d="M12.0469 11.6601L12.0869 11.7001L12.1169 11.6701L12.0869 11.6201L12.0469 11.6601Z"
        fill="#F7BB4E"
      />
      <path
        d="M13.9694 2.26001H10.2094C9.45937 2.26001 8.85938 2.87001 8.85938 3.61001V6.63001C8.85938 7.38001 9.46937 7.98001 10.2094 7.98001H13.9694C14.7194 7.98001 15.3194 7.37001 15.3194 6.63001V3.61001C15.3194 2.86001 14.7094 2.26001 13.9694 2.26001Z"
        fill="#F7BB4E"
      />
      <path
        d="M13.027 3.31006H11.147C10.904 3.31006 10.707 3.50705 10.707 3.75006C10.707 3.99306 10.904 4.19006 11.147 4.19006H13.027C13.27 4.19006 13.467 3.99306 13.467 3.75006C13.467 3.50705 13.27 3.31006 13.027 3.31006Z"
        fill="white"
      />
    </svg>
  );
};

export default TwoKeys;
