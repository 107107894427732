/**
 * @generated SignedSource<<cee0e5a72d324e67f33ee537186df23c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockedReason = "CHAT_ABUSE" | "DEALER" | "DUPLICATED_ARTICLE" | "ILLEGAL_BUSINESS" | "PERSONAL_INFO" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ArticleNoticeSection_query$data = {
  readonly blockedReason: BlockedReason | null | undefined;
  readonly " $fragmentType": "ArticleNoticeSection_query";
};
export type ArticleNoticeSection_query$key = {
  readonly " $data"?: ArticleNoticeSection_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleNoticeSection_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleNoticeSection_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "kind": "ScalarField",
      "name": "blockedReason",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c1ebec569a0031b85b78626f3bd57486";

export default node;
