import { graphql, useMutation } from 'react-relay';

import { useHideArticle_HideArticleMutation } from '@/__generated__/useHideArticle_HideArticleMutation.graphql';
import { useHideArticle_UndoHideArticleMutation } from '@/__generated__/useHideArticle_UndoHideArticleMutation.graphql';
import { useToast } from '@/hooks/useToast';

const useHideArticle = () => {
  const { setToast } = useToast();

  const [hide, isHideInFlight] = useMutation<useHideArticle_HideArticleMutation>(graphql`
    mutation useHideArticle_HideArticleMutation($input: HideArticleInput!) {
      result: hideArticle(input: $input) {
        isHide
        promotionEndsAt
      }
    }
  `);

  const [undoHide, isUndoHideInFlight] = useMutation<useHideArticle_UndoHideArticleMutation>(
    graphql`
      mutation useHideArticle_UndoHideArticleMutation($input: UndoHideArticleInput!) {
        result: undoHideArticle(input: $input) {
          isHide
          promotionEndsAt
        }
      }
    `
  );

  const getMutation = (operation: typeof hide | typeof undoHide) => (id: string) =>
    new Promise<boolean>((resolve, reject) => {
      operation({
        variables: { input: { articleId: id } },
        onCompleted(response, errors) {
          if (errors) {
            return reject(errors);
          }
          const {
            result: { isHide: nextHideState },
          } = response;
          const message = nextHideState ? '게시글을 숨겼어요.' : '게시글이 다시 이웃에게 보여요.';

          setToast(message);
          return resolve(nextHideState);
        },
        onError(error) {
          return reject(error);
        },
      });
    });

  const hideArticle = getMutation(hide);
  const undoHideArticle = getMutation(undoHide);

  return { hideArticle, isHideInFlight, undoHideArticle, isUndoHideInFlight };
};

export default useHideArticle;
