import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';

const ChatBubble = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)} ${rem(12)};
  background-color: ${vars.$scale.color.gray100};
  border-radius: 0 ${rem(20)} ${rem(20)} ${rem(20)};
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.bodyL2Regular}
`;

export default ChatBubble;
