import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React, { MouseEventHandler } from 'react';

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
  visible: boolean;
  zIndex?: number;
}

const Dim: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  zIndex = 11,
  onClick,
  children,
  ...props
}) => {
  return (
    <DimContainer onClick={(e) => onClick?.(e)} visible={visible} zIndex={zIndex} {...props}>
      {children}
    </DimContainer>
  );
};

export default Dim;

const easeInOpacity = keyframes`
  0% {background-color: rgba(0, 0, 0, 0);}
  100% {background-color: ${vars.$semantic.color.overlayDim};}
`;
const easeOutOpacity = keyframes`
  0% {background-color: ${vars.$semantic.color.overlayDim};}
  100% {background-color: rgba(0, 0, 0, 0);}
`;

const DimContainer = styled.div<{ visible: boolean; zIndex: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({ visible, zIndex }) => (visible ? zIndex : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation-name: ${({ visible }) => (visible ? easeInOpacity : easeOutOpacity)};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
`;
