import styled from '@emotion/styled';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import type { useCanProxyInspection_query$key } from '@/__generated__/useCanProxyInspection_query.graphql';

import { Footer_article$key } from '@/__generated__/Footer_article.graphql';
import BottomFixedLayer from '@/components/Base/BottomFixedLayer';
import HStack from '@/components/Base/HStack';
import Spacer from '@/components/Base/Spacer';
import LikeButton from '@/components/LikeButton';
import useCanProxyInspection from '@/hooks/useCanProxyInspection';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import { useArticleContext } from '@/pages/Article/ArticleContext';
import ArticleProxyInspectionButton from '@/pages/Article/components/ArticleProxyInspectionButton';
import WarrantyButton from '@/pages/Article/components/WarrantyButton';

import ChatButton from './ChatButton';

interface Props {
  articleRef: Footer_article$key;
  queryRef: useCanProxyInspection_query$key;
}

const Footer: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, queryRef }) => {
  const { isMyArticle } = useArticleContext();
  const article = useFragment(
    graphql`
      fragment Footer_article on Article {
        carData {
          inspection {
            id
          }
        }
        ...LikeButton_article
        ...ArticleProxyInspectionButton_article
        ...WarrantyButton_article
        ...ChatButton_article
        ...useIsWarrantable_article
      }
    `,
    articleRef
  );
  const canProxyInspection = useCanProxyInspection(queryRef);
  const { isWarrantable } = useIsWarrantable(article);

  return (
    <FooterLayer>
      <LikeButton articleRef={article} color="gray600" from="article" showToast />
      <Spacer axis="horizontal" size={12} />
      <HStack align="center" gap={8} w="100%">
        {canProxyInspection && <ArticleProxyInspectionButton articleRef={article} />}
        {!isMyArticle && isWarrantable && <WarrantyButton articleRef={article} />}
        <ChatButton articleRef={article} />
      </HStack>
    </FooterLayer>
  );
};

export default Footer;

const FooterLayer = styled(BottomFixedLayer)`
  display: flex;
`;
