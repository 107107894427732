import styled from '@emotion/styled';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { MinimalArticleCard_article$key } from '@/__generated__/MinimalArticleCard_article.graphql';
import defaultThumbnail from '@/assets/images/empty_img_car.png';
import HStack from '@/components/Base/HStack';
import RatioImage from '@/components/Base/OptimizedImage/RatioImage';
import { thumbnailBorderCss } from '@/components/Base/OptimizedImage/utils';
import { Caption1Bold, Caption1Regular, Label2Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useFlow } from '@/stackflow';
import { ellipsis, exactSize, pressed } from '@/styles/mixins';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getCarSpec } from '@/utils/article/getCarSpec';
import { getMergedImages } from '@/utils/article/getMergedImages';

type Props = {
  articleRef: MinimalArticleCard_article$key;
  onClick?: (data: ArticleEventProperties) => void;
};

const IMAGE_WIDTH = 48;

const MinimalArticleCard: React.FC<Props> = ({ articleRef, onClick, ...props }) => {
  const article = useFragment(
    graphql`
      fragment MinimalArticleCard_article on Article {
        id
        ...getCarSpec_article
        ...getMergedImages_article
        ...getArticleEventProperties_article
      }
    `,
    articleRef
  );
  const { id } = article;
  const { name, priceText, modelYearText, driveDistanceText } = getCarSpec(article);
  const mergedImages = getMergedImages(article);

  const { push } = useFlow();
  const handleClick = () => {
    push('article', { articleId: id });
    onClick?.(getArticleEventProperties(article));
  };

  return (
    <HStack
      align="center"
      css={[pressed()]}
      gap={12}
      onClick={handleClick}
      px={16}
      py={14}
      {...props}
    >
      <Image
        alt="매물 이미지"
        css={thumbnailBorderCss}
        fallback={defaultThumbnail}
        ratio="1/1"
        sizes={`${(IMAGE_WIDTH * 1.2).toString()}px`}
        src={mergedImages?.[0]?.url}
      />
      <VStack gap={2}>
        <Label2Regular css={ellipsis()}>{name}</Label2Regular>
        <HStack align="center" gap={4}>
          <Caption1Bold>{priceText}</Caption1Bold>
          {'⸱'}
          <Caption1Regular color="gray700">{modelYearText}</Caption1Regular>
          {'⸱'}
          <Caption1Regular color="gray700">{driveDistanceText}</Caption1Regular>
        </HStack>
      </VStack>
    </HStack>
  );
};

const Image = styled(RatioImage)`
  ${exactSize('both', IMAGE_WIDTH)}
`;

export default MinimalArticleCard;
