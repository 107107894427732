import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge';

import { UPDATE_ARTICLE } from '@/constants/event';

const { driver } = installKarrotBridgeDriver();
export const karrotBridge = makeKarrotBridge({ driver });
export const environment = karrotBridge.driver.getCurrentEnvironment();

export const toast = (message: string) => {
  return karrotBridge.openToast({ toast: { body: message, type: 'TOAST' } });
};

export const emitEvent = (eventName: string, data?: Record<string, unknown>) => {
  const payload = data ? JSON.stringify(data) : undefined;
  return karrotBridge.emitToStream({ stream: { eventName, data: payload } });
};

export const emitArticleUpdateEvent = () => {
  return emitEvent(UPDATE_ARTICLE);
};
