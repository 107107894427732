import { IconClockFill, IconClockRegular } from '@seed-design/icon';
import { formatDuration } from 'date-fns';
import { ko } from 'date-fns/locale';
import React from 'react';

import useCountdown from '@/hooks/useCountdown';
import { getSizeProps } from '@/utils/style';

import { SeedColor, getSeedColor } from './Box';
import HStack from './HStack';
import Text from './Text';

type Props = {
  bold?: boolean;
  color?: SeedColor;
  format?: string[];
  initialEndsAt: number;
  suffix?: string;
};

const CountdownTimer: React.FC<React.PropsWithChildren<Props>> = ({
  initialEndsAt,
  format = ['days', 'hours', 'minutes'],
  bold = false,
  suffix = '',
  color = 'gray900',
  ...props
}) => {
  const { duration } = useCountdown({ initialEndsAt });
  const durationText = formatDuration(duration, {
    format,
    locale: ko,
  });
  const Clock = bold ? IconClockFill : IconClockRegular;
  return (
    <HStack align="center" gap={2} {...props}>
      <Clock {...getSizeProps(16)} color={getSeedColor(color)} />
      <Text color={color} variant={bold ? 'label3Bold' : 'label3Regular'}>
        {durationText}
        {suffix}
      </Text>
    </HStack>
  );
};

export default CountdownTimer;
