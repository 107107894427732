import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { HotArticlesSection_query$key } from '@/__generated__/HotArticlesSection_query.graphql';
import ArticleCarousel from '@/components/Article/ArticleCarousel';
import TitledSection from '@/components/TitledSection';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';

type Props = {
  queryRef: HotArticlesSection_query$key;
};

const HotArticlesSection: React.FC<React.PropsWithChildren<Props>> = ({ queryRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const { hotArticles } = useFragment(
    graphql`
      fragment HotArticlesSection_query on Query
      @argumentDefinitions(count: { type: "Int", defaultValue: 20 }) {
        hotArticles(n: $count) {
          id
          ...ArticleCard_article
        }
      }
    `,
    queryRef
  );

  const handleClick = () => {
    push('hot_articles', {});
    track('main_click_more_from_carousel', {
      carouselType: 'HOT',
    });
  };

  if (!hotArticles.length) {
    return null;
  }

  return (
    <TitledSection onClickMore={handleClick} title="지금 인기있는 매물">
      <ArticleCarousel
        articles={hotArticles}
        onClick={(data) => {
          track('main_click_article_from_carousel', {
            ...data,
            carouselType: 'HOT',
          });
        }}
      />
    </TitledSection>
  );
};

export default HotArticlesSection;
