import { TestUserInfo } from '@/store/dev';

export function getActiveTestUser() {
  const prefix = 'CAR_COMMON';
  const testUserList = JSON.parse(
    localStorage.getItem(`${prefix}#testUserInfo`) ?? '[]'
  ) as TestUserInfo[];
  const activeTestUser = testUserList.find((user) => user.selected);

  return activeTestUser;
}
