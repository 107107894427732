import { RangeSlider } from '@daangn/sprout-components-slider';
import { memo } from 'react';

import { DriveDistanceMap, InvertedDriveDistanceMap } from './constants';
import Section from './Section';
import { getLabeledPoints } from './utils';

type Props = {
  driveDistanceMax: null | number;
  driveDistanceMin: null | number;
  driveDistanceRangeMax: number;
  driveDistanceRangeMin: number;
  handleChangeDriveDistance: (value: [number, number]) => void;
};

const driveDistanceMarkers = Object.entries(getLabeledPoints(DriveDistanceMap)).map(
  ([k, v], index, list) => {
    let label = '';

    if (v || Number(k) === 0) {
      const correctedValue = v ?? 0;
      label = `${
        correctedValue >= 10000 ? `${Math.floor(correctedValue / 10000)}만` : correctedValue
      }km`;
    } else {
      label = '전체';
    }

    return {
      value: Number(k),
      label,
      align:
        index === 0 ? ('start' as const) : index === list.length - 1 ? ('end' as const) : undefined,
    };
  }
);

const DriveDistanceSection: React.FC<Props> = memo(
  ({
    driveDistanceMin,
    driveDistanceMax,
    driveDistanceRangeMin,
    driveDistanceRangeMax,
    handleChangeDriveDistance,
  }) => {
    return (
      <Section.Container>
        <Section.Header>
          <Section.Title>주행거리</Section.Title>
          <Section.Description>
            {driveDistanceMin === null && driveDistanceMax === null ? (
              '전체'
            ) : (
              <>
                {driveDistanceMin !== null ? `${Math.floor(driveDistanceMin / 10000)}만` : 0}
                km
                {' - '}
                {driveDistanceMax !== null ? `${Math.floor(driveDistanceMax / 10000)}만km` : '전체'}
              </>
            )}
          </Section.Description>
        </Section.Header>
        <Section.SliderWrapper>
          <RangeSlider
            markers={driveDistanceMarkers}
            maxValue={driveDistanceRangeMax}
            minStepsBetweenThumbs={1}
            minValue={driveDistanceRangeMin}
            onChange={handleChangeDriveDistance}
            value={[
              driveDistanceMin !== null
                ? InvertedDriveDistanceMap[driveDistanceMin]
                : driveDistanceRangeMin,
              driveDistanceMax !== null
                ? InvertedDriveDistanceMap[driveDistanceMax]
                : driveDistanceRangeMax,
            ]}
          />
        </Section.SliderWrapper>
      </Section.Container>
    );
  }
);

DriveDistanceSection.displayName = 'DriveDistanceSection';

export default DriveDistanceSection;
