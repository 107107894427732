/**
 * @generated SignedSource<<d27ee292d92b71642c15c601318fd802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsWarrantable_article$data = {
  readonly carData: {
    readonly " $fragmentSpreads": FragmentRefs<"useIsWarrantable_carData">;
  };
  readonly price: number | null | undefined;
  readonly warrantyPurchasable: boolean;
  readonly " $fragmentType": "useIsWarrantable_article";
};
export type useIsWarrantable_article$key = {
  readonly " $data"?: useIsWarrantable_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsWarrantable_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsWarrantable_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warrantyPurchasable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsWarrantable_carData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "07e91f1bf67dd965d5730416529d7ef2";

export default node;
