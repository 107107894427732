import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { HorizontalArticleCard_article$key } from '@/__generated__/HorizontalArticleCard_article.graphql';
import defaultThumbnail from '@/assets/images/empty_img_car.png';
import ArticleThumbnailBadges from '@/components/Article/ArticleThumbnailBadges';
import Box from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import RatioImage from '@/components/Base/OptimizedImage/RatioImage';
import { thumbnailBorderCss } from '@/components/Base/OptimizedImage/utils';
import VStack from '@/components/Base/VStack';
import LikeButton from '@/components/LikeButton';
import { useFlow } from '@/stackflow';
import { pressed } from '@/styles/mixins';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';
import { getMergedImages } from '@/utils/article/getMergedImages';

import ArticleActionCount from './ArticleActionCount';
import ArticleSpec from './ArticleSpec';

const IMAGE_WIDTH = 118;

interface Props {
  articleRef: HorizontalArticleCard_article$key;
  onClick?: (data: ArticleEventProperties) => void;
}

const HorizontalArticleCard: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  onClick,
  ...props
}) => {
  const { push } = useFlow();
  const article = useFragment<HorizontalArticleCard_article$key>(
    graphql`
      fragment HorizontalArticleCard_article on Article {
        id
        ...ArticleSpec_article
        ...getMergedImages_article
        ...ArticleActionCount_article
        ...getArticleEventProperties_article
        ...ArticleThumbnailBadges_article
        ...LikeButton_article
      }
    `,
    articleRef
  );
  const trackingData = getArticleEventProperties(article);
  const mergedImages = getMergedImages(article);

  const handleClick = () => {
    push('article', { articleId: article.id });
    onClick?.(trackingData);
  };

  return (
    <Container align="flex-start" onClick={handleClick} pa={16} {...props} pos="relative">
      <CardImage
        alt="매물 이미지"
        css={thumbnailBorderCss}
        fallback={defaultThumbnail}
        ratio="1/1"
        sizes={`${(IMAGE_WIDTH * 1.2).toString()}px`}
        src={mergedImages?.[0]?.url}
      >
        <ArticleThumbnailBadges articleRef={article} />
      </CardImage>
      <VStack justify="space-between" ox="hidden" w="100%">
        <HStack align="flex-start" gap={8} justify="space-between">
          <ArticleSpec articleRef={article} />
          <Box minW={20}> </Box>
        </HStack>
        <HStack justify="flex-end" mt={4}>
          <ArticleActionCount articleRef={article} />
        </HStack>
      </VStack>
      <ArticleLikeButton articleRef={article} color="gray600" minW={20} pos="absolute" size={20} />
    </Container>
  );
};

export default memo(HorizontalArticleCard);

const Container = styled(HStack)`
  ${pressed()}
`;

const ArticleLikeButton = styled(LikeButton)({
  top: rem(8),
  right: rem(8),
});

const CardImage = styled(RatioImage)`
  width: ${rem(IMAGE_WIDTH)};
  margin-right: ${rem(16)};
  flex: 1 0 auto;
  padding: 0;
`;
