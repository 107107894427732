/* eslint-disable @typescript-eslint/naming-convention */

import axios from 'axios';

import CONFIG from '@/constants/config';
import { getInfo } from '@/sdk/bridge/info';
import { type Size } from '@/types/Image/Size';

export type RequestUploadUrlInput = {
  blob: Blob;
  mimeType: string;
  size: Size;
  type?: RequestUploadUrlResponse['type'];
};

type RequestUploadUrlResponse = {
  __typename: 'Image';
  content_type: string;
  filepath: string;
  id: string;
  medium: string;
  region: 'kr';
  service: 'car';
  thumbnail: string;
  type: 'article';
  upload_url: string;
  url: string;
  user_id: number;
} & Size;

function getExtension(contentType: string) {
  return contentType.replace('image/', '');
}

function getRandomHex() {
  let result = '';
  const characters = '0123456789abcdef';
  const charactersLength = characters.length;
  for (let i = 0; i < 64; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function requestUploadUrl({ blob, mimeType, size, type = 'article' }: RequestUploadUrlInput) {
  const url = `${CONFIG.BIGPICTURE_HOST}/api/v2/request_upload_url`;
  const { app, region, user } = await getInfo();
  const filepath = `articles/${getRandomHex()}_${Date.now()}.${getExtension(mimeType)}`;

  const data = {
    user_id: user.id,
    body: blob,
    region: 'kr',
    service: 'car',
    type,
    content_type: mimeType,
    filepath,
    ...size,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': user?.authToken,
      'X-REGION-ID': region.id,
      'X-User-Agent': app.userAgent,
    },
  };

  try {
    const res = await axios.post(url, JSON.stringify(data), config).then((res) => res.data);

    if (res.error) {
      throw res.error;
    }
    return res.data.image as RequestUploadUrlResponse;
  } catch (e) {
    throw e;
  }
}

async function uploadFileToS3({
  url,
  blob,
  mimeType,
  imageId,
}: {
  imageId: string;
  url: string;
} & Pick<RequestUploadUrlInput, 'blob' | 'mimeType'>) {
  const config = {
    headers: {
      'Content-Type': mimeType,
      'x-amz-meta-id': imageId,
    },
  };
  try {
    const { data } = await axios.put(url, blob, config);
    return data;
  } catch (e) {
    throw e;
  }
}

const bigPicture = {
  requestUploadUrl,
  uploadFileToS3,
};

export default bigPicture;
