/**
 * @generated SignedSource<<aa84d51b2634a292a40d3c4caf3c4a31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PromotionPricing = "CLICK_1000" | "CLICK_250" | "CLICK_600";
import { FragmentRefs } from "relay-runtime";
export type PromotionItem_promotionPricings$data = {
  readonly amount: number;
  readonly type: PromotionPricing;
  readonly " $fragmentType": "PromotionItem_promotionPricings";
};
export type PromotionItem_promotionPricings$key = {
  readonly " $data"?: PromotionItem_promotionPricings$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionItem_promotionPricings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionItem_promotionPricings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "type": "PromotionPricings",
  "abstractKey": null
};

(node as any).hash = "83d4b162d2cb70c01a9e323b98640a9b";

export default node;
