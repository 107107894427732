import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { Props as LabelProps } from 'recharts/types/component/Label';

type Props = {
  marginX?: number;
  marginY?: number;
} & LabelProps;

const LABEL_WIDTH = 54;
const LEFT_RATIO = 0.685;

const PromotionEstimationLabel: React.FC<React.PropsWithChildren<Props>> = ({
  viewBox,
  marginX = 0,
  marginY = -30,
}) => {
  return (
    <foreignObject
      {...viewBox}
      height={22}
      width={LABEL_WIDTH}
      x={(viewBox as any)?.x ? (viewBox as any).x - LABEL_WIDTH * LEFT_RATIO + (marginX ?? 0) : 0}
      y={(viewBox as any)?.y ? (viewBox as any).y + (marginY ?? 0) : 0}
    >
      <Container>
        <Text>광고했을 때</Text>
      </Container>
    </foreignObject>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(LABEL_WIDTH)};
  height: ${rem(16)};
  border-radius: ${rem(2)};
  background: ${vars.$scale.color.carrotAlpha100};

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: ${LEFT_RATIO * 100}%;
    margin-left: ${rem(-5)};
    border-width: ${rem(5)};
    border-style: solid;
    border-color: ${vars.$scale.color.carrotAlpha100} transparent transparent transparent;
`;

const Text = styled.p`
  ${vars.$semantic.typography.label6Bold}
  color: ${vars.$semantic.color.primary};
`;

export default PromotionEstimationLabel;
