/**
 * @generated SignedSource<<38ec3253e295a4fdc906559c437d5f76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsWarrantable_carData$data = {
  readonly isWarrantable: boolean;
  readonly " $fragmentType": "useIsWarrantable_carData";
};
export type useIsWarrantable_carData$key = {
  readonly " $data"?: useIsWarrantable_carData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsWarrantable_carData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsWarrantable_carData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWarrantable",
      "storageKey": null
    }
  ],
  "type": "CarData",
  "abstractKey": null
};

(node as any).hash = "ef0853d5e9e5f532cbe4ddb7d6515281";

export default node;
