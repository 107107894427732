import React, { MouseEventHandler, PropsWithChildren } from 'react';

import HStack from '@/components/Base/HStack';
import { Label2Regular } from '@/components/Base/Text';
import withDefaultProps from '@/hocs/withDefaultProps';

type Props = PropsWithChildren<{
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

const RenewedBottomSheetItem: React.FC<Props> = ({ onClick, children, ...props }) => {
  return (
    <Container {...props} onClick={onClick}>
      <Label2Regular>{children}</Label2Regular>
    </Container>
  );
};

const Container = withDefaultProps(HStack, {
  align: 'center',
  justify: 'center',
  pb: 10,
  pt: 16,
  px: 16,
});

export default RenewedBottomSheetItem;
