import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';

import { articleFormState } from '@/store/article';
import { validationFailedStepState } from '@/store/write';

const useResetArticleForm = () => {
  const resetArticleForm = useResetAtom(articleFormState);
  const resetValidationFailedStep = useResetAtom(validationFailedStepState);

  return useCallback(() => {
    resetArticleForm();
    resetValidationFailedStep();
  }, [resetArticleForm, resetValidationFailedStep]);
};

export default useResetArticleForm;
