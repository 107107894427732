type CarSize = 'compact' | 'large' | 'medium' | 'small';

export const getCarSize = (displacement: number): CarSize => {
  switch (true) {
    case 0 < displacement && displacement < 1000:
      return 'compact';
    case 1000 <= displacement && displacement < 1600:
      return 'small';
    case 1600 <= displacement && displacement < 2000:
      return 'medium';
    case displacement >= 2000:
      return 'large';
    default:
      return 'medium';
  }
};
