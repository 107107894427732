import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleBadges_article$key } from '@/__generated__/ArticleBadges_article.graphql';
import { SquareBadge } from '@/components/Base/Badge';
import HStack from '@/components/Base/HStack';
import { OptionText } from '@/types/Article/Option';
import { getLastTransferInspection } from '@/utils/article/getLastTransferInspection';
import { AccidentType } from '@/utils/report/summaryAccidentHistory';

type Props = {
  articleRef: ArticleBadges_article$key;
  fallback?: JSX.Element;
  size: 'large' | 'medium' | 'small';
};

const MAX_BADGE_COUNT = 3;

const ArticleBadges: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  size = 'small',
  fallback = null,
  ...props
}) => {
  const article = useFragment(
    graphql`
      fragment ArticleBadges_article on Article {
        status
        carData {
          options
          accidentType
        }
        ...getLastTransferInspection_article
        ...isInspectedCar_article
      }
    `,
    articleRef
  );
  const { carData } = article;
  const { options, accidentType } = carData;

  const lastTransferInspection = getLastTransferInspection(article);
  const isOnlyOneOwner = !lastTransferInspection;
  const isNonSmoking = (options ?? []).includes('SMOKING_FREE');
  const isVisible = accidentType !== AccidentType.Accident || isOnlyOneOwner || isNonSmoking;

  if (!isVisible) {
    return fallback;
  }

  const badgeProps = {
    size,
    style: 'normal' as const,
  };

  const getAccidentTypeBadge = () => {
    switch (accidentType) {
      case AccidentType.AbsoluteNoAccident:
        return (
          <SquareBadge {...badgeProps} variant="accent">
            완전 무사고
          </SquareBadge>
        );
      case AccidentType.NoAccident:
        return (
          <SquareBadge {...badgeProps} variant="accent">
            무사고
          </SquareBadge>
        );
      default:
        return undefined;
    }
  };

  const badges = [
    getAccidentTypeBadge(),
    isOnlyOneOwner && (
      <SquareBadge {...badgeProps} variant="basic">
        1인 소유
      </SquareBadge>
    ),
    isNonSmoking && (
      <SquareBadge {...badgeProps} variant="basic">
        {OptionText['SMOKING_FREE']}
      </SquareBadge>
    ),
  ]
    .filter(Boolean)
    .slice(0, MAX_BADGE_COUNT);

  return badges.length > 0 ? (
    <HStack gap={4} ox="hidden" {...props}>
      {badges.map((badge, index) => (
        <React.Fragment key={index}>{badge}</React.Fragment>
      ))}
    </HStack>
  ) : (
    fallback
  );
};

export default ArticleBadges;
