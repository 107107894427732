import styled from '@emotion/styled';
import { rem } from 'polished';

import { getSeedColor } from '@/components/Base/Box';
import VStack from '@/components/Base/VStack';

export const GradientVStack = styled(VStack)({
  border: '1px solid transparent',
  backgroundImage: `linear-gradient(${getSeedColor('paperDefault')}, ${getSeedColor(
    'paperDefault'
  )}), linear-gradient(145deg, ${getSeedColor('accent')}, ${getSeedColor(
    'divider2'
  )}, ${getSeedColor('divider2')})`,
  borderRadius: rem(6),
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
});
