import { objectValues } from '@toss/utils';

import type { ValueOf } from '@/types/global';

import { AdditionalOption } from '@/types/Article/AdditionalOption';
import { ConvenienceOption } from '@/types/Article/ConvenienceOption';
import { InExteriorOption } from '@/types/Article/InExteriorOption';
import { SafetyOption } from '@/types/Article/SafetyOption';

export const OptionPriorities = [
  'LEATHER_SEAT',
  'HEATED_SEAT',
  'VENTILATED_SEAT',
  'HEATED_STEERING_WHEEL',
  'NAVIGATION',
  'CRUISE_CONTROL',
  'PARK_SENSOR_REAR',
  'REAR_CAMERA',
  'BSD',
  'AEB',
  'AROUND_VIEW',
  'LDWS',
] as const;

export const Option = {
  ...InExteriorOption,
  ...SafetyOption,
  ...ConvenienceOption,
  ...AdditionalOption,
} as const;

export const OptionNames = objectValues(Option);

export const OptionText: Record<Option, string> = {
  LEATHER_SEAT: '가죽 시트',
  POWER_SEAT: '전동 시트',
  VENTILATED_SEAT: '통풍 시트',
  HEATED_SEAT: '열선 시트',
  HEATED_STEERING_WHEEL: '열선 스티어링휠',
  SUNROOF: '선루프',
  PANORAMA_SUNROOF: '파노라마 선루프',
  HEADLAMP: '헤드램프 (LED)',
  AUTO_TRUNK: '전동 트렁크',
  AUTO_FOLDING_SIDE_MIRROR: '전동 접이식 미러',
  HIPASS: '하이패스',
  PARK_SENSOR_FRONT: '주차 센서: 전방',
  PARK_SENSOR_REAR: '주차 센서: 후방',
  REAR_CAMERA: '후방 카메라',
  AROUND_VIEW: '360도 어라운드 뷰',
  LDWS: '차선 이탈 경보',
  BSD: '후측방 경보',
  AEB: '긴급 제동 (AEB)',
  TPMS: '타이어 공기압 센서',
  BLUETOOTH: '블루투스',
  NAVIGATION: '내비게이션',
  SMART_KEY: '스마트 키',
  CRUISE_CONTROL: '크루즈 컨트롤',
  AUTO_AIR_CONDITIONER: '자동 에어컨',
  AUTO_WIPER: '자동 와이퍼',
  HUD: '헤드업 디스플레이',
  EPB: '전자식 주차브레이크 (EPB)',
  NUM_OF_KEY_2: '키 2개',
  DASHBOARD_CAMERA: '블랙박스 포함',
  SMOKING_FREE: '금연 차량',
};
export type Option = ValueOf<typeof Option>;
