import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';

import { getSizeProps } from '@/utils/style';

type Props = {
  actionName: React.ReactNode;
  description: React.ReactNode;
  onClick: () => void;
};

const BannerButton: React.FC<Props> = ({ onClick, description, actionName, ...props }) => {
  return (
    <Container {...props} onClick={onClick}>
      <Description>{description}</Description>
      <ActionText>
        {actionName}
        <RightIcon {...getSizeProps(12)} color={vars.$semantic.color.accent} />
      </ActionText>
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  flex-direction: column;
  background: ${vars.$semantic.color.warningLow};
  padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(18)};
  border-radius: ${rem(10)};
`;

const Description = styled.p`
  ${vars.$semantic.typography.bodyL2Regular}
  color: ${vars.$scale.color.red950};
  margin-bottom: ${rem(10)};
  text-align: left;
`;

const RightIcon = styled(IconChevronRightFill)`
  margin-left: ${rem(4)};
`;

const ActionText = styled.p`
  display: flex;
  align-items: center;
  ${vars.$semantic.typography.subtitle2Bold}
  color: ${vars.$semantic.color.accent};
`;

export default BannerButton;
