import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCopyRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleLocationSection_article$key } from '@/__generated__/ArticleLocationSection_article.graphql';
import KarrotMap from '@/components/KarrotMap/KarrotMap';
import { useToast } from '@/hooks/useToast';
import useTrack from '@/hooks/useTrack';
import { copyToClipboard } from '@/utils/clipboard';
import { getSizeProps } from '@/utils/style';

import SectionTitle from './SectionTitle';

type Props = {
  articleRef: ArticleLocationSection_article$key;
};

const ArticleLocationSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { track } = useTrack();
  const { setToast } = useToast();

  const article = useFragment(
    graphql`
      fragment ArticleLocationSection_article on Article {
        region {
          name1
          name2
          name3
        }
        coordinate {
          lat
          lon
        }
      }
    `,
    articleRef
  );
  const { region, coordinate } = article;
  const { name1, name2, name3 } = region ?? {};
  const address = region ? `${name1} ${name2} ${name3}` : '';

  const handleClickMap = () => {
    track('article_click_map');
  };

  const handleClickCopy = () => {
    copyToClipboard(address)
      .then(() => {
        setToast('클립보드에 복사되었어요.');
      })
      .catch(() => {
        setToast('주소 복사가 실패했어요.');
      });
    track('article_click_copy_address');
  };

  return (
    <Container>
      <SectionTitle>거래 지역</SectionTitle>
      {coordinate?.lat && coordinate?.lon && (
        <KarrotMap
          address={address}
          coordinates={{ lat: coordinate.lat, lng: coordinate.lon }}
          name="차량 위치"
          onClick={handleClickMap}
        />
      )}
      {address && (
        <AddressWrapper>
          {region && <Address>{address}</Address>}
          <AddressCopyButton onClick={handleClickCopy}>
            <CopyIcon {...getSizeProps(16)} color={vars.$scale.color.gray600} />
            복사
          </AddressCopyButton>
        </AddressWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddressWrapper = styled.div`
  display: flex;
  margin-top: ${rem(10)};
`;

const Address = styled.p`
  ${vars.$semantic.typography.label3Regular}
  color: ${vars.$scale.color.gray900};
`;

const CopyIcon = styled(IconCopyRegular)`
  margin-right: ${rem(4)};
`;

const AddressCopyButton = styled.button`
  display: flex;
  align-items: center;
  ${vars.$semantic.typography.label3Bold}
  color: ${vars.$scale.color.gray600};
`;

export default ArticleLocationSection;
