/* eslint-disable @typescript-eslint/naming-convention */
import { ZodError, ZodLiteral, ZodUnion, z } from 'zod';

export const isZodError = (error: any): error is ZodError => {
  return error instanceof Error && error.name === 'ZodError';
};

export const getFirstMessageFromZodError = (error: any) => {
  if (!isZodError(error)) {
    return undefined;
  }
  return error.issues?.[0].message;
};

export type Validation =
  | {
      isValid: false;
      message: string;
    }
  | {
      isValid: true;
    };

export const toSuperRefinement =
  <T>(validator: (v: T) => Validation) =>
  (v: T, ctx: z.RefinementCtx) => {
    const validation = validator(v);
    if (validation.isValid === false) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validation.message,
      });
    }
  };

export function getUnionValues<T extends ZodUnion<[ZodLiteral<string>, ...ZodLiteral<string>[]]>>(
  schema: T
): T['_def']['options'][number]['value'][] {
  return schema._def.options.map((option) => option.value).filter((value) => value !== 'NoIssues');
}
