import { captureException } from '@sentry/core';
import { AxiosError } from 'axios';
import { ZodError } from 'zod';

import { RelayNetworkError } from '@/types/global';

import { getMessageFromError, isRelayNetworkError } from './relay';
import { getFirstMessageFromZodError, isZodError } from './zod';

export const isError = (error: any): error is Error => {
  return error instanceof Error;
};

export const isAxiosError = (error: Error): error is AxiosError =>
  !!error?.name?.match('AxiosError');

type CarAxiosError = AxiosError<{
  errors: {
    location: {
      column: number;
      line: number;
    };
    message: string;
  }[];
}>;

export const isCarAxiosError = (error: Error): error is CarAxiosError =>
  isAxiosError(error) && Array.isArray((error?.response?.data as any)?.errors);

type ErrorResult<TError = any> =
  | {
      error: CarAxiosError;
      message: string;
      type: 'CarAxiosError';
    }
  | {
      error: Error;
      message: string;
      type: 'Error';
    }
  | {
      error: RelayNetworkError;
      message: string;
      type: 'RelayNetworkError';
    }
  | {
      error: TError;
      message: string;
      type: 'Unknown';
    }
  | {
      error: ZodError;
      message: string;
      type: 'ZodError';
    };

type Option = {
  reportToSentry?: boolean;
};

const defaultErrorMessage = '오류가 발생했어요. 잠시 후 다시 시도해 주세요.';

const errorToResult = <TError = any>(error: TError): ErrorResult<TError> => {
  if (isError(error)) {
    if (isRelayNetworkError(error)) {
      return {
        type: 'RelayNetworkError',
        message: getMessageFromError(error) ?? defaultErrorMessage,
        error,
      };
    }
    if (isZodError(error)) {
      return {
        type: 'ZodError',
        message: getFirstMessageFromZodError(error) ?? defaultErrorMessage,
        error,
      };
    }
    if (isCarAxiosError(error)) {
      return {
        type: 'CarAxiosError',
        message:
          error.response?.data.errors.map(({ message }) => message).join('\n') ?? error.message,
        error,
      };
    }
    return {
      type: 'Error',
      error,
      message: error.message,
    };
  }
  return {
    error,
    message: typeof error === 'string' ? error : defaultErrorMessage,
    type: 'Unknown',
  };
};

export const handleError = <TError = any>(error: TError, option?: Option): ErrorResult<TError> => {
  const result = errorToResult(error);
  if (option?.reportToSentry) {
    captureException(error, {
      tags: {
        errorMessage: result.message,
      },
    });
  }
  return result;
};
