import styled from '@emotion/styled';

const ButtonDiv = styled.button({
  background: 'none',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
  textAlign: 'inherit',
  color: 'inherit',
});

export default ButtonDiv;
