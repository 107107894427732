/**
 * @generated SignedSource<<41910686cee5841af60f51ef5ff67a9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleLocationSection_article$data = {
  readonly coordinate: {
    readonly lat: number;
    readonly lon: number;
  } | null | undefined;
  readonly region: {
    readonly name1: string;
    readonly name2: string;
    readonly name3: string;
  };
  readonly " $fragmentType": "ArticleLocationSection_article";
};
export type ArticleLocationSection_article$key = {
  readonly " $data"?: ArticleLocationSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleLocationSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleLocationSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name3",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Point",
      "kind": "LinkedField",
      "name": "coordinate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lon",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "fff32b9a1465a81a17d438cac7576c18";

export default node;
