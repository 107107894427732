import { graphql, useLazyLoadQuery } from 'react-relay';

import { useMyTradeReviewsQuery } from '@/__generated__/useMyTradeReviewsQuery.graphql';

const useMyTradeReviews = () => {
  const { myTradeReviews } = useLazyLoadQuery<useMyTradeReviewsQuery>(
    graphql`
      query useMyTradeReviewsQuery {
        myTradeReviews {
          id
          articleId
          createdAt
        }
      }
    `,
    {}
  );

  return myTradeReviews;
};

export default useMyTradeReviews;
