import type { CSSProperties } from 'react';

import styled, { CSSObject } from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';

import withDefaultProps from '@/hocs/withDefaultProps';
import { ellipsis } from '@/styles/mixins';

import Box, { Props as BoxProps } from './Box';
const allTextColor = {
  ...vars.$semantic.color,
  ...vars.$static.color,
  ...vars.$scale.color,
};

export type TextColor = keyof typeof allTextColor;
export type TextVariant = keyof typeof vars.$semantic.typography;

type Props = {
  color?: TextColor;
  ellipsis?: number;
  inline?: boolean;
  lineHeight?: CSSProperties['lineHeight'];
  variant: TextVariant;
  whiteSpace?: CSSProperties['whiteSpace'];
  wordBreak?: CSSProperties['wordBreak'];
} & BoxProps;

const propsToStyle = ({
  variant,
  inline,
  color,
  ellipsis: _ellipsis,
  wordBreak: _wordBreak,
  lineHeight: _lineHeight,
  whiteSpace: _whiteSpace,
}: Props): CSSObject[] => {
  const colorStyle = color
    ? {
        color: allTextColor[color],
      }
    : {};
  const inlineStyle = inline !== undefined ? { display: inline ? 'inline' : 'block' } : {};
  const ellipsisStyle = _ellipsis ? ellipsis(_ellipsis) : {};
  const wordBreak = _wordBreak ? { wordBreak: _wordBreak } : {};
  const whiteSpace = _whiteSpace ? { whiteSpace: _whiteSpace } : {};
  const lineHeight = _lineHeight
    ? { lineHeight: typeof _lineHeight === 'number' ? rem(_lineHeight) : _lineHeight }
    : {};

  return [
    vars.$semantic.typography[variant],
    inlineStyle,
    colorStyle,
    ellipsisStyle,
    whiteSpace,
    wordBreak,
    lineHeight,
  ];
};

const Text = styled(Box)<Props>(propsToStyle);

export default Text;

export const BodyL1Bold = withDefaultProps(Text, { variant: 'bodyL1Bold', color: 'gray900' });
export const BodyL1Regular = withDefaultProps(Text, { variant: 'bodyL1Regular', color: 'gray900' });
export const BodyL2Bold = withDefaultProps(Text, { variant: 'bodyL2Bold', color: 'gray900' });
export const BodyL2Regular = withDefaultProps(Text, { variant: 'bodyL2Regular', color: 'gray900' });
export const BodyM1Bold = withDefaultProps(Text, { variant: 'bodyM1Bold', color: 'gray900' });
export const BodyM1Regular = withDefaultProps(Text, { variant: 'bodyM1Regular', color: 'gray900' });
export const BodyM2Bold = withDefaultProps(Text, { variant: 'bodyM2Bold', color: 'gray900' });
export const BodyM2Regular = withDefaultProps(Text, { variant: 'bodyM2Regular', color: 'gray900' });
export const Caption1Bold = withDefaultProps(Text, { variant: 'caption1Bold', color: 'gray900' });
export const Caption1Regular = withDefaultProps(Text, {
  variant: 'caption1Regular',
  color: 'gray900',
});
export const Caption2Bold = withDefaultProps(Text, { variant: 'caption2Bold', color: 'gray900' });
export const Caption2Regular = withDefaultProps(Text, {
  variant: 'caption2Regular',
  color: 'gray900',
});
export const H1 = withDefaultProps(Text, { variant: 'h1', color: 'gray900' });
export const H2 = withDefaultProps(Text, { variant: 'h2', color: 'gray900' });
export const H3 = withDefaultProps(Text, { variant: 'h3', color: 'gray900' });
export const H4 = withDefaultProps(Text, { variant: 'h4', color: 'gray900' });
export const Label1Bold = withDefaultProps(Text, { variant: 'label1Bold', color: 'gray900' });
export const Label1Regular = withDefaultProps(Text, { variant: 'label1Regular', color: 'gray900' });
export const Label2Bold = withDefaultProps(Text, { variant: 'label2Bold', color: 'gray900' });
export const Label2Regular = withDefaultProps(Text, { variant: 'label2Regular', color: 'gray900' });
export const Label3Bold = withDefaultProps(Text, { variant: 'label3Bold', color: 'gray900' });
export const Label3Regular = withDefaultProps(Text, { variant: 'label3Regular', color: 'gray900' });
export const Label4Bold = withDefaultProps(Text, { variant: 'label4Bold', color: 'gray900' });
export const Label4Regular = withDefaultProps(Text, { variant: 'label4Regular', color: 'gray900' });
export const Label5Bold = withDefaultProps(Text, { variant: 'label5Bold', color: 'gray900' });
export const Label5Regular = withDefaultProps(Text, { variant: 'label5Regular', color: 'gray900' });
export const Label6Bold = withDefaultProps(Text, { variant: 'label6Bold', color: 'gray900' });
export const Label6Regular = withDefaultProps(Text, { variant: 'label6Regular', color: 'gray900' });
export const Subtitle1Bold = withDefaultProps(Text, { variant: 'subtitle1Bold', color: 'gray900' });
export const Subtitle1Regular = withDefaultProps(Text, {
  variant: 'subtitle1Regular',
  color: 'gray900',
});
export const Subtitle2Bold = withDefaultProps(Text, { variant: 'subtitle2Bold', color: 'gray900' });
export const Subtitle2Regular = withDefaultProps(Text, {
  variant: 'subtitle2Regular',
  color: 'gray900',
});
export const Title1Bold = withDefaultProps(Text, { variant: 'title1Bold', color: 'gray900' });
export const Title1Regular = withDefaultProps(Text, { variant: 'title1Regular', color: 'gray900' });
export const Title2Bold = withDefaultProps(Text, { variant: 'title2Bold', color: 'gray900' });
export const Title2Regular = withDefaultProps(Text, { variant: 'title2Regular', color: 'gray900' });
export const Title3Bold = withDefaultProps(Text, { variant: 'title3Bold', color: 'gray900' });
export const Title3Regular = withDefaultProps(Text, { variant: 'title3Regular', color: 'gray900' });
