import { css } from '@emotion/react';
import { IconExpandMoreFill, IconExpandMoreRegular, IconExpandMoreThin } from '@seed-design/icon';
import React from 'react';

import { PropsOf } from '@/types/React/PropsOf';
import { getSizeProps } from '@/utils/style';

type ExpandMoreIcon =
  | typeof IconExpandMoreFill
  | typeof IconExpandMoreRegular
  | typeof IconExpandMoreThin;
type Props = {
  expanded?: boolean;
  size?: number;
  weight?: 'fill' | 'regular' | 'thin';
} & PropsOf<ExpandMoreIcon>;

const DEFAULT_SIZE = 24;
const Icon = {
  thin: IconExpandMoreThin,
  regular: IconExpandMoreRegular,
  fill: IconExpandMoreFill,
};

const ExpandMore: React.FC<React.PropsWithChildren<Props>> = ({
  expanded = false,
  size = DEFAULT_SIZE,
  weight = 'regular',
  ...props
}) => {
  const IconComponent = Icon[weight];

  return (
    <IconComponent
      {...getSizeProps(size)}
      css={css`
        transition: transform 0.3s;
        ${expanded &&
        css`
          transform: rotate(180deg);
        `}
      `}
      {...props}
    />
  );
};

export default ExpandMore;
