import {
  IconAebThin,
  IconCarAroundViewThin,
  IconCarBlindSpotThin,
  IconCarCruiseControlThin,
  IconCarHeatedSeatThin,
  IconCarHeatedSteeringWheelThin,
  IconCarLdwsThin,
  IconCarLeatherSeatThin,
  IconCarNavigationThin,
  IconCarRearCameraThin,
  IconCarRearSensorThin,
  IconCarVentilationSeatThin,
} from '@seed-design/icon';
import React from 'react';

import { Option } from '@/types/Article/Option';
import { getSizeProps } from '@/utils/style';

import Spacer from './Base/Spacer';

type Props = {
  option: Option;
  size?: number;
};
type Icon =
  | typeof IconAebThin
  | typeof IconCarAroundViewThin
  | typeof IconCarBlindSpotThin
  | typeof IconCarCruiseControlThin
  | typeof IconCarHeatedSeatThin
  | typeof IconCarHeatedSteeringWheelThin
  | typeof IconCarLdwsThin
  | typeof IconCarLeatherSeatThin
  | typeof IconCarNavigationThin
  | typeof IconCarRearCameraThin
  | typeof IconCarRearSensorThin
  | typeof IconCarVentilationSeatThin;

const IconMap: {
  [key in Option]?: Icon;
} = {
  AROUND_VIEW: IconCarAroundViewThin,
  CRUISE_CONTROL: IconCarCruiseControlThin,
  AEB: IconAebThin,
  LDWS: IconCarLdwsThin,
  BSD: IconCarBlindSpotThin,
  VENTILATED_SEAT: IconCarVentilationSeatThin,
  HEATED_STEERING_WHEEL: IconCarHeatedSteeringWheelThin,
  REAR_CAMERA: IconCarRearCameraThin,
  NAVIGATION: IconCarNavigationThin,
  PARK_SENSOR_REAR: IconCarRearSensorThin,
  HEATED_SEAT: IconCarHeatedSeatThin,
  LEATHER_SEAT: IconCarLeatherSeatThin,
};

const DEFAULT_SIZE = 18;

const OptionIcon: React.FC<Props> = ({ option, size = DEFAULT_SIZE, ...props }) => {
  const Icon = IconMap[option];

  if (!Icon) {
    return <Spacer size={size} />;
  }

  return <Icon {...getSizeProps(size)} {...props} />;
};

export default OptionIcon;
