import { rem } from 'polished';
import React from 'react';

const Manner5: React.FC<React.PropsWithChildren<unknown>> = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(24)}
      viewBox="0 0 24 24"
      width={rem(24)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <mask height="24" id="mask0_844_18126" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FAD84A"
        />
      </mask>
      <g mask="url(#mask0_844_18126)">
        <path
          d="M4.35 18.5999C6.75244 18.5999 8.7 16.6523 8.7 14.2499C8.7 11.8475 6.75244 9.8999 4.35 9.8999C1.94756 9.8999 0 11.8475 0 14.2499C0 16.6523 1.94756 18.5999 4.35 18.5999Z"
          fill="url(#paint0_radial_844_18126)"
          opacity="0.6"
        />
        <path
          d="M19.6498 18.5999C22.0522 18.5999 23.9998 16.6523 23.9998 14.2499C23.9998 11.8475 22.0522 9.8999 19.6498 9.8999C17.2474 9.8999 15.2998 11.8475 15.2998 14.2499C15.2998 16.6523 17.2474 18.5999 19.6498 18.5999Z"
          fill="url(#paint1_radial_844_18126)"
          opacity="0.6"
        />
      </g>
      <path
        clipRule="evenodd"
        d="M7.7999 9.7124C7.29233 9.7124 6.7124 10.1247 6.7124 10.7999C6.7124 11.1106 6.46056 11.3624 6.1499 11.3624C5.83924 11.3624 5.5874 11.1106 5.5874 10.7999C5.5874 9.37515 6.80747 8.5874 7.7999 8.5874C8.79233 8.5874 10.0124 9.37514 10.0124 10.7999C10.0124 11.1106 9.76056 11.3624 9.4499 11.3624C9.13924 11.3624 8.8874 11.1106 8.8874 10.7999C8.8874 10.1247 8.30747 9.7124 7.7999 9.7124Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.3499 9.7124C15.8424 9.7124 15.2625 10.1247 15.2625 10.7999C15.2625 11.1106 15.0106 11.3624 14.7 11.3624C14.3893 11.3624 14.1375 11.1106 14.1375 10.7999C14.1375 9.37515 15.3575 8.5874 16.3499 8.5874C17.3424 8.5874 18.5624 9.37514 18.5624 10.7999C18.5624 11.1106 18.3106 11.3624 17.9999 11.3624C17.6893 11.3624 17.4374 11.1106 17.4374 10.7999C17.4374 10.1247 16.8575 9.7124 16.3499 9.7124Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        d="M7.3498 13.5C6.76991 13.5 6.2998 13.9701 6.2998 14.55C6.2998 14.8665 6.41632 15.2888 6.57921 15.6813C6.75833 16.113 7.04294 16.6292 7.47264 17.1286C8.35543 18.1545 9.80335 19.05 11.9998 19.05C14.1963 19.05 15.6442 18.1545 16.527 17.1286C16.9567 16.6292 17.2413 16.113 17.4204 15.6813C17.5833 15.2888 17.6998 14.8665 17.6998 14.55C17.6998 13.9701 17.2297 13.5 16.6498 13.5H7.3498Z"
        fill="#895F00"
      />
      <path
        clipRule="evenodd"
        d="M5.8851 5.5681L5.88346 5.57135C5.88453 5.5694 5.88746 5.56417 5.89174 5.55715C5.90035 5.54304 5.91526 5.52008 5.93683 5.49187C5.9804 5.43489 6.04828 5.36021 6.14285 5.29266C6.32036 5.16586 6.62069 5.04175 7.10894 5.16381C7.30986 5.21404 7.51346 5.09188 7.56369 4.89096C7.61392 4.69004 7.49176 4.48644 7.29084 4.43621C6.57909 4.25827 6.05441 4.43416 5.70692 4.68236C5.53899 4.80231 5.41937 4.93388 5.34107 5.03628C5.30171 5.08775 5.27208 5.13277 5.25139 5.1667L5.21448 5.2323C5.12186 5.41755 5.19694 5.6428 5.38218 5.73542C5.5662 5.82743 5.78972 5.75393 5.88346 5.57135L5.88402 5.57024L5.88458 5.56913L5.8851 5.5681Z"
        fill="#D49D3A"
        fillRule="evenodd"
        opacity="0.85"
      />
      <path
        d="M18.4677 5.73542C18.2837 5.82743 18.0602 5.75394 17.9664 5.57136C17.9654 5.56941 17.9624 5.56417 17.9581 5.55715C17.9495 5.54304 17.9346 5.52008 17.913 5.49187C17.8695 5.43489 17.8016 5.36021 17.707 5.29266C17.5295 5.16587 17.2292 5.04175 16.7409 5.16381C16.54 5.21404 16.3364 5.09188 16.2862 4.89096C16.236 4.69004 16.3581 4.48644 16.559 4.43621C17.2708 4.25827 17.7955 4.43416 18.143 4.68236C18.3109 4.80231 18.4305 4.93388 18.5088 5.03628C18.5482 5.08775 18.5778 5.13277 18.5985 5.1667C18.7177 5.36214 18.6913 5.62362 18.4677 5.73542Z"
        fill="#D49D3A"
        opacity="0.85"
      />
      <path
        clipRule="evenodd"
        d="M8.40019 14.7C8.344 14.7 8.29253 14.7314 8.26682 14.7813C8.2411 14.8313 8.24623 14.8925 8.27889 14.9382L8.27993 14.9396L8.28289 14.9436L8.29245 14.9558C8.30048 14.9658 8.31186 14.9793 8.32694 14.9959C8.35711 15.0291 8.40201 15.0745 8.46429 15.1279C8.58895 15.2348 8.78266 15.3731 9.06623 15.51C9.63366 15.784 10.5566 16.05 12.0002 16.05C13.4434 16.05 14.3307 15.7841 14.863 15.5081C15.1291 15.3701 15.3052 15.2302 15.4167 15.1213C15.4724 15.0669 15.5117 15.0205 15.538 14.9862C15.5531 14.9664 15.5678 14.946 15.5804 14.9244C15.607 14.8779 15.6068 14.8209 15.5799 14.7747C15.5531 14.7284 15.5037 14.7 15.4502 14.7H8.40019Z"
        fill="white"
        fillRule="evenodd"
      />
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(4.3512 14.2016) scale(4.3512)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_844_18126"
          r="1"
        >
          <stop stopColor="#F15A24" stopOpacity="0.5" />
          <stop offset="0.0178806" stopColor="#F15E25" stopOpacity="0.5089" />
          <stop offset="0.2407" stopColor="#F6892B" stopOpacity="0.6203" />
          <stop offset="0.4561" stopColor="#FAAC30" stopOpacity="0.7281" />
          <stop offset="0.6595" stopColor="#FDC433" stopOpacity="0.8298" />
          <stop offset="0.8461" stopColor="#FED335" stopOpacity="0.923" />
          <stop offset="1" stopColor="#FFD836" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(19.5787 14.2016) scale(4.3512)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_844_18126"
          r="1"
        >
          <stop stopColor="#F15A24" stopOpacity="0.5" />
          <stop offset="0.0178806" stopColor="#F15E25" stopOpacity="0.5089" />
          <stop offset="0.2407" stopColor="#F6892B" stopOpacity="0.6203" />
          <stop offset="0.4561" stopColor="#FAAC30" stopOpacity="0.7281" />
          <stop offset="0.6595" stopColor="#FDC433" stopOpacity="0.8298" />
          <stop offset="0.8461" stopColor="#FED335" stopOpacity="0.923" />
          <stop offset="1" stopColor="#FFD836" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Manner5;
