import React from 'react';

import { usePvEvent } from '@/hooks/usePvEvent';
import { ActivityParams } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

const MainPv: React.FC<React.PropsWithChildren<unknown>> = ({}) => {
  const activityParams = useCamelCaseParams<ActivityParams<'main'>>();
  usePvEvent('main', activityParams);
  return null;
};

export default MainPv;
