import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { memo } from 'react';

import AspectRatio from '@/components/Base/AspectRatio';
import Divider from '@/components/Base/Divider';

import Placeholder from './Placeholder';

const SkeletonPageArticle: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BoxPaper data-testid="page-loading">
      <AspectRatio ratio="1/1">
        <Placeholder br={0} h={'100%'} w="100%" />
      </AspectRatio>
      <BoxContents>
        <BoxFlex>
          <Placeholder br={40} h={40} mr={12} w={40} />
          <div>
            <Placeholder h={20} mb={4} w={72} />
            <Placeholder h={16} w={45} />
          </div>
        </BoxFlex>
        <Divider />
        <Placeholder h={22} mb={4} mt={16} w={120} />
        <Placeholder h={22} w={90} />
      </BoxContents>
    </BoxPaper>
  );
};

export default memo(SkeletonPageArticle);

const BoxPaper = styled.div`
  background: ${vars.$semantic.color.paperDefault};
`;

const BoxContents = styled.div`
  padding: ${rem(16)};
  margin-top: ${rem(6)};
`;

const BoxFlex = styled.div`
  display: flex;
  padding-top: ${rem(4)};
  padding-bottom: ${rem(20)};
`;
