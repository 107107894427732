/**
 * @generated SignedSource<<4acbdf74a1f88e3c85f4964111db6c21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CompanyOrigin = "FOREIGN" | "KOREAN";
import { FragmentRefs } from "relay-runtime";
export type carData_getCarNameByCompanyOrigin_carData$data = {
  readonly autoupModel: {
    readonly company: {
      readonly companyOrigin: CompanyOrigin;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"carData_assembleCarName_carData">;
  readonly " $fragmentType": "carData_getCarNameByCompanyOrigin_carData";
};
export type carData_getCarNameByCompanyOrigin_carData$key = {
  readonly " $data"?: carData_getCarNameByCompanyOrigin_carData$data;
  readonly " $fragmentSpreads": FragmentRefs<"carData_getCarNameByCompanyOrigin_carData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carData_getCarNameByCompanyOrigin_carData"
};

(node as any).hash = "f37a17850920300075cbd8b521bad0b7";

export default node;
