import { GraphQLError, RelayNetworkError } from '@/types/global';

export const isRelayNetworkError = (error: Error): error is RelayNetworkError =>
  !!error?.name?.match('RelayNetwork');

export const pickFirstError = (e: RelayNetworkError) => {
  return (e?.source?.errors ?? []).find(
    (error) => error?.message || error?.extensions?.message || error?.extensions?.type
  );
};

export const getMessageFromError = (e: RelayNetworkError) => {
  const errors = e?.source?.errors ?? [];

  for (const error of errors) {
    const message = error?.message ?? error?.extensions?.message;
    if (message) {
      return message;
    }
  }

  return null;
};

export const checkErrorExist = (e: RelayNetworkError, type: string) => {
  const errors = e?.source?.errors ?? [];

  return errors.some((error) => error?.extensions?.type === type);
};

export const isServiceError = (error?: GraphQLError) => {
  return error?.extensions?.type;
};
