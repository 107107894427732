import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { memo } from 'react';

import SkeletonVerticalArticleCard from '@/components/Skeleton/SkeletonVerticalArticleCard';
import { safeAreaInset } from '@/styles/mixins';

const SkeletonPageHotArticles: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <ItemsWrapper>
        <SkeletonVerticalArticleCard />
        <SkeletonVerticalArticleCard />
        <SkeletonVerticalArticleCard />
        <SkeletonVerticalArticleCard />
      </ItemsWrapper>
    </Container>
  );
};

export default memo(SkeletonPageHotArticles);

const Container = styled.div`
  padding: 0 ${rem(8)};
  ${safeAreaInset({ paddingBottom: 0 })};
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${rem(10)} 0;
  align-items: start;
`;
