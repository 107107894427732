import { objectEntries } from '@toss/utils';

import type {
  MechanicReportCategory,
  MechanicReportPath,
  ReportItemStatePath,
} from '@/types/MechanicReport/MechanicReport';
import type { ReportItemState } from '@/types/MechanicReport/ReportItemState';
import type { FullReport } from '@/utils/report/getFullReport';

import { mutable } from '../misc';
import { consumableGroups } from './consumableGroups';
import { maintainableCategories } from './maintainableCategories';
import { pickMechanicReport } from './pickMechanicReport';
import { selectionListTable } from './selectionListTable';

export const hasPartIssue = (part: ReportItemState | undefined, partPath: ReportItemStatePath) => {
  if (!part) {
    return false;
  }
  const [availableSelectionList, allowedSelectionList] = selectionListTable[partPath];
  const hasIssue = part.selections.some(
    (v) => availableSelectionList.includes(v) && !allowedSelectionList.includes(v)
  );
  return hasIssue;
};

export const hasGroupIssue = (
  group: Record<string, ReportItemState | undefined>,
  groupPath: MechanicReportPath
) => {
  const hasIssue = objectEntries(group).some(([partPath, part]) => {
    if (!part) {
      return false;
    }
    const fullPath = `${groupPath}.${partPath}` as ReportItemStatePath;
    return hasPartIssue(part, fullPath);
  });
  return hasIssue;
};

export const hasCategoryIssue = (
  category: Record<string, Record<string, ReportItemState | undefined> | undefined>,
  categoryPath: MechanicReportCategory,
  excludeGroupPaths: MechanicReportPath[] = []
) => {
  const hasIssue = objectEntries(category).some(([groupPath, group]) => {
    if (!group) {
      return false;
    }
    const path = `${categoryPath}.${groupPath}` as MechanicReportPath;
    return hasGroupIssue(group, path) && !excludeGroupPaths.includes(path);
  });
  return hasIssue;
};

export const getClientSideIssueCount = (report: FullReport) => {
  const pickedReport = pickMechanicReport(report, maintainableCategories);
  const issuedCategories = objectEntries(pickedReport).filter(([categoryPath, category]) => {
    const hasIssue = hasCategoryIssue(
      category,
      categoryPath as MechanicReportCategory,
      mutable(consumableGroups)
    );
    return hasIssue;
  });
  return issuedCategories.length;
};
