import { rem } from 'polished';

type Size = {
  height: number;
  width: number;
};

export function getSizeProps(size: Size | number) {
  if (typeof size === 'number') {
    return {
      width: rem(size),
      height: rem(size),
    };
  }
  return {
    width: rem(size.width),
    height: rem(size.height),
  };
}
