import { graphql, readInlineData } from 'relay-runtime';

import { getPriceText_article$key } from '@/__generated__/getPriceText_article.graphql';

import { getBillionFormatFromPrice } from '../number';

export const getPriceText = (articleRef: getPriceText_article$key) => {
  const { price, leaseInfo, rentalInfo } = readInlineData(
    graphql`
      fragment getPriceText_article on Article @inline {
        id
        price
        leaseInfo {
          monthlyFee
        }
        rentalInfo {
          monthlyFee
        }
      }
    `,
    articleRef
  );

  const articleTypeText = leaseInfo ? '리스' : rentalInfo ? '렌트' : '';
  const monthlyFee = leaseInfo?.monthlyFee || rentalInfo?.monthlyFee;
  const priceText = monthlyFee
    ? `${articleTypeText} ${getBillionFormatFromPrice(monthlyFee)}원/월`
    : `${getBillionFormatFromPrice(price ?? 0)}원`;

  return priceText;
};
