import { useCallback } from 'react';
import { UseMutationConfig, useMutation } from 'react-relay';
import { GraphQLTaggedNode, MutationParameters } from 'relay-runtime';

const useMutationAsync = <TMutation extends MutationParameters>(mutation: GraphQLTaggedNode) => {
  const [mutate, loading] = useMutation<TMutation>(mutation);
  const mutateAsync = useCallback(
    (params: UseMutationConfig<TMutation>): Promise<TMutation['response']> => {
      return new Promise((resolve, reject) => {
        mutate({
          ...params,
          onCompleted(response, errors) {
            if (response) {
              resolve(response);
            }
            reject(errors);
          },
          onError(error) {
            reject(error);
          },
        });
      });
    },
    [mutate]
  );

  return [mutateAsync, loading] as const;
};

export default useMutationAsync;
