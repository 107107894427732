/**
 * @generated SignedSource<<03e947a854900639e58d0ffc42cfad28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionPricing = "CLICK_1000" | "CLICK_250" | "CLICK_600";
export type PromotionPaymentLayerQuery$variables = Record<PropertyKey, never>;
export type PromotionPaymentLayerQuery$data = {
  readonly promotionPricings: ReadonlyArray<{
    readonly amount: number;
    readonly type: PromotionPricing;
    readonly " $fragmentSpreads": FragmentRefs<"PromotionItem_promotionPricings">;
  }>;
};
export type PromotionPaymentLayerQuery = {
  response: PromotionPaymentLayerQuery$data;
  variables: PromotionPaymentLayerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionPaymentLayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PromotionPricings",
        "kind": "LinkedField",
        "name": "promotionPricings",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PromotionItem_promotionPricings"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PromotionPaymentLayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PromotionPricings",
        "kind": "LinkedField",
        "name": "promotionPricings",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "214b7e61e389803246c6285fb7be0e52",
    "id": null,
    "metadata": {},
    "name": "PromotionPaymentLayerQuery",
    "operationKind": "query",
    "text": "query PromotionPaymentLayerQuery {\n  promotionPricings {\n    type\n    amount\n    ...PromotionItem_promotionPricings\n  }\n}\n\nfragment PromotionItem_promotionPricings on PromotionPricings {\n  type\n  amount\n}\n"
  }
};
})();

(node as any).hash = "c84a562c2b9c1137f8f599d089cfa167";

export default node;
