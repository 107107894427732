import { ChipToggleButton } from '@daangn/sprout-components-chips';
import { memo } from 'react';

import { CarType, CarTypeText } from '@/types/CarData/CarType';

import Section from './Section';

type Props = {
  carTypes: CarType[] | null;
  fixedCarTypes?: CarType[];
  handleChangeCarType: (carType: CarType) => void;
};

const CarTypeSection: React.FC<Props> = memo(({ carTypes, fixedCarTypes, handleChangeCarType }) => {
  return (
    <Section.Container>
      <Section.Header>
        <Section.Title>차종</Section.Title>
      </Section.Header>
      <Section.ButtonWrapper>
        {Object.values(CarType).map((carType) => {
          const isActive = (carTypes ?? []).includes(carType);
          const isDisabled = (fixedCarTypes ?? []).includes(carType);
          return (
            <ChipToggleButton
              isDisabled={isDisabled}
              isSelected={isActive}
              key={carType}
              onClick={() => handleChangeCarType(carType)}
              size="small"
            >
              {CarTypeText[carType]}
            </ChipToggleButton>
          );
        })}
      </Section.ButtonWrapper>
    </Section.Container>
  );
});

CarTypeSection.displayName = 'CarTypeSection';

export default CarTypeSection;
