import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { IS_LOCAL } from '@/constants/config';

import fetchGraphQL from './fetchGraphQL';
import getDataID from './getDataID';

const environment = new Environment({
  network: Network.create(fetchGraphQL),
  store: new Store(new RecordSource()),
  requiredFieldLogger: ({ kind, owner, fieldPath }) => {
    if (IS_LOCAL) {
      const requiredKind = kind.split('.')[1].toUpperCase();
      // eslint-disable-next-line no-console
      console.log(
        `[Relay Required Field Unavailable - ${requiredKind}] owner: ${owner}, fieldPath: ${fieldPath}`
      );
    }
  },
  getDataID,
});

export default environment;
