import { Duration, intervalToDuration } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';

import useLatest from '@/hooks/useLatest';

type Options = {
  // timestamp
  initialEndsAt: number;
  onTimeEnd?: () => void;
};

const INTERVAL = 1000;

const useCountdown = ({ initialEndsAt = 0, onTimeEnd }: Options) => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const onTimeEndRef = useLatest(onTimeEnd);
  const [endsAt, setEndsAt] = useState(initialEndsAt);
  const [duration, setDuration] = useState<Duration>({ seconds: 0 });

  const clearIntervalRef = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, []);

  const clearCountdown = useCallback(() => {
    setEndsAt(0);
    clearIntervalRef();
  }, [clearIntervalRef]);

  useEffect(() => {
    if (endsAt <= Date.now()) {
      setDuration({ seconds: 0 });
      return;
    }
    clearIntervalRef();
    intervalRef.current = setInterval(() => {
      setDuration(
        intervalToDuration({
          start: new Date(),
          end: new Date(endsAt),
        })
      );
    }, INTERVAL);

    return () => clearIntervalRef();
  }, [clearIntervalRef, endsAt]);

  useEffect(() => {
    if (intervalRef.current && onTimeEndRef.current) {
      const { days, hours, minutes, months, seconds, weeks, years } = duration;
      const isEmptyDuration =
        !years && !months && !weeks && !days && !hours && !minutes && !seconds;
      if (isEmptyDuration) {
        onTimeEndRef.current();
        clearIntervalRef();
      }
    }
  }, [clearIntervalRef, duration, onTimeEndRef]);

  return { duration, setEndsAt, clearCountdown };
};

export default useCountdown;
