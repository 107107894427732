/**
 * @generated SignedSource<<e59f3844329ecddaa1427fce4d111065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleEventType = "BUYER_SELECTED" | "CHATTED" | "CREATED" | "DELETED" | "FREE_PROMOTED" | "HOT_ARTICLE" | "PAID_PULLED_UP" | "PRICE_SALE" | "PROMOTED" | "PULLED_UP" | "RESTORED" | "REVIEW_CREATED" | "STALED" | "STALED_HIDE" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ResaledArticleBanner_sanitizedArticle$data = {
  readonly events: ReadonlyArray<{
    readonly t: number;
    readonly type: ArticleEventType | null | undefined;
  }>;
  readonly minActualPrice: number | null | undefined;
  readonly price: number;
  readonly " $fragmentType": "ResaledArticleBanner_sanitizedArticle";
};
export type ResaledArticleBanner_sanitizedArticle$key = {
  readonly " $data"?: ResaledArticleBanner_sanitizedArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResaledArticleBanner_sanitizedArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResaledArticleBanner_sanitizedArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minActualPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "t",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SanitizedArticle",
  "abstractKey": null
};

(node as any).hash = "7c2675815c01faaddf93d755086c305e";

export default node;
