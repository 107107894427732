import { format } from 'date-fns';

import { compose, pipeWithNullifyValue } from './misc';

const DEFAULT_LOCALE = 'ko-KR';
const LOCALE = navigator.language || DEFAULT_LOCALE;

export const getCommaFormat = (num: number) => {
  return Number(num ?? 0).toLocaleString(LOCALE);
};

export const getKmFormat = (str: string) => {
  return str + 'km';
};

export const getWonFormat = (str: string) => {
  return str + '원';
};

export const getCommaKmFormat = pipeWithNullifyValue(getCommaFormat, getKmFormat);
export const getCommaWonFormat = pipeWithNullifyValue(getCommaFormat, getWonFormat);

export const NUM_1만 = 10000;
export const NUM_1억 = Math.pow(NUM_1만, 2);

export const getExactPrice = (price: number) => {
  if (!price) {
    return 0;
  }

  return Number(price) * NUM_1만;
};

export const getPriceFormat = compose(getCommaFormat, getExactPrice);

export const getBillionFormat = (number: number) => {
  const result = [];
  const format = compose(getCommaFormat, Math.floor);

  if (!number) {
    return '0';
  }

  if (number >= NUM_1억) {
    result.push(`${format(number / NUM_1억)}억`);
    number %= NUM_1억;
  }

  if (number > 0) {
    result.push(`${format(number / NUM_1만)}만`);
  }

  return `${result.join(' ')}`;
};

export const getBillionFormatFromPrice = (price: number) => {
  return compose(getBillionFormat, getExactPrice)(price);
};

export const getShortenYear = (year: number) => {
  if (!year) {
    return '';
  }

  const date = new Date(year, 0, 1);

  return format(date, 'yy');
};

export const padLeft = (value: number): string => {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
};

export const unpadLeft = (value: string): number => {
  let numericParts = value;

  if (value.startsWith('0')) {
    numericParts = numericParts.slice(1);
  }
  const numberified = Number(numericParts);

  if (isNaN(numberified)) {
    return 0;
  }
  return numberified;
};

export const formatFloat = (floatNumber?: null | number) => {
  if (floatNumber == null) {
    return '';
  }
  const trimmed = floatNumber.toFixed(1);

  return trimmed.endsWith('.0') ? trimmed.slice(0, trimmed.length - 2) : trimmed;
};

export const isInRange = (num: number, min: number, max: number) => min <= num && num <= max;

export const sum = (arr: number[]) => arr.reduce((total, curr) => total + curr, 0);

export const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(min, num), max);
};

export const average = (arr: number[]) => sum(arr) / arr.length;

/**
 * [대한민국의 전화번호 체계](https://ko.wikipedia.org/wiki/%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD%EC%9D%98_%EC%A0%84%ED%99%94%EB%B2%88%ED%98%B8_%EC%B2%B4%EA%B3%84)
 *
 */
export const isValidKoreaNumber = (v = '') => {
  const seoulNoRegex = /02\-?[0-9]{3,4}\-?[0-9]{4}$/;
  const otherNoRegex = /0[3-6][1-5]\-?[0-9]{3,4}\-?[0-9]{4}$/;
  const phoneNoRegex = /010\-?[0-9]{3,4}\-?[0-9]{4}$/;
  return seoulNoRegex.test(v) || otherNoRegex.test(v) || phoneNoRegex.test(v);
};
