import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const Car: React.FC<React.PropsWithChildren<Props>> = ({
  width = 28,
  height = 28,
  fill: propFill,
  ...props
}) => {
  const fill = propFill ?? '#358EF9';

  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 28 28"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.5116 13.6967C24.1966 13.475 23.8699 13.265 23.5199 13.0667L23.2749 11.7017H24.4999C25.1416 11.7017 25.6666 11.1767 25.6666 10.535C25.6666 9.89333 25.1416 9.36833 24.4999 9.36833H22.8666L22.3183 6.28833C22.1783 5.46 21.5833 4.78333 20.7899 4.52667C18.7833 3.87333 16.4733 3.5 13.9999 3.5C11.5266 3.5 9.21659 3.87333 7.20992 4.52667C6.41659 4.78333 5.83325 5.47167 5.68159 6.28833L5.13325 9.36833H3.49992C2.85825 9.36833 2.33325 9.89333 2.33325 10.535C2.33325 11.1767 2.85825 11.7017 3.49992 11.7017H4.72492L4.47992 13.0667C4.12992 13.265 3.79159 13.475 3.47659 13.6967C2.85825 14.14 2.49659 14.8517 2.49659 15.61V23.9517C2.49659 24.2783 2.75325 24.535 3.07992 24.535H7.76992C8.09658 24.535 8.35325 24.2783 8.35325 23.9517V22.3067H19.6583V23.9517C19.6583 24.2783 19.9149 24.535 20.2416 24.535H24.9083C25.2349 24.535 25.4916 24.2783 25.4916 23.9517V15.61C25.4916 14.8517 25.1299 14.14 24.5116 13.6967ZM8.87825 18.6083C8.00325 18.6083 7.30325 17.9083 7.30325 17.0333C7.30325 16.1583 8.00325 15.4583 8.87825 15.4583C9.75325 15.4583 10.4533 16.1583 10.4533 17.0333C10.4533 17.9083 9.75325 18.6083 8.87825 18.6083ZM19.1449 18.6083C18.2699 18.6083 17.5699 17.9083 17.5699 17.0333C17.5699 16.1583 18.2699 15.4583 19.1449 15.4583C20.0199 15.4583 20.7199 16.1583 20.7199 17.0333C20.7199 17.9083 20.0199 18.6083 19.1449 18.6083ZM14.0116 10.815C11.4683 10.815 9.09992 11.2233 7.06992 11.9233L7.99159 6.73167C9.82325 6.13667 11.8999 5.83333 14.0233 5.83333C16.1466 5.83333 18.2233 6.14833 20.0549 6.73167L20.9766 11.935C18.9466 11.235 16.5783 10.8267 14.0233 10.8267L14.0116 10.815Z"
        fill={fill}
      />
    </svg>
  );
};

export default Car;
