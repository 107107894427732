import styled from '@emotion/styled';
import { format } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { SameCarTransactionHistoryItem_article$key } from '@/__generated__/SameCarTransactionHistoryItem_article.graphql';
import HStack from '@/components/Base/HStack';
import Spacer from '@/components/Base/Spacer';
import { Label3Regular, Label4Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import TransactionHistoryItem from '@/components/TransactionHistoryItem';
import { getFilteredEvents } from '@/utils/article/getFilteredEvents';
import { getBillionFormatFromPrice, getShortenYear } from '@/utils/number';
import { getDriveDistanceText } from '@/utils/string';

type Props = {
  articleRef: SameCarTransactionHistoryItem_article$key;
};

const SameCarTransactionHistoryItem: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment SameCarTransactionHistoryItem_article on Article {
        carData {
          autoupModel {
            subseries {
              id
            }
          }
        }
        resaleOriginArticle {
          id
          publishedAt
          price
          minActualPrice
          events {
            type
            t
          }
          driveDistance
          carData {
            modelYear
            autoupModel {
              grade {
                name
              }
              subgrade {
                name
              }
            }
          }
        }
        ...TransactionHistoryItem_article
      }
    `,
    articleRef
  );
  const {
    carData: { autoupModel },
    resaleOriginArticle,
  } = article;
  const subseriesId = autoupModel?.subseries?.id;

  if (!subseriesId) {
    return null;
  }

  const renderResaleArticle = () => {
    if (!resaleOriginArticle) {
      return null;
    }
    const { price, minActualPrice, driveDistance, carData, events } = resaleOriginArticle;
    const [latestTradedEvent] = getFilteredEvents(events, 'TRADED');
    const { modelYear, autoupModel } = carData;
    const { grade, subgrade } = autoupModel ?? {};

    return (
      <VStack gap={8}>
        {/* TODO: TransactionHistoryItem과 스키마에서 매물 타입 통일되면 공통 컴포넌트로 추출 */}
        <Label4Bold color="warning">
          이전 거래기록{' '}
          {latestTradedEvent ? format(new Date(latestTradedEvent.t), 'yy년 M월 d일') : ''}
        </Label4Bold>
        <HStack align="center" gap={10}>
          <DynamicWidthBlock>
            <Label3Regular color="gray600" wb="break-word">
              {[grade?.name, subgrade?.name]
                .filter((name) => Boolean(name) && name !== '없음')
                .join(' ')}
            </Label3Regular>
          </DynamicWidthBlock>
          <FixedWidthBlock width={72}>
            <Label3Regular color="gray600">
              {getBillionFormatFromPrice(minActualPrice ?? price)}원
            </Label3Regular>
          </FixedWidthBlock>
          <FixedWidthBlock width={50}>
            <Label3Regular color="gray600">{getShortenYear(modelYear)}년식</Label3Regular>
          </FixedWidthBlock>
          <FixedWidthBlock width={70}>
            <Label3Regular color="gray600">{getDriveDistanceText(driveDistance)}km</Label3Regular>
          </FixedWidthBlock>
          <Spacer axis="horizontal" size={14} />
        </HStack>
      </VStack>
    );
  };

  return (
    <VStack pb={12}>
      <TransactionHistoryItem articleRef={article} highlighted resaled={!!resaleOriginArticle} />
      {renderResaleArticle()}
    </VStack>
  );
};

const DynamicWidthBlock = styled.div`
  flex: 1 1 auto;
`;

const FixedWidthBlock = styled.div<{ width: number }>`
  min-width: ${({ width }) => rem(width)};
  flex-basis: ${({ width }) => rem(width)};
`;

export default SameCarTransactionHistoryItem;
