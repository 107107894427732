/**
 * @generated SignedSource<<858fb6f550125cc8e4cebf86cf1bcabe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StaticArticleCard_article$data = {
  readonly carData: {
    readonly modelYear: number;
    readonly " $fragmentSpreads": FragmentRefs<"carData_getCarFullName_carData">;
  };
  readonly chatRoomCount: number;
  readonly driveDistance: number;
  readonly price: number | null | undefined;
  readonly voteCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"getMergedImages_article">;
  readonly " $fragmentType": "StaticArticleCard_article";
};
export type StaticArticleCard_article$key = {
  readonly " $data"?: StaticArticleCard_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"StaticArticleCard_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaticArticleCard_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "driveDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voteCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chatRoomCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modelYear",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "carData_getCarFullName_carData",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "manufacturer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupModel",
              "kind": "LinkedField",
              "name": "autoupModel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoupCompany",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getMergedImages_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "74fd463e8104246e38144aec5dbe34ff";

export default node;
