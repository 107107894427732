import type { ValueOf } from '@/types/global';

export const ArticleStatus = {
  None: 'NONE',
  OnSale: 'ON_SALE',
  Reserved: 'RESERVED',
  Traded: 'TRADED',
} as const;

export const ArticleStatusText = {
  [ArticleStatus.None]: '',
  [ArticleStatus.OnSale]: '판매중',
  [ArticleStatus.Reserved]: '예약중',
  [ArticleStatus.Traded]: '거래완료',
};
export type ArticleStatus = ValueOf<typeof ArticleStatus>;
