/**
 * @generated SignedSource<<b9cee9dc9984d70d87facc8a13b9e4dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleNoticeSection_article$data = {
  readonly isReviewed: boolean;
  readonly resaleOriginArticle: {
    readonly " $fragmentSpreads": FragmentRefs<"ResaledArticleBanner_sanitizedArticle">;
  } | null | undefined;
  readonly " $fragmentType": "ArticleNoticeSection_article";
};
export type ArticleNoticeSection_article$key = {
  readonly " $data"?: ArticleNoticeSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleNoticeSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleNoticeSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReviewed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SanitizedArticle",
      "kind": "LinkedField",
      "name": "resaleOriginArticle",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ResaledArticleBanner_sanitizedArticle"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "5bf72e9269a9be89970d22089fd45a42";

export default node;
