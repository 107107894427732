import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconArrowThin } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';

import { getSizeProps } from '@/utils/style';

import Car from './icon/Car';

type Props = {
  onClick: () => void;
};

const MainShortcutBanner: React.FC<React.PropsWithChildren<Props>> = ({ onClick, ...props }) => {
  return (
    <Container onClick={onClick} {...props}>
      <Car height={18} width={18} />
      <Text>
        <b>중고차 직거래</b> 바로가기
      </Text>
      <IconArrowThin {...getSizeProps(16)} color={vars.$scale.color.gray900} />
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${rem(10)} 0;
  background: ${vars.$semantic.color.paperDefault};
`;

const Text = styled.p`
  ${vars.$semantic.typography.bodyL2Regular}
  color: ${vars.$scale.color.gray900};
  margin-left: ${rem(2)};
  margin-right: ${rem(4)};

  & > b {
    ${vars.$semantic.typography.bodyL2Bold}
  }
`;

export default MainShortcutBanner;
