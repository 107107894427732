import { MenuFab, MenuFabItem, MenuFabItemGroup } from '@daangn/sprout-components-fab';
import '@daangn/sprout-components-fab/index.css';
import styled from '@emotion/styled';
import { IconCameraRegular, IconMobileRegular } from '@seed-design/icon';
import { TouchEventHandler, useEffect, useRef, useState } from 'react';

import useSimulateBigFont from '@/hooks/useSimulateBigFont';
import { useFlow } from '@/stackflow';
import { clamp } from '@/utils/number';
import { useStorageState } from '@/utils/storage';
import { getSizeProps } from '@/utils/style';

const zIndex = 9999;
const buttonWidth = 42;
const buttonHeight = 42;
const SAFE_AREA_BUFFER = 32;
const defaultPosition = () => ({
  left: 0,
  top: window.innerHeight - buttonHeight,
});

const SAVED_POSITION_KEY = 'devLaboratoryButtonPosition';

const DevLaboratoryButton = () => {
  const divRef = useRef<HTMLDivElement>(null);
  useSimulateBigFont();
  const { push } = useFlow();
  const [{ x: shiftX, y: shiftY }, setShift] = useState({ x: 0, y: 0 });
  const [{ left, top }, setPosition] = useStorageState(SAVED_POSITION_KEY, {
    defaultValue: defaultPosition(),
  });
  const handleClick = () => {
    push('dev_laboratory', {});
  };
  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    if (e.touches.length > 1) {
      e.preventDefault();
      return;
    }
    if (!divRef.current) {
      return;
    }
    const { left, top } = divRef.current.getBoundingClientRect();
    const { clientX, clientY } = e.touches[0];
    setShift({ x: clientX - left, y: clientY - top });
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    if (e.touches.length > 1) {
      e.preventDefault();
      return;
    }
    if (!divRef.current) {
      return;
    }
    const { clientX, clientY } = e.touches[0];
    const minX = 0;
    const maxX = window.innerWidth - buttonWidth;
    const minY = 0;
    const maxY = window.innerHeight - buttonHeight - SAFE_AREA_BUFFER;

    setPosition({
      left: clamp(clientX - shiftX, minX, maxX),
      top: clamp(clientY - shiftY, minY, maxY),
    });
  };

  const handleTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
    setShift({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (left > window.innerWidth || top > window.innerHeight) {
      setPosition(defaultPosition());
    }
  }, [left, setPosition, top]);

  return (
    <DndButton
      onDragStart={() => false}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      ref={divRef}
      style={{ left, top }}
    >
      <MenuFab
        height={buttonHeight}
        isExtended={false}
        label="디버깅"
        onAction={(id) => {
          if (id === 'vConsole') {
            requestIdleCallback(() => {
              (
                document.getElementsByClassName('vc-switch')?.[0] as HTMLButtonElement | undefined
              )?.click();
            });
          }
          if (id === 'laboratory') {
            handleClick();
          }
        }}
        width={buttonWidth}
      >
        <MenuFabItemGroup>
          <MenuFabItem icon={<IconMobileRegular {...getSizeProps(20)} />} id="vConsole">
            vConsole
          </MenuFabItem>
          <MenuFabItem icon={<IconCameraRegular {...getSizeProps(20)} />} id="laboratory">
            개발 실험실
          </MenuFabItem>
        </MenuFabItemGroup>
      </MenuFab>
    </DndButton>
  );
};

const DndButton = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: ${zIndex};
`;

export default DevLaboratoryButton;
