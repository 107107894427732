import { useHistoryTick } from '@stackflow/plugin-history-sync';
import { useCallback } from 'react';

const useRedirectAppScheme = () => {
  const { requestHistoryTick } = useHistoryTick();
  const redirect = useCallback(
    (url: string) => {
      requestHistoryTick((next) => {
        window.location.href = url;
        return next();
      });
    },
    [requestHistoryTick]
  );

  return redirect;
};

export default useRedirectAppScheme;
