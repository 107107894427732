import { PromotionPricing } from '@/__generated__/PromotionPaymentLayerQuery.graphql';

export const getViews = (type: PromotionPricing) => {
  const viewMap: Record<PromotionPricing, number> = {
    CLICK_250: 250,
    CLICK_600: 600,
    CLICK_1000: 1000,
  };

  return viewMap[type];
};
