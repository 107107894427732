import { graphql, useFragment } from 'react-relay';

import { useNotificationCreatable_article$key } from '@/__generated__/useNotificationCreatable_article.graphql';
import { useNotificationCreatable_query$key } from '@/__generated__/useNotificationCreatable_query.graphql';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';
import { Nullish } from '@/types/global';

const useNotificationCreatable = ({
  queryRef,
  articleRef,
}: {
  articleRef: Nullish<useNotificationCreatable_article$key>;
  queryRef: useNotificationCreatable_query$key;
}) => {
  const { notifications } = useFragment(
    graphql`
      fragment useNotificationCreatable_query on Query {
        notifications: newArticleNotificationConditions {
          id
          subseries {
            id
          }
        }
      }
    `,
    queryRef
  );
  const { carData } = useFragment(
    graphql`
      fragment useNotificationCreatable_article on Article {
        carData {
          autoupModel {
            subseries {
              id
            }
          }
        }
      }
    `,
    articleRef
  ) ?? { carData: { autoupModel: { subseries: { id: null } } } };

  const { autoupModel } = carData;
  const subseriesId = autoupModel?.subseries?.id;
  const isNotificationCreatable =
    !!subseriesId &&
    (!notifications ||
      (notifications.length < MAX_NOTIFICATION_COUNT &&
        notifications.every((notification) =>
          notification.subseries?.every((ss) => ss.id !== subseriesId)
        )));

  return isNotificationCreatable;
};

export default useNotificationCreatable;
