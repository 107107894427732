/**
 * @generated SignedSource<<1294ede1275294d97e8b6f9d089610e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FromWhere = "FEED" | "HOT" | "UNKNOWN";
export type useIncreaseViewCount_IncreaseViewCountMutation$variables = {
  from?: FromWhere | null | undefined;
  id: string;
};
export type useIncreaseViewCount_IncreaseViewCountMutation$data = {
  readonly increaseArticleViewCount: {
    readonly viewCount: number;
  };
};
export type useIncreaseViewCount_IncreaseViewCountMutation = {
  response: useIncreaseViewCount_IncreaseViewCountMutation$data;
  variables: useIncreaseViewCount_IncreaseViewCountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "articleId",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "fromWhere",
    "variableName": "from"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useIncreaseViewCount_IncreaseViewCountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "increaseArticleViewCount",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useIncreaseViewCount_IncreaseViewCountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "increaseArticleViewCount",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e2873a7f701ee4466c794abe74466c3",
    "id": null,
    "metadata": {},
    "name": "useIncreaseViewCount_IncreaseViewCountMutation",
    "operationKind": "mutation",
    "text": "mutation useIncreaseViewCount_IncreaseViewCountMutation(\n  $id: String!\n  $from: FromWhere\n) {\n  increaseArticleViewCount(articleId: $id, fromWhere: $from) {\n    viewCount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "96a0613c444c2423361db13175d8991d";

export default node;
