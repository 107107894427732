/**
 * @generated SignedSource<<22b7afb1a9da070b95459205b151b8a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type Article_article$data = {
  readonly chatRoomCount: number;
  readonly id: string;
  readonly isHide: boolean;
  readonly leaseInfo: {
    readonly contractStartedAt: any;
  } | null | undefined;
  readonly price: number | null | undefined;
  readonly rentalInfo: {
    readonly contractStartedAt: any;
  } | null | undefined;
  readonly status: ArticleStatus;
  readonly user: {
    readonly id: string;
  };
  readonly viewCount: number;
  readonly voteCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleAdvertisementSection_article" | "ArticleCarHistorySection_article" | "ArticleCarInfoSection_article" | "ArticleExpenseSection_article" | "ArticleImageSection_article" | "ArticleInspectionSection_article" | "ArticleLeaseRentalSection_article" | "ArticleLocationSection_article" | "ArticleMetaInfoSection_article" | "ArticleNoticeSection_article" | "ArticleOptionsSection_article" | "ArticlePriceSection_article" | "ArticleProxyInspectionSection_article" | "ArticleStatusChangeSection_article" | "ArticleStorySection_article" | "ArticleTitleSection_article" | "ArticleWarrantySection_article" | "Footer_article" | "Navigation_article" | "NewArticleNotificationSection_article" | "User_article" | "getArticleEventProperties_article" | "getPublishedAtText_article" | "isInspectedCar_article" | "useIsWarrantable_article" | "useNotificationCreatable_article">;
  readonly " $fragmentType": "Article_article";
};
export type Article_article$key = {
  readonly " $data"?: Article_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"Article_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chatRoomCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voteCount",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contractStartedAt",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineDataFragmentSpread",
  "name": "isInspectedCar_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Inspection",
          "kind": "LinkedField",
          "name": "inspection",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isMechanicReportVisible",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Article_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHide",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseInfo",
      "kind": "LinkedField",
      "name": "leaseInfo",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentalInfo",
      "kind": "LinkedField",
      "name": "rentalInfo",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleImageSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleStatusChangeSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleNoticeSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleTitleSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleExpenseSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLeaseRentalSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleMetaInfoSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleAdvertisementSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCarInfoSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewArticleNotificationSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleProxyInspectionSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePriceSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleOptionsSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleStorySection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleInspectionSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleWarrantySection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCarHistorySection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLocationSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Navigation_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "User_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNotificationCreatable_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getPublishedAtText_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pullUpCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedAt",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getArticleEventProperties_article",
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "driveDistance",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatImage",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carData_getCarDataEventProperties",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displacement",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "modelYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fuelType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "options",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accidentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carName",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isWarrantable",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v7/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v7/*: any*/)
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "8083b8f2ea309153cd0e7785e2645232";

export default node;
