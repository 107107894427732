import { atomWithCarStorage, atomWithCommonStorage } from '@/utils/jotai';

export const bigFontState = atomWithCarStorage('BigFontState', false);

export const DEFAULT_CPL = 16;

export const bigFontCplState = atomWithCarStorage('BigFontCpl', DEFAULT_CPL);

export type TestUserInfo = {
  authToken: string;
  id: number;
  production: boolean;
  selected: boolean;
};

export const testUserInfoState = atomWithCommonStorage<TestUserInfo[]>('testUserInfo', []);
