import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { CompanyIcon_autoupCompany$key } from '@/__generated__/CompanyIcon_autoupCompany.graphql';

import Car from './icon/Car';

type Props = {
  active: boolean;
  companyRef: CompanyIcon_autoupCompany$key;
  size?: number;
};

const IMAGE_WIDTH_RATIO = 5 / 6;
const IMAGE_HEIGHT_RATIO = 5 / 9;
const DEFAULT_SIZE = 64;

const CompanyIcon: React.FC<React.PropsWithChildren<Props>> = ({
  companyRef,
  size = DEFAULT_SIZE,
  ...props
}) => {
  const { imgUrl } = useFragment(
    graphql`
      fragment CompanyIcon_autoupCompany on AutoupCompany {
        imgUrl
      }
    `,
    companyRef
  );

  return (
    <Container {...props} size={size}>
      {imgUrl ? (
        <CompanyImage size={size} src={imgUrl} />
      ) : (
        <Car
          fill={vars.$scale.color.gray300}
          height={size * IMAGE_HEIGHT_RATIO}
          width={size * IMAGE_HEIGHT_RATIO}
        />
      )}
    </Container>
  );
};

const Container = styled.div<Pick<Props, 'active' | 'size'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f8fa;
  border: 2px solid ${({ active }) => (active ? vars.$semantic.color.accent : '#f7f8fa')};
  ${({ size }) => {
    const sizeInRem = rem(size ?? DEFAULT_SIZE);

    return css`
      min-width: ${sizeInRem};
      min-height: ${sizeInRem};
      border-radius: ${sizeInRem};
    `;
  }}
`;

const CompanyImage = styled.img<Pick<Props, 'size'>>`
  ${({ size }) => {
    const width = (size ?? DEFAULT_SIZE) * IMAGE_WIDTH_RATIO;
    const height = (size ?? DEFAULT_SIZE) * IMAGE_HEIGHT_RATIO;

    return css`
      width: ${rem(width)};
      height: ${rem(height)};
    `;
  }}
`;

export default CompanyIcon;
