import { graphql, readInlineData } from 'relay-runtime';

import { getMergedImages_article$key } from '@/__generated__/getMergedImages_article.graphql';

import { getSortedImages } from './getSortedImages';

export const getMergedImages = (articleRef: getMergedImages_article$key) => {
  const { images } = readInlineData(
    graphql`
      fragment getMergedImages_article on Article @inline {
        images {
          type
          images {
            id
            url
          }
        }
      }
    `,
    articleRef
  );

  return getSortedImages(images);
};
