import { Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { useEffect, useRef } from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

interface Props {
  onLoad?: () => void;
}

const LoadingBox: React.FC<React.PropsWithChildren<Props>> = ({ onLoad, ...props }) => {
  const $container = useRef(null);
  const isIntersecting = useIntersectionObserver($container);

  useEffect(() => {
    isIntersecting && onLoad?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return (
    <Container ref={$container} {...props}>
      <Spinner />
    </Container>
  );
};

export default LoadingBox;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(32)};
  width: 100%;
`;
