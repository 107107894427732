import { graphql } from 'react-relay';

import { useDeleteArticle_DeleteArticleMutation } from '@/__generated__/useDeleteArticle_DeleteArticleMutation.graphql';
import useMutationAsync from '@/hooks/useMutationAsync';
import { useToast } from '@/hooks/useToast';
import getDataID from '@/relay/getDataID';
import { getMessageFromError } from '@/utils/relay';

const useDeleteArticle = () => {
  const [deleteMutation, isDeleteInFlight] =
    useMutationAsync<useDeleteArticle_DeleteArticleMutation>(graphql`
      mutation useDeleteArticle_DeleteArticleMutation($input: DeleteArticleInput!) {
        result: deleteArticle(input: $input)
      }
    `);
  const { setToast } = useToast();

  const deleteArticle = async (id: string) => {
    try {
      const { result } = await deleteMutation({
        variables: { input: { articleId: id } },
        updater(store) {
          const dataID = getDataID({ id }, 'Article');
          const articleRecord = store.get(dataID);

          articleRecord?.setValue(new Date().toISOString(), 'deletedAt');
        },
      });

      if (result) {
        setToast('게시물이 삭제되었어요.');
      }
      return result;
    } catch (e: any) {
      const message = getMessageFromError(e);
      if (message) {
        setToast(message);
      }
    }
  };

  return { deleteArticle, isDeleteInFlight };
};

export default useDeleteArticle;
