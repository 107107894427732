import IconHyundaiCapital from '@/assets/images/loan/hyundai_capital.png';

export const loanList = [
  {
    id: 'hcs' as const,
    title: '현대캐피탈 중고차론(온라인)',
    interestRate: '3.9%~',
    description: '직장인 0.2% 추가할인! 안심점검 서비스까지',
    limit: '1억',
    icon: IconHyundaiCapital,
    link: '',
  },
];
