import { Divider } from '@daangn/sprout-components-divider';
import { vars } from '@seed-design/design-token';
import { IconExpandLessRegular, IconExpandMoreRegular, IconInfoThin } from '@seed-design/icon';
import { useToggleState } from '@toss/react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleExpenseSection_article$key } from '@/__generated__/ArticleExpenseSection_article.graphql';
import { ArticleExpenseSection_user$key } from '@/__generated__/ArticleExpenseSection_user.graphql';
import Box, { getSeedColor } from '@/components/Base/Box';
import ButtonDiv from '@/components/Base/ButtonDiv';
import { ColoredSpan } from '@/components/Base/ColoredText';
import HStack from '@/components/Base/HStack';
import {
  Caption1Bold,
  Caption2Bold,
  Caption2Regular,
  Label3Bold,
  Label3Regular,
  Title3Bold,
} from '@/components/Base/Text';
import Tooltip from '@/components/Base/Tooltip';
import VStack from '@/components/Base/VStack';
import withDefaultProps from '@/hocs/withDefaultProps';
import useArticleStep from '@/hooks/useArticleStep';
import useIsWarrantable from '@/hooks/useIsWarrantable';
import useTrack from '@/hooks/useTrack';
import { useWarrantyPrice } from '@/hooks/useWarrantyPrice';
import { CarType } from '@/types/CarData/CarType';
import { FuelType } from '@/types/CarData/FuelType';
import { Nullish } from '@/types/global';
import {
  매도비,
  매매알선_수수료율,
  자동차_변경_등록비,
  최소_매매알선_수수료,
} from '@/utils/article/article';
import { getAcquisitionTax } from '@/utils/article/getAcquisitionTax';
import { getPublicBondPurchaseExpense } from '@/utils/article/getPublicBondPurchaseExpense';
import { NUM_1만, formatFloat, getBillionFormat, getCommaFormat } from '@/utils/number';
import { getSizeProps } from '@/utils/style';

import InsuranceBottomSheet from './InsuranceBottomSheet';
import LoanBottomSheet from './LoanBottomSheet';

type Props = {
  articleRef: ArticleExpenseSection_article$key;
  userRef: Nullish<ArticleExpenseSection_user$key>;
};

const ArticleExpenseSection: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  userRef,
}) => {
  const { stepPush } = useArticleStep();
  const { track } = useTrack();
  const [isToggled, toggle] = useToggleState(false);
  const article = useFragment(
    graphql`
      fragment ArticleExpenseSection_article on Article {
        price
        region {
          id
          name1Id
        }
        carData {
          carType
          fuelType
          displacement
        }
        insuranceTrackingData {
          samsung {
            url
          }
          kb {
            url
          }
          db {
            url
          }
          carrot {
            url
          }
        }
        loanTrackingUrl {
          hcs
        }
        ...useIsWarrantable_article
        ...InsuranceBottomSheet_article
        ...LoanBottomSheet_article
      }
    `,
    articleRef
  );
  const { price, carData, insuranceTrackingData, loanTrackingUrl } = article;
  const { isWarrantable } = useIsWarrantable(article);
  const { warrantyFee } = useWarrantyPrice();
  const user = useFragment(
    graphql`
      fragment ArticleExpenseSection_user on User {
        region {
          name1Id
        }
      }
    `,
    userRef
  );

  const region1Id = user?.region?.name1Id ?? article.region?.name1Id;
  const originalPrice = (price ?? 0) * NUM_1만;
  const acquisitionTax = getAcquisitionTax({
    price: originalPrice,
    carType: carData.carType as CarType,
    fuelType: carData.fuelType as FuelType,
    displacement: carData.displacement ?? 0,
  });
  const publicBondPurchaseExpense = getPublicBondPurchaseExpense({
    price: originalPrice,
    fuelType: carData.fuelType as FuelType,
    displacement: carData.displacement ?? 0,
    region1Id,
  });
  const totalExpense = acquisitionTax.amount + publicBondPurchaseExpense + 자동차_변경_등록비;
  const 매매알선_수수료 = Math.max(
    Math.floor(originalPrice * 매매알선_수수료율),
    최소_매매알선_수수료
  );
  const totalDiscountedExpense = Math.floor(매도비 + 매매알선_수수료);

  const isInsuranceAvailable = Object.values(insuranceTrackingData).filter(Boolean).length > 0;
  const isLoanAvailable =
    Object.values(loanTrackingUrl).filter((url) => !!url || url === '').length > 0;

  const getLogProps = (name: 'incidental_expense_dealer' | 'incidental_expense_total') => {
    return {
      afterShow: () => {
        track(`article_click_${name}_show`);
      },
      afterHide: () => {
        track(`article_click_${name}_hide`);
      },
    };
  };

  return (
    <>
      <Title3Bold mb={4}>{getCommaFormat(price ?? 0)}만원</Title3Bold>
      <Label3Regular color="gray700" data-tooltip-id="article_incidental_expenses_discounted_cost">
        <HStack align="center" gap={2}>
          <span>
            직거래로 약{' '}
            <ColoredSpan color="success">{getBillionFormat(totalDiscountedExpense)}원</ColoredSpan>{' '}
            아껴요
          </span>
          <IconInfoThinSmall />
        </HStack>
      </Label3Regular>
      <Tooltip
        id="article_incidental_expenses_discounted_cost"
        width={246}
        {...getLogProps('incidental_expense_dealer')}
      >
        <VStack divider={<Divider />} gap={16}>
          <Caption1Bold color="gray00">딜러를 통해 구매하면 아래 비용이 발생해요</Caption1Bold>
          <VStack gap={8}>
            {[
              { label: '딜러 매도비', value: `${getCommaFormat(매도비)}원` },
              {
                label: `딜러 매매알선 수수료(${formatFloat(매매알선_수수료율 * 100)}%)`,
                value: `${getCommaFormat(매매알선_수수료)}원`,
              },
              {
                label: '딜러 판매가 - 직거래 판매가',
                value: '준비중',
                disabled: true,
              },
            ].map(({ label, value, disabled }) => (
              <HStack justify="space-between" key={label}>
                <DetailText>{label}</DetailText>
                <DetailValueText color={disabled ? 'gray500' : 'gray00'}>{value}</DetailValueText>
              </HStack>
            ))}
          </VStack>
        </VStack>
      </Tooltip>
      <VStack>
        <VStack
          bc="divider3"
          br={6}
          divider={<Divider UNSAFE_style={{ backgroundColor: getSeedColor('divider3') }} />}
          gap={1}
          mt={16}
        >
          <Box px={16} py={16}>
            <VStack>
              <HStack justify="space-between">
                <Label3Bold color="gray700">총 구매 비용</Label3Bold>
                <ButtonDiv onClick={toggle}>
                  <HStack align="center" gap={4}>
                    <Label3Bold>
                      {getCommaFormat(
                        originalPrice + totalExpense + (isWarrantable ? warrantyFee : 0)
                      )}
                      원
                    </Label3Bold>
                    {isToggled ? (
                      <IconExpandLessRegular {...getSizeProps(14)} />
                    ) : (
                      <IconExpandMoreRegular {...getSizeProps(14)} />
                    )}
                  </HStack>
                </ButtonDiv>
              </HStack>
              {isToggled ? (
                <>
                  <VStack bg="paperContents" br={6} gap={14} mt={12} px={16} py={14}>
                    <HStack justify="space-between">
                      <Label3Regular color="gray700">차량 가격</Label3Regular>
                      <Label3Regular color="gray900">
                        {getCommaFormat(originalPrice)}원
                      </Label3Regular>
                    </HStack>
                    {isWarrantable && (
                      <HStack justify="space-between">
                        <Label3Regular color="gray700">진단보증 패키지</Label3Regular>
                        <Label3Regular color="gray900">
                          {getCommaFormat(warrantyFee)}원
                        </Label3Regular>
                      </HStack>
                    )}
                    <HStack justify="space-between">
                      <Label3Regular
                        color="gray700"
                        data-tooltip-id="article_incidental_expenses_total_cost"
                      >
                        <HStack align="center" gap={2}>
                          <span>이전 등록비</span>
                          <IconInfoThinSmall />
                        </HStack>
                      </Label3Regular>
                      <Label3Regular color="gray900">
                        {getCommaFormat(totalExpense)}원
                      </Label3Regular>
                    </HStack>
                  </VStack>
                  <Tooltip
                    id="article_incidental_expenses_total_cost"
                    width={246}
                    {...getLogProps('incidental_expense_total')}
                  >
                    <VStack gap={8}>
                      {[
                        {
                          label: `취득세(${Math.floor(acquisitionTax.taxRate * 100)}%)`,
                          value: `${getCommaFormat(acquisitionTax.originalAmount)}원`,
                        },
                        ...(acquisitionTax.deduction
                          ? [
                              {
                                label: '취득세 공제',
                                value: `-${getCommaFormat(acquisitionTax.deduction)}원`,
                              },
                            ]
                          : []),
                        {
                          label: '공채 할인',
                          value: `${getCommaFormat(publicBondPurchaseExpense)}원`,
                        },
                        {
                          label: '자동차 변경 등록비',
                          value: `${getCommaFormat(자동차_변경_등록비)}원`,
                        },
                      ].map(({ label, value }) => (
                        <HStack justify="space-between" key={label}>
                          <DetailText>{label}</DetailText>
                          <DetailValueText>{value}</DetailValueText>
                        </HStack>
                      ))}
                    </VStack>
                  </Tooltip>
                </>
              ) : isWarrantable ? (
                <Caption2Regular color="gray600" mt={4}>
                  진단보증 패키지 포함
                </Caption2Regular>
              ) : null}
            </VStack>
          </Box>
          {isInsuranceAvailable && (
            <VStack gap={14} px={16} py={16}>
              {isInsuranceAvailable && (
                <HStack justify="space-between">
                  <Label3Regular color="gray700">보험료</Label3Regular>
                  <ButtonDiv onClick={() => stepPush({ state: 'insurance' })}>
                    <Label3Regular color="gray600">
                      <u>조회하기</u>
                    </Label3Regular>
                  </ButtonDiv>
                </HStack>
              )}
              {isLoanAvailable && (
                <HStack justify="space-between">
                  <Label3Regular color="gray700">대출한도</Label3Regular>
                  <ButtonDiv onClick={() => stepPush({ state: 'loan' })}>
                    <Label3Regular color="gray600">
                      <u>조회하기</u>
                    </Label3Regular>
                  </ButtonDiv>
                </HStack>
              )}
            </VStack>
          )}
        </VStack>
      </VStack>
      <InsuranceBottomSheet articleRef={article} />
      <LoanBottomSheet articleRef={article} />
    </>
  );
};

const DetailText = withDefaultProps(Caption2Regular, {
  color: 'gray00',
});

const DetailValueText = withDefaultProps(Caption2Bold, {
  color: 'gray00',
});

const IconInfoThinSmall = withDefaultProps(IconInfoThin, {
  ...getSizeProps(14),
  color: vars.$scale.color.gray700,
});

export default ArticleExpenseSection;
