import { addDays, endOfDay, isAfter } from 'date-fns';
import { graphql, readInlineData } from 'relay-runtime';

import { getWarrantyDeadline_inspection$key } from '@/__generated__/getWarrantyDeadline_inspection.graphql';
import { assertNonNullish } from '@/types/Assert/assertNonNullish';

export const SAMSUNG_INSURANCE_HARD_DEADLINE_08_30 = new Date(1725116399999);

export const getWarrantyDeadline = (
  inspectionRef: getWarrantyDeadline_inspection$key,
  hardDeadline?: boolean
) => {
  const { fixedDate } = readInlineData(
    graphql`
      fragment getWarrantyDeadline_inspection on Inspection @inline {
        fixedDate
      }
    `,
    inspectionRef
  );

  assertNonNullish(fixedDate, '진단 날짜가 존재하지 않아요.');
  const after30Days = endOfDay(addDays(new Date(fixedDate), 30));
  const deadlineDate = hardDeadline
    ? SAMSUNG_INSURANCE_HARD_DEADLINE_08_30
    : isAfter(after30Days, SAMSUNG_INSURANCE_HARD_DEADLINE_08_30)
      ? SAMSUNG_INSURANCE_HARD_DEADLINE_08_30
      : after30Days;
  const isOverDeadline = isAfter(Date.now(), deadlineDate);
  return {
    isOverDeadline,
    deadlineDate,
  };
};
