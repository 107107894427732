import { objectValues } from '@toss/utils';

import type { ValueOf } from '@/types/global';

export const InExteriorOption = {
  LEATHER_SEAT: 'LEATHER_SEAT',
  POWER_SEAT: 'POWER_SEAT',
  VENTILATED_SEAT: 'VENTILATED_SEAT',
  HEATED_SEAT: 'HEATED_SEAT',
  HEATED_STEERING_WHEEL: 'HEATED_STEERING_WHEEL',
  SUNROOF: 'SUNROOF',
  PANORAMA_SUNROOF: 'PANORAMA_SUNROOF',
  HEADLAMP: 'HEADLAMP',
  AUTO_TRUNK: 'AUTO_TRUNK',
  AUTO_FOLDING_SIDE_MIRROR: 'AUTO_FOLDING_SIDE_MIRROR',
  HIPASS: 'HIPASS',
} as const;
export const InExteriorOptionNames = objectValues(InExteriorOption);
export type InExteriorOption = ValueOf<typeof InExteriorOption>;
