import { rem } from 'polished';
import React from 'react';

const Manner2: React.FC<React.PropsWithChildren<unknown>> = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(24)}
      viewBox="0 0 24 24"
      width={rem(24)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        clipRule="evenodd"
        d="M8.02382 7.16883C8.04102 7.37522 7.88765 7.55648 7.68126 7.57368L5.88126 7.72368C5.67487 7.74088 5.49361 7.58751 5.47641 7.38111C5.45921 7.17472 5.61259 6.99347 5.81898 6.97627L7.61898 6.82627C7.82537 6.80907 8.00662 6.96244 8.02382 7.16883Z"
        fill="#D49D3A"
        fillRule="evenodd"
        opacity="0.85"
      />
      <path
        clipRule="evenodd"
        d="M15.8263 7.16883C15.8435 6.96244 16.0247 6.80907 16.2311 6.82627L18.0311 6.97627C18.2375 6.99347 18.3909 7.17472 18.3737 7.38111C18.3565 7.58751 18.1752 7.74088 17.9688 7.72368L16.1688 7.57368C15.9624 7.55648 15.8091 7.37522 15.8263 7.16883Z"
        fill="#D49D3A"
        fillRule="evenodd"
        opacity="0.85"
      />
      <path
        d="M8.85005 11.3999C8.85005 12.5999 8.25005 13.4999 7.65005 13.4999C7.05005 13.4999 6.30005 12.5999 6.30005 11.3999"
        fill="#895F00"
      />
      <path
        clipRule="evenodd"
        d="M5.5874 11.3999C5.5874 11.0892 5.83924 10.8374 6.1499 10.8374H8.9999C9.31056 10.8374 9.5624 11.0892 9.5624 11.3999C9.5624 11.7106 9.31056 11.9624 8.9999 11.9624H6.1499C5.83924 11.9624 5.5874 11.7106 5.5874 11.3999Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        d="M17.7 11.3999C17.7 12.5999 17.1 13.4999 16.35 13.4999C15.6 13.4999 15 12.5999 15 11.3999"
        fill="#895F00"
      />
      <path
        clipRule="evenodd"
        d="M14.2876 11.3999C14.2876 11.0892 14.5394 10.8374 14.8501 10.8374H17.8501C18.1608 10.8374 18.4126 11.0892 18.4126 11.3999C18.4126 11.7106 18.1608 11.9624 17.8501 11.9624H14.8501C14.5394 11.9624 14.2876 11.7106 14.2876 11.3999Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        d="M9.4799 17.1C10.0386 16.8876 10.8666 16.6875 11.9983 16.6875C13.13 16.6875 13.958 16.8876 14.5167 17.1C14.7953 17.2059 15.005 17.3142 15.1505 17.4005C15.2232 17.4436 15.2797 17.4811 15.3206 17.5101C15.599 17.7066 15.7059 18.0806 15.4703 18.3601C15.2706 18.597 14.9155 18.6305 14.6742 18.4364C14.6742 18.4364 14.6674 18.4314 14.6591 18.4255C14.642 18.4134 14.6108 18.3924 14.5649 18.3651C14.4733 18.3108 14.3226 18.2316 14.1081 18.15C13.6805 17.9874 12.991 17.8125 11.9983 17.8125C11.3151 17.8125 10.7755 17.8953 10.3661 18C10.1806 18.0474 10.0218 18.0993 9.88844 18.15C9.67393 18.2316 9.52326 18.3108 9.43159 18.3652C9.38571 18.3924 9.3545 18.4134 9.33738 18.4255C9.32908 18.4314 9.32233 18.4365 9.32233 18.4365C9.08097 18.6305 8.72592 18.597 8.52621 18.3601C8.29521 18.0861 8.40226 17.7032 8.6759 17.51C8.71687 17.4811 8.77339 17.4436 8.84606 17.4005C8.99151 17.3142 9.20123 17.2059 9.4799 17.1Z"
        fill="#895F00"
      />
    </svg>
  );
};

export default Manner2;
