import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';

import RandomAdBanner, { AdItem } from '@/components/Banner/RandomAdBanner';
import Box from '@/components/Base/Box';
import ScheduledDisplay from '@/components/Base/ScheduledDisplay';

type Ad = {
  adOwner: string;
  end: Date;
  items: AdItem[];
  start: Date;
};

// 기간이 서로 겹치지 않아야 함
const ads: Ad[] = [
  {
    adOwner: '캐롯자동차보험',
    start: startOfDay(new Date('2024-06-14')),
    end: endOfDay(new Date('2024-06-20')),
    items: [
      {
        imageUrl:
          'https://assetstorage.krrt.io/1030025633538651406/2a9557d0-b672-4271-a0e7-139aa7849d74/width=1074,height=456.png',
        link: 'https://www.carrotins.com/mobile/calculation/car/personal/intro/?utm_campaign=car&utm_source=daangn_feed&utm_medium=display&utm_content=purchase_yj_dontforget&utm_term=tf_usedcar&afccd=PA00656&afcDtcd=tf_usedcar&afcDtFlgcd=purchase_yj_dontforget&main=y',
      },
      {
        imageUrl:
          'https://assetstorage.krrt.io/1030025633538651406/9545c40c-f89c-429a-928c-fad4b57f44e9/width=1074,height=456.png',
        link: 'https://www.carrotins.com/mobile/calculation/car/personal/intro/?utm_campaign=car&utm_source=daangn_feed&utm_medium=display&utm_content=rejoin_yj_91.1&utm_term=tf_usedcar&afccd=PA00656&afcDtcd=tf_usedcar&afcDtFlgcd=rejoin_yj_91.1&main=y',
      },
    ],
  },
];

const FeedAdSection: React.FC = () => {
  if (!ads.length) {
    return null;
  }
  const minStart = new Date(Math.min(...ads.map((ad) => ad.start.getTime())));
  const maxEnd = new Date(Math.max(...ads.map((ad) => ad.end.getTime())));

  return (
    <ScheduledDisplay end={maxEnd} start={minStart}>
      <Box mx={16} my={16}>
        {ads.map((ad) => (
          <ScheduledDisplay end={ad.end} key={ad.adOwner} start={ad.start}>
            <RandomAdBanner adOwner={ad.adOwner} items={ad.items} />
          </ScheduledDisplay>
        ))}
      </Box>
    </ScheduledDisplay>
  );
};

export default FeedAdSection;
