import { vars } from '@seed-design/design-token';
import { PropsWithChildren } from 'react';

import HStack from '@/components/Base/HStack';
import Text from '@/components/Base/Text';
import { getSizeProps } from '@/utils/style';

import TouchResponsiveButton from './TouchResponsiveButton';

type Icon = React.ForwardRefExoticComponent<
  Omit<
    {
      size?: number | string | undefined;
    } & React.SVGProps<SVGSVGElement>,
    'ref'
  > &
    React.RefAttributes<SVGSVGElement>
>;
type Props = {
  icon: Icon;
  onClick: () => void;
};

const DrawerIconButton: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  icon: Icon,
  children,
  ...props
}) => {
  return (
    <TouchResponsiveButton onClick={onClick} {...props}>
      <HStack gap={6}>
        <Icon {...iconProps} />
        <Text color="gray900" variant="label2Regular">
          {children}
        </Text>
      </HStack>
    </TouchResponsiveButton>
  );
};

const iconProps = {
  ...getSizeProps(20),
  color: vars.$scale.color.gray900,
};

export default DrawerIconButton;
