/**
 * @generated SignedSource<<0fe3bf30d31268d20a1271959a89d14e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getArticleEventProperties_article$data = {
  readonly carData: {
    readonly isWarrantable: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"carData_getCarDataEventProperties">;
  };
  readonly chatRoomCount: number;
  readonly content: string;
  readonly driveDistance: number;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly images: ReadonlyArray<{
      readonly id: string;
    }>;
  }>;
  readonly price: number | null | undefined;
  readonly voteCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"isInspectedCar_article">;
  readonly " $fragmentType": "getArticleEventProperties_article";
};
export type getArticleEventProperties_article$key = {
  readonly " $data"?: getArticleEventProperties_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getArticleEventProperties_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getArticleEventProperties_article"
};

(node as any).hash = "b97cbbffcd7a4f9ff85295f137a89cc8";

export default node;
