import { isInRange } from './number';

export const getDriveDistanceText = (driveDistance: number): string => {
  let text;

  if (driveDistance < 10000) {
    return driveDistance.toString();
  }
  text = driveDistance / 10000;
  text = driveDistance % 10000 === 0 ? text.toString() : text.toFixed(1);
  text += '만';

  return text;
};

export const maskPhoneNumber = (content: string): string => {
  const MASKING_TEXT = ' ***-****-**** ';
  if (!content) {
    return '';
  }

  return content
    .replace(/공일([공|일|육|칠|팔|구])[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,}/, MASKING_TEXT)
    .replace(/01([0|1|6|7|8|9])(-|.)?([0-9]{3,4})(-|.)?([0-9]{4})/, MASKING_TEXT);
};

export const maskName = (text: string): string => {
  if (!text) {
    return '';
  }
  const name = removeBlank(text);

  if (name.length === 1) {
    return '*';
  }
  if (name.length === 2) {
    return name[0] + '*';
  }
  return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
};

export const maskCarNumber = (carNo: string) => {
  if (!isValidCarNumber(carNo)) {
    return carNo;
  }
  return carNo.slice(0, -2) + '**';
};

export const isValidCarNumber = (carNo: string) => {
  const carNumberRegex = /^\d{2,3}[가-힣]{1}\d{4}$/gm;
  const validRegions = [
    '서울',
    '경기',
    '인천',
    '부산',
    '대구',
    '대전',
    '광주',
    '울산',
    '강원',
    '충북',
    '충남',
    '전북',
    '전남',
    '경북',
    '경남',
    '제주',
  ];
  let trimmed = removeBlank(carNo);

  if (validRegions.some((region) => trimmed.startsWith(region))) {
    const legacyCarNumberRegex = /^\d{1,2}[가-힣]{1}\d{4}$/gm;

    trimmed = trimmed.slice(2);

    if (!isInRange(trimmed.length, 6, 7)) {
      return false;
    }

    return legacyCarNumberRegex.test(trimmed);
  }

  if (!isInRange(trimmed.length, 7, 8)) {
    return false;
  }

  return carNumberRegex.test(trimmed);
};

export const formatCarNumber = (carNo = '') => {
  if (!carNo) {
    return '';
  }

  const length = carNo.length;

  if (length < 7 || length > 8) {
    return carNo;
  }

  return carNo.slice(0, length - 4) + ' ' + carNo.slice(length - 4);
};

export const removeBlank = (value = '') => value.trim().split(' ').join('');

export const toPhoneNumber = (numText: string) => {
  type Entry = [RegExp, string];
  const formatTelByEntries = (val: string, entries: Entry[]) => {
    for (const [regex, replacer] of entries) {
      if (regex.test(val)) {
        return val.replace(regex, replacer);
      }
    }
    return val;
  };

  if (numText.startsWith('02')) {
    return formatTelByEntries(numText, [
      [/(^02)(\d{3})(\d{1,4})$/, '$1-$2-$3'],
      [/(^02)(\d{4})(\d{1,4})$/, '$1-$2-$3'],
      [/(^02)(\d{4})(\d{4})\d+$/, '$1-$2-$3'],
      [/(^02)(\d{1,4})$/, '$1-$2'],
    ]);
  }

  return formatTelByEntries(numText, [
    [/(^\d{3})(\d{3})(\d{1,4})$/, '$1-$2-$3'],
    [/(^\d{3})(\d{4})(\d{1,4})$/, '$1-$2-$3'],
    [/(^\d{3})(\d{4})(\d{4})\d+$/, '$1-$2-$3'],
    [/(^\d{3})(\d{1,4})$/, '$1-$2'],
  ]);
};

export const fromPhoneNumber = (numText: string) => numText.replaceAll('-', '');
