import { ForwardedRef, ImgHTMLAttributes, forwardRef, memo } from 'react';

import { getLowScaleImageSrc } from '@/utils/image';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  lowScaleSrc?: string;
  src?: string;
}

const LowScaleImage = (
  { src, lowScaleSrc, ...props }: Props,
  ref: ForwardedRef<HTMLImageElement>
) => {
  const lowScaleImageSrc = lowScaleSrc ?? getLowScaleImageSrc(src);
  if (!lowScaleImageSrc) {
    return null;
  }

  return <img ref={ref} src={lowScaleImageSrc} {...props} />;
};

export default memo(forwardRef<HTMLImageElement, Props>(LowScaleImage));

LowScaleImage.displayName = 'LowScaleImage';
