import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  PromotionItem_promotionPricings$key,
  PromotionPricing,
} from '@/__generated__/PromotionItem_promotionPricings.graphql';
import { SquareBadge } from '@/components/Base/Badge';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { Caption1Bold, Label3Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { getCommaFormat } from '@/utils/number';
import { getViews } from '@/utils/promotion';

type Props = {
  disabled?: boolean;
  discount: number;
  onClick?: (type: PromotionPricing) => void;
  promotionRef: PromotionItem_promotionPricings$key;
  recommended: boolean;
  selected: boolean;
};

const itemTitle: Record<PromotionPricing, string> = {
  CLICK_250: '베이직',
  CLICK_600: '스탠다드',
  CLICK_1000: '프리미엄',
};

const PromotionItem: React.FC<React.PropsWithChildren<Props>> = ({
  promotionRef,
  selected = false,
  recommended = false,
  disabled = false,
  discount,
  onClick,
  ...props
}) => {
  const promotion = useFragment(
    graphql`
      fragment PromotionItem_promotionPricings on PromotionPricings {
        type
        amount
      }
    `,
    promotionRef
  );
  const { type, amount } = promotion;
  const views = getViews(type);

  const handleClick: MouseEventHandler<HTMLDivElement> = () => {
    if (disabled) {
      return;
    }
    onClick?.(type);
  };

  return (
    <Container
      align="center"
      br={10}
      justify="space-between"
      onClick={handleClick}
      opacity={disabled ? 0.3 : 1}
      px={selected ? 15 : 16}
      py={selected ? 19 : 20}
      selected={selected}
      {...props}
    >
      <VStack>
        <HStack gap={4}>
          <Label3Bold>{itemTitle[type]}</Label3Bold>
          {recommended && (
            <SquareBadge size="medium" style="bold" variant="accent">
              추천
            </SquareBadge>
          )}
        </HStack>
        <HStack gap={4} mt={4}>
          <Caption1Bold color="gray600">조회수 약 {getCommaFormat(views)}회</Caption1Bold>
        </HStack>
      </VStack>
      <VStack align="flex-end">
        {discount > 0 && (
          <Caption1Bold color="gray600" td="line-through">
            {getCommaFormat(amount + discount)}원
          </Caption1Bold>
        )}
        <Label3Bold>{getCommaFormat(amount)}원</Label3Bold>
      </VStack>
    </Container>
  );
};

const Container = styled(HStack)<Pick<Props, 'selected'>>`
  border: ${({ selected }) =>
    selected ? `2px solid ${getSeedColor('gray900')}` : `1px solid ${getSeedColor('divider2')}`};
`;

export default PromotionItem;
