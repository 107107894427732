import { IS_PROD } from '@/constants/config';

export function isCarTeamUserId(id: string): boolean {
  const productionIds = [
    // Chris
    '42144287',
    // Eddy
    '6202335',
    // Wynn
    '12227938',
    // Red
    '44879493',
    // Yohan
    '11051709',
    // Channy
    '5487159',
    // Willy
    '5796',
    // Gemma
    '16375148',
    // Bunny
    '16898760',
    // Mini.choi
    '15789723',
    // Wade.park
    '3186650',
    // Peter
    '13810868',
  ];
  const alphaIds = [
    // Chris
    '5712',
    // Eddy
    '7198',
    // Wynn
    '10277',
    // Red
    '10001047',
    // Yohan
    '10001188',
    // Channy
    '10002386',
    // Willy
    '1354',
    // Gemma
    '10002767',
    // Bunny
    '10002902',
    // Bunny Android
    '10004208',
    // Mini.choi
    '10002917',
    // Wade.park
    '10003599',
    // Peter
    '2214',
  ];
  return (IS_PROD ? productionIds : alphaIds).includes(id);
}
