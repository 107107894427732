import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleOptionsSection_article$key } from '@/__generated__/ArticleOptionsSection_article.graphql';
import OptionIcon from '@/components/OptionIcon';
import { useFlow } from '@/stackflow';
import { Option, OptionPriorities, OptionText } from '@/types/Article/Option';
import { parseOptions } from '@/utils/article/parseOptions';

import SectionTitle from './SectionTitle';

type Props = {
  articleRef: ArticleOptionsSection_article$key;
};

type PriorOption = (typeof OptionPriorities)[number];

const findPositiveIndex = (o: Option) => {
  const index = OptionPriorities.findIndex((p) => p === o);

  return index < 0 ? Infinity : index;
};

const ArticleOptionsSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { push } = useFlow();
  const article = useFragment(
    graphql`
      fragment ArticleOptionsSection_article on Article {
        id
        ...parseOptions_article
      }
    `,
    articleRef
  );
  const { id } = article;
  const { options } = parseOptions(article);
  const prioritizedOptions = [...options]
    .sort((a, b) => findPositiveIndex(a) - findPositiveIndex(b))
    .filter((option) => OptionPriorities.includes(option as PriorOption));
  const hasMoreOptions = options.length > prioritizedOptions.length;

  const handleClick = () => {
    push('article_options_list', { articleId: id });
  };

  return (
    <Container>
      <SectionTitle onClickMore={hasMoreOptions ? handleClick : undefined}>
        옵션 {options.length}개
      </SectionTitle>
      <Wrapper>
        {OptionPriorities.map((option) => (
          <OptionItem disabled={!options.includes(option)} key={option}>
            <OptionIcon option={option} size={24} />
            <Text>{OptionText[option]}</Text>
          </OptionItem>
        ))}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  margin: ${rem(8)} ${rem(-8)} 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${rem(24)} ${rem(6)};
  align-items: flex-start;
`;

const OptionItem = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
`;

const Text = styled.p`
  margin-top: ${rem(8)};
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Regular}
`;

export default ArticleOptionsSection;
