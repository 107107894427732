/**
 * @generated SignedSource<<abd55d7902f3a7f423a92321f80af665>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Manufacturer = "ACURA" | "ASTON_MARTIN" | "AUDI" | "BAIC" | "BENTLEY" | "BENZ" | "BMW" | "CADILLAC" | "CAMMSYS" | "CHEVROLET" | "CHEVROLET_GM_DAEWOO" | "CHRYSLER" | "CITROEN" | "CYAN" | "DAECHANG_MOTORS" | "DAEWOO_BUS" | "DFSK" | "DODGE" | "DS" | "ETC" | "FERRARI" | "FIAT" | "FORD" | "FOTON" | "FOX" | "GENESIS" | "GMC" | "HONDA" | "HUMMER" | "HYUNDAI" | "INFINITI" | "ISUZU" | "JAGUAR" | "JEEP" | "KIA" | "LAMBORGHINI" | "LAND_ROVER" | "LEXUS" | "LINCOLN" | "LOTUS" | "MAIV" | "MASERATI" | "MAYBACH" | "MAZDA" | "MC_LAREN" | "MINI" | "MITSUBISHI" | "NISSAN" | "OULLIM" | "PEUGEOT" | "PORSCHE" | "POWER_PLAZA" | "RENAULT_SAMSUNG" | "ROLLS" | "SAAB" | "SMART" | "SSANGYOUNG" | "SUBARU" | "SUZUKI" | "TESLA" | "TOYOTA" | "VOLKSWAGEN" | "VOLVO" | "ZHIDOU";
import { FragmentRefs } from "relay-runtime";
export type carData_getCarFullName_carData$data = {
  readonly autoupModel: {
    readonly company: {
      readonly name: string;
    };
  } | null | undefined;
  readonly carName: string;
  readonly manufacturer: Manufacturer;
  readonly " $fragmentType": "carData_getCarFullName_carData";
};
export type carData_getCarFullName_carData$key = {
  readonly " $data"?: carData_getCarFullName_carData$data;
  readonly " $fragmentSpreads": FragmentRefs<"carData_getCarFullName_carData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carData_getCarFullName_carData"
};

(node as any).hash = "8134322179b37ff3b740cc00475e5d2e";

export default node;
