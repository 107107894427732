import styled from '@emotion/styled';
import React, { memo } from 'react';

import HStack from '@/components/Base/HStack';
import FilterList from '@/components/Filter/FilterList';

const FilterHeader = ({ ...props }) => {
  return (
    <Container
      {...props}
      align="center"
      bg="paperDefault"
      pb={8}
      pos="sticky"
      pt={12}
      w="100%"
      z={1}
    >
      <FilterList />
    </Container>
  );
};

const Container = styled(HStack)({
  top: 0,
});

export default memo(FilterHeader);
