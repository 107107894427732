import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { ErrorBoundary } from '@sentry/react';
import { useActivity } from '@stackflow/react';
import { rem } from 'polished';
import { type PropsWithChildren, memo, useCallback, useRef } from 'react';

import type { KarrotMapResourceProps } from '@/components/KarrotMap/KarrotMapResource';

import KarrotMapResource from '@/components/KarrotMap/KarrotMapResource';
import useInViewportOnce from '@/hooks/useInViewportOnce';
import { openKarrotMap } from '@/utils/url';

const FallbackButton = ({ onClick }: { onClick?: () => void }) => {
  return <BoxButton onClick={onClick}>지도에서 위치보기</BoxButton>;
};

type Props = {
  address?: string;
  heightRatio?: '60%' | '100%';
  name?: string;
  onClick?: () => void;
} & KarrotMapResourceProps;

const KarrotMap = ({
  coordinates,
  polygon,
  name,
  address,
  onClick,
  children,
  heightRatio = '60%',
}: PropsWithChildren<Props>) => {
  const containerRef = useRef(null);
  const { isActive } = useActivity();
  const inViewportOnce = useInViewportOnce(containerRef, {
    threshold: 0.1,
  });

  const handleClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick();
    if (coordinates) {
      openKarrotMap({ coordinates, name, address });
    }
  }, [address, coordinates, name, onClick]);

  return (
    <ErrorBoundary fallback={<FallbackButton onClick={handleClick} />}>
      <MapContainer data-height-ratio={heightRatio} onClick={handleClick} ref={containerRef}>
        {inViewportOnce && isActive && (
          <KarrotMapResource coordinates={coordinates} polygon={polygon} />
        )}
        {children}
      </MapContainer>
    </ErrorBoundary>
  );
};

const MapContainer = styled.div`
  width: 100%;
  &[data-height-ratio='60%'] {
    height: calc((100vw - ${rem(32)}) * 0.6);
  }
  &[data-height-ratio='100%'] {
    height: calc((100vw - ${rem(32)}));
  }
  border: 1px solid ${vars.$scale.color.grayAlpha50};
  border-radius: ${rem(6)};
  background-color: ${vars.$scale.color.gray100};
  position: relative;
  & canvas {
    border-radius: ${rem(6)};
  }
`;

export default memo(KarrotMap);
