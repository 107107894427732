import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { useCallback, useRef } from 'react';

import ButtonDiv from '@/components/Base/ButtonDiv';
import NormalImage from '@/components/Base/OptimizedImage/NormalImage';
import useImpression from '@/hooks/useImpression';
import useTrack from '@/hooks/useTrack';
import { pressed } from '@/styles/mixins';
import { openInAppBrowser } from '@/utils/url';

type Props = {
  adOwner: string;
  imageUrl: string;
  link: string;
  type: 'A' | 'B';
};

const AdBanner: React.FC<Props> = ({ adOwner, type, link, imageUrl, ...props }) => {
  const bannerRef = useRef<HTMLButtonElement>(null);
  const { track } = useTrack();

  const handleClickAd = useCallback(() => {
    track('main_click_ad_banner', {
      adOwner,
      bannerType: type,
    });
    openInAppBrowser(link);
  }, [track, link, adOwner, type]);

  const handleImpressAd = useCallback(() => {
    track('main_impression_ad_banner', {
      adOwner,
      bannerType: type,
    });
  }, [track, adOwner, type]);

  useImpression(bannerRef, handleImpressAd);

  return (
    <Button {...props} onClick={handleClickAd} ref={bannerRef}>
      <Image alt="광고 배너" src={imageUrl} />
    </Button>
  );
};

const Button = styled(ButtonDiv)([
  {
    borderRadius: rem(10),
  },
  pressed(),
]);

const Image = styled(NormalImage)({ borderRadius: rem(10), width: '100%', maxWidth: '100%' });

export default AdBanner;
