import { useActivity } from '@stackflow/react';
import { usePrevious } from '@toss/react';
import { useEffect } from 'react';

import useLatest from '@/hooks/useLatest';

export const useReactiveEffect = (callback: () => void) => {
  const { isActive } = useActivity();
  const prevIsActive = usePrevious(isActive);
  const callbackRef = useLatest(callback);

  useEffect(() => {
    if (isActive === true && prevIsActive === false) {
      callbackRef.current();
    }
  }, [callbackRef, isActive, prevIsActive]);
};
