import { Spinner } from '@daangn/sprout-components-spinner';
import styled from '@emotion/styled';
import React from 'react';

import Center from '@/components/Base/Center';

interface Props {}

const LoadingScreen: React.FC<React.PropsWithChildren<Props>> = (...props) => {
  return (
    <Container {...props}>
      <Spinner />
    </Container>
  );
};

export default LoadingScreen;

const Container = styled(Center)`
  padding-bottom: calc(
    var(--stackflow-plugin-basic-ui-app-bar-min-height) + env(safe-area-inset-bottom)
  );
`;
