/**
 * @generated SignedSource<<9f33f0fcd39091b47035e087d5bad173>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CarHistoryStatus = "EXPIRED" | "NOT_REQUESTED" | "OWNED" | "REQUESTED" | "UNABLE_TO_FETCH";
import { FragmentRefs } from "relay-runtime";
export type ArticleCarHistorySection_article$data = {
  readonly carData: {
    readonly carHistory: {
      readonly " $fragmentSpreads": FragmentRefs<"HistoryGrid_carHistory">;
    } | null | undefined;
    readonly carHistoryStatus: CarHistoryStatus;
  };
  readonly id: string;
  readonly " $fragmentType": "ArticleCarHistorySection_article";
};
export type ArticleCarHistorySection_article$key = {
  readonly " $data"?: ArticleCarHistorySection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCarHistorySection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCarHistorySection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carHistoryStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarHistory",
          "kind": "LinkedField",
          "name": "carHistory",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HistoryGrid_carHistory"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "1a496bcfb4d4d2dde1dbf186083d6ba9";

export default node;
