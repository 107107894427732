import authPlugin from '@daangn/plantae-plugin-auth';
import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers';
import requestIdPlugin from '@daangn/plantae-plugin-request-id';
import { captureException, withScope } from '@sentry/core';
import axios, { AxiosError } from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { createAxiosInterceptors } from 'plantae/axios';

import { IS_LOCAL } from '@/constants/config';
import { karrotBridge } from '@/sdk/bridge';
import { getInfo } from '@/sdk/bridge/info';

const MAX_RETRY_COUNT = 3;

const getRetryDelay = (retryCount: number) => {
  const delay = Math.pow(2, retryCount) * 300;
  const buffer = delay * 0.2 * Math.random();

  return delay + buffer;
};

const getFetcher = (baseUrl: string) => {
  const api = axios.create({ baseURL: baseUrl });
  const daangnAuthPlugin = authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: !IS_LOCAL,
    },
  });
  const daangnRequestIdPlugin = requestIdPlugin();
  const daangnCommonHeadersPlugin = commonHeadersPlugin({ bridge: karrotBridge });

  const { request, response } = createAxiosInterceptors({
    client: api,
    plugins: [daangnAuthPlugin, daangnRequestIdPlugin, daangnCommonHeadersPlugin],
  });

  api.interceptors.request.use(request.onFulfilled, request.onRejected);
  api.interceptors.response.use(response.onFulfilled, response.onRejected);

  api.interceptors.request.use(
    async (config) => {
      const { app, region, user } = await getInfo();

      if (IS_LOCAL) {
        config.headers.set('X-AUTH-TOKEN', user?.authToken);
      }
      config.headers.set('X-REGION-ID', region?.id);
      config.headers.set('X-User-Agent', app.userAgent);

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(undefined, (error: AxiosError) => {
    // https://github.com/softonic/axios-retry/blob/3822205c6d2efc8eaa6a67d000fe7509bdde99b3/es/index.mjs#L88-L93
    const canRetry = isNetworkOrIdempotentRequestError(error);
    const retryCount = error.config?.['axios-retry']?.retryCount ?? 0;
    const maxRetried = retryCount >= MAX_RETRY_COUNT;

    if (!canRetry || maxRetried) {
      withScope((scope) => {
        if (error.name) {
          error.name = [error.name, error.config?.baseURL || error.config?.url]
            .filter(Boolean)
            .join(' - ');
        }
        error.config?.method && scope.setTag('method', error.config?.method);
        error.response?.status && scope.setTag('statusCode', error.response?.status);
        error.config?.url && scope.setTag('url', error.config?.url);

        captureException(error);
      });
    }
    return Promise.reject(error);
  });

  axiosRetry(api, {
    retries: MAX_RETRY_COUNT,
    retryDelay: getRetryDelay,
  });

  return api;
};

export default getFetcher;
