import { Callout, CalloutDescription } from '@daangn/sprout-components-callout';
import { Divider } from '@daangn/sprout-components-divider';
import { css } from '@emotion/react';
import { useAtomValue } from 'jotai';
import { rem } from 'polished';
import { ForwardedRef, Fragment, forwardRef, useCallback, useEffect, useState } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';

import type { MainQueryContainerQuery$data } from '@/__generated__/MainQueryContainerQuery.graphql';

import { ArticleFeedQueryContainer_query$key } from '@/__generated__/ArticleFeedQueryContainer_query.graphql';
import { ArticleFeedQueryContainerPaginationQuery } from '@/__generated__/ArticleFeedQueryContainerPaginationQuery.graphql';
import ArticleCard from '@/components/Article/ArticleCard';
import Box from '@/components/Base/Box';
import LoadingBox from '@/components/Base/Loading/LoadingBox';
import VStack from '@/components/Base/VStack';
import { useFilterContext } from '@/components/Filter/FilterContextProvider';
import FilterHeader from '@/components/Filter/FilterHeader';
import FloatingCreateNotificationButton from '@/components/Notification/FloatingCreateNotificationButton';
import SkeletonHorizontalArticleCardList from '@/components/Skeleton/SkeletonHorizontalArticleCardList';
import { useEventCallback } from '@/hooks/useEventCallback';
import useTrack from '@/hooks/useTrack';
import FeedAdSection from '@/pages/Main/components/FeedAdSection';
import UserFeedback from '@/pages/Main/components/UserFeedback';
import useSelectedCondition from '@/pages/Search/hooks/useSelectedCondition';
import { userFeedbackSentStatusSelector } from '@/store/userFeedback';
import { safeAreaInset } from '@/styles/mixins';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';

import NoMatchedArticles from './NoMatchedArticles';

const ARTICLES_PER_PAGE = 20;
const AD_INDEX = 13;

type Props = {
  filterHeaderTopMargin?: number;
  isScrollReached: boolean;
  onAfterFilterChanged?: () => void;
  query: MainQueryContainerQuery$data;
};

const ArticleFeedQueryContainer = (
  { onAfterFilterChanged, isScrollReached, filterHeaderTopMargin = 0, query }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { track } = useTrack<'main'>();
  const [page, setPage] = useState(2);
  const userFeedbackSentStatus = useAtomValue(userFeedbackSentStatusSelector);
  const { filter, isOptionsActive, isWarrantyActive, filterChangedCount } = useFilterContext();

  const selectedCondition = useSelectedCondition({
    companyIds: undefined,
    seriesIds: undefined,
    subseriesIds: undefined,
  });

  const {
    data: { articleFeedV2: articleFeed },
    hasNext,
    loadNext,
  } = usePaginationFragment<
    ArticleFeedQueryContainerPaginationQuery,
    ArticleFeedQueryContainer_query$key
  >(
    graphql`
      fragment ArticleFeedQueryContainer_query on Query
      @argumentDefinitions(
        filter: { type: "ArticleFeedV2Input!" }
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
      )
      @refetchable(queryName: "ArticleFeedQueryContainerPaginationQuery") {
        articleFeedV2(first: $count, after: $cursor, input: $filter)
          @connection(key: "ArticleFeedQueryContainer_query_articleFeedV2") {
          edges {
            node {
              id
              ...ArticleCard_article
            }
          }
        }
      }
    `,
    query
  );

  const handleLoadMore = useCallback(() => {
    loadNext(ARTICLES_PER_PAGE);
    track('main_load_more_articles', { page });
    setPage((p) => p + 1);
  }, [loadNext, page, track]);

  const paddingBottom = isOptionsActive ? 16 + 35 + 8 : 0;
  const handleClickItem = useEventCallback((data: ArticleEventProperties) => {
    track('main_click_article', { ...filter, ...data });
  });

  useEffect(() => {
    if (filterChangedCount > 0) {
      onAfterFilterChanged?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChangedCount]);

  const articleCardNodes = articleFeed.edges.map((edge) => {
    return (
      <ArticleCard
        articleRef={edge.node}
        key={edge.node.id}
        onClick={handleClickItem}
        variant="horizontal"
      />
    );
  });

  if (articleCardNodes.length > AD_INDEX) {
    articleCardNodes.splice(AD_INDEX, 0, <FeedAdSection key="ad-section" />);
  }

  if (userFeedbackSentStatus !== 'sent') {
    articleCardNodes.splice(
      6,
      0,
      <Box key="user-feedback" mx={16} my={16}>
        <UserFeedback key={'user-feedback'} />
      </Box>
    );
  }
  return (
    <Fragment>
      <FilterHeader
        css={css`
          top: ${rem(filterHeaderTopMargin)};
        `}
      />
      {isWarrantyActive && (
        <Box px={16} py={8}>
          <Callout>
            <CalloutDescription>
              최대 500만원까지 고장 수리비를 지원받는 차량이에요.
            </CalloutDescription>
          </Callout>
        </Box>
      )}
      <Box css={safeAreaInset({ paddingBottom })} ref={ref}>
        {!articleFeed.edges.length ? (
          hasNext ? (
            <SkeletonHorizontalArticleCardList />
          ) : (
            <NoMatchedArticles />
          )
        ) : (
          <>
            <VStack
              divider={
                <Box f={'1 0 auto'} mx={16}>
                  <Divider />
                </Box>
              }
              gap={1}
            >
              {articleCardNodes}
            </VStack>
            <FloatingCreateNotificationButton
              queryRef={query}
              selectedCondition={selectedCondition}
              visible={isOptionsActive && isScrollReached}
            />
            {hasNext && <LoadingBox onLoad={handleLoadMore} />}
          </>
        )}
      </Box>
    </Fragment>
  );
};

export default forwardRef<HTMLDivElement, Props>(ArticleFeedQueryContainer);
