import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCheckFill } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';

import { getSizeProps } from '@/utils/style';

const ArticlePromotionExplanation: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <IconCheckFill {...getSizeProps(16)} color={vars.$semantic.color.primary} />
      <Title>{children}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  ${vars.$semantic.typography.caption1Bold}
  margin-left: ${rem(6)};
  color: ${vars.$scale.color.gray900};
`;

export default ArticlePromotionExplanation;
