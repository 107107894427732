/**
 * @generated SignedSource<<45b84b52ad7caf1d4ccab9117707613c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleChartSection_article$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleChart_article">;
  readonly " $fragmentType": "ArticleChartSection_article";
};
export type ArticleChartSection_article$key = {
  readonly " $data"?: ArticleChartSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleChartSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleChartSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleChart_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "4de48548d39ff8eeac5a128eed26901c";

export default node;
