import { P, match } from 'ts-pattern';

import type {
  CreateNewArticleNotificationConditionV2Input,
  H3Resolution,
} from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';

import { useFilterContext } from '@/components/Filter/FilterContextProvider';
import useUser from '@/hooks/useUser';
import { getMinimumBoundaryPolygonByDistance } from '@/utils/map/getMinimumBoundaryPolygonByDistance';
import { getPolygonCells } from '@/utils/map/getPolygonCells';
import { getResolutionByDistance } from '@/utils/map/getResolutionByDistance';
import { nullify } from '@/utils/misc';

type Props = {
  companyIds?: string[];
  seriesIds?: string[];
  subseriesIds?: string[];
};

const useSelectedCondition = ({ companyIds = [], seriesIds = [], subseriesIds = [] }: Props) => {
  const { filter } = useFilterContext();
  const { region } = useUser();
  const {
    carTypes,
    companyOrigin,
    distance,
    driveDistanceMax,
    driveDistanceMin,
    fuelTypes,
    gears,
    modelYearMax,
    modelYearMin,
    priceMax,
    priceMin,
    saleTypes,
    // warrantyScope,
    companyIds: _companyIds,
  } = filter;

  const h3IndexList = match(distance)
    .with(P.union(100, 25, 50), (v) => ({
      h3Index: getPolygonCells(getMinimumBoundaryPolygonByDistance(region.centerCoordinates, v), v),
      resolution: `RESOLUTION_${getResolutionByDistance(v)}` as H3Resolution,
    }))
    .otherwise(() => undefined);

  const selectedCondition: CreateNewArticleNotificationConditionV2Input = {
    carTypes,
    companyOrigin,
    driveDistanceMax,
    driveDistanceMin,
    fuelTypes,
    gears,
    modelYearMax,
    modelYearMin,
    priceMax,
    priceMin,
    saleTypes,
    // warrantyScope,
    companyIds: nullify(companyIds ?? _companyIds),
    seriesIds: nullify(seriesIds),
    subseriesIds: nullify(subseriesIds),
    baseDistanceKm: (distance ?? 999) === 999 ? undefined : distance,
    baseRegionId: (distance ?? 999) === 999 ? undefined : region.id,
    h3IndexList,
  };

  return selectedCondition;
};

export default useSelectedCondition;
