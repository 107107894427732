/* eslint-disable no-console */
import { captureException, withScope } from '@sentry/react';
import { RequestParameters, Variables } from 'relay-runtime';

import CONFIG, { IS_LOCAL } from '@/constants/config';
import { GraphQLError } from '@/types/global';
import getFetcher from '@/utils/fetcher';
import { isServiceError } from '@/utils/relay';
import { maskCarNumber, maskName } from '@/utils/string';

import {
  getFakeInspectionMutationResponse,
  isFakeInspectionMutation,
  isFakeInspectionQuery,
  mockedInspectionResponse,
} from './fakeInspection';

const fetcher = getFetcher(CONFIG.API_HOST);

fetcher.interceptors.response.use(undefined, (error) => {
  captureException(error);
  return Promise.reject(error);
});

async function fetchGraphQL(params: RequestParameters, variables: Variables) {
  if (isFakeInspectionQuery(params, variables)) {
    return mockedInspectionResponse;
  }
  if (isFakeInspectionMutation(params, variables)) {
    return getFakeInspectionMutationResponse(params);
  }

  const { data } = await fetcher.post('graphql', { query: params.text, variables });
  if (IS_LOCAL) {
    const error = data.errors?.[0];
    (error ? console.group : console.groupCollapsed)(
      `GraphQL Operation: [${params.operationKind}] ${params.name} ${error ? '🚨' : '✅'}`
    );
    console.group('Variables');
    console.log(JSON.stringify(variables, null, 2));
    console.groupEnd();
    console.group('Response');
    console.log(data.data ?? error);
    console.groupEnd();
    console.groupCollapsed('Operation');
    console.log(params.text);
    console.groupEnd();
    console.groupCollapsed('Context');
    console.log(params.metadata);
    console.groupEnd();
    console.groupEnd();
  }

  data.errors?.forEach((error: GraphQLError) => {
    withScope((scope) => {
      const maskedVariables = {
        ...variables,
        ...(variables.carNo && { carNo: maskCarNumber(variables.carNo) }),
        ...(variables.ownerName && { ownerName: maskName(variables.ownerName) }),
      };

      scope.setTag('kind', params.operationKind);
      scope.setTag('operationName', params.name);
      scope.setExtra('query', params.text);
      scope.setExtra('variables', maskedVariables);
      scope.setExtra('errorResponse', error);

      const exception = new Error(error?.message || '');
      exception.name = `RelayError - [${params.operationKind}][${params.name}]`;

      if (isServiceError(error)) {
        scope.setLevel('warning');
      }

      captureException(exception);

      if (error.extensions) {
        error.extensions.isReportedException = true;
      }
    });
  });

  return data;
}

export default fetchGraphQL;
