import { Environment } from '@daangn/karrotbridge/lib/core/getCurrentEnvironment';
import { SnackbarProvider } from '@daangn/sprout-components-snackbar';
import { TransferIdentificationProvider } from '@daangn/transfer-identification-sdk';
import { ThemeProvider } from '@emotion/react';
import { Provider as JotaiProvider } from 'jotai';
import React, { useMemo } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';

import { FeatureFlagContextProvider } from '@/contexts/FeatureFlagContext';
import { NotificationContextProvider } from '@/contexts/NotificationContext';
import { UploadImageContextProvider } from '@/contexts/UploadImageContext';
import relayEnvironment from '@/relay/environment';

type Props = { environment: Environment };

const Providers: React.FC<React.PropsWithChildren<Props>> = ({ environment, children }) => {
  const theme = useMemo(
    () => ({
      environment: environment === 'Android' ? ('Android' as const) : ('Cupertino' as const),
    }),
    [environment]
  );

  return (
    <JotaiProvider>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <TransferIdentificationProvider previous_screen_name="car">
          <SnackbarProvider offset="auto">
            <NotificationContextProvider>
              <FeatureFlagContextProvider>
                <UploadImageContextProvider>
                  <ThemeProvider theme={theme}>{children}</ThemeProvider>
                </UploadImageContextProvider>
              </FeatureFlagContextProvider>
            </NotificationContextProvider>
          </SnackbarProvider>
        </TransferIdentificationProvider>
      </RelayEnvironmentProvider>
    </JotaiProvider>
  );
};

export default Providers;
