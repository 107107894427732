import { graphql, readInlineData } from 'relay-runtime';

import { getArticleEventProperties_article$key } from '@/__generated__/getArticleEventProperties_article.graphql';
import { getCarDataEventProperties } from '@/utils/carData';

import { ArticleEventProperties } from './ArticleEventProperties';
import { isInspectedCar } from './isInspectedCar';

export const getArticleEventProperties = (
  articleRef: getArticleEventProperties_article$key
): ArticleEventProperties => {
  const article = readInlineData(
    graphql`
      fragment getArticleEventProperties_article on Article @inline {
        id
        voteCount
        chatRoomCount
        driveDistance
        price
        content
        images {
          images {
            id
          }
        }
        carData {
          ...carData_getCarDataEventProperties
          isWarrantable
        }
        ...isInspectedCar_article
      }
    `,
    articleRef
  );

  return {
    articleId: article.id,
    chatRoomCount: article.chatRoomCount,
    isWarrantable: article.carData.isWarrantable && (article.price ?? 0) > 500,
    price: article.price ?? 0,
    voteCount: article.voteCount,
    imageCount: article.images.reduce((sum, item) => sum + item.images.length, 0),
    contentCount: article.content?.length ?? 0,
    ...getCarDataEventProperties(article.carData),
    driveDistance: article.driveDistance,
    isInspected: isInspectedCar(article),
  };
};
