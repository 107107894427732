import styled from '@emotion/styled';
import React from 'react';

import Box from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import Text, { TextColor } from '@/components/Base/Text';
import { exactSize } from '@/styles/mixins';

type Props = {
  color: TextColor;
  variant: 'bodyL1Regular' | 'bodyL2Regular';
};

const BulletedItem: React.FC<React.PropsWithChildren<Props>> = ({
  variant,
  color,
  children,
  ...props
}) => {
  const margin = variant === 'bodyL1Regular' ? 11 : 9;

  return (
    <HStack align="flex-start" {...props}>
      <Bullet bg={color} br={5} mx={margin} my={margin} />
      <Text color={color} variant={variant}>
        {children}
      </Text>
    </HStack>
  );
};

const Bullet = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...exactSize('both', 4),
});

export default BulletedItem;
