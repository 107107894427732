import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const CarrotRight: React.FC<React.PropsWithChildren<Props>> = ({
  width = 7,
  height = 13,
  fill: propFill,
  ...props
}) => {
  const fill = propFill ?? vars.$semantic.color.primary;

  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 7 13"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 6.49994L0.7 12.2618V0.738038L7 6.49994Z" fill={fill} />
    </svg>
  );
};

export default CarrotRight;
