import { graphql, readInlineData } from 'relay-runtime';

import type { getFullReport_MechanicReport$key } from '@/__generated__/getFullReport_MechanicReport.graphql';

import { nullsToUndefined } from '@/utils/misc';

const getFullReportFragment = graphql`
  fragment getFullReport_MechanicReport on MechanicReport @inline {
    id
    baseInfo {
      driveDistance
      gearType
      powertrainLayout
      powerSteeringType
      fuelType
      tuningHistory {
        comment
        imgs {
          id
          url
        }
        selections
      }
    }
    accidentHistory {
      externalPanel {
        leftFender
        rightFender
        leftFrontDoor
        rightFrontDoor
        leftRearDoor
        rightRearDoor
        leftQuarterPanel
        rightQuarterPanel
        hood
        trunkLid
        roofPanel
        radiatorSupport
        leftSideSealPanel
        rightSideSealPanel
      }
      mainFrame {
        frontPanel
        leftInsidePanel
        rightInsidePanel
        leftFrontWheelHouse
        rightFrontWheelHouse
        leftRearWheelHouse
        rightRearWheelHouse
        leftFrontSideMember
        rightFrontSideMember
        leftRearSideMember
        rightRearSideMember
        crossMember
        dashPanel
        floorPanel
        packageTray
        leftPillarPanelA
        leftPillarPanelB
        leftPillarPanelC
        leftPillarPanelD
        rightPillarPanelA
        rightPillarPanelB
        rightPillarPanelC
        rightPillarPanelD
        trunkFloor
        rearPanel
      }
    }
    tire {
      productionDate {
        front
      }
      tireTreadDepth {
        frontDriverSeat
        rearDriverSeat
        frontPassengerSeat
        rearPassengerSeat
      }
      tireCondition {
        front {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rear {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      brakePadWear {
        front
        rear
      }
    }
    engine {
      operation {
        ignition {
          comment
          imgs {
            id
            url
          }
          selections
        }
        idle {
          comment
          imgs {
            id
            url
          }
          selections
        }
        acceleration {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      engineOil {
        flow {
          comment
          imgs {
            id
            url
          }
          selections
        }
        condition {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      oilLeak {
        rockerArmCover {
          comment
          imgs {
            id
            url
          }
          selections
        }
        cylinderHead {
          comment
          imgs {
            id
            url
          }
          selections
        }
        engineBlock {
          comment
          imgs {
            id
            url
          }
          selections
        }
        oilPan {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontCover {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      coolant {
        level {
          comment
          imgs {
            id
            url
          }
          selections
        }
        condition {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      coolantLeak {
        cylinderHead {
          comment
          imgs {
            id
            url
          }
          selections
        }
        waterPump {
          comment
          imgs {
            id
            url
          }
          selections
        }
        radiator {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      highPressurePump {
        highPressurePump {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    interiorCondition {
      centerConsole {
        centerConsole {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      centerFascia {
        centerFascia {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      dashboard {
        dashboard {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      doorTrim {
        doorTrim {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }

      driverSeatMat {
        driverSeatMat {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      handle {
        handle {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      headliner {
        pillarCover {
          comment
          imgs {
            id
            url
          }
          selections
        }
        roof {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      seat {
        row1 {
          comment
          imgs {
            id
            url
          }
          selections
        }
        row2 {
          comment
          imgs {
            id
            url
          }
          selections
        }
        row3 {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    options {
      climateControlSystem {
        airConditioner {
          comment
          imgs {
            id
            url
          }
          selections
        }
        autoAirConditioner {
          comment
          imgs {
            id
            url
          }
          selections
        }
        heater {
          comment
          imgs {
            id
            url
          }
          selections
        }
        blowerMotor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        blowerDirectionController {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      avSystem {
        monitor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        radio {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      steeringHandle {
        electricalSteeringHandle {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      navigation {
        navigation {
          comment
          imgs {
            id
            url
          }
          selections
        }
        touchPanel {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      powerWindow {
        powerWindow {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      autoWiper {
        autoWiper {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      wiper {
        wiper {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      autoFoldSideMirror {
        autoFoldSideMirror {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      sunroof {
        sunroof {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      panoramicSunroof {
        panoramicSunroof {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      camera {
        around {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rear {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      leatherSeat {
        leatherSeat {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      powerSeat {
        powerSeat {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      ventilatedSeat {
        ventilatedSeat {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      heatedSeat {
        heatedSeat {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      indoorLight {
        indoorLight {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      hud {
        hud {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      heatedSteeringWheel {
        heatedSteeringWheel {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      headlamp {
        headlamp {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      autoTrunk {
        autoTrunk {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      epb {
        epb {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      hipass {
        hipass {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      parkingSensor {
        front {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rear {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      ldws {
        ldws {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      bsd {
        bsd {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      aeb {
        aeb {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      tpms {
        tpms {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      dashboardCamera {
        dashboardCamera {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      bluetooth {
        bluetooth {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      smartKey {
        smartKey {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      cruiseControl {
        cruiseControl {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    autoTransmission {
      operation {
        PRND {
          comment
          imgs {
            id
            url
          }
          selections
        }
        DNRP {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      missionOil {
        level {
          comment
          imgs {
            id
            url
          }
          selections
        }
        condition {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      oilLeak {
        oilPan {
          comment
          imgs {
            id
            url
          }
          selections
        }
        missionCase {
          comment
          imgs {
            id
            url
          }
          selections
        }
        pump {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    manualTransmission {
      operation {
        operation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      oilLeak {
        oilLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      gearShift {
        gearShift {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    powertrain {
      clutchAssembly {
        clutchAssembly {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      driveShaft {
        rubberBootDamage {
          comment
          imgs {
            id
            url
          }
          selections
        }
        greaseLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      differential {
        caseLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
        oilSealLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      transferCase {
        caseLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
        oilSealLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      propellerShaft {
        shaftLooseness {
          comment
          imgs {
            id
            url
          }
          selections
        }
        discDamage {
          comment
          imgs {
            id
            url
          }
          selections
        }
        bearingDamage {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    steering {
      operation {
        steeringLooseness {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      powerOil {
        level {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      oilLeak {
        pump {
          comment
          imgs {
            id
            url
          }
          selections
        }
        gearBox {
          comment
          imgs {
            id
            url
          }
          selections
        }
        highPressureHose {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      tieRodAndBallJoint {
        tieRodAndBallJoint {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    highVoltageElectricalSystem {
      batteryIsolation {
        isolation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      chargingPortIsolation {
        isolation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      wire {
        wire {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    brakingSystem {
      operation {
        vacuumBrakeBooster {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      oilLeak {
        masterCylinder {
          comment
          imgs {
            id
            url
          }
          selections
        }
        caliper {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      brakeFluid {
        brakeFluid {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      suspension {
        suspension {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    electricalSystem {
      generator {
        outputVoltage {
          comment
          imgs {
            id
            url
          }
          selections
        }
        noise {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      starterMotor {
        operation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      radiatorFanMotor {
        radiatorFanMotor {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    highVoltageElectricalSystem {
      chargingPortIsolation {
        isolation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      batteryIsolation {
        isolation {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      wire {
        wire {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    fuelSystem {
      fuelLeak {
        gasolineLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
        dieselLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
        lpgLeak {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    exterior {
      muffler {
        muffler {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      roof {
        roof {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      front {
        frontBumper {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontBumperMolding {
          comment
          imgs {
            id
            url
          }
          selections
        }
        grille {
          comment
          imgs {
            id
            url
          }
          selections
        }
        headlamp {
          comment
          imgs {
            id
            url
          }
          selections
        }
        turnSignal {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontWindshield {
          comment
          imgs {
            id
            url
          }
          selections
        }
        tinting {
          comment
          imgs {
            id
            url
          }
          selections
        }
        hood {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      rear {
        tailLamp {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearWindowTinting {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearBumper {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearBumperMolding {
          comment
          imgs {
            id
            url
          }
          selections
        }
        trunkLid {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      left {
        fender {
          comment
          imgs {
            id
            url
          }
          selections
        }
        fenderGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        quarterPanelGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontDoor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearDoor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        sideSealPanel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        quarterPanel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        aPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        bPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        cPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        dPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        doorGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        doorMolding {
          comment
          imgs {
            id
            url
          }
          selections
        }
        sideMirror {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontWheel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearWheel {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
      right {
        fender {
          comment
          imgs {
            id
            url
          }
          selections
        }
        fenderGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        quarterPanelGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontDoor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearDoor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        sideSealPanel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        quarterPanel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        aPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        bPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        cPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        dPillar {
          comment
          imgs {
            id
            url
          }
          selections
        }
        doorGarnish {
          comment
          imgs {
            id
            url
          }
          selections
        }
        doorMolding {
          comment
          imgs {
            id
            url
          }
          selections
        }
        sideMirror {
          comment
          imgs {
            id
            url
          }
          selections
        }
        frontWheel {
          comment
          imgs {
            id
            url
          }
          selections
        }
        rearWheel {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    photos {
      carRegistration {
        id
        url
      }
      exterior {
        id
        url
      }
      interior {
        id
        url
      }
      paperReport {
        id
        url
      }
      wheel {
        id
        url
      }
      maintenanceReceipts {
        id
        url
      }
    }
    opinion {
      comment
      imgs {
        id
        url
      }
    }
    diagnosis {
      etc
      engine
      transmission
      imgs {
        id
        url
      }
    }
    paint {
      paint {
        sameColor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        differentColor {
          comment
          imgs {
            id
            url
          }
          selections
        }
        wrapping {
          comment
          imgs {
            id
            url
          }
          selections
        }
        ppf {
          comment
          imgs {
            id
            url
          }
          selections
        }
      }
    }
    diagnosis {
      engine
      etc
      transmission
      imgs {
        id
        url
      }
    }
    pros
    notice {
      comment
      imgs {
        id
        url
      }
    }
  }
`;

export const getFullReport = (ref: getFullReport_MechanicReport$key) => {
  const report = readInlineData(getFullReportFragment, ref);
  const fixedTypeReport = nullsToUndefined(report);
  return fixedTypeReport;
};

export type FullReport = ReturnType<typeof getFullReport>;
