import ErrorScreen from '@/components/Error/ErrorScreen';
import { useFlow } from '@/stackflow';

type Props = {
  error: Error;
};

const ActivityErrorScreen = ({ error }: Props) => {
  const { pop } = useFlow();
  return <ErrorScreen buttonText="뒤로 가기" error={error} onClick={pop} />;
};

export default ActivityErrorScreen;
