import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { rem } from 'polished';

import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import VStack from '@/components/Base/VStack';
import NoAccident from '@/components/NoAccident';

import { useOverlayState } from './useOverlayState';

const useNoAccident = () => {
  const [isOpened, open, close] = useOverlayState('no_accident');
  const node = (
    <BottomSheet active={isOpened} onClickClose={close} title="무사고">
      <VStack pb={8} px={16}>
        <NoAccidentExplanation />
        <BoxButton onClick={close} size="xlarge" variant="primary" width="100%">
          확인
        </BoxButton>
      </VStack>
    </BottomSheet>
  );

  return [open, node] as const;
};

const NoAccidentExplanation = styled(NoAccident)({
  paddingTop: rem(4),
  paddingBottom: rem(24),
});

export default useNoAccident;
