import loadImage, { MetaData } from 'blueimp-load-image';

import bigPicture, { RequestUploadUrlInput } from '@/apis/bigPicture';
import { karrotBridge } from '@/sdk/bridge';

export type LoadImageResult = { image: HTMLCanvasElement } & MetaData;
export type PreviewImage = { name: string; src: string };

export const readImage = async (file: File) => {
  const data = await loadImage(file, {
    maxWidth: 1920,
    maxHeight: 1920,
    canvas: true,
  });
  return data as LoadImageResult;
};

export const uploadImage = async ({ blob, mimeType, size }: RequestUploadUrlInput) => {
  const current = new Date();
  const {
    upload_url: uploadUrl,
    id,
    thumbnail,
  } = await bigPicture.requestUploadUrl({ blob, mimeType, size });

  await bigPicture.uploadFileToS3({ url: uploadUrl, blob, mimeType, imageId: id });

  return { id, url: thumbnail, duration: getImageDurationASSeconds(current) };
};

export const imageToBlob = ({ image }: { file: File } & LoadImageResult) => {
  return new Promise<Blob>((resolve, reject) => {
    const onConvertComplete = (blob: Blob | null) => {
      if (!blob) {
        return reject();
      }
      resolve(blob);
    };
    // https://blog.naver.com/readion76/60149926473 jpeg quality mapping table
    image.toBlob(onConvertComplete, 'image/jpeg', 0.92);
  });
};

export const validateImageFileType = (file: File) => {
  return file && /^image\/(png|jpg|jpeg)/.test(file.type);
};

const DEFAULT_MAX_IMAGE_COUNT = 10;
const defaultImageConfig = {
  maxImageCount: DEFAULT_MAX_IMAGE_COUNT,
};

export const setMaxImageCount = (maxImageCount: number) => {
  karrotBridge.configureImage({ image: { ...defaultImageConfig, maxImageCount } });
};

export const resetMaxImageCount = () => {
  karrotBridge.configureImage({
    image: { ...defaultImageConfig, maxImageCount: DEFAULT_MAX_IMAGE_COUNT },
  });
};

export const getImageDurationASSeconds = (original: Date) => {
  return (new Date().getTime() - original.getTime()) / 1000;
};

export const getFileSizeAsMB = (file: Blob | File) => {
  return file.size / 1024 / 1024;
};

export const summaryImageSizes = (files: (Blob | File)[]) => {
  const sizes = files.map((file) => getFileSizeAsMB(file));
  return {
    count: files.length,
    sizeAverage: sizes.reduce((result, size) => result + size, 0) / sizes.length,
    sizeMax: Math.max(...sizes),
  };
};
