import { BoxButton } from '@daangn/sprout-components-button';
import { graphql, useFragment } from 'react-relay';

import { ArticleProxyInspectionButton_article$key } from '@/__generated__/ArticleProxyInspectionButton_article.graphql';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';

const Fragment = graphql`
  fragment ArticleProxyInspectionButton_article on Article {
    id
  }
`;

type Props = {
  articleRef: ArticleProxyInspectionButton_article$key;
};

const ArticleProxyInspectionButton: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(Fragment, articleRef);
  const { push } = useFlow();
  const { track } = useTrack();
  const handleClick = () => {
    push('article_proxy_inspection_guide', { articleId: article.id });
    track('article_click_proxy_inspection_button');
  };

  return (
    <BoxButton onClick={handleClick} size="xlarge" variant="primaryLow" width="100%">
      출장 진단
    </BoxButton>
  );
};

export default ArticleProxyInspectionButton;
