/**
 * @generated SignedSource<<4a4e6a1046afcac520f6c85c2336d2db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ArticleExpenseSection_article$data = {
  readonly carData: {
    readonly carType: CarType;
    readonly displacement: number | null | undefined;
    readonly fuelType: FuelType;
  };
  readonly insuranceTrackingData: {
    readonly carrot: {
      readonly url: string;
    } | null | undefined;
    readonly db: {
      readonly url: string;
    } | null | undefined;
    readonly kb: {
      readonly url: string;
    } | null | undefined;
    readonly samsung: {
      readonly url: string;
    } | null | undefined;
  };
  readonly loanTrackingUrl: {
    readonly hcs: string | null | undefined;
  };
  readonly price: number | null | undefined;
  readonly region: {
    readonly id: string;
    readonly name1Id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"InsuranceBottomSheet_article" | "LoanBottomSheet_article" | "useIsWarrantable_article">;
  readonly " $fragmentType": "ArticleExpenseSection_article";
};
export type ArticleExpenseSection_article$key = {
  readonly " $data"?: ArticleExpenseSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleExpenseSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleExpenseSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name1Id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fuelType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displacement",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InsuranceTrackingData",
      "kind": "LinkedField",
      "name": "insuranceTrackingData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceTrackingUrl2",
          "kind": "LinkedField",
          "name": "samsung",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceTrackingUrl2",
          "kind": "LinkedField",
          "name": "kb",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceTrackingUrl2",
          "kind": "LinkedField",
          "name": "db",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceTrackingUrl2",
          "kind": "LinkedField",
          "name": "carrot",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LoanTrackingUrl",
      "kind": "LinkedField",
      "name": "loanTrackingUrl",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hcs",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InsuranceBottomSheet_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoanBottomSheet_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "18e42f4f6e29435de4dc505f349756de";

export default node;
