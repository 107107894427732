import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleAdvertisementSection_article$key } from '@/__generated__/ArticleAdvertisementSection_article.graphql';
import Spacer from '@/components/Base/Spacer';
import ExpandMore from '@/components/icon/ExpandMore';
import { PromotionContextProvider } from '@/contexts/PromotionContext';
import { carStorage } from '@/utils/storage';

import { useArticleContext } from '../ArticleContext';
import ArticleChartSection from './Chart/ArticleChartSection';
import ArticlePromotionSection from './Promotion/ArticlePromotionSection';

type Props = {
  articleRef: ArticleAdvertisementSection_article$key;
};

const ArticleAdvertisementSection: React.FC<Props> = ({ articleRef }) => {
  const { isChartSectionVisible } = useArticleContext();
  const [isFolded, setFolded] = useState(() => {
    const initialValue = carStorage.getItem('advertisementFoldState');

    return initialValue === 'true';
  });
  const article = useFragment(
    graphql`
      fragment ArticleAdvertisementSection_article on Article {
        ...ArticleChartSection_article
        ...ArticlePromotionSection_article
        ...PromotionContext_article
      }
    `,
    articleRef
  );

  useEffect(() => {
    carStorage.setItem('advertisementFoldState', isFolded.toString());
  }, [isFolded]);

  if (!isChartSectionVisible) {
    return null;
  }

  return (
    <>
      <Container>
        <TitleWrapper onClick={() => setFolded((prev) => !prev)}>
          <SectionTitle>당근 홈에 광고하기</SectionTitle>
          <ExpandMore expanded={!isFolded} size={16} />
        </TitleWrapper>
        {!isFolded && isChartSectionVisible && <ArticleChartSection articleRef={article} />}
        <PromotionContextProvider articleRef={article}>
          <ArticlePromotionSection articleRef={article} folded={isFolded} />
        </PromotionContextProvider>
      </Container>
      <Spacer axis="vertical" size={24} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${vars.$semantic.color.divider2};
  border-radius: ${rem(10)};
  padding: ${rem(14)};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(16)};
`;

const SectionTitle = styled.p`
  ${vars.$semantic.typography.subtitle1Bold}
  color: ${vars.$scale.color.gray900};
`;

export default ArticleAdvertisementSection;
