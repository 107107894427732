import React, { PropsWithChildren } from 'react';

import Box from '@/components/Base/Box';
import { PropsOf } from '@/types/React/PropsOf';

type Props = PropsWithChildren<{ onClick?: () => void; size: number } & PropsOf<typeof Box>>;

const Circle: React.FC<Props> = ({ children, size, onClick, ...props }) => {
  return (
    <Box br={size} maxH={size} maxW={size} minH={size} minW={size} onClick={onClick} {...props}>
      {children}
    </Box>
  );
};

export default Circle;
