import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const PositivityBooster: React.FC<Props> = ({ width = 72, height = 72, ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 72 72"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5374_92026)">
        <g clipPath="url(#clip1_5374_92026)">
          <path
            d="M36.0031 66.0062C52.5734 66.0062 66.0062 52.5734 66.0062 36.0031C66.0062 19.4328 52.5734 6 36.0031 6C19.4328 6 6 19.4328 6 36.0031C6 52.5734 19.4328 66.0062 36.0031 66.0062Z"
            fill="#F4E3D9"
          />
          <path
            d="M49.1912 18.2454H49.1478C48.2592 18.2421 47.4083 17.8864 46.7817 17.2564C46.1552 16.6263 45.8042 15.7734 45.8058 14.8848C45.8097 14.8593 45.8077 14.8333 45.8002 14.8087C45.7927 14.784 45.7798 14.7613 45.7624 14.7423C45.7456 14.7236 45.725 14.7087 45.7021 14.6985C45.6791 14.6884 45.6543 14.6831 45.6292 14.6831C45.604 14.6831 45.5792 14.6884 45.5562 14.6985C45.5333 14.7087 45.5127 14.7236 45.4958 14.7423C45.4856 14.7657 45.4803 14.791 45.4803 14.8167C45.4803 14.8423 45.4856 14.8676 45.4958 14.8911C45.495 15.3316 45.4074 15.7676 45.2381 16.1743C45.0688 16.5809 44.821 16.9502 44.509 17.2611C44.1969 17.572 43.8267 17.8184 43.4194 17.9862C43.0121 18.1541 42.5758 18.24 42.1353 18.2392C42.1112 18.2342 42.0863 18.2349 42.0626 18.2413C42.0389 18.2478 42.017 18.2598 41.9989 18.2764C41.9776 18.2908 41.9603 18.3102 41.9484 18.3329C41.9365 18.3556 41.9304 18.3809 41.9307 18.4066C41.9338 18.4521 41.9542 18.4947 41.9876 18.5258C42.0209 18.5569 42.0649 18.5741 42.1105 18.574C42.9974 18.5757 43.8474 18.9291 44.474 19.5568C45.1005 20.1846 45.4524 21.0353 45.4524 21.9222V21.9655C45.454 22.0089 45.4724 22.0499 45.5036 22.0799C45.5348 22.11 45.5765 22.1268 45.6198 22.1268H45.6757C45.7184 22.1268 45.7594 22.1098 45.7896 22.0796C45.8199 22.0493 45.8368 22.0083 45.8368 21.9655V21.9222C45.8368 21.0353 46.1888 20.1846 46.8153 19.5568C47.4419 18.9291 48.2919 18.5757 49.1788 18.574H49.2222C49.2439 18.574 49.2654 18.5697 49.2854 18.5612C49.3053 18.5527 49.3234 18.5403 49.3385 18.5246C49.3535 18.509 49.3652 18.4905 49.373 18.4702C49.3807 18.4499 49.3842 18.4283 49.3834 18.4066C49.3851 18.3822 49.381 18.3577 49.3714 18.3352C49.3619 18.3127 49.3471 18.2927 49.3284 18.277C49.3096 18.2613 49.2874 18.2502 49.2636 18.2447C49.2398 18.2392 49.2149 18.2395 49.1912 18.2454Z"
            fill="white"
          />
          <path
            d="M32.3016 19.8947H32.2705C31.6128 19.8947 30.982 19.6334 30.5168 19.1683C30.0517 18.7032 29.7904 18.0724 29.7904 17.4146C29.7943 17.3968 29.7935 17.3783 29.788 17.3609C29.7826 17.3435 29.7727 17.3278 29.7594 17.3154C29.7472 17.301 29.732 17.2895 29.7149 17.2816C29.6978 17.2736 29.6791 17.2695 29.6603 17.2695C29.6414 17.2695 29.6227 17.2736 29.6056 17.2816C29.5885 17.2895 29.5733 17.301 29.561 17.3154C29.5533 17.3319 29.5493 17.3499 29.5493 17.3681C29.5493 17.3863 29.5533 17.4043 29.561 17.4208C29.561 18.0786 29.2997 18.7094 28.8346 19.1745C28.3695 19.6396 27.7387 19.9009 27.0809 19.9009C27.0655 19.8933 27.0486 19.8893 27.0314 19.8893C27.0141 19.8893 26.9972 19.8933 26.9817 19.9009C26.9667 19.9128 26.9544 19.9278 26.9458 19.945C26.9373 19.9621 26.9326 19.981 26.9321 20.0001C26.9351 20.0331 26.95 20.0638 26.9739 20.0866C26.9979 20.1094 27.0293 20.1228 27.0623 20.1241C27.7201 20.1241 28.3509 20.3854 28.8161 20.8505C29.2812 21.3157 29.5425 21.9465 29.5425 22.6042C29.5425 22.6197 29.5455 22.635 29.5514 22.6493C29.5573 22.6636 29.566 22.6766 29.577 22.6875C29.5879 22.6985 29.6009 22.7072 29.6152 22.7131C29.6295 22.719 29.6448 22.7221 29.6603 22.7221H29.6913C29.722 22.7205 29.7511 22.7076 29.7729 22.6859C29.7947 22.6641 29.8076 22.635 29.8091 22.6042C29.8091 21.9465 30.0704 21.3157 30.5355 20.8505C31.0006 20.3854 31.6314 20.1241 32.2892 20.1241H32.3202C32.352 20.1225 32.3819 20.1088 32.4039 20.0857C32.4258 20.0626 32.438 20.032 32.438 20.0001C32.4365 19.984 32.4317 19.9684 32.4239 19.9542C32.4161 19.94 32.4055 19.9276 32.3927 19.9177C32.3799 19.9079 32.3652 19.9007 32.3495 19.8968C32.3338 19.8928 32.3175 19.8921 32.3016 19.8947Z"
            fill="white"
          />
          <path
            d="M53.4134 26.2936C53.1064 26.2928 52.8026 26.2315 52.5193 26.1133C52.2361 25.9951 51.9788 25.8222 51.7623 25.6046C51.5458 25.387 51.3744 25.1288 51.2576 24.8449C51.1409 24.561 51.0813 24.2568 51.0821 23.9499C51.0889 23.9342 51.0925 23.9174 51.0925 23.9003C51.0925 23.8832 51.0889 23.8663 51.0821 23.8507C51.0699 23.8374 51.055 23.8267 51.0385 23.8194C51.0219 23.8121 51.004 23.8083 50.986 23.8083C50.9679 23.8083 50.95 23.8121 50.9335 23.8194C50.9169 23.8267 50.9021 23.8374 50.8899 23.8507C50.8781 23.8848 50.8781 23.9219 50.8899 23.9561C50.8891 24.2631 50.8278 24.5669 50.7096 24.8501C50.5914 25.1334 50.4185 25.3907 50.2009 25.6072C49.9832 25.8236 49.7251 25.9951 49.4412 26.1118C49.1572 26.2286 48.8531 26.2882 48.5462 26.2874C48.5141 26.2761 48.4791 26.2761 48.447 26.2874C48.4215 26.3119 48.406 26.3451 48.4036 26.3804C48.4052 26.4122 48.4189 26.4422 48.442 26.4641C48.4651 26.486 48.4957 26.4982 48.5276 26.4982C49.1459 26.4982 49.7388 26.7438 50.176 27.181C50.6132 27.6182 50.8589 28.2112 50.8589 28.8295V28.8605C50.8604 28.8913 50.8733 28.9204 50.8951 28.9422C50.9168 28.9639 50.9459 28.9768 50.9767 28.9783H51.0139C51.044 28.9767 51.0724 28.9636 51.0932 28.9417C51.114 28.9198 51.1255 28.8907 51.1255 28.8605V28.8295C51.1271 28.2117 51.3733 27.6197 51.8101 27.1828C52.247 26.746 52.839 26.4998 53.4568 26.4982C53.4723 26.4982 53.4876 26.4952 53.5019 26.4893C53.5162 26.4833 53.5292 26.4746 53.5401 26.4637C53.551 26.4528 53.5597 26.4398 53.5656 26.4255C53.5716 26.4112 53.5746 26.3959 53.5746 26.3804C53.5713 26.3632 53.5643 26.3469 53.5539 26.3327C53.5435 26.3185 53.5301 26.3069 53.5147 26.2986C53.4992 26.2902 53.4821 26.2855 53.4646 26.2846C53.4471 26.2837 53.4296 26.2868 53.4134 26.2936Z"
            fill="white"
          />
          <path
            d="M25.4314 36.1271C28.2017 35.241 30.5397 33.3498 31.9851 30.8259C33.5166 28.259 33.6096 25.3448 33.9568 24.0056C34.335 22.5113 34.9674 20.2234 37.5592 20.2854C40.2873 20.3722 41.1615 22.6043 41.1305 25.289C41.0995 27.7691 40.6345 32.2953 40.6345 32.2953C41.0561 32.4937 43.3192 32.2953 45.5575 32.2953C47.7958 32.2953 50.6355 33.1199 50.3503 36.6913C49.9845 41.2423 49.5753 45.0493 49.3645 47.0706C49.1537 49.0919 48.422 51.696 44.0819 51.696H34.4404C31.5015 51.696 28.5316 50.1335 25.2578 48.385C25.2578 43.3814 25.4314 36.1271 25.4314 36.1271Z"
            fill="#43C5ED"
          />
          <path d="M25.6485 35.3149H21.637V49.2779H25.6485V35.3149Z" fill="#0A7DC4" />
          <path
            d="M25.6486 51.1442H21.6308C21.1375 51.1442 20.6644 50.9482 20.3156 50.5994C19.9667 50.2506 19.7708 49.7774 19.7708 49.2841V35.3149C19.7708 34.8216 19.9667 34.3485 20.3156 33.9996C20.6644 33.6508 21.1375 33.4548 21.6308 33.4548H25.6424C26.1357 33.4548 26.6088 33.6508 26.9577 33.9996C27.3065 34.3485 27.5025 34.8216 27.5025 35.3149V49.2841C27.5025 49.7764 27.3074 50.2485 26.9599 50.5972C26.6124 50.9458 26.1408 51.1425 25.6486 51.1442ZM23.4971 47.424H23.7885V37.175H23.4909L23.4971 47.424Z"
            fill="#0A7DC4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5374_92026">
          <rect fill="white" height="72" width="72" />
        </clipPath>
        <clipPath id="clip1_5374_92026">
          <rect fill="white" height="60" transform="translate(6 6)" width="60" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PositivityBooster;
