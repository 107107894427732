/**
 * @generated SignedSource<<1eaabd056b726f722c966f601aa6a9c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleOptionsSection_article$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"parseOptions_article">;
  readonly " $fragmentType": "ArticleOptionsSection_article";
};
export type ArticleOptionsSection_article$key = {
  readonly " $data"?: ArticleOptionsSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleOptionsSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleOptionsSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "parseOptions_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "options",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "bba04bc2f042fa741b42e6abb340d1eb";

export default node;
