import { Portal } from '@daangn/karrot-clothes';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import { AnimatePresence, motion } from 'framer-motion';
import { rem } from 'polished';
import React, { type RefObject } from 'react';

import HStack from '@/components/Base/HStack';
import LoadingCover from '@/components/Base/Loading/LoadingCover';
import { Subtitle1Regular, Title2Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { safeAreaInset } from '@/styles/mixins';
import { PropsOf } from '@/types/React/PropsOf';
import { getSizeProps } from '@/utils/style';

export type Props = {
  active: boolean;
  justifyHeader?: PropsOf<typeof HStack>['justify'];
  loading?: boolean;
  onClickClose?: () => void;
  scrollContainerRef?: RefObject<HTMLDivElement>;
  showCloseButton?: boolean;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
  topMargin?: number;
  zIndex?: number;
};

const DEFAULT_Z_INDEX = 120;
const cubicBezier = [0.23, 1, 0.32, 1];

const BottomSheet: React.FC<React.PropsWithChildren<Props>> = ({
  active,
  loading,
  title,
  subtitle,
  zIndex = DEFAULT_Z_INDEX,
  topMargin = 80,
  showCloseButton = false,
  justifyHeader = 'space-between',
  scrollContainerRef,
  onClickClose,
  children,
}) => {
  return (
    <Portal selector="#root">
      <AnimatePresence>
        {active && (
          <Dim
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            key="BottomSheet_Dim"
            onClick={onClickClose}
            transition={{ duration: 0.3, ease: cubicBezier }}
            zIndex={zIndex - 1}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {active && (
          <Container
            animate={{ translateY: '0%' }}
            exit={{ translateY: '100%' }}
            initial={{ translateY: '100%' }}
            key="BottomSheet_Container"
            topMargin={topMargin}
            transition={{ duration: 0.5, ease: cubicBezier }}
            zIndex={zIndex}
          >
            {(title || showCloseButton) && (
              <HStack
                align="flex-start"
                justify={justifyHeader}
                pb={8}
                pos="relative"
                pt={20}
                px={16}
                w="100%"
              >
                <VStack gap={6}>
                  {subtitle && <Subtitle1Regular color="gray700">{subtitle}</Subtitle1Regular>}
                  <Title2Bold>{title ?? ''}</Title2Bold>
                </VStack>
                {showCloseButton && (
                  <CloseButton onClick={onClickClose}>
                    <IconCloseRegular {...getSizeProps(24)} color={vars.$scale.color.gray900} />
                  </CloseButton>
                )}
              </HStack>
            )}
            <Content ref={scrollContainerRef}>
              {children}
              {loading && <LoadingCover />}
            </Content>
          </Container>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default BottomSheet;

const Container = styled(motion.div, {
  shouldForwardProp: (propName) => propName !== 'zIndex' && propName !== 'topMargin',
})<Pick<PropsOf<typeof BottomSheet>, 'topMargin' | 'zIndex'>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex ?? DEFAULT_Z_INDEX};
  max-height: calc(100vh - ${({ topMargin }) => rem(topMargin ?? 0)});
  background: ${vars.$semantic.color.paperDefault};
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-top-left-radius: ${rem(16)};
  border-top-right-radius: ${rem(16)};
`;

const Dim = styled(motion.div, {
  shouldForwardProp: (propName) => propName !== 'zIndex',
})<{ zIndex: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: ${vars.$semantic.color.overlayDim};
`;

const CloseButton = styled.button`
  position: absolute;
  right: ${rem(16)};
  display: flex;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
`;

const Content = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: auto;
  width: 100%;
  position: relative;
  ${safeAreaInset({ paddingBottom: 0 })}
`;
