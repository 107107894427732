/**
 * @generated SignedSource<<0159b2716a6880812143b7c33e80cb80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccidentType = "ABSOLUTE_NO_ACCIDENT" | "ACCIDENT" | "NO_ACCIDENT";
import { FragmentRefs } from "relay-runtime";
export type ArticleMetaInfoSection_article$data = {
  readonly carData: {
    readonly accidentType: AccidentType | null | undefined;
    readonly carHistory: {
      readonly " $fragmentSpreads": FragmentRefs<"carHistory_getCarHistoriesExpenseTotalAmount">;
    } | null | undefined;
  };
  readonly ownerNameVerified: boolean;
  readonly user: {
    readonly selfVerified: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useIsWarrantable_article">;
  readonly " $fragmentType": "ArticleMetaInfoSection_article";
};
export type ArticleMetaInfoSection_article$key = {
  readonly " $data"?: ArticleMetaInfoSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleMetaInfoSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleMetaInfoSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selfVerified",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerNameVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accidentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CarHistory",
          "kind": "LinkedField",
          "name": "carHistory",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "carHistory_getCarHistoriesExpenseTotalAmount",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CarHistoryAccident",
                  "kind": "LinkedField",
                  "name": "accidents",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expenseEstimate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "claim",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "40e67bc93cdd9e58a9fc9b78fc5fb4fe";

export default node;
