import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  ArticleChart_article$data,
  ArticleChart_article$key,
} from '@/__generated__/ArticleChart_article.graphql';
import { Unpack } from '@/types/global';
import { isInRange } from '@/utils/number';

import ArticleDailyChart from './ArticleDailyChart';
import ArticleOneDayChart from './ArticleOneDayChart';
import { ChartPeriod, EventItem } from './types';
import { getDiff } from './utils';

type Props = {
  articleRef: ArticleChart_article$key;
  period: ChartPeriod;
};
type StatisticsItem = Unpack<ArticleChart_article$data['statistics']>;

const ArticleChart: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, period }) => {
  const {
    events,
    statistics,
    statisticsIn48Hours,
    estimationsPromoted24HoursAgo,
    estimationsPromotedFromNowOn,
  } = useFragment(
    graphql`
      fragment ArticleChart_article on Article {
        events {
          t
          type
        }
        statistics {
          t
          view
        }
        statisticsIn48Hours {
          t
          view
        }
        estimationsPromoted24HoursAgo: statisticsPromoted24HoursAgo {
          t
          view
        }
        estimationsPromotedFromNowOn: statisticsPromotedFromNowOn {
          t
          view
        }
      }
    `,
    articleRef
  );

  const promotionEvents = events.filter((event) => event.type === 'PROMOTED');
  const expectedIncrease = getDiff(estimationsPromotedFromNowOn as StatisticsItem[]);
  const estimationTimestamps = estimationsPromoted24HoursAgo.map((estimation) => estimation.t);
  const [estimationStart, estimationEnd] = [
    Math.min(...estimationTimestamps),
    Math.max(...estimationTimestamps),
  ];
  const recentStatistics = statistics.filter((stat) =>
    isInRange(stat.t, estimationStart, estimationEnd)
  );
  const futureStatistics = statisticsIn48Hours.slice(
    0,
    estimationsPromoted24HoursAgo.length - recentStatistics.length + 1
  );

  const commonProps = {
    events: promotionEvents as EventItem[],
    expectedIncrease,
  };

  return (
    <Container>
      {period === '24h' ? (
        <ArticleOneDayChart
          {...commonProps}
          estimations={estimationsPromoted24HoursAgo as StatisticsItem[]}
          futureStatistics={futureStatistics as StatisticsItem[]}
          statistics={recentStatistics as StatisticsItem[]}
        />
      ) : (
        <ArticleDailyChart
          {...commonProps}
          estimations={estimationsPromotedFromNowOn as StatisticsItem[]}
          statistics={statistics as StatisticsItem[]}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(((100vw - ${rem(60)}) / 1.363) + ${rem(100)});
`;

export default ArticleChart;
