import { injectGlobal } from '@emotion/css';
import { vars } from '@seed-design/design-token';
import React, { useRef } from 'react';

const useHighlight = () => {
  const highlightedElementRef = useRef<HTMLElement | null>(null);

  const on = (elementRef: React.RefObject<HTMLElement>) => {
    if (!elementRef.current) {
      return;
    }
    const element = elementRef.current;
    highlightedElementRef.current = element;

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.body.classList.add('highlighted');
    element.classList.add('highlight');
    document.addEventListener('click', off, { once: true });
  };

  const off = () => {
    const element = highlightedElementRef.current;

    document.body.classList.remove('highlighted');
    if (element) {
      element.classList.remove('highlight');
    }
  };

  return { on, off };
};

injectGlobal`
  .highlight {
    pointer-events: auto;
    box-shadow: 0 0 0 99999px ${vars.$semantic.color.overlayDim};
    position: relative;
    z-index: 999;
    pointer-events: auto;
    transition: all 0.5s ease;
  }

  body.highlighted {
    pointer-events: none;
  }
`;

export default useHighlight;
