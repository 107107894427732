import { IconChattingThin, IconHeartRegular } from '@seed-design/icon';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleActionCount_article$key } from '@/__generated__/ArticleActionCount_article.graphql';
import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { Caption1Regular } from '@/components/Base/Text';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: ArticleActionCount_article$key;
};

const ArticleActionCount: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, ...props }) => {
  const article = useFragment(
    graphql`
      fragment ArticleActionCount_article on Article {
        voteCount
        chatRoomCount
      }
    `,
    articleRef
  );

  if (!article.chatRoomCount && !article.voteCount) {
    return null;
  }

  return (
    <HStack gap={8} {...props}>
      {article.chatRoomCount > 0 && (
        <HStack align="center" gap={2}>
          <IconChattingThin {...iconProps} />
          <Caption1Regular color="gray600">{article.chatRoomCount}</Caption1Regular>
        </HStack>
      )}
      {article.voteCount > 0 && (
        <HStack align="center" gap={2}>
          <IconHeartRegular {...iconProps} />
          <Caption1Regular color="gray600">{article.voteCount}</Caption1Regular>
        </HStack>
      )}
    </HStack>
  );
};

const iconProps = {
  ...getSizeProps(15),
  color: getSeedColor('gray600'),
};

export default ArticleActionCount;
