import { add, set } from 'date-fns';
import { RequestParameters, Variables } from 'relay-runtime';

const InspectionQueryNames = [
  'WriteReportQuery',
  // MechanicReport 필요해서 제외
  // 'WriteReportViewQuery',
  'WriteReportContentQuery',
  'WriteReportConfirmQuery',
];
const InspectionMutationNames = [
  'useEditCarDataMutation_editCarDataMutation',
  // MechanicReport 필요해서 제외
  // 'useEditMechanicReport_EditMechanicReportMutation',
  'useRejectMechanicReport_RejectMechanicReportMutation',
  'useSubmitMechanicReport_SubmitMechanicReportMutation',
];

export function isFakeInspectionQuery(params: RequestParameters, variables: Variables) {
  return (
    InspectionQueryNames.includes(params.name) &&
    Number(variables.inspectionId) === Number.MAX_SAFE_INTEGER
  );
}

export function isFakeInspectionMutation(params: RequestParameters, variables: Variables) {
  return (
    InspectionMutationNames.includes(params.name) &&
    Number(variables.inspectionId) === Number.MAX_SAFE_INTEGER
  );
}

const fakeCarNo = '999당9999';

export function getFakeInspectionMutationResponse(params: RequestParameters) {
  switch (params.name) {
    case 'useEditCarDataMutation_editCarDataMutation':
      return {
        data: {
          editInspectableCarData: {
            carNo: fakeCarNo,
          },
        },
      };
    case 'useRejectMechanicReport_RejectMechanicReportMutation':
      return {
        data: {
          rejectMechanicReport: true,
        },
      };
    case 'useSubmitMechanicReport_SubmitMechanicReportMutation':
      return new Promise<any>((resolve) => {
        const delay = 1500;
        setTimeout(() => {
          resolve({
            data: {
              submitMechanicReport: {
                id: `${Number.MAX_SAFE_INTEGER}`,
              },
            },
          });
        }, delay);
      });
    default:
      throw new Error('정의되지 않은 fake mutation 입니다.');
  }
}

const twoDaysAfter = set(add(new Date(), { days: 2 }), {
  hours: 19,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});
export const mockedInspectionResponse = {
  data: {
    mechanicReport: null,
    inspection: {
      carData: {
        carNo: fakeCarNo,
        carRegistrationYear: 2016,
        carRegistrationMonth: 2,
        modelYear: 2016,
        fuelType: 'GASOLINE',
        gear: 'AUTO',
        displacement: 998,
        driveDistance: 12345,
        carType: 'SMALL',
        autoupModel: {
          company: {
            name: '당근',
            id: '2',
          },
          series: {
            name: '테스트',
            id: '64',
          },
          subseries: {
            name: '멋있는 차량',
            id: '1332',
            imgUrl: 'https://zdata.nicednr.co.kr:8181/carFile/20151215/m.png',
          },
          grade: {
            id: '6938',
            name: '(테스트 차량)',
          },
          subgrade: {
            name: '(실제 데이터 아님)',
            id: '18949',
          },
        },
        carHistory: {
          collectedAt: '2023-12-08T00:00:00.000Z',
          uninsured: [
            {
              from: '2015-01-01T00:00:00.000Z',
              to: '2023-09-01T00:00:00.000Z',
            },
          ],
          uninsuredTotalYear: 8,
          uninsuredTotalMonth: 8,
          numOfTotalAccidents: 0,
          numOfTheftAccidents: 0,
          numOfSubmergedAccidents: 0,
          accidents: [
            {
              accidentAt: '2019-10-10T00:00:00.000Z',
              expenseEstimate: 1384052,
              claim: 1640000,
              type: 'OTHERS',
              expensePart: 790700,
              expenseWage: 103333,
              expensePainting: 490019,
              reserve: 0,
              repaired: ['휀더(우측)(교체)'],
            },
          ],
          gov: false,
          generalBusiness: false,
          rent: false,
          changes: [
            {
              date: '2015-01-05T00:00:00.000Z',
              type: 'REGISTER',
              carNo: '19버XXXX',
            },
            {
              date: '2023-10-06T00:00:00.000Z',
              type: 'OWNER',
              carNo: null,
            },
            {
              date: '2023-10-16T00:00:00.000Z',
              type: 'OWNER',
              carNo: null,
            },
          ],
        },
      },
      status: 'INSPECTION_STARTED',
      fixedDate: twoDaysAfter.toISOString(),
      id: `${Number.MAX_SAFE_INTEGER}`,
    },
  },
};
