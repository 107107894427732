import type { MapLibreGLGeoJSONSourceRaw } from '@daangn/maps';
import type { GeoJSONSourceOptions } from 'mapbox-gl';

export const toGeoJsonSourceRaw = (
  source: GeoJSONSourceOptions['data']
): MapLibreGLGeoJSONSourceRaw => {
  return {
    type: 'geojson',
    data: source,
  };
};
