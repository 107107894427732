import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleTransactionHistorySection_article$key } from '@/__generated__/ArticleTransactionHistorySection_article.graphql';
import Divider from '@/components/Base/Divider';
import GlobalLoading from '@/components/Base/GlobalLoading';
import VStack from '@/components/Base/VStack';
import TransactionHistoryItem from '@/components/TransactionHistoryItem';
import useTrack from '@/hooks/useTrack';
import useTradedArticles from '@/hooks/useTradedArticles';
import { useFlow } from '@/stackflow';

import SameCarTransactionHistoryItem from './SameCarTransactionHistoryItem';
import SectionTitle from './SectionTitle';

type Props = {
  articleRef: ArticleTransactionHistorySection_article$key;
};

const LOAD_COUNT = 20;
const MAX_DISPLAY_COUNT = 3;

const ArticleTransactionHistorySection: React.FC<Props> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const article = useFragment(
    graphql`
      fragment ArticleTransactionHistorySection_article on Article {
        id
        carData {
          autoupModel {
            subseries {
              id
              name
            }
            grade {
              id
              name
            }
            subgrade {
              id
            }
          }
        }
        resaleOriginArticle {
          id
        }
        ...SameCarTransactionHistoryItem_article
      }
    `,
    articleRef
  );
  const {
    id,
    carData: { autoupModel },
    resaleOriginArticle,
  } = article;
  const { subseries, grade, subgrade } = autoupModel ?? {};
  const tradedArticles = useTradedArticles({
    subseriesId: subseries?.id ?? '',
    gradeId: grade?.id,
    subgradeId: subgrade?.id,
    count: LOAD_COUNT,
  });
  const filteredArticles = tradedArticles.filter(
    (tradedArticle) =>
      ![resaleOriginArticle?.id, article.id].filter(Boolean).includes(tradedArticle.id)
  );

  if (!subseries?.id) {
    return null;
  }

  const handleClickMore = () => {
    push('subseries_transaction_history', { subseriesId: subseries.id, articleId: id });
    track('article_click_transaction_history_more');
  };

  const handleClickItem = (clickedId: string) => {
    track('article_click_transaction_history_item', { clickedId });
  };

  return (
    <Container>
      <SectionTitle
        css={css`
          margin-bottom: ${rem(12)};
        `}
        onClickMore={filteredArticles.length > MAX_DISPLAY_COUNT ? handleClickMore : undefined}
      >
        당근에서 거래된 다른 차량의
        <br />
        판매가와 비교해보세요
      </SectionTitle>
      <React.Suspense fallback={<GlobalLoading />}>
        <SameCarTransactionHistoryItem articleRef={article} />
        <Divider />
        <VStack divider={<Divider />} gap={1}>
          {filteredArticles.slice(0, MAX_DISPLAY_COUNT).map((tradedArticle) => (
            <TransactionHistoryItem
              articleRef={tradedArticle}
              key={tradedArticle.id}
              onClick={handleClickItem}
            />
          ))}
        </VStack>
      </React.Suspense>
    </Container>
  );
};

const Container = styled(VStack)({
  borderRadius: rem(10),
});

export default ArticleTransactionHistorySection;
