import { AdditionalOptionNames } from '@/types/Article/AdditionalOption';
import { Option } from '@/types/Article/Option';

export const getSplittedOptions = (options: Option[] = []) => {
  const additionalOptionNames = AdditionalOptionNames as ReadonlyArray<Option>;
  const carOptions = options.filter((option) => !additionalOptionNames.includes(option));
  const additionalOptions = options.filter((option) => additionalOptionNames.includes(option));

  return { options: carOptions, additionalOptions };
};
