import { match } from 'ts-pattern';

import type { Distance } from './allDistances';

export const getResolutionByDistance = (distance: Distance) => {
  return match(distance)
    .with(25, () => 6 as const)
    .with(50, () => 5 as const)
    .with(100, () => 4 as const)
    .with(999, () => 1 as const)
    .exhaustive();
};
