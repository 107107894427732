import { useStorageState as _useStorageState } from '@toss/react';
import { v4 as uuidv4 } from 'uuid';

import { FilterState } from '@/components/Filter/FilterContextProvider';
import CONFIG from '@/constants/config';
import { FormArticle } from '@/store/article';
import { WriteStep } from '@/store/write';

export const StorageKey = {
  NotificationMaxReached: 'NotificationMaxReached',
  TempArticle: 'TempArticle_v2',
  TempWriteStep: 'TempWriteStep',
  Filter: 'Filter2',
  GAClientID: 'ga:clientId1',
};

class BaseStorage {
  prefix: string;
  storage: Storage;

  constructor(prefix: string, storage: Storage) {
    this.prefix = prefix;
    this.storage = storage;
  }

  getItem(key: string): null | string {
    return this.storage.getItem(this.getKey(key));
  }

  protected getKey(key: string) {
    return `${this.prefix}#${key}`;
  }

  removeItem(key: string) {
    this.storage.removeItem(this.getKey(key));
  }

  setItem(key: string, value: string) {
    this.storage.setItem(this.getKey(key), value);
  }
}

class CarStorage extends BaseStorage {
  getFilter(): FilterState | null {
    const filter = this.getItem(StorageKey.Filter);

    if (!filter) {
      return null;
    }

    try {
      return JSON.parse(filter);
    } catch (e) {
      return null;
    }
  }

  getGaClientId(): string {
    const clientId = this.getItem(StorageKey.GAClientID) ?? uuidv4();

    this.setItem(StorageKey.GAClientID, clientId);

    return clientId;
  }

  getTempArticle(): FormArticle | null {
    const tempArticle = this.getItem(StorageKey.TempArticle);
    const parsed = JSON.parse(tempArticle || '{}');
    if (!parsed.ownerName || !parsed.carNo) {
      return null;
    }
    return parsed;
  }

  getTempWriteStep(): WriteStep {
    return (this.getItem(StorageKey.TempWriteStep) as WriteStep) ?? WriteStep.CarInfo;
  }

  setFilter(filter: FilterState) {
    this.setItem(StorageKey.Filter, JSON.stringify(filter));
  }

  setTempArticle(article: any) {
    this.setItem(StorageKey.TempArticle, JSON.stringify(article));
  }

  setTempWriteStep(step: WriteStep) {
    this.setItem(StorageKey.TempWriteStep, step);
  }
}

class SessionStorage extends BaseStorage {
  getReferrer(): null | string {
    return this.getItem('referrer');
  }

  setReferrer(referrer?: string) {
    if (!this.getReferrer() && referrer) {
      this.setItem('referrer', referrer);
    }
  }
}

export const carStorage = new CarStorage(CONFIG.SERVICE_KEY, window.localStorage);
export const sessionStorage = new SessionStorage(CONFIG.SERVICE_KEY, window.sessionStorage);
export const commonStorage = new CarStorage('CAR_COMMON', window.localStorage);

export type CarStorageKind = BaseStorage | CarStorage | SessionStorage;

export const useStorageState: typeof _useStorageState = (key: string, options?: any) => {
  return _useStorageState(`${CONFIG.SERVICE_KEY}#${key}`, options);
};
