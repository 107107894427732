import { useStack } from '@stackflow/react';
import { useCallback } from 'react';

import { _useStepFlow, TypeUseStepFlow } from './Stack';

export const useStepFlow: TypeUseStepFlow = (activityName) => {
  const actions = _useStepFlow(activityName);
  const stack = useStack();
  const stepPush = useCallback<(typeof actions)['stepPush']>(
    (...params) => {
      if (stack.globalTransitionState === 'loading') {
        return undefined;
      }
      return actions.stepPush(...params);
    },
    [actions, stack.globalTransitionState]
  );
  const stepReplace = useCallback<(typeof actions)['stepReplace']>(
    (...params) => {
      if (stack.globalTransitionState === 'loading') {
        return undefined;
      }
      return actions.stepReplace(...params);
    },
    [actions, stack.globalTransitionState]
  );
  const stepPop = useCallback<(typeof actions)['stepPop']>(
    (...params) => {
      if (stack.globalTransitionState === 'loading') {
        return undefined;
      }
      return actions.stepPop(...params);
    },
    [actions, stack.globalTransitionState]
  );
  return {
    ...actions,
    stepPush,
    stepReplace,
    stepPop,
  };
};
