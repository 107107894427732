import constate from 'constate';
import { useState } from 'react';
import { graphql } from 'relay-runtime';

import { FeatureFlagContextQuery } from '@/__generated__/FeatureFlagContextQuery.graphql';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import useFetchQuery from '@/hooks/useFetchQuery';

const useFeatureFlag = () => {
  const [isEnabledPriceEstimation, setIsEnabledPriceEstimation] = useState(false);
  const [isEnabledWarranty, setIsEnabledWarranty] = useState(false);

  const fetchFeatureFlag = useFetchQuery<FeatureFlagContextQuery>(graphql`
    query FeatureFlagContextQuery {
      features {
        enabled
        name
      }
    }
  `);

  useEffectOnce(async () => {
    const { features } = await fetchFeatureFlag({});
    setIsEnabledPriceEstimation(
      features.some(({ enabled, name }) => name === 'PRICE_REDUCTION_REPORT' && enabled)
    );
    setIsEnabledWarranty(features.some(({ enabled, name }) => name === 'WARRANTY' && enabled));
  });

  return { isEnabledPriceEstimation, isEnabledWarranty };
};

export const [FeatureFlagContextProvider, useFeatureFlagContext] = constate(useFeatureFlag);
