/**
 * @generated SignedSource<<24e4ecaa9ddb15c1712777afffb0e7d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CarHistoryChangeType = "CAR_NO" | "OWNER" | "REGISTER";
import { FragmentRefs } from "relay-runtime";
export type ArticleStories_article$data = {
  readonly carData: {
    readonly carHistory: {
      readonly changes: ReadonlyArray<{
        readonly date: any;
        readonly type: CarHistoryChangeType;
      }>;
    } | null | undefined;
  };
  readonly createdAt: any | null | undefined;
  readonly driveDistance: number;
  readonly " $fragmentSpreads": FragmentRefs<"getLastTransferInspection_article" | "parseOptions_article">;
  readonly " $fragmentType": "ArticleStories_article";
};
export type ArticleStories_article$key = {
  readonly " $data"?: ArticleStories_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleStories_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "driveDistance",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleStories_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarHistory",
          "kind": "LinkedField",
          "name": "carHistory",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CarHistoryChange",
              "kind": "LinkedField",
              "name": "changes",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getLastTransferInspection_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NiceDnrHistory",
              "kind": "LinkedField",
              "name": "niceDnrHistory",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "parseOptions_article",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarData",
          "kind": "LinkedField",
          "name": "carData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "options",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "782e7d505cead403e35070ca939d5b9a";

export default node;
