import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import type { useCanProxyInspection_query$key } from '@/__generated__/useCanProxyInspection_query.graphql';

import { Nullish } from '@/types/global';

const useCanProxyInspection = (articleRef: Nullish<useCanProxyInspection_query$key>) => {
  const queryRef = useFragment(
    graphql`
      fragment useCanProxyInspection_query on Query
      @argumentDefinitions(articleId: { type: "ID!" }) {
        isProxyInspectableArticle(articleId: $articleId) {
          result
        }
      }
    `,
    articleRef
  );
  return !!queryRef?.isProxyInspectableArticle.result;
};

export default useCanProxyInspection;
