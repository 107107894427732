import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { Props as LabelProps } from 'recharts/types/component/Label';

import { ExtendedEventType } from './types';

type Props = {
  marginX?: number;
  marginY?: number;
  type: ExtendedEventType;
} & LabelProps;

const LABEL_WIDTH = 46;
const ESTIMATION_LABEL_WIDTH = 54;

const PromotionLabel: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  viewBox,
  marginX = 0,
  marginY = -30,
}) => {
  const isRealPromotion = ['PROMOTED', 'PROMOTION_ENDED'].includes(type);
  const width = isRealPromotion ? LABEL_WIDTH : ESTIMATION_LABEL_WIDTH;
  const getLabelText = () => {
    switch (type) {
      case 'PROMOTED':
        return '광고 시작';
      case 'PROMOTION_ENDED':
        return '광고 종료';
      case 'ESTIMATION':
        return '광고했을 때';
      default:
        return '';
    }
  };

  return (
    <foreignObject
      {...viewBox}
      height={22}
      width={width}
      x={(viewBox as any)?.x ? (viewBox as any).x - width / 2 + (marginX ?? 0) : 0}
      y={(viewBox as any)?.y ? (viewBox as any).y + (marginY ?? 0) : 0}
    >
      <Container type={type}>
        <Text type={type}>{getLabelText()}</Text>
      </Container>
    </foreignObject>
  );
};

const Container = styled.div<Pick<Props, 'type'>>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ type }) => {
    switch (type) {
      case 'ESTIMATION':
        return rem(ESTIMATION_LABEL_WIDTH);
      default:
        return rem(LABEL_WIDTH);
    }
  }};
  height: ${rem(16)};
  border-radius: ${rem(2)};
  background: ${({ type }) => {
    switch (type) {
      case 'ESTIMATION':
        return vars.$scale.color.carrotAlpha100;
      default:
        return vars.$scale.color.carrot500;
    }
  }};

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: ${rem(-5)};
    border-width: ${rem(5)};
    border-style: solid;
    border-color: ${({ type }) => {
      switch (type) {
        case 'ESTIMATION':
          return vars.$scale.color.carrotAlpha100;
        default:
          return vars.$scale.color.carrot500;
      }
    }} transparent transparent transparent;
`;

const Text = styled.p<Pick<Props, 'type'>>`
  ${vars.$semantic.typography.label6Bold}
  color: ${({ type }) => {
    switch (type) {
      case 'ESTIMATION':
        return vars.$semantic.color.primary;
      default:
        return vars.$scale.color.gray00;
    }
  }}
`;

export default PromotionLabel;
