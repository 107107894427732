import { useCallback, useState } from 'react';
import { graphql } from 'relay-runtime';

import { useGetCarHistory_GetCarHistoryMutation } from '@/__generated__/useGetCarHistory_GetCarHistoryMutation.graphql';
import { useGetCarHistoryQuery } from '@/__generated__/useGetCarHistoryQuery.graphql';
import useFetchQuery from '@/hooks/useFetchQuery';
import useMutationAsync from '@/hooks/useMutationAsync';
import { sleep } from '@/utils/misc';

const Query = graphql`
  query useGetCarHistoryQuery($id: ID!) {
    article(id: $id) {
      article {
        carData {
          carHistoryStatus
          carHistory {
            collectedAt
            ...InsuranceSection_carHistory
            ...AccidentSection_carHistory
            ...CarDamageSection_carHistory
            ...SpecialUseSection_carHistory
            ...OwnerChangeSection_carHistory
            ...CarNumberChangeSection_carHistory
          }
        }
      }
    }
  }
`;

const Mutation = graphql`
  mutation useGetCarHistory_GetCarHistoryMutation($id: ID!) {
    getCarHistory(articleId: $id) {
      carData {
        carHistoryStatus
        carHistory {
          collectedAt
          ...InsuranceSection_carHistory
          ...AccidentSection_carHistory
          ...CarDamageSection_carHistory
          ...SpecialUseSection_carHistory
          ...OwnerChangeSection_carHistory
          ...CarNumberChangeSection_carHistory
        }
      }
    }
  }
`;

const useGetCarHistory = () => {
  const fetchCarHistory = useFetchQuery<useGetCarHistoryQuery>(Query);
  const [mutation, isMutationInFlight] =
    useMutationAsync<useGetCarHistory_GetCarHistoryMutation>(Mutation);
  const [isLoading, setLoading] = useState(false);

  const getCarHistory = useCallback(
    async ({ id }: { id: string }) => {
      try {
        setLoading(true);
        await mutation({ variables: { id } });
        let count = 0;

        while (count++ < 10) {
          const { article } = await fetchCarHistory({ id });
          const carData = article?.article?.carData;

          if (!carData) {
            continue;
          }
          const { carHistory, carHistoryStatus } = carData;

          if (carHistoryStatus === 'OWNED') {
            return carHistory;
          }
          await sleep(3000);
        }
        throw new Error(
          '보험 이력을 불러오는 데 시간이 걸리고 있어요. 등록이 완료되면 알려드릴게요.'
        );
      } catch (e: any) {
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    },
    [mutation, fetchCarHistory]
  );

  return [getCarHistory, isMutationInFlight || isLoading] as const;
};

export default useGetCarHistory;
