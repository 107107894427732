import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';

import BottomSheetItem from '@/components/Base/BottomSheet/BottomSheetItem';
import useTrack from '@/hooks/useTrack';

import { SortText, useFilterContext, useFilterUpdaterContext } from './FilterContextProvider';

const sortItems = (['LATEST', 'CHEAPEST'] as const).map((value) => ({
  title: SortText[value],
  value,
}));

const SortFilter: React.FC = () => {
  const { sort } = useFilterContext();
  const { updateOpenedFilter, updateSort, startTransition } = useFilterUpdaterContext();
  const { trackWithActivityName } = useTrack();

  const handleChange = (nextValue: 'CHEAPEST' | 'LATEST') => {
    updateOpenedFilter(null);
    startTransition(() => {
      updateSort(nextValue);
    });
    trackWithActivityName('click_change_filter_sort', { sort: nextValue });
  };

  return (
    <Container>
      {sortItems.map(({ title, value }) => (
        <BottomSheetItem
          active={sort === value}
          key={value}
          onClick={() => handleChange(value)}
          value={value}
        >
          {title}
        </BottomSheetItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: ${rem(2)} ${rem(16)} ${rem(9)};
  position: relative;
`;

export default SortFilter;
