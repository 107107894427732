/**
 * @generated SignedSource<<05d2facb4defe42b46154c4c1f471964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type InspectionStatus = "BUYER_REQUESTED" | "CANCELED" | "COMPLETED" | "DISCONNECTED" | "INSPECTION_STARTED" | "MATCHED" | "MATCH_FAILED" | "PENDING" | "REJECTED" | "WAITING_FOR_REVIEW";
import { FragmentRefs } from "relay-runtime";
export type isInspectedCar_article$data = {
  readonly carData: {
    readonly inspection: {
      readonly isMechanicReportVisible: boolean;
      readonly status: InspectionStatus;
    } | null | undefined;
  };
  readonly " $fragmentType": "isInspectedCar_article";
};
export type isInspectedCar_article$key = {
  readonly " $data"?: isInspectedCar_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"isInspectedCar_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "isInspectedCar_article"
};

(node as any).hash = "4b07e8cb2006f50841e51ec5f47c5b2d";

export default node;
