import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { PropsWithChildren } from 'react';

import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { BodyL1Regular, Subtitle1Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { exactSize } from '@/styles/mixins';

type Props = {
  number: number;
  title: React.ReactNode;
};

const NumberedSection: React.FC<PropsWithChildren<Props>> = ({ number, title, children }) => {
  return (
    <HStack align="flex-start" gap={8} w="100%">
      <Circle align="center" bg="gray100" br={100} justify="center" mt={1}>
        <Number color="gray800">{number}</Number>
      </Circle>
      <VStack align="flex-start" gap={4}>
        <Subtitle1Bold>{title}</Subtitle1Bold>
        {children && <BodyL1Regular color="gray700">{children}</BodyL1Regular>}
      </VStack>
    </HStack>
  );
};

const Circle = styled(HStack)(() => [exactSize('both', 20)]);
const Number = styled.p({
  color: getSeedColor('gray800'),
  fontSize: rem(12),
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export default NumberedSection;
