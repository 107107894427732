import { StackflowPlugin } from '@stackflow/core';

import { getInfo } from '@/sdk/bridge/info';
import { carTracker } from '@/sdk/tracker/CarTracker';

function pageViewPlugin(): StackflowPlugin {
  return () => ({
    key: 'plugin-pageview',
    async onInit() {
      const info = await getInfo();
      carTracker.init(info);
    },
  });
}

export default pageViewPlugin;
