import { ReactNode } from 'react';

import HStack from '@/components/Base/HStack';
import Text from '@/components/Base/Text';

type Props = {
  isBold?: boolean;
  isPositive?: boolean;
  label: string;
  value: ReactNode;
};

const Row: React.FC<Props> = ({ label, value, isPositive = false, isBold = false }) => {
  return (
    <HStack justify="space-between" w="100%">
      <Text color="gray900" variant={isBold ? 'label3Bold' : 'label3Regular'}>
        {label}
      </Text>
      <Text color={isPositive ? 'success' : 'gray600'} variant="label3Bold">
        {value}
      </Text>
    </HStack>
  );
};

export default Row;
