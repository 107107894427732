import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { ArticleError_articleResponse$key } from '@/__generated__/ArticleError_articleResponse.graphql';
import { ArticleErrorMessage } from '@/constants/message';

type Props = {
  articleResponseRef: ArticleError_articleResponse$key;
};

const ArticleError: React.FC<React.PropsWithChildren<Props>> = ({ articleResponseRef }) => {
  const { errors } = useFragment(
    graphql`
      fragment ArticleError_articleResponse on ArticleResponse {
        errors {
          type
        }
      }
    `,
    articleResponseRef
  );
  const message =
    ArticleErrorMessage[errors?.[0]?.type as string] ?? '매물 정보를 불러올 수 없어요.';

  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: ${rem(188)};
`;

const Message = styled.p`
  color: ${vars.$scale.color.gray600};
  text-align: center;
  ${vars.$semantic.typography.bodyL1Regular}
`;

export default ArticleError;
