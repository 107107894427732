import { vars } from '@seed-design/design-token';
import { IconMenuRegular, IconWriteRegular } from '@seed-design/icon';
import React from 'react';

import HStack from '@/components/Base/HStack';
import ScreenHelmet, { ScreenHelmetProps } from '@/components/Base/ScreenHelmet';
import VStack from '@/components/Base/VStack';
import CONFIG from '@/constants/config';
import { useOverlayState } from '@/hooks/useOverlayState';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { getSizeProps } from '@/utils/style';

import DrawerMenu from './DrawerMenu';

type Props = ScreenHelmetProps;

const MainHelmet: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { track } = useTrack();
  const [, openDrawer] = useOverlayState('drawer');
  const { push } = useFlow();

  const handleClickWrite = () => {
    push('fetch_car_info', {});
    track('main_click_write_article');
  };

  const handleClickDrawer = () => {
    openDrawer();
    track('main_click_open_drawer');
  };

  return (
    <>
      <ScreenHelmet
        renderRight={() => (
          <HStack align="center" gap={4}>
            <VStack
              align="center"
              aria-label="글쓰기"
              justify="center"
              onClick={handleClickWrite}
              px={6}
              py={6}
              role="button"
            >
              <IconWriteRegular {...iconProps} />
            </VStack>
            <VStack
              align="center"
              aria-label="메뉴"
              justify="center"
              onClick={handleClickDrawer}
              px={6}
              py={6}
              role="button"
            >
              <IconMenuRegular {...iconProps} />
            </VStack>
          </HStack>
        )}
        title={CONFIG.SERVICE_NAME}
        {...props}
      />
      <DrawerMenu onClickWrite={handleClickWrite} />
    </>
  );
};

const iconProps = {
  ...getSizeProps(24),
  color: vars.$scale.color.gray900,
};

export default MainHelmet;
