export function assert(
  condition: unknown,
  error: Error | string = new Error('올바르지 않은 접근이에요.')
): asserts condition {
  if (!condition) {
    if (typeof error === 'string') {
      throw new Error(error);
    } else {
      throw error;
    }
  }
}
