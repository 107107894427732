import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics';
import { vars } from '@seed-design/design-token';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import { stackflow } from '@stackflow/react';

import routes from '@/bootstrap/routes';
import { environment, karrotBridge } from '@/sdk/bridge';
import bridgeSwipeBackPlugin from '@/stackflow/plugins/bridgeSwipeBack';
import { getSizeProps } from '@/utils/style';

import { CarActivities } from './Activity';
import pageViewPlugin from './plugins/pageView';
import popPlugin from './plugins/pop';
import routeWrapperPlugin from './plugins/routeWrapper';
import titleSyncPlugin from './plugins/titleSync';

const isAndroid = environment === 'Android';

const activityMap = Object.fromEntries(routes.map((route) => [route.name, route.component]));
const routeMap = Object.fromEntries(
  routes.map((route) => [
    route.name,
    {
      path: route.path,
      decode: route.decode,
    },
  ])
);

const { Stack, useFlow, useStepFlow, activities, actions } = stackflow({
  transitionDuration: 350,
  activities: activityMap as unknown as CarActivities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme: isAndroid ? 'android' : 'cupertino',
      appBar: {
        borderColor: isAndroid ? vars.$semantic.color.divider2 : vars.$semantic.color.divider3,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        backButton: {
          renderIcon() {
            return <IconChevronLeftRegular {...getSizeProps(24)} />;
          },
          ariaLabel: '뒤로가기',
        },
        closeButton: {
          ariaLabel: '나가기',
          onClick() {
            karrotBridge.closeRouter({});
          },
        },
      },
    }),
    historySyncPlugin({
      routes: routeMap,
      fallbackActivity: () => 'main',
    }),
    titleSyncPlugin(),
    routeWrapperPlugin(),
    bridgeSwipeBackPlugin(),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'car',
      itemId: {
        article: ({ articleId }) => {
          return articleId ?? '';
        },
        trade_review: ({ tradeReviewId }) => {
          return tradeReviewId ?? '';
        },
      },
    }),
    pageViewPlugin(),
    popPlugin(),
  ],
});

export { Stack, actions, activities, useStepFlow as _useStepFlow };
export type TypeUseFlow = typeof useFlow;
export type TypeUseStepFlow = typeof useStepFlow;
export type TypeActivities = typeof activities;
