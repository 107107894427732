/**
 * @generated SignedSource<<090952e443df6616226a9c383eefcc60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticlePromotionSection_article$data = {
  readonly createdAt: any | null | undefined;
  readonly promotionEndsAt: any | null | undefined;
  readonly region: {
    readonly id: string;
    readonly name1: string;
    readonly name2: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ArticlePromotionTimer_article">;
  readonly " $fragmentType": "ArticlePromotionSection_article";
};
export type ArticlePromotionSection_article$key = {
  readonly " $data"?: ArticlePromotionSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlePromotionSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticlePromotionSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promotionEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name2",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePromotionTimer_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "e87c21a448da7081a0b836501030c84c";

export default node;
