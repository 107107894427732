import {
  IconAndroidShareFill,
  IconAndroidShareRegular,
  IconAndroidShareThin,
  IconIosShareFill,
  IconIosShareRegular,
  IconIosShareThin,
} from '@seed-design/icon';
import { rem } from 'polished';

import { IS_ANDROID } from '@/constants/config';
import { PropsOf } from '@/types/React/PropsOf';
import { filterNil } from '@/utils/misc';

type ShareIcon =
  | typeof IconAndroidShareFill
  | typeof IconAndroidShareRegular
  | typeof IconAndroidShareThin
  | typeof IconIosShareFill
  | typeof IconIosShareRegular
  | typeof IconIosShareThin;
type Props = {
  height?: number;
  onClick?: () => void;
  weight?: 'fill' | 'regular' | 'thin';
  width?: number;
} & PropsOf<ShareIcon>;

const Share: React.FC<React.PropsWithChildren<Props>> = ({
  weight = 'regular',
  width,
  height,
  ...props
}) => {
  const AndroidIcon = {
    thin: IconAndroidShareThin,
    regular: IconAndroidShareRegular,
    fill: IconAndroidShareFill,
  }[weight];
  const IosIcon = {
    thin: IconIosShareThin,
    regular: IconIosShareRegular,
    fill: IconIosShareFill,
  }[weight];
  const IconComponent = IS_ANDROID ? AndroidIcon : IosIcon;

  return (
    <IconComponent
      {...props}
      {...filterNil({
        width: width ? rem(width) : undefined,
        height: height ? rem(height) : undefined,
      })}
    />
  );
};

export default Share;
