import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import { useIsInspectableRegionQuery } from '@/__generated__/useIsInspectableRegionQuery.graphql';
import useUser from '@/hooks/useUser';

const Query = graphql`
  query useIsInspectableRegionQuery($regionId: ID!, $skip: Boolean!) {
    isInspectableRegion(regionId: $regionId) @skip(if: $skip)
  }
`;

const useIsInspectableRegion = () => {
  const { region } = useUser();
  const { isInspectableRegion } = useLazyLoadQuery<useIsInspectableRegionQuery>(Query, {
    regionId: `${region?.id}`,
    skip: !region?.id,
  });

  return isInspectableRegion ?? false;
};

export default useIsInspectableRegion;
