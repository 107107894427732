import { graphql } from 'react-relay';

import { useIncreaseViewCount_IncreaseViewCountMutation } from '@/__generated__/useIncreaseViewCount_IncreaseViewCountMutation.graphql';
import useMutationAsync from '@/hooks/useMutationAsync';

const Mutation = graphql`
  mutation useIncreaseViewCount_IncreaseViewCountMutation($id: String!, $from: FromWhere) {
    increaseArticleViewCount(articleId: $id, fromWhere: $from) {
      viewCount
    }
  }
`;

const useIncreaseViewCount = () => {
  return useMutationAsync<useIncreaseViewCount_IncreaseViewCountMutation>(Mutation);
};

export default useIncreaseViewCount;
