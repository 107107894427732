import { compareDesc, format } from 'date-fns';
import { graphql, readInlineData } from 'relay-runtime';

import { carHistory_getCarHistoriesExpenseTotalAmount$key } from '@/__generated__/carHistory_getCarHistoriesExpenseTotalAmount.graphql';
import { carHistory_summaryCarHistoryAccident$key } from '@/__generated__/carHistory_summaryCarHistoryAccident.graphql';
import { carHistory_summaryCarHistoryAccidents$key } from '@/__generated__/carHistory_summaryCarHistoryAccidents.graphql';
import { carHistory_summaryCarHistoryOwnerChanges$key } from '@/__generated__/carHistory_summaryCarHistoryOwnerChanges.graphql';
import { Nullish } from '@/types/global';

import { getCommaFormat, sum } from './number';

export const getCountText = (count: number) => {
  if (!count) {
    return '';
  }
  return `${count}회`;
};

export const summaryCarHistoryOwnerChanges = (
  carHistoryRef: carHistory_summaryCarHistoryOwnerChanges$key
) => {
  const { changes } = readInlineData(
    graphql`
      fragment carHistory_summaryCarHistoryOwnerChanges on CarHistory @inline {
        changes {
          date
          type
        }
      }
    `,
    carHistoryRef
  );
  const ownerChanges = changes
    .filter((change) => change.type === 'OWNER')
    .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
  return { ownerChanges, description: getCountText(ownerChanges.length) };
};

export const summaryCarHistoryAccident = (
  accidentRef: carHistory_summaryCarHistoryAccident$key
) => {
  const {
    accidentAt,
    expensePart,
    expenseWage,
    expensePainting,
    expenseEstimate,
    claim,
    reserve,
    repaired,
  } = readInlineData(
    graphql`
      fragment carHistory_summaryCarHistoryAccident on CarHistoryAccident @inline {
        accidentAt
        expensePart
        expenseWage
        expensePainting
        expenseEstimate
        claim
        reserve
        repaired
      }
    `,
    accidentRef
  );
  const hasEstimate = expenseEstimate > 0;
  const dateText =
    `${format(new Date(accidentAt), 'yyyy년 M월 d일')}` +
    (!hasEstimate && claim > 0 ? '(미수선)' : '');
  const description = (() => {
    if (hasEstimate) {
      return `${getCommaFormat(expenseEstimate)}원`;
    }
    if (claim > 0) {
      return `${getCommaFormat(claim)}원` + (reserve > 0 ? ' + 미확정' : '');
    }
    return '보험료 미확정';
  })();
  return {
    hasEstimate,
    dateText,
    description,
    expensePart,
    expenseWage,
    expensePainting,
    repaired,
  };
};

export const summaryCarHistoryAccidents = (
  ref: carHistory_summaryCarHistoryAccidents$key,
  type: 'mine' | 'other'
) => {
  const { accidents } = readInlineData(
    graphql`
      fragment carHistory_summaryCarHistoryAccidents on CarHistory @inline {
        accidents {
          accidentAt
          expenseEstimate
          claim
          type
          ...carHistory_summaryCarHistoryAccident
        }
      }
    `,
    ref
  );

  const filteredAccidents = accidents
    .filter((accident) =>
      (type === 'mine' ? ['MINE_INSURED_BY_MINE', 'MINE_INSURED_BY_OTHER'] : ['OTHERS']).includes(
        accident.type
      )
    )
    .sort((a, b) => compareDesc(new Date(a.accidentAt), new Date(b.accidentAt)));
  const expenseTotal = sum(
    filteredAccidents.map(({ expenseEstimate, claim }) => expenseEstimate || claim)
  );
  const description = (() => {
    if (!filteredAccidents.length) {
      return '';
    }
    const expenseText = expenseTotal > 0 ? ` (${getCommaFormat(expenseTotal)}원)` : '';
    return getCountText(filteredAccidents.length) + expenseText;
  })();
  return {
    count: filteredAccidents.length,
    title: type === 'mine' ? '내 차 피해 이력' : '상대 차 가해 이력',
    filteredAccidents,
    description,
    expenseTotal,
  };
};

export const getCarHistoriesExpenseTotalAmount = (
  ref: Nullish<carHistory_getCarHistoriesExpenseTotalAmount$key>
) => {
  if (!ref) {
    return {
      count: 0,
      expenseTotalAmount: 0,
    };
  }
  const { accidents } = readInlineData(
    graphql`
      fragment carHistory_getCarHistoriesExpenseTotalAmount on CarHistory @inline {
        accidents {
          expenseEstimate
          claim
        }
      }
    `,
    ref
  );

  return {
    count: accidents.length,
    expenseTotalAmount: accidents.reduce((result, next) => {
      return result + (next.expenseEstimate || next.claim);
    }, 0),
  };
};
