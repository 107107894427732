import React, { PropsWithChildren } from 'react';

import HStack from '@/components/Base/HStack';

type Props = {
  size: 'large' | 'small';
};

const WarrantyBadge: React.FC<PropsWithChildren<Props>> = ({ size, children, ...props }) => {
  return (
    <HStack
      align="center"
      bg="carrot50"
      br={2}
      justify="center"
      px={size === 'small' ? 4.8 : 6}
      py={size === 'small' ? 2.4 : 4}
      w="fit-content"
      {...props}
    >
      {children}
    </HStack>
  );
};

export default WarrantyBadge;
