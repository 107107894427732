import { useMemo } from 'react';

import FilterContextProvider, {
  defaultFilterState,
} from '@/components/Filter/FilterContextProvider';
import SyncFilterToStorage from '@/pages/Main/components/SyncFilterToStorage';
import { carStorage } from '@/utils/storage';
export const MainContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const initialFilterState = useMemo(() => {
    const savedFilter = carStorage.getFilter();
    return savedFilter ?? defaultFilterState;
  }, []);

  return (
    <FilterContextProvider
      activityName={'main'}
      allowedFilterFields={['companyOrigin', 'carTypes']}
      initialFilterOnce={initialFilterState}
    >
      {children}
      <SyncFilterToStorage />
    </FilterContextProvider>
  );
};
