/**
 * @generated SignedSource<<027a28e42ec896eb81d6ba253f325727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CarHistoryAccidentType = "MINE_INSURED_BY_MINE" | "MINE_INSURED_BY_OTHER" | "OTHERS";
export type CarHistoryChangeType = "CAR_NO" | "OWNER" | "REGISTER";
import { FragmentRefs } from "relay-runtime";
export type HistoryGrid_carHistory$data = {
  readonly accidents: ReadonlyArray<{
    readonly type: CarHistoryAccidentType;
  }>;
  readonly changes: ReadonlyArray<{
    readonly type: CarHistoryChangeType;
  }>;
  readonly generalBusiness: boolean;
  readonly numOfSubmergedAccidents: number;
  readonly numOfTotalAccidents: number;
  readonly rent: boolean;
  readonly " $fragmentType": "HistoryGrid_carHistory";
};
export type HistoryGrid_carHistory$key = {
  readonly " $data"?: HistoryGrid_carHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoryGrid_carHistory">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistoryGrid_carHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generalBusiness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarHistoryAccident",
      "kind": "LinkedField",
      "name": "accidents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarHistoryChange",
      "kind": "LinkedField",
      "name": "changes",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfTotalAccidents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfSubmergedAccidents",
      "storageKey": null
    }
  ],
  "type": "CarHistory",
  "abstractKey": null
};
})();

(node as any).hash = "e1a9f9cd2f56219247c8619eeb693d05";

export default node;
