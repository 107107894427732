import { flatten } from 'lodash-es';

import {
  ArticleImageType,
  getMergedImages_article$data,
} from '@/__generated__/getMergedImages_article.graphql';
import { ImageType } from '@/types/Article/ImageType';
import { Nullish } from '@/types/global';

export const getSortedImages = (images?: getMergedImages_article$data['images']) => {
  if (!images) {
    return [];
  }

  const sorted = [...images].sort((a, b) => {
    const getIndex = (imageType: Nullish<ArticleImageType>) => {
      const ImageTypeOrders: ReadonlyArray<ImageType> = [
        ImageType.EXTERIOR,
        ImageType.INTERIOR,
        ImageType.DOCUMENT,
        ImageType.UNKNOWN,
      ];
      if (!imageType) {
        return ImageTypeOrders.length;
      }
      return ImageTypeOrders.findIndex((type) => type === imageType);
    };
    return getIndex(a.type) - getIndex(b.type);
  });

  return flatten(sorted.map(({ images, type }) => images.map((v) => ({ ...v, type }))));
};
