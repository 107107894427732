import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconCalendarThin, IconChevronRightThin, IconViewCountRegular } from '@seed-design/icon';
import { format } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { TransactionHistoryItem_article$key } from '@/__generated__/TransactionHistoryItem_article.graphql';
import HStack from '@/components/Base/HStack';
import { Label3Regular, Label4Bold, Label4Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useFlow } from '@/stackflow';
import { getTradeDurationFromArticle } from '@/utils/article/getTradeDurationFromArticle';
import { getBillionFormatFromPrice, getShortenYear } from '@/utils/number';
import { getDriveDistanceText } from '@/utils/string';
import { getSizeProps } from '@/utils/style';

import Spacer from './Base/Spacer';

type Props = {
  articleRef: TransactionHistoryItem_article$key;
  highlighted?: boolean;
  onClick?: (id: string) => void;
  resaled?: boolean;
};

const TransactionHistoryItem: React.FC<Props> = ({
  articleRef,
  resaled = false,
  highlighted = false,
  onClick,
}) => {
  const { push } = useFlow();
  const article = useFragment(
    graphql`
      fragment TransactionHistoryItem_article on Article {
        id
        price
        minActualPrice
        driveDistance
        events {
          type
          t
        }
        carData {
          modelYear
          autoupModel {
            grade {
              name
            }
            subgrade {
              name
            }
          }
        }
        ...getTradeDurationFromArticle_article
      }
    `,
    articleRef
  );

  const { id, price, minActualPrice, driveDistance, carData, events } = article;
  const { modelYear, autoupModel } = carData;
  const { grade, subgrade } = autoupModel ?? {};

  const tradedEvent = events.find((event) => event.type === 'TRADED');
  const diff = getTradeDurationFromArticle(article);

  if (!highlighted && !tradedEvent?.t) {
    return null;
  }

  const handleClick = () => {
    if (highlighted) {
      return;
    }
    push('article_summary', { articleId: id });
    onClick?.(id);
  };

  const Icon = highlighted ? IconViewCountRegular : IconCalendarThin;

  return (
    <VStack gap={8} key={id} onClick={handleClick} py={12}>
      <HStack align="center" gap={4}>
        <Icon
          {...getSizeProps(16)}
          color={vars.$scale.color[highlighted ? 'gray900' : 'gray800']}
        />
        {highlighted ? (
          <HStack gap={4}>
            <Label4Bold color="gray900">지금 보고있는 차</Label4Bold>
            {resaled && <Label4Bold color="warning">재판매 차량</Label4Bold>}
          </HStack>
        ) : (
          <Label4Regular color="gray800">
            {format(new Date(tradedEvent?.t ?? Date.now()), 'yy년 M월 d일')}
          </Label4Regular>
        )}
        {diff <= 3 && !highlighted && (
          <Label4Bold color="accent">
            {diff <= 1 ? '하루' : `${diff}일`}만에 거래되었어요
          </Label4Bold>
        )}
      </HStack>
      <HStack align="center" gap={10}>
        <DynamicWidthBlock>
          <Label3Regular wb="break-word">
            {[grade?.name, subgrade?.name]
              .filter((name) => Boolean(name) && name !== '없음')
              .join(' ')}
          </Label3Regular>
        </DynamicWidthBlock>
        <FixedWidthBlock width={72}>
          <Label3Regular>{getBillionFormatFromPrice(minActualPrice ?? price ?? 0)}원</Label3Regular>
        </FixedWidthBlock>
        <FixedWidthBlock width={50}>
          <Label3Regular>{getShortenYear(modelYear)}년식</Label3Regular>
        </FixedWidthBlock>
        <FixedWidthBlock width={70}>
          <Label3Regular>{getDriveDistanceText(driveDistance)}km</Label3Regular>
        </FixedWidthBlock>
        <FixedWidthBlock width={14}>
          {highlighted ? (
            <Spacer size={14} />
          ) : (
            <IconChevronRightThin {...getSizeProps(14)} color={vars.$scale.color.gray900} />
          )}
        </FixedWidthBlock>
      </HStack>
    </VStack>
  );
};

const DynamicWidthBlock = styled.div`
  flex: 1 1 auto;
`;

const FixedWidthBlock = styled.div<{ width: number }>`
  display: flex;
  min-width: ${({ width }) => rem(width)};
  flex: 0 0 ${({ width }) => rem(width)};
`;

export default TransactionHistoryItem;
