import { ALL_BIG_PICTURE_CDN_IMAGE_URLS } from '@/constants/config';

import { getUrlWithQuery } from './url';

type ImageSource = 'big_picture' | 'external';
const getImageSource = (src: string): ImageSource => {
  if (ALL_BIG_PICTURE_CDN_IMAGE_URLS.some((url) => src.startsWith(url))) {
    return 'big_picture';
  }
  return 'external';
};

// https://daangn.slack.com/archives/C02SLBZBYM8/p1662445451904049?thread_ts=1662444804.150399&cid=C02SLBZBYM8
type BigPictureImageType = 'cover' | 'crop' | 'inside';
// https://github.com/daangn/hoian-lambda-thumbnail-v2/blob/a5e2a4eb71213a135d5a74959e324881810bcce5/lambda/origin-response-function/index.js#L25
type BigPictureImageFormat = 'gif' | 'jpeg' | 'jpg' | 'png' | 'svg' | 'tiff' | 'webp';
type BigPictureImageUrlParams = {
  f: BigPictureImageFormat;
  q: number;
  // `{width}x{height}`의 형식입니다
  s: `${number}x${number}`;
  // inside일 경우 height는 9999이하의 자를 수 있는 최대 수로 대체됩니다.
  t: BigPictureImageType;
};
const makeBigPictureImageParams = (option: ImageOption): BigPictureImageUrlParams => {
  return {
    f: option.format ?? 'jpeg',
    q: option.quality ?? 95,
    s: `${option.width}x${option.height ?? 9999}`,
    t: option.height ? 'crop' : 'inside',
  };
};

type ImageOption = {
  blur?: boolean;
  format?: 'jpeg' | 'webp';
  height?: number;
  quality?: number;
  width: number;
};

export const getImageUrl = (url: string, option: ImageOption) => {
  const source = getImageSource(url);
  if (source === 'external') {
    return url;
  }

  return getUrlWithQuery(url, makeBigPictureImageParams(option));
};

export const isCDNImageUrl = (src: string): boolean => {
  const source = getImageSource(src);
  return !(source === 'external');
};

export const getSrcSet = (src?: string) => {
  if (!src || !isCDNImageUrl(src)) {
    return undefined;
  }
  const widths = [200, 375, 580, 768, 960, 1440, 2048, 5120];
  const urls = widths.map(
    (width) =>
      `${getImageUrl(src, {
        width,
        format: 'webp',
      })} ${width}w`
  );
  return urls.join(',\n');
};

export const getLowScaleImageSrc = (src?: string) => {
  if (!src || !isCDNImageUrl(src)) {
    return undefined;
  }
  return getImageUrl(src, {
    width: 50,
    quality: 5,
    format: 'webp',
    blur: true,
  });
};
