import { TextButton } from '@daangn/sprout-components-button';
import { Divider } from '@daangn/sprout-components-divider';
import { IconCheckRegular, IconChevronRightFill } from '@seed-design/icon';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleProxyInspectionSection_article$key } from '@/__generated__/ArticleProxyInspectionSection_article.graphql';
import { getSeedColor } from '@/components/Base/Box';
import Center from '@/components/Base/Center';
import { ColoredSpan } from '@/components/Base/ColoredText';
import { GradientVStack } from '@/components/Base/GradientVStack';
import HStack from '@/components/Base/HStack';
import { Label3Bold, Subtitle2Regular, Title3Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: ArticleProxyInspectionSection_article$key;
};

const ArticleProxyInspectionSection: React.FC<Props> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const { id } = useFragment(
    graphql`
      fragment ArticleProxyInspectionSection_article on Article {
        id
      }
    `,
    articleRef
  );

  const handleClick = () => {
    push('article_proxy_inspection_guide', { articleId: id });
    track('article_click_proxy_inspection_section_more');
  };

  return (
    <GradientVStack>
      <VStack gap={16} pa={16}>
        <Title3Bold>
          차 볼 시간이 없어서 못 가시나요?
          <br />
          <ColoredSpan>전문진단사가 대신 가서 봐드려요</ColoredSpan>
        </Title3Bold>
        <VStack bg="paperContents" br={6} gap={8} pa={16}>
          <Label3Bold>전문진단사 출장 진단</Label3Bold>
          {[
            '엔진/미션 등 186개의 항목 진단',
            '사고 이력, 차량 상태 확인',
            '정비/교체 필요한 부분 안내',
          ].map((description, index) => (
            <HStack align="center" gap={8} key={index}>
              <IconCheckRegular {...getSizeProps(16)} color={getSeedColor('accent')} />
              <Subtitle2Regular color="gray800">{description}</Subtitle2Regular>
            </HStack>
          ))}
        </VStack>
      </VStack>
      <Divider />
      <Center py={8} w="100%">
        <TextButton bold onClick={handleClick} size="small" style="basic" variant="secondaryLow">
          <HStack align="center">
            더 알아보기
            <IconChevronRightFill {...getSizeProps(14)} color={getSeedColor('gray600')} />
          </HStack>
        </TextButton>
      </Center>
    </GradientVStack>
  );
};

export default ArticleProxyInspectionSection;
