export enum ReservationStep {
  // 사전점검 선택
  InputSellingType = 'sellingType',
  // 차량정보 확인
  CheckCarInformation = 'carInfo',
  // 장소 입력
  InputReservationAddress = 'address',
  // 날짜, 시간 입력
  InputReservationTimes = 'dates',
  // 연락처 입력
  InputUserAuthentication = 'contact',
  // 예약 정보 확인하고 예약하기
  PreConfirmReservation = 'preconfirm',
  // 예약 성공
  SuccessReservation = 'success',
}
