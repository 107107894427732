import { Portal } from '@daangn/karrot-clothes';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconNotificationFill } from '@seed-design/icon';
import { useActivity } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import type { CreateNewArticleNotificationConditionV2Input } from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';

import { FloatingCreateNotificationButton_query$key } from '@/__generated__/FloatingCreateNotificationButton_query.graphql';
import CreateNotificationRenderer, {
  type CreateNotificationRendererProps,
} from '@/components/Notification/CreateNotificationRenderer';
import useTrack from '@/hooks/useTrack';
import { safeAreaInset } from '@/styles/mixins';
import { getSizeProps } from '@/utils/style';

type Props = {
  queryRef: FloatingCreateNotificationButton_query$key;
  selectedCondition: CreateNewArticleNotificationConditionV2Input;
  visible: boolean;
};

const FloatingCreateNotificationButton: React.FC<React.PropsWithChildren<Props>> = ({
  queryRef,
  visible,
  selectedCondition,
}) => {
  const { isTop, zIndex } = useActivity();
  const queryFragment = useFragment(
    graphql`
      fragment FloatingCreateNotificationButton_query on Query {
        ...CreateNotificationRenderer_query
      }
    `,
    queryRef
  );

  const { trackWithActivityName } = useTrack();

  const renderFloatingCreateNotificationButton: CreateNotificationRendererProps['render'] = ({
    enabled,
    createNotification,
    isInFlight,
  }: {
    createNotification: () => Promise<void>;
    enabled: boolean;
    hasNoDuplicatedNotification: boolean;
    isInFlight: boolean;
    isNotReachedNotificationLimit: boolean;
    isSelectedConditionAvailable: boolean;
  }) => {
    const handleClick = async () => {
      await createNotification();
      trackWithActivityName('click_create_notification', {});
    };

    return visible && enabled && isTop ? (
      <Button
        animate={{ translateY: 0, translateX: '-50%' }}
        disabled={isInFlight}
        exit={{ translateY: 112, translateX: '-50%' }}
        initial={{ translateY: 64, translateX: '-50%' }}
        onClick={handleClick}
        transition={{ duration: 0.45, ease: [0.34, 1.56, 0.64, 1] }}
        // https://github.com/daangn/stackflow/blob/5de913f1cc19092d88ed6bd7b248ccec90a4b75f/extensions/plugin-basic-ui/src/components/AppScreen.tsx#L88
        zIndex={zIndex * 5 + 3}
      >
        <IconNotificationFill {...getSizeProps(16)} color={vars.$scale.color.gray100} />
        <Text>이 조건으로 알림 받기</Text>
      </Button>
    ) : null;
  };

  return (
    <Portal selector="#root">
      <AnimatePresence>
        <CreateNotificationRenderer
          queryRef={queryFragment}
          render={renderFloatingCreateNotificationButton}
          selectedCondition={selectedCondition}
        />
      </AnimatePresence>
    </Portal>
  );
};

const Button = styled(motion.button, {
  shouldForwardProp: (propName) => propName !== 'zIndex',
})<{ zIndex: number }>`
  display: flex;
  align-items: center;
  padding: ${rem(8)} ${rem(16)};
  position: fixed;
  left: 50%;
  ${safeAreaInset({ bottom: 16 })}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: ${rem(100)};
  background: ${vars.$scale.color.gray900};
  z-index: ${({ zIndex }) => zIndex}};
`;

const Text = styled.p`
  margin-left: ${rem(4)};
  color: ${vars.$scale.color.gray00};
  ${vars.$semantic.typography.label3Regular};
`;

export default FloatingCreateNotificationButton;
