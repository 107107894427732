import { graphql, readInlineData } from 'relay-runtime';

import { getTradeDurationFromArticle_article$key } from '@/__generated__/getTradeDurationFromArticle_article.graphql';

import { getTradeDuration } from './getTradeDuration';

export const getTradeDurationFromArticle = (
  articleRef: getTradeDurationFromArticle_article$key
) => {
  const { events } = readInlineData(
    graphql`
      fragment getTradeDurationFromArticle_article on Article @inline {
        events {
          type
          t
        }
      }
    `,
    articleRef
  );

  return getTradeDuration(events);
};
