import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import {
  IconChevronRightRegular,
  IconNotificationFill,
  IconWarningRegular,
} from '@seed-design/icon';
import { josa } from '@toss/hangul';
import { rem } from 'polished';
import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { NewArticleNotificationSection_article$key } from '@/__generated__/NewArticleNotificationSection_article.graphql';
import useTrack from '@/hooks/useTrack';
import { exactSize } from '@/styles/mixins';
import { getSizeProps } from '@/utils/style';

import NewArticleNotificationBottomSheet from './NewArticleNotificationBottomSheet';

type Props = {
  articleRef: NewArticleNotificationSection_article$key;
  visible: boolean;
};

const NewArticleNotificationSection: React.FC<Props> = ({ articleRef, visible }) => {
  const { track } = useTrack();
  const [isOpened, setOpened] = useState(false);

  const { id, carData } = useFragment(
    graphql`
      fragment NewArticleNotificationSection_article on Article {
        id
        carData {
          autoupModel {
            subseries {
              id
              name
              ...NewArticleNotificationBottomSheet_subseries
            }
          }
        }
      }
    `,
    articleRef
  );
  const { autoupModel } = carData;
  const subseriesId = autoupModel?.subseries?.id;
  const subseriesName = autoupModel?.subseries?.name ?? '';

  const loggerParams = { article_id: id, subseries_id: subseriesId, subseries_name: subseriesName };

  if (!visible || !subseriesName || !autoupModel) {
    return null;
  }

  const handleClickOpen = () => {
    setOpened(true);
    track('article_click_open_new_article_notification', loggerParams);
  };
  const handleClickClose = () => {
    setOpened(false);
    track('article_click_close_new_article_notification', loggerParams);
  };

  return (
    <>
      <Container>
        <Button onClick={handleClickOpen}>
          <Content>
            <Circle>
              <IconNotificationFill
                {...getSizeProps(19.2)}
                color={vars.$semantic.color.onPrimary}
              />
            </Circle>
            <TextWrapper>
              <Title>{josa(subseriesName, '을/를')} 찾고 계세요?</Title>
              <Description>새로운 매물이 등록되면 알려드릴게요</Description>
            </TextWrapper>
          </Content>
          <IconWrapper>
            <IconChevronRightRegular {...getSizeProps(20)} color={vars.$scale.color.carrot950} />
          </IconWrapper>
        </Button>
        <InfoTextWrapper>
          <IconWarning {...getSizeProps(14)} color={vars.$scale.color.gray600} />
          <InfoText>알림은 언제든지 해제할 수 있어요.</InfoText>
        </InfoTextWrapper>
      </Container>
      {autoupModel.subseries && (
        <NewArticleNotificationBottomSheet
          active={isOpened}
          onClickClose={handleClickClose}
          subseriesRef={autoupModel.subseries}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${rem(20)};
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)} ${rem(16)};
  background-color: ${vars.$scale.color.carrotAlpha50};
  border-radius: ${rem(8)};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${exactSize('both', 32)}
  border-radius: 50%;
  margin-right: ${rem(12)};
  background-color: ${vars.$semantic.color.primary};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Title = styled.p`
  ${vars.$semantic.typography.subtitle2Bold}
  color: ${vars.$scale.color.carrot950};
`;

const Description = styled.p`
  ${vars.$semantic.typography.subtitle2Regular}
  color: ${vars.$scale.color.carrot950};
  margin-top: ${rem(4)};
`;

const IconWrapper = styled.div`
  ${exactSize('both', 20)}
`;

const InfoTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(8)};
`;

const InfoText = styled.p`
  ${vars.$semantic.typography.caption2Regular};
  color: ${vars.$scale.color.gray600};
`;

const IconWarning = styled(IconWarningRegular)`
  margin-right: ${rem(4)};
`;

export default NewArticleNotificationSection;
