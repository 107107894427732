import React, { useCallback, useTransition } from 'react';

import Box from '@/components/Base/Box';
import {
  useFilterContext,
  useFilterUpdaterContext,
} from '@/components/Filter/FilterContextProvider';
import NoResult from '@/components/NoResult';
import { useToast } from '@/hooks/useToast';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import useUser from '@/hooks/useUser';

const NoMatchedArticles: React.FC = () => {
  const [isPending, startTransition] = useTransition();
  const {
    filter: { distance },
  } = useFilterContext();
  const { resetFilter } = useFilterUpdaterContext();
  const update = useUpdateFilter();
  const {
    region: { name3 },
  } = useUser();
  const { setToast } = useToast();

  const handleClickResetFilter = useCallback(() => {
    startTransition(() => {
      resetFilter();
    });
  }, [resetFilter, startTransition]);

  const handleClickDistanceFilter = useCallback(() => {
    startTransition(() => {
      update({
        distance: 999,
      });
    });
    setToast({
      message: '동네 범위가 ‘전국’으로 변경되었어요.',
    });
  }, [setToast, update]);

  type Desc = { buttonText: string; description: string; onClickButton: () => void; title: string };
  const desc: Desc =
    distance === 999
      ? {
          buttonText: '필터 초기화',
          description: '필터를 초기화하거나 조건을 변경해 보세요.',
          onClickButton: handleClickResetFilter,
          title: '등록된 매물이 없어요',
        }
      : {
          buttonText: '전국 매물 보기',
          description: '전국에서 찾아볼까요?',
          onClickButton: handleClickDistanceFilter,
          title: `‘${name3} ${distance}km’ 범위에
등록된 매물이 없어요`,
        };

  return (
    <Box py={24}>
      <NoResult
        buttonProps={{
          children: desc.buttonText,
          isLoading: isPending,
          onClick: desc.onClickButton,
        }}
        description={desc.description}
        title={desc.title}
      />
    </Box>
  );
};

export default NoMatchedArticles;
