import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { CSSProperties } from 'react';

import { type SeedColor, getSeedColor } from '@/components/Base/Box';

export const noScrollbar = css`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ellipsis = (line = 1) => css`
  text-overflow: ellipsis;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

export const pressed = (
  defaultColor: SeedColor = 'paperDefault',
  activeColor: SeedColor = 'grayPressed'
) => css`
  background-color: ${getSeedColor(defaultColor)};
  transition: background-color 300ms;
  &:active {
    background-color: ${getSeedColor(activeColor)};
    transition: background-color 0s;
  }
`;

export const exactSize = (attr: 'both' | 'height' | 'width', size: number): SerializedStyles => {
  const sizeInRem = rem(size);

  if (attr === 'both') {
    return css`
      ${(['width', 'height'] as const).map((prop) => exactSize(prop, size))};
    `;
  }

  return css`
    ${attr}: ${sizeInRem};
    min-${attr}: ${sizeInRem};
    max-${attr}: ${sizeInRem};
  `;
};

type Direction = 'bottom' | 'left' | 'right' | 'top';
type AllDirectionProperty<T extends string> = `${T}Bottom` | `${T}Left` | `${T}Right` | `${T}Top`;

export const safeAreaInset = <
  T extends Pick<
    CSSProperties,
    AllDirectionProperty<'margin'> | AllDirectionProperty<'padding'> | Direction
  >,
>(
  properties: T
): SerializedStyles => {
  const propertiesWithFallback = Object.entries(properties).reduce((obj, [key, value]) => {
    const valueInRem = rem(value);
    const postfix = (['left', 'right', 'top', 'bottom'] as const).find((direction) =>
      key.toLowerCase().includes(direction)
    );

    if (!postfix) {
      return obj;
    }

    return {
      ...obj,
      [key]: [valueInRem, `calc(${valueInRem} + env(safe-area-inset-${postfix}))`],
    };
  }, {});

  return css(propertiesWithFallback);
};

export const SafeAreaInsetTopDiv = styled.div<{ height?: number }>(({ height = 0 }) =>
  safeAreaInset({
    paddingTop: height,
  })
);

export const SafeAreaInsetBottomDiv = styled.div<{ height?: number }>(({ height = 0 }) =>
  safeAreaInset({
    paddingBottom: height,
  })
);
