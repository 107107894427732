import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';

type Props = {
  isLoading?: boolean;
  onClickConfirm?: () => void;
  onClickRefresh?: () => void;
};

const FilterButtonFooter: React.FC<React.PropsWithChildren<Props>> = ({
  onClickConfirm,
  onClickRefresh,
  isLoading,
}) => {
  return (
    <ButtonWrapper>
      <BoxButton onClick={onClickRefresh} size="xlarge" variant="secondary">
        초기화
      </BoxButton>
      <BoxButton isLoading={isLoading} onClick={onClickConfirm} size="xlarge" variant="primary">
        적용하기
      </BoxButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '3fr 7fr',
  gridGap: rem(8),
  padding: `${rem(12)} ${rem(16)} ${rem(8)}`,
});

export default FilterButtonFooter;
