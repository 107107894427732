import { rem } from 'polished';
import React from 'react';

const Manner1: React.FC<React.PropsWithChildren<unknown>> = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height={rem(24)}
      viewBox="0 0 24 24"
      width={rem(24)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FADD65"
      />
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_844_18062)"
      />
      <path
        clipRule="evenodd"
        d="M7.34994 18.75L7.06053 18.2677C6.79415 18.4275 6.70777 18.773 6.8676 19.0394C7.02735 19.3057 7.37261 19.3921 7.63894 19.2326L7.63864 19.2328L7.63834 19.2329L7.63834 19.2329C7.63837 19.2329 7.63858 19.2328 7.63895 19.2326L7.63934 19.2323L7.64495 19.2291L7.67572 19.2116C7.70445 19.1956 7.74939 19.1711 7.80953 19.1403C7.92988 19.0785 8.11065 18.9913 8.34371 18.8945C8.8106 18.7007 9.48278 18.4707 10.2963 18.3292C11.9186 18.047 14.0955 18.1168 16.3518 19.527C16.6153 19.6916 16.9623 19.6116 17.1269 19.3481C17.2916 19.0847 17.2115 18.7376 16.9481 18.573C14.4044 16.9832 11.9313 16.9029 10.1036 17.2208C9.1921 17.3793 8.43928 17.6368 7.91241 17.8555C7.64861 17.965 7.44031 18.0652 7.29582 18.1394C7.22353 18.1765 7.16711 18.2071 7.12758 18.2292C7.10781 18.2402 7.09225 18.2491 7.08104 18.2556L7.06749 18.2635L7.06318 18.2661L7.06165 18.267L7.06104 18.2674C7.06078 18.2675 7.06053 18.2677 7.34994 18.75Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.08361 12.3808C6.37037 12.2613 6.6997 12.3969 6.81918 12.6837C7.12729 13.4232 7.56192 13.6299 7.89242 13.6512C8.26608 13.6753 8.63943 13.4768 8.83192 13.188C9.00425 12.9295 9.35349 12.8597 9.61197 13.032C9.87045 13.2043 9.9403 13.5536 9.76798 13.8121C9.36047 14.4233 8.60882 14.8248 7.81999 14.7739C6.98798 14.7202 6.22261 14.1769 5.78072 13.1164C5.66124 12.8296 5.79684 12.5003 6.08361 12.3808Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.9164 12.3808C18.2031 12.5003 18.3387 12.8296 18.2192 13.1164C17.7774 14.1769 17.012 14.7202 16.18 14.7739C15.3911 14.8248 14.6395 14.4233 14.232 13.8121C14.0597 13.5536 14.1295 13.2043 14.388 13.032C14.6465 12.8597 14.9957 12.9295 15.168 13.188C15.3605 13.4768 15.7339 13.6753 16.1075 13.6512C16.438 13.6299 16.8727 13.4232 17.1808 12.6837C17.3003 12.3969 17.6296 12.2613 17.9164 12.3808Z"
        fill="#895F00"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.5738 9.26106C17.6782 9.43996 17.6177 9.66957 17.4389 9.77393L13.8389 11.8739C13.7229 11.9416 13.5796 11.9421 13.4631 11.8752C13.3467 11.8083 13.2749 11.6843 13.2749 11.55V10.2C13.2749 9.99291 13.4428 9.82501 13.6499 9.82501C13.857 9.82501 14.0249 9.99291 14.0249 10.2V10.8971L17.061 9.1261C17.2398 9.02174 17.4695 9.08217 17.5738 9.26106Z"
        fill="#C69238"
        fillRule="evenodd"
        opacity="0.9"
      />
      <path
        clipRule="evenodd"
        d="M6.43007 9.25446C6.53807 9.07774 6.76888 9.02203 6.9456 9.13002L6.55451 9.76999C6.37779 9.66199 6.32208 9.43118 6.43007 9.25446ZM6.55451 9.76999L6.67289 9.84213C6.74869 9.88826 6.85794 9.95463 6.99182 10.0357C7.25953 10.1977 7.62598 10.4185 8.02075 10.6535C8.8046 11.1201 9.71748 11.653 10.1823 11.8854C10.3676 11.978 10.5928 11.903 10.6855 11.7177C10.7781 11.5325 10.703 11.3072 10.5178 11.2146C10.0826 10.997 9.19551 10.4799 8.40436 10.009C8.01163 9.77525 7.64683 9.55544 7.38017 9.39404C7.24686 9.31335 7.13813 9.24729 7.06277 9.20143L6.9456 9.13002L6.55451 9.76999Z"
        fill="#C69238"
        fillRule="evenodd"
        opacity="0.9"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_844_18062"
          x1="11.9676"
          x2="11.9676"
          y1="-1.49475"
          y2="16.4565"
        >
          <stop stopColor="#3F61FF" stopOpacity="0.5" />
          <stop offset="0.0185834" stopColor="#4565F9" stopOpacity="0.5117" />
          <stop offset="0.2298" stopColor="#878DB4" stopOpacity="0.6446" />
          <stop offset="0.4206" stopColor="#BAAD7E" stopOpacity="0.7647" />
          <stop offset="0.5841" stopColor="#DEC76B" stopOpacity="0.8676" />
          <stop offset="0.7142" stopColor="#F4D865" stopOpacity="0.9495" />
          <stop offset="0.7945" stopColor="#FADD65" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Manner1;
