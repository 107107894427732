import { type SetStateAction, atom } from 'jotai';
import { RESET } from 'jotai/utils';
import { pick, throttle } from 'lodash-es';
import { commitMutation, graphql } from 'react-relay';
import { z } from 'zod';

import { article_DraftArticleMutation } from '@/__generated__/article_DraftArticleMutation.graphql';
import environment from '@/relay/environment';
import { AdditionalOptionNames } from '@/types/Article/AdditionalOption';
import { ConvenienceOptionNames } from '@/types/Article/ConvenienceOption';
import { ImageType } from '@/types/Article/ImageType';
import { InExteriorOptionNames } from '@/types/Article/InExteriorOption';
import { Option } from '@/types/Article/Option';
import { SafetyOptionNames } from '@/types/Article/SafetyOption';
import { AutoupModel } from '@/types/CarData/CarInfo';
import { DistributiveOmit } from '@/types/global';
import { type Image } from '@/types/Image/Image';
import {
  BaseArticleSchema,
  CreateArticlePayloadSchema,
  EditArticlePayloadSchema,
  ExtraIncludedInRentalFeeSchema,
  LeaseInfoSchema,
  LeaseRentalCommonInfoSchema,
  RentalInfoSchema,
} from '@/types/WriteArticle';
import { NUM_1만 } from '@/utils/number';
import { carStorage } from '@/utils/storage';

const imageTypes = [ImageType.EXTERIOR, ImageType.INTERIOR];

export type ExtraIncludedInLeaseFee = {
  acquisitionTax: boolean;
  carMaintenance: boolean;
  insurance: boolean;
};

export const defaultExtraIncludedInLeaseFee: ExtraIncludedInLeaseFee = {
  acquisitionTax: false,
  insurance: false,
  carMaintenance: false,
};

export type ExtraIncludedInRentalFee = z.input<typeof ExtraIncludedInRentalFeeSchema>;

export const defaultExtraIncludedInRentalFee: ExtraIncludedInRentalFee = {
  carTax: false,
  insurance: false,
  carMaintenance: false,
};

type LeaseRentalCommonInfo = z.input<typeof LeaseRentalCommonInfoSchema>;

const defaultLeaseRentalCommonInfo: LeaseRentalCommonInfo = {
  contractCompany: undefined,
  contractStartedAt: undefined,
  contractEndedAt: undefined,
  monthlyFee: undefined,
  residualValue: undefined,
  acquisitionPrice: undefined,
  postContractOption: undefined,
  deposit: undefined,
} as any;

export type LeaseInfo = z.input<typeof LeaseInfoSchema>;

export const defaultLeaseInfo: LeaseInfo = {
  ...defaultLeaseRentalCommonInfo,
  leaseType: 'OPERATING',
  extraIncludedInMonthlyFee: { ...defaultExtraIncludedInLeaseFee },
};

export type RentalInfo = z.input<typeof RentalInfoSchema>;

export const defaultRentalInfo: RentalInfo = {
  ...defaultLeaseRentalCommonInfo,
  extraIncludedInMonthlyFee: { ...defaultExtraIncludedInRentalFee },
};

export type Article = z.input<typeof BaseArticleSchema>;

export type CreateArticlePayload = z.input<typeof CreateArticlePayloadSchema>;
export type EditArticlePayload = z.input<typeof EditArticlePayloadSchema>;

export type FormArticle = {
  autoupModel?: Partial<AutoupModel>;
  imgUrl?: string;
  leaseInfo?: Partial<LeaseInfo>;
  price?: number;
  rentalInfo?: Partial<RentalInfo>;
} & Partial<
  DistributiveOmit<
    CreateArticlePayload | EditArticlePayload,
    'autoupModel' | 'leaseInfo' | 'rentalInfo'
  >
>;

export const defaultImages = imageTypes.map((type) => ({ type, images: [] as Image[] }));

export const defaultArticleForm: FormArticle = {
  carNo: '',
  carName: '',
  ownerName: '',
  options: [],
  images: [...defaultImages],
  content: '',
  autoupModel: {},
};

const TEMP_SAVE_DELAY = 5000;
const throttledSaveDraft = throttle(async (formArticle: FormArticle) => {
  const draftFields = [
    'carNo',
    'ownerName',
    'driveDistance',
    'content',
    'price',
    'fuelType',
    'gear',
    'modelYear',
    'carRegistrationYear',
    'carRegistrationMonth',
  ];
  commitMutation<article_DraftArticleMutation>(environment, {
    mutation: graphql`
      mutation article_DraftArticleMutation($input: DraftArticleInput!) {
        draftArticle(input: $input)
      }
    `,
    variables: {
      input: pick(formArticle, draftFields),
    },
  });
}, TEMP_SAVE_DELAY);
throttledSaveDraft;

export const _articleFormState = atom(defaultArticleForm);

export const articleFormState = atom(
  (get) => {
    return get(_articleFormState);
  },
  (get, set, newStateGetter: SetStateAction<FormArticle> | typeof RESET) => {
    if (newStateGetter === RESET) {
      set(_articleFormState, defaultArticleForm);
      return;
    }
    const newState =
      typeof newStateGetter === 'function'
        ? newStateGetter(get(_articleFormState))
        : newStateGetter;

    const getIndex = (option: Option) => {
      return [
        InExteriorOptionNames,
        SafetyOptionNames,
        ConvenienceOptionNames,
        AdditionalOptionNames,
      ].findIndex((optionNames) => (optionNames as ReadonlyArray<Option>).includes(option));
    };
    const getArticleType = (article: FormArticle) => {
      if (article.leaseInfo) {
        return 'LEASE' as const;
      }
      if (article.rentalInfo) {
        return 'RENTAL' as const;
      }
      return 'GENERAL' as const;
    };
    const sorted = {
      ...newState,
      options: [...(newState.options ?? [])].sort((a, b) => getIndex(a) - getIndex(b)),
    };
    const computed = {
      ...sorted,
      type: getArticleType(sorted),
    };
    if (!(sorted as EditArticlePayload).id && sorted.autoupModel?.company) {
      carStorage.setTempArticle(sorted);
      throttledSaveDraft(sorted);
    }

    set(_articleFormState, computed);
  }
);

export const MAX_DISTANCE = NUM_1만 * 1000;
