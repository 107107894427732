import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { bigFontCplState, bigFontState } from '@/store/dev';

const useSimulateBigFont = () => {
  const bigFont = useAtomValue(bigFontState);
  const CPL = useAtomValue(bigFontCplState);

  useEffect(() => {
    if (bigFont) {
      const nextFontSize = Math.round(window.innerWidth / CPL) + 'px';

      document.documentElement.style.fontSize = nextFontSize;
    } else {
      const DEFAULT_FONT_SIZE = '16px';
      document.documentElement.style.fontSize = DEFAULT_FONT_SIZE;
    }
  }, [bigFont, CPL]);

  return null;
};

export default useSimulateBigFont;
