import { useEnterDoneEffect } from '@stackflow/react';
import { useRef } from 'react';

export const useEnterDoneEffectOnce = (callback: (...args: any) => void) => {
  const once = useRef(false);
  useEnterDoneEffect(() => {
    if (once.current === false) {
      callback();
      once.current = true;
    }
  }, [callback, once]);
};
