import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconExpandMoreRegular } from '@seed-design/icon';
import { useActivity } from '@stackflow/react';
import { rem } from 'polished';
import React, { memo, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleStatusChangeSection_article$key } from '@/__generated__/ArticleStatusChangeSection_article.graphql';
import RenewedBottomSheet from '@/components/Base/BottomSheet/RenewedBottomSheet';
import RenewedBottomSheetItem from '@/components/Base/BottomSheet/RenewedBottomSheetItem';
import Spacer from '@/components/Base/Spacer';
import { useAlertDialog } from '@/hooks/useAlertDialog';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import useExecuteAfterLoad from '@/hooks/useExecuteAfterLoad';
import useHighlight from '@/hooks/useHighlight';
import useMyTradeReviews from '@/hooks/useMyTradeReviews';
import { useOverlayState } from '@/hooks/useOverlayState';
import useTrack from '@/hooks/useTrack';
import useUpdateArticleStatus from '@/hooks/useUpdateArticleStatus';
import useUser from '@/hooks/useUser';
import { useFlow } from '@/stackflow';
import { ArticleStatus, ArticleStatusText } from '@/types/Article/ArticleStatus';
import { sessionStorage } from '@/utils/storage';
import { getSizeProps } from '@/utils/style';

import { useArticleContext } from '../ArticleContext';

type Props = {
  articleRef: ArticleStatusChangeSection_article$key;
};

const ArticleStatusChangeSection: React.FC<Props> = ({ articleRef }) => {
  const { isMyArticle } = useArticleContext();
  const { isRoot } = useActivity();
  const { push } = useFlow();
  const { track } = useTrack();
  const { checkLogin } = useUser();
  const { on } = useHighlight();
  const execute = useExecuteAfterLoad();
  const { updateArticleStatus } = useUpdateArticleStatus();
  const myTradeReviews = useMyTradeReviews();
  const statusBoxRef = useRef<HTMLDivElement>(null);
  const [statusBoxOpened, openStatusBox, closeStatusBox] = useOverlayState('statusChangeMenu');
  const [askChangeStatusIfTradeReviewIsExist, askChangeStatusIfTradeReviewIsExistDialog] =
    useAlertDialog({
      id: 'askChangeStatusIfTradeReviewExist',
      description: '판매중으로 변경하면 서로 주고받은 거래후기가 취소돼요. 그래도 변경하시겠어요?',
      primaryActionLabel: '변경하기',
      secondaryActionLabel: '취소',
    });
  const [askChangeStatusWhileAd, askChangeStatusWhileAdDialog] = useAlertDialog({
    id: 'askChangeStatusWhileAd',
    description:
      '이 매물은 광고 중이에요. 매물 상태를 판매완료로 변경하면 이 매물의 광고는 더 이상 진행할 수 없고, 금액은 환불되지 않아요. 그래도 변경하시겠어요?',
    primaryActionLabel: '변경하기',
    secondaryActionLabel: '취소',
  });

  const { id, status, promotionEndsAt, chatRooms } = useFragment(
    graphql`
      fragment ArticleStatusChangeSection_article on Article {
        id
        status
        promotionEndsAt
        chatRooms {
          id
        }
      }
    `,
    articleRef
  );
  const tradeReview = myTradeReviews.find((review) => review.articleId === id);
  const handleChangeStatus = async (selectedStatus: ArticleStatus) => {
    if (!checkLogin()) {
      return;
    }
    closeStatusBox();
    if (promotionEndsAt && selectedStatus === ArticleStatus.Traded) {
      const answer = await askChangeStatusWhileAd();
      if (answer !== 'primary') {
        return undefined;
      }
    }
    if (status === 'TRADED' && selectedStatus === ArticleStatus.OnSale && tradeReview) {
      const answer = await askChangeStatusIfTradeReviewIsExist();
      if (answer !== 'primary') {
        return undefined;
      }
    }
    const changedStatus = await updateArticleStatus({ id, status: selectedStatus });
    track('article_click_change_status', { status: selectedStatus });

    if (!chatRooms || !chatRooms.length) {
      return;
    }
    if (changedStatus === 'TRADED') {
      return push('article_select_buyer', { articleId: id });
    }
    if (changedStatus === 'RESERVED') {
      return push('article_select_reserver', { articleId: id });
    }
  };

  useEffectOnce(() => {
    const referrer = sessionStorage.getReferrer();
    if (isRoot && referrer === 'unsynced_article_status') {
      execute(() => {
        setTimeout(() => {
          on(statusBoxRef);
        }, 300);
      });
    }
  });

  if (!isMyArticle) {
    return null;
  }

  return (
    <Container>
      <SelectBox onClick={openStatusBox} ref={statusBoxRef}>
        {ArticleStatusText[status]}
        {<IconExpand {...getSizeProps(16)} color={vars.$scale.color.gray900} />}
      </SelectBox>
      <Spacer axis="vertical" size={16} />
      <RenewedBottomSheet active={statusBoxOpened} onClickClose={closeStatusBox}>
        {Object.values(ArticleStatus)
          .filter((status) => status !== ArticleStatus.None)
          .map((status) => (
            <RenewedBottomSheetItem key={status} onClick={() => handleChangeStatus(status)}>
              {ArticleStatusText[status]}
            </RenewedBottomSheetItem>
          ))}
      </RenewedBottomSheet>
      {askChangeStatusWhileAdDialog}
      {askChangeStatusIfTradeReviewIsExistDialog}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectBox = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  padding: ${rem(8)} ${rem(14)};
  border-radius: ${rem(5)};
  border: 1px solid ${vars.$scale.color.gray300};
  ${vars.$semantic.typography.label3Bold};
`;

const IconExpand = styled(IconExpandMoreRegular)`
  margin-left: ${rem(4)};
`;

export default memo(ArticleStatusChangeSection);
