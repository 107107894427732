import { useEffect } from 'react';

import apis from '@/apis';

const useCheckVisited = () => {
  return useEffect(() => {
    apis.shortcut.visited();
  }, []);
};

export default useCheckVisited;
