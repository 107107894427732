import { josa } from '@toss/hangul';
import { graphql, useMutation } from 'react-relay';

import {
  ArticleStatus,
  useUpdateArticleStatus_UpdateArticleStatusMutation,
} from '@/__generated__/useUpdateArticleStatus_UpdateArticleStatusMutation.graphql';
import { useToast } from '@/hooks/useToast';
import { emitArticleUpdateEvent } from '@/sdk/bridge';
import { ArticleStatusText } from '@/types/Article/ArticleStatus';
import { handleError } from '@/utils/error';

const useUpdateArticleStatus = () => {
  const { setToast } = useToast();

  const [edit, isEditInFlight] = useMutation<useUpdateArticleStatus_UpdateArticleStatusMutation>(
    graphql`
      mutation useUpdateArticleStatus_UpdateArticleStatusMutation(
        $articleId: ID!
        $status: ArticleStatus!
      ) {
        result: updateArticleStatus(articleId: $articleId, status: $status) {
          status
          promotionEndsAt
        }
      }
    `
  );

  const updateArticleStatus = ({ id, status }: { id: string; status: ArticleStatus }) => {
    return new Promise<ArticleStatus>((resolve, reject) => {
      edit({
        variables: { articleId: id, status },
        onCompleted(response, errors) {
          if (errors) {
            return reject(errors);
          }
          const {
            result: { status: changedStatus },
          } = response;
          const statusText = ArticleStatusText[changedStatus];

          setToast(`게시물이 ${josa(statusText, '으로/로')} 변경되었어요.`);
          emitArticleUpdateEvent();

          return resolve(status);
        },
        onError(error) {
          const { message } = handleError(error, { reportToSentry: true });
          setToast(message);
          return reject(error);
        },
      });
    });
  };

  return { updateArticleStatus, isEditInFlight };
};

export default useUpdateArticleStatus;
