/**
 * @generated SignedSource<<0fb861c4a8bf04090db9e478c8db750b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleTransactionHistorySection_article$data = {
  readonly carData: {
    readonly autoupModel: {
      readonly grade: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly subgrade: {
        readonly id: string;
      } | null | undefined;
      readonly subseries: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
  };
  readonly id: string;
  readonly resaleOriginArticle: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SameCarTransactionHistoryItem_article">;
  readonly " $fragmentType": "ArticleTransactionHistorySection_article";
};
export type ArticleTransactionHistorySection_article$key = {
  readonly " $data"?: ArticleTransactionHistorySection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTransactionHistorySection_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTransactionHistorySection_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupModel",
          "kind": "LinkedField",
          "name": "autoupModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubseries",
              "kind": "LinkedField",
              "name": "subseries",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupGrade",
              "kind": "LinkedField",
              "name": "grade",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubgrade",
              "kind": "LinkedField",
              "name": "subgrade",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SanitizedArticle",
      "kind": "LinkedField",
      "name": "resaleOriginArticle",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SameCarTransactionHistoryItem_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "e35551c5914e3e37fb86278d8c3d378a";

export default node;
