import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';

const Section = {
  Container: styled.div`
    width: 100%;
    padding: ${rem(20)} 0;
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
  `,
  Title: styled.p`
    ${vars.$semantic.typography.subtitle2Bold}
    color: ${vars.$scale.color.gray900};
  `,
  Description: styled.p`
    ${vars.$semantic.typography.bodyL2Regular}
    margin-left: ${rem(4)};
    margin-bottom: ${rem(12)};
    color: ${vars.$scale.color.gray700};
  `,
  ButtonWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(12)} ${rem(-4)} ${rem(-4)} ${rem(-4)};

    & > * {
      margin: ${rem(4)};
    }
  `,
  SliderWrapper: styled.div`
    width: 100%;
    margin-bottom: ${rem(24)};
  `,
};

export default Section;
