import { BoxButton } from '@daangn/sprout-components-button';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { P, match } from 'ts-pattern';

import { ChatButton_article$key } from '@/__generated__/ChatButton_article.graphql';
import { ChatErrorMessage } from '@/constants/message';
import useIdentificationBottomSheet from '@/hooks/useIdentificationBottomSheet';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import { useToast } from '@/hooks/useToast';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { ArticleStatus } from '@/types/Article/ArticleStatus';
import { getArticleEventProperties } from '@/utils/article/getArticleEventProperties';

import { useArticleContext } from '../ArticleContext';

type Props = {
  articleRef: ChatButton_article$key;
};

const ChatButton: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { isMyArticle, refetch } = useArticleContext();
  const redirect = useRedirectAppScheme();
  const { push } = useFlow();
  const { track } = useTrack();
  const { setToast } = useToast();
  const article = useFragment(
    graphql`
      fragment ChatButton_article on Article {
        id
        isUserChatted
        chatRoomCount
        status
        chatChannelInfo {
          error
          targetUri
        }
        ...getArticleEventProperties_article
      }
    `,
    articleRef
  );
  const { id, isUserChatted, chatRoomCount, status, chatChannelInfo } = article;

  const moveToChat = (url: string) => {
    const UPDATE_DELAY = 500;

    redirect(url);
    setTimeout(() => {
      refetch();
    }, UPDATE_DELAY);
  };

  const openChannel = async () => {
    const error = chatChannelInfo.error;
    if (error) {
      const message = ChatErrorMessage[error] ?? '잠시 문제가 발생했어요. 다시 시도해 주세요.';
      setToast(message);
      return;
    }
    moveToChat(chatChannelInfo.targetUri);
  };

  const [checkIsVerified, identificationBottomSheetNode] = useIdentificationBottomSheet({
    previousScreenName: 'car_chat',
  });

  const handleClickChat = async () => {
    if (isMyArticle) {
      track('article_click_move_chat_list');
      push('article_channel_list', { articleId: id });
      return;
    }
    const selfVerified = await checkIsVerified();

    track(isUserChatted ? 'article_click_chat_already' : 'article_first_chat', {
      selfVerified,
      ...getArticleEventProperties(article),
    });

    if (!selfVerified) {
      return;
    }
    openChannel();
  };

  const chatButtonText = match([isMyArticle, status])
    .with([true, P.any], () => `채팅 목록 보기 (${chatRoomCount})`)
    .with([false, ArticleStatus.Traded], () => '거래완료')
    .with([false, ArticleStatus.Reserved], () => '예약중')
    .otherwise(() => '채팅하기');

  return (
    <>
      <BoxButton
        isDisabled={
          !isMyArticle && (status === ArticleStatus.Traded || status === ArticleStatus.Reserved)
        }
        onClick={handleClickChat}
        size="xlarge"
        variant="primary"
        width="100%"
      >
        {chatButtonText}
      </BoxButton>
      {identificationBottomSheetNode}
    </>
  );
};

export default ChatButton;
