import { TextButton } from '@daangn/sprout-components-button';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill, IconInfoThin } from '@seed-design/icon';
import { Fragment } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { P, match } from 'ts-pattern';

import { ArticleInspectionSection_article$key } from '@/__generated__/ArticleInspectionSection_article.graphql';
import { ArticleInspectionSectionQuery } from '@/__generated__/ArticleInspectionSectionQuery.graphql';
import Center from '@/components/Base/Center';
import { ColoredSpan } from '@/components/Base/ColoredText';
import Divider from '@/components/Base/Divider';
import { GradientVStack } from '@/components/Base/GradientVStack';
import HStack from '@/components/Base/HStack';
import MultilineText from '@/components/Base/MultilineText';
import Text, { BodyL2Regular, Label3Bold, Label3Regular, Title3Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import useAbsoluteNoAccident from '@/hooks/useAbsoluteNoAccident';
import useInspectionAdvantages from '@/hooks/useInspectionAdvantages';
import useNoAccident from '@/hooks/useNoAccident';
import { useOverlayState } from '@/hooks/useOverlayState';
import useTrack from '@/hooks/useTrack';
import CarHistoryExpiredBottomSheet from '@/pages/Article/components/Inspection/CarHistoryExpiredBottomSheet';
import { useFlow } from '@/stackflow';
import { assertNonNullish } from '@/types/Assert/assertNonNullish';
import { getCarHistoriesExpenseTotalAmount } from '@/utils/carHistory';
import { formatTemplates } from '@/utils/dateStr';
import { getIn } from '@/utils/misc';
import { allReportItemStatePaths } from '@/utils/report/allReportItemStatePaths';
import { consumableGroups } from '@/utils/report/consumableGroups';
import { getAllTireState } from '@/utils/report/getAllTireState';
import { getClientSideIssueCount, hasGroupIssue } from '@/utils/report/getClientSideIssues';
import { getFullReport } from '@/utils/report/getFullReport';
import { AccidentType, summaryAccidentHistory } from '@/utils/report/summaryAccidentHistory';
import { getDriveDistanceText } from '@/utils/string';
import { getSizeProps } from '@/utils/style';

import Row from './Row';

type Props = {
  articleRef: ArticleInspectionSection_article$key;
};

const ArticleInspectionSection: React.FC<Props> = ({ articleRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const [
    isCarHistoryExpiredBottomSheetOpened,
    openCarHistoryExpiredBottomSheet,
    closeCarHistoryExpiredBottomSheet,
  ] = useOverlayState('article_inspection_car_history_expired');
  const [openNoAccidentBottomSheet, noAccidentNode] = useNoAccident();
  const [openAbsoluteNoAccidentBottomSheet, absoluteNoAccidentNode] = useAbsoluteNoAccident();
  const [openInspectionAdvantages, inspectionAdvantagesNode] = useInspectionAdvantages();

  const {
    carData: { accidentType, inspection, carHistory, carHistoryStatus },
  } = useFragment(
    graphql`
      fragment ArticleInspectionSection_article on Article {
        carData {
          accidentType
          carHistoryStatus
          carHistory {
            ...carHistory_getCarHistoriesExpenseTotalAmount
          }
          inspection {
            id
            fixedDate
          }
        }
      }
    `,
    articleRef
  );
  const { mechanicReport } = useLazyLoadQuery<ArticleInspectionSectionQuery>(
    graphql`
      query ArticleInspectionSectionQuery($inspectionId: ID!) {
        mechanicReport(inspectionId: $inspectionId) {
          ...getFullReport_MechanicReport
        }
      }
    `,
    { inspectionId: inspection!.id }
  );
  assertNonNullish(mechanicReport);
  const report = getFullReport(mechanicReport);
  const { count, expenseTotalAmount } = getCarHistoriesExpenseTotalAmount(carHistory);

  const { message: accidentSummary } = summaryAccidentHistory(
    report.accidentHistory,
    expenseTotalAmount
  );
  const hasAccident = accidentType === AccidentType.Accident;
  const isAbsoluteNoAccident = accidentType === AccidentType.AbsoluteNoAccident;

  const { isReplacementRequired: isTireReplacementRequired, treadLeftKm } = getAllTireState(
    report.tire
  );

  const issueCount = getClientSideIssueCount(report);
  const isMaintenanceNeeded = issueCount > 0;
  type InExteriorStatus = 'ok' | 'should_check' | 'should_fix';
  const getInExteriorStatusText = (v: InExteriorStatus) => {
    return match(v)
      .with('ok', () => '정상')
      .with('should_check', () => '확인필요')
      .with('should_fix', () => '수리필요')
      .exhaustive();
  };
  const [exteriorStatus, interiorStatus]: InExteriorStatus[] = (
    ['exterior', 'interiorCondition'] as const
  ).map((categoryPath) => {
    const issuedPartValues = allReportItemStatePaths
      .filter((path) => path.startsWith(categoryPath))
      .map((fullPath) => getIn(report, fullPath))
      .filter(
        (v) =>
          v &&
          v.selections.length > 0 &&
          v.selections[0] !== 'NoIssues' &&
          v.selections[0] !== 'None'
      );
    return issuedPartValues.length === 0
      ? 'ok'
      : issuedPartValues.some(
            (v) =>
              v &&
              v.selections[0] !== 'Scratch' &&
              v.selections[0] !== 'DoorDing' &&
              v.selections[0] !== 'Used'
          )
        ? 'should_fix'
        : 'should_check';
  });

  const isExteriorOk = exteriorStatus === 'ok';
  const isInteriorOk = interiorStatus === 'ok';

  const isConsumableOk = consumableGroups.every((groupPath) => {
    const group = getIn(report, groupPath);
    return !hasGroupIssue(group, groupPath);
  });

  const isPerfect = !hasAccident && !isTireReplacementRequired && !isMaintenanceNeeded;
  const isAbsolutePerfect =
    isPerfect && isExteriorOk && isInteriorOk && isConsumableOk && !expenseTotalAmount;

  const handleClick = () => {
    push('inspection_report', { inspectionId: inspection!.id });
    track('article_click_inspection_section_more');
  };

  return (
    <Fragment>
      <GradientVStack>
        <VStack gap={16} px={16} py={16}>
          <HStack align="flex-start" justify="space-between">
            <VStack gap={6}>
              <Title3Bold>
                <MultilineText
                  keywords={['최상 컨디션', '모두 통과', '모두 확인']}
                  render={(matchedKeyword) => (
                    <ColoredSpan color="accent">{matchedKeyword}</ColoredSpan>
                  )}
                  text={
                    isAbsolutePerfect
                      ? '모든 검사를 통과한 \n최상 컨디션의 차량이에요.'
                      : isPerfect
                        ? '안전성 검사를 \n모두 통과한 차량이에요.'
                        : '진단으로 사고 · 수리 내역을 \n모두 확인했어요'
                  }
                />
              </Title3Bold>
              {inspection?.fixedDate && (
                <Label3Regular color="gray600">
                  진단일: {formatTemplates['24년 1월 1일'](inspection.fixedDate)}
                </Label3Regular>
              )}
            </VStack>
            <TextButton
              UNSAFE_style={{
                padding: 0,
              }}
              onClick={openInspectionAdvantages}
              size="small"
              variant="secondaryLow"
            >
              <HStack align="center" gap={2}>
                <IconInfoThin {...getSizeProps(16)} />
                진단 차량
              </HStack>
            </TextButton>
          </HStack>
          {(!hasAccident || !isTireReplacementRequired || isConsumableOk) && (
            <VStack bg="paperContents" br={10} gap={16} px={16} py={16}>
              <VStack gap={8}>
                <Label3Bold>이 차의 장점</Label3Bold>
                {!hasAccident && (
                  <BodyL2Regular>
                    <Text color="gray900" variant="bodyL2Regular">
                      <MultilineText
                        keywords={[isAbsoluteNoAccident ? '완전 무사고' : '무사고']}
                        render={(matchedKeyword) => (
                          <Text
                            color="success"
                            inline
                            onClick={
                              isAbsoluteNoAccident
                                ? openAbsoluteNoAccidentBottomSheet
                                : openNoAccidentBottomSheet
                            }
                            td="underline"
                            variant="bodyL2Bold"
                          >
                            {matchedKeyword}
                          </Text>
                        )}
                        text={accidentSummary}
                      />
                    </Text>
                  </BodyL2Regular>
                )}
                {!isTireReplacementRequired && (
                  <BodyL2Regular>
                    <Text color="gray900" variant="bodyL2Regular">
                      타이어는{' '}
                      <Text color="success" inline variant="bodyL2Bold">
                        {getDriveDistanceText(treadLeftKm)}km
                      </Text>{' '}
                      더 사용할 수 있어요.
                    </Text>
                  </BodyL2Regular>
                )}
                {isConsumableOk && (
                  <BodyL2Regular>
                    <Text color="gray900" variant="bodyL2Regular">
                      오일류 소모품 교체가 필요 없어요.
                    </Text>
                  </BodyL2Regular>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
        <VStack divider={<Divider />} gap={32} px={16} py={16}>
          <VStack gap={12}>
            <Text color="gray900" variant="label3Bold">
              안전성 검사
            </Text>
            <Row
              isPositive={!hasAccident}
              label="사고 이력"
              value={match(accidentType)
                .with('ABSOLUTE_NO_ACCIDENT', () => '완전 무사고')
                .with('NO_ACCIDENT', () => '무사고')
                .with('ACCIDENT', () => '있음')
                .otherwise(() => '')}
            />
            <Row
              isPositive={!isMaintenanceNeeded}
              label="정비 필요한 부분"
              value={!isMaintenanceNeeded ? '없음' : `${issueCount}개`}
            />
            <Row
              isPositive={!isTireReplacementRequired}
              label="타이어"
              value={!isTireReplacementRequired ? '정상' : '교체 필요'}
            />
          </VStack>

          <VStack gap={12}>
            <HStack justify="space-between" w="100%">
              <Text color="gray900" variant="label3Bold">
                외관 / 실내
              </Text>
              <HStack gap={4}>
                <Text color={isExteriorOk ? 'success' : 'gray600'} variant="label3Bold">
                  {getInExteriorStatusText(exteriorStatus)}
                </Text>
                <Text color="gray900" variant="label3Bold">
                  /
                </Text>
                <Text color={interiorStatus === 'ok' ? 'success' : 'gray600'} variant="label3Bold">
                  {getInExteriorStatusText(interiorStatus)}
                </Text>
              </HStack>
            </HStack>
            <Row
              isBold={true}
              isPositive={isConsumableOk}
              label="소모품"
              value={isConsumableOk ? '정상' : '교체 필요'}
            />
            <Row
              isBold={true}
              isPositive={!count}
              label="보험이력"
              value={match(carHistoryStatus)
                .with('OWNED', () => (!count ? '없음' : `${count}회`))
                .with(P.union('EXPIRED', 'NOT_REQUESTED'), () => (
                  <Label3Bold
                    color="gray600"
                    onClick={openCarHistoryExpiredBottomSheet}
                    td="underline"
                  >
                    보험이력 조회
                  </Label3Bold>
                ))
                .with('REQUESTED', () => '조회 중')
                .with('UNABLE_TO_FETCH', () => '조회 불가')
                .exhaustive()}
            />
          </VStack>
        </VStack>
        <Divider />
        <Center py={8} w="100%">
          <TextButton bold onClick={handleClick} size="small" variant="secondaryLow">
            <HStack align="center">
              더보기
              <IconChevronRightFill {...getSizeProps(14)} color={vars.$scale.color.gray600} />
            </HStack>
          </TextButton>
        </Center>
      </GradientVStack>
      {noAccidentNode}
      {absoluteNoAccidentNode}
      {inspectionAdvantagesNode}
      <CarHistoryExpiredBottomSheet
        active={isCarHistoryExpiredBottomSheetOpened}
        onClickClose={closeCarHistoryExpiredBottomSheet}
      />
    </Fragment>
  );
};

export default ArticleInspectionSection;
