import { atomWithReset } from 'jotai/utils';
export enum WriteStep {
  CarInfo = 'CAR_INFO',
  InExterior = 'IN_EXTERIOR',
  Safety = 'SAFETY',
  Convenience = 'CONVENIENCE',
  DriveDistance = 'DRIVE_DISTANCE',
  Price = 'PRICE',
  Additional = 'ADDITIONAL',
  Image = 'IMAGE',
  Review = 'REVIEW',
}

export const writeSteps = Object.values(WriteStep);
export const writeStepProgresses = [
  WriteStep.InExterior,
  WriteStep.Safety,
  WriteStep.Convenience,
  WriteStep.DriveDistance,
  WriteStep.Price,
  WriteStep.Additional,
  WriteStep.Image,
];

export const validationFailedStepState = atomWithReset<WriteStep | null>(null);
