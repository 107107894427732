/**
 * @generated SignedSource<<8e12541809496367cc11390e0e9b6b9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Gear = "AUTO" | "CVT" | "MANUAL" | "SEMI_AUTO" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ArticleCarInfoSection_article$data = {
  readonly carData: {
    readonly autoupModel: {
      readonly company: {
        readonly name: string;
      };
      readonly grade: {
        readonly name: string;
      } | null | undefined;
      readonly subgrade: {
        readonly name: string;
      } | null | undefined;
      readonly subseries: {
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly carRegistrationMonth: number | null | undefined;
    readonly carRegistrationYear: number | null | undefined;
    readonly carType: CarType;
    readonly displacement: number | null | undefined;
    readonly fuelType: FuelType;
    readonly gear: Gear;
    readonly modelYear: number;
  };
  readonly id: string;
  readonly " $fragmentType": "ArticleCarInfoSection_article";
};
export type ArticleCarInfoSection_article$key = {
  readonly " $data"?: ArticleCarInfoSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCarInfoSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCarInfoSection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carRegistrationYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carRegistrationMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modelYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displacement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fuelType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupModel",
          "kind": "LinkedField",
          "name": "autoupModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupCompany",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubseries",
              "kind": "LinkedField",
              "name": "subseries",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupGrade",
              "kind": "LinkedField",
              "name": "grade",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubgrade",
              "kind": "LinkedField",
              "name": "subgrade",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "8f0c363018414bfa2fedb04ae0f9c213";

export default node;
