import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { noop } from 'lodash-es';
import { rem } from 'polished';
import { Suspense, createContext, useCallback, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import { PromotionContext_article$key } from '@/__generated__/PromotionContext_article.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import GlobalLoading from '@/components/Base/GlobalLoading';
import ConfettiLayer from '@/components/Promotion/ConfettiLayer';
import PromotionExampleLayer from '@/components/Promotion/PromotionExampleLayer';
import PromotionPaymentLayer from '@/components/Promotion/PromotionPaymentLayer';
import useArticleStep from '@/hooks/useArticleStep';
import { ActivityName, useFlow } from '@/stackflow';
import { useCamelCaseParams } from '@/utils/url';

type PromotionDisplayState = 'confetti' | 'payment' | 'suggest_promotion';
type Props = {
  activityName?: ActivityName;
  articleRef: PromotionContext_article$key;
};

type PromotionContextState = {
  close: () => void;
  open: (state: PromotionDisplayState) => void;
};

const PromotionContext = createContext<PromotionContextState>({
  open: noop,
  close: noop,
});

export const PromotionContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  activityName = 'article',
  children,
}) => {
  const article = useFragment(
    graphql`
      fragment PromotionContext_article on Article {
        ...PromotionExampleLayer_article
        ...ConfettiLayer_article
        ...PromotionPaymentLayer_article
      }
    `,
    articleRef
  );
  const { pop } = useFlow();
  const { stepPush, stepReplace, stepPop } = useArticleStep(activityName);
  const { state } = useCamelCaseParams<{ state?: 'confetti' | 'payment' | 'suggest_promotion' }>();
  const open = useCallback(
    (displayState: PromotionDisplayState) => {
      const action = !!state ? stepReplace : stepPush;
      action({ state: displayState });
    },
    [state, stepPush, stepReplace]
  );
  const bottomSheetProps = {
    onClickClose: stepPop,
  };
  const handleCloseConfetti = () => {
    stepPop();
    pop();
  };

  return (
    <PromotionContext.Provider value={{ open, close: stepPop }}>
      {children}
      <Suspense fallback={<GlobalLoading />}>
        <BottomSheet
          {...bottomSheetProps}
          active={state === 'suggest_promotion'}
          title="당근 홈에 광고해보세요"
        >
          <PromotionExampleLayer articleRef={article} onClickClose={stepPop} />
        </BottomSheet>
        <BottomSheet
          {...bottomSheetProps}
          active={state === 'payment'}
          title={
            <>
              <Title>광고하기</Title>
              <Description>당근 홈화면에 24시간동안 광고해요.</Description>
            </>
          }
        >
          <PromotionPaymentLayer articleRef={article} onPromoteComplete={() => open('confetti')} />
        </BottomSheet>
        <BottomSheet
          {...bottomSheetProps}
          active={state === 'confetti'}
          title="지금부터 당근 홈에 노출돼요!"
        >
          <ConfettiLayer articleRef={article} onClickClose={handleCloseConfetti} />
        </BottomSheet>
      </Suspense>
    </PromotionContext.Provider>
  );
};

export const usePromotionContext = () => {
  return useContext(PromotionContext);
};

const Title = styled.span`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title2Bold};
`;

const Description = styled.p`
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption1Regular};
  margin-top: ${rem(2)};
`;
