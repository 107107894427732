import { graphql, readInlineData } from 'relay-runtime';
import { P, match } from 'ts-pattern';

import { isInspectedCar_article$key } from '@/__generated__/isInspectedCar_article.graphql';

export const isInspectedCar = (articleRef: isInspectedCar_article$key) => {
  const { carData } = readInlineData(
    graphql`
      fragment isInspectedCar_article on Article @inline {
        carData {
          inspection {
            status
            isMechanicReportVisible
          }
        }
      }
    `,
    articleRef
  );
  return match(carData.inspection)
    .with(
      P.union(
        {
          status: 'COMPLETED',
          isMechanicReportVisible: true,
        },
        {
          status: 'WAITING_FOR_REVIEW',
          isMechanicReportVisible: true,
        }
      ),
      () => true
    )
    .otherwise(() => false);
};
