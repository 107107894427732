/**
 * @generated SignedSource<<a9fce8796aea5d9c228fd52be9e2fe06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleCard_article$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"HorizontalArticleCard_article" | "MinimalArticleCard_article" | "VerticalArticleCard_article" | "VerticalCArticleCard_article">;
  readonly " $fragmentType": "ArticleCard_article";
};
export type ArticleCard_article$key = {
  readonly " $data"?: ArticleCard_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCard_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCard_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HorizontalArticleCard_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VerticalArticleCard_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VerticalCArticleCard_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MinimalArticleCard_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "28373f04069c9a9f403356eb8de3f71e";

export default node;
