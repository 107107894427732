import { useCallback } from 'react';

const useExecuteAfterLoad = () => {
  const execute = useCallback((callback: () => void) => {
    if (document.readyState === 'complete') {
      callback();
    } else {
      window.addEventListener('load', callback, { once: true });
    }
    return () => {
      window.removeEventListener('load', callback);
    };
  }, []);

  return execute;
};

export default useExecuteAfterLoad;
