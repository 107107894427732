import { memo } from 'react';

import HStack from '@/components/Base/HStack';

import Placeholder from './Placeholder';

const SkeletonHorizontalArticleCard = () => {
  return (
    <HStack bg="paperDefault" pa={16}>
      <Placeholder br={6} h={118} mr={16} w={118} />
      <div>
        <Placeholder br={6} h={20} w={180} />
        <Placeholder br={6} h={20} mt={8} w={136} />
      </div>
    </HStack>
  );
};

export default memo(SkeletonHorizontalArticleCard);
