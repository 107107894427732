/**
 * @generated SignedSource<<97197fff0a82f1ab5af6bc5685687a78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleAdvertisementSection_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ArticleChartSection_article" | "ArticlePromotionSection_article" | "PromotionContext_article">;
  readonly " $fragmentType": "ArticleAdvertisementSection_article";
};
export type ArticleAdvertisementSection_article$key = {
  readonly " $data"?: ArticleAdvertisementSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleAdvertisementSection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleAdvertisementSection_article",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleChartSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePromotionSection_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionContext_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "72eee4cf11892c1619d353391ea76bb8";

export default node;
