import { BoxButton } from '@daangn/sprout-components-button';
import { Divider } from '@daangn/sprout-components-divider';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill, IconExpandMoreThin, IconInfoThin } from '@seed-design/icon';
import { rem } from 'polished';
import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { P, match } from 'ts-pattern';

import { ArticleLeaseRentalSection_article$key } from '@/__generated__/ArticleLeaseRentalSection_article.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import BottomSheetItem from '@/components/Base/BottomSheet/BottomSheetItem';
import Box, { getSeedColor } from '@/components/Base/Box';
import ButtonDiv from '@/components/Base/ButtonDiv';
import HStack from '@/components/Base/HStack';
import {
  Caption1Bold,
  Caption2Regular,
  Label3Bold,
  Label3Regular,
  Label5Regular,
  Title3Bold,
} from '@/components/Base/Text';
import Tooltip from '@/components/Base/Tooltip';
import VStack from '@/components/Base/VStack';
import withDefaultProps from '@/hocs/withDefaultProps';
import { useFlow } from '@/stackflow';
import { getArticleLeaseRentalPriceInfo } from '@/utils/article/getArticleLeaseRentalPriceInfo';
import { getBillionFormatFromPrice } from '@/utils/number';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: ArticleLeaseRentalSection_article$key;
};

const ArticleLeaseRentalSection: React.FC<React.PropsWithChildren<Props>> = ({ articleRef }) => {
  const { push } = useFlow();

  const articleFragment = useFragment(
    graphql`
      fragment ArticleLeaseRentalSection_article on Article {
        id
        leaseInfo {
          postContractOption
        }
        rentalInfo {
          postContractOption
        }
        ...getArticleLeaseRentalPriceInfo_article
      }
    `,
    articleRef
  );
  const { id, leaseInfo, rentalInfo } = articleFragment;
  const info = leaseInfo ?? rentalInfo;
  const [isBottomSheetOpened, setBottomSheetOpened] = useState(false);
  const [postContract, setPostContract] = useState<'BUY' | 'RETURN'>(
    match(info?.postContractOption)
      .with('BUY_OR_RETURN', () => 'RETURN' as const)
      .with('BUY', () => 'BUY' as const)
      .with('RETURN', () => 'RETURN' as const)
      .otherwise(() => 'RETURN' as const)
  );
  const {
    acquisitionText,
    acquisitionPrice,
    articleTypeText,
    postContractPrice,
    totalAcquisitionPrice,
    totalContractPeriod,
    remainingContractPeriod,
    remainingInstallment,
    monthlyFee,
  } = getArticleLeaseRentalPriceInfo(articleFragment, postContract);

  return (
    <>
      <Title3Bold mb={4}>
        {leaseInfo != null ? '리스' : '렌트'} {getBillionFormatFromPrice(monthlyFee)}원/월
      </Title3Bold>
      <VStack
        bc="divider3"
        br={6}
        divider={<Divider UNSAFE_style={{ backgroundColor: getSeedColor('divider3') }} />}
        gap={1}
        mt={16}
      >
        <Box px={16} py={16}>
          <VStack>
            <HStack justify="space-between">
              <ButtonDiv data-tooltip-id="article_lease_rental_total_cost">
                <HStack align="center" gap={4} justify="center">
                  <Label3Bold color="gray700">총 인수 비용</Label3Bold>
                  <IconInfoThinSmall />
                </HStack>
              </ButtonDiv>
              <Label3Bold>{getBillionFormatFromPrice(totalAcquisitionPrice)}원</Label3Bold>
            </HStack>
          </VStack>
        </Box>
        <VStack gap={14} px={16} py={16}>
          <HStack justify="space-between">
            <Label3Regular color="gray700">남은 개월 수</Label3Regular>
            <Label3Regular color="gray900">
              {remainingContractPeriod}개월 / {totalContractPeriod}개월
            </Label3Regular>
          </HStack>
          <HStack justify="space-between">
            <Label3Regular color="gray700">승계 후 총 월 납입금</Label3Regular>
            <Label3Regular color="gray900">
              {getBillionFormatFromPrice(remainingInstallment)}원
            </Label3Regular>
          </HStack>
          <HStack justify="space-between">
            <ButtonDiv data-tooltip-id="article_lease_rental_acquisition_price">
              <HStack align="center" gap={4} justify="center">
                <Label3Regular color="gray700">{acquisitionText}</Label3Regular>
                <IconInfoThinSmall />
              </HStack>
            </ButtonDiv>
            <Label3Regular color={acquisitionPrice < 0 ? 'success' : 'gray900'}>
              {getBillionFormatFromPrice(Math.abs(acquisitionPrice))}원
            </Label3Regular>
          </HStack>
          <HStack justify="space-between">
            <VStack align="flex-start" gap={4}>
              <ButtonDiv
                onClick={() => {
                  if (info?.postContractOption !== 'BUY_OR_RETURN') {
                    return;
                  }
                  setBottomSheetOpened(true);
                }}
              >
                <HStack align="center" gap={4} justify="center">
                  <Label3Regular color="gray700">
                    만기 후 {postContract === 'BUY' ? '구매' : '반납'}
                  </Label3Regular>
                  {info?.postContractOption === 'BUY_OR_RETURN' && (
                    <IconExpandMoreThin color={getSeedColor('gray700')} {...getSizeProps(14)} />
                  )}
                </HStack>
              </ButtonDiv>
              <Label5Regular color="gray600">
                {postContract === 'BUY'
                  ? `${articleTypeText}사에 지급해야 할 구매 비용 (잔존가치 - 보증금)`
                  : `${articleTypeText}사에서 환급받는 보증금`}
              </Label5Regular>
            </VStack>
            <Label3Regular
              color={
                (postContract === 'RETURN' && postContractPrice > 0) ||
                (postContract === 'BUY' && postContractPrice < 0)
                  ? 'success'
                  : 'gray900'
              }
            >
              {getBillionFormatFromPrice(Math.abs(postContractPrice))}원
            </Label3Regular>
          </HStack>
          <BoxButton
            marginTop={rem(2)}
            onClick={() => push('article_lease_rental', { articleId: id })}
            size="xsmall"
            variant="secondary"
          >
            <HStack align="center" gap={2}>
              <span>{articleTypeText} 정보 더보기</span>
              <IconChevronRightFill {...getSizeProps(12)} color={vars.$scale.color.gray900} />
            </HStack>
          </BoxButton>
        </VStack>
      </VStack>
      <BottomSheet
        active={isBottomSheetOpened}
        onClickClose={() => setBottomSheetOpened(false)}
        title=""
      >
        <BottomSheetItemWrapper>
          {[
            { label: '반납', value: 'RETURN' },
            { label: '구매', value: 'BUY' },
          ].map(({ label, value }) => (
            <BottomSheetItem
              active={postContract === value}
              key={value}
              onClick={(nextValue) => {
                setPostContract(nextValue);
                setBottomSheetOpened(false);
              }}
              value={value}
            >
              {label}
            </BottomSheetItem>
          ))}
        </BottomSheetItemWrapper>
      </BottomSheet>
      <Tooltip
        id="article_lease_rental_acquisition_price"
        width={acquisitionPrice >= 0 ? 206 : 184}
      >
        {match(acquisitionPrice)
          .with(P.number.gte(0), () => '판매자에게 지급해야 할 금액이에요.')
          .with(P.number, () => '판매자에게 받게 될 금액이에요.')
          .exhaustive()}
      </Tooltip>
      <Tooltip id="article_lease_rental_total_cost" width={246}>
        <VStack>
          <Caption1Bold color="gray00">
            차량을 인수하고 만기 때까지 운행했을 때 <br />
            발생하는 총비용이에요.
          </Caption1Bold>
          <Box my={8}>
            <Divider UNSAFE_style={{ backgroundColor: getSeedColor('divider1') }} />
          </Box>
          <VStack gap={8}>
            <HStack align="center" justify="space-between">
              <WhiteCaption2Regular>승계 후 총 월 납입금</WhiteCaption2Regular>
              <WhiteCaption2Regular>
                <b>{getBillionFormatFromPrice(remainingInstallment)}원</b>
              </WhiteCaption2Regular>
            </HStack>
            <HStack align="center" justify="space-between">
              <WhiteCaption2Regular>{acquisitionText}</WhiteCaption2Regular>
              <WhiteCaption2Regular>
                <b>{getBillionFormatFromPrice(acquisitionPrice)}원</b>
              </WhiteCaption2Regular>
            </HStack>
            <HStack align="center" justify="space-between">
              <WhiteCaption2Regular>
                {postContract === 'RETURN' ? '보증금' : '인수 비용'}
              </WhiteCaption2Regular>
              <WhiteCaption2Regular>
                <b>
                  {postContract === 'RETURN' && postContractPrice > 0 ? '-' : ''}
                  {getBillionFormatFromPrice(postContractPrice)}원
                </b>
              </WhiteCaption2Regular>
            </HStack>
          </VStack>
        </VStack>
      </Tooltip>
    </>
  );
};

const WhiteCaption2Regular = withDefaultProps(Caption2Regular, {
  color: 'gray00',
});

const BottomSheetItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(16)};
`;

const IconInfoThinSmall = withDefaultProps(IconInfoThin, {
  ...getSizeProps(14),
  color: vars.$scale.color.gray700,
});

export default ArticleLeaseRentalSection;
