import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { differenceInDays } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ResaledArticleBanner_sanitizedArticle$key } from '@/__generated__/ResaledArticleBanner_sanitizedArticle.graphql';
import Tooltip from '@/components/Base/Tooltip';
import { getFilteredEvents } from '@/utils/article/getFilteredEvents';
import { getBillionFormatFromPrice } from '@/utils/number';

type Props = {
  sanitizedArticleRef: ResaledArticleBanner_sanitizedArticle$key;
};

const ResaledArticleBanner: React.FC<Props> = ({ sanitizedArticleRef, ...props }) => {
  const { price, minActualPrice, events } = useFragment(
    graphql`
      fragment ResaledArticleBanner_sanitizedArticle on SanitizedArticle {
        price
        minActualPrice
        events {
          type
          t
        }
      }
    `,
    sanitizedArticleRef
  );
  const [lastTradedEvent] = getFilteredEvents(events, 'TRADED');

  return (
    <Container {...props}>
      <Text>
        당근에서{' '}
        {lastTradedEvent
          ? `${differenceInDays(new Date(), new Date(lastTradedEvent.t))}일 전에`
          : '이전에'}{' '}
        <b>{getBillionFormatFromPrice(minActualPrice ?? price)}원</b>에 거래되었던{' '}
        <UnderlinedText data-tooltip-id="resaled_article_banner_explanation">
          재판매 차량
        </UnderlinedText>
        이에요.
      </Text>
      <Tooltip id="resaled_article_banner_explanation" width={220}>
        당근에 등록된 후 소유주 변경 이력이 있는 차량이 다시 매물로 올라온 차량을 의미해요.
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  padding: ${rem(14)} ${rem(16)};
  border-radius: ${rem(10)};
  background-color: ${vars.$scale.color.yellowAlpha100};
`;

const Text = styled.p`
  ${vars.$semantic.typography.bodyM2Regular};
  color: ${vars.$scale.color.yellow950};

  b {
    ${vars.$semantic.typography.bodyM2Bold};
  }
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

export default ResaledArticleBanner;
