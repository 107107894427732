/**
 * @generated SignedSource<<2e4ac80c01264e78f6efa9cf21a47bb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getSortedInsuranceList_article$data = {
  readonly insuranceTrackingData: {
    readonly carrot: {
      readonly order: number;
      readonly url: string;
    } | null | undefined;
    readonly db: {
      readonly order: number;
      readonly url: string;
    } | null | undefined;
    readonly kb: {
      readonly order: number;
      readonly url: string;
    } | null | undefined;
    readonly samsung: {
      readonly order: number;
      readonly url: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "getSortedInsuranceList_article";
};
export type getSortedInsuranceList_article$key = {
  readonly " $data"?: getSortedInsuranceList_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getSortedInsuranceList_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getSortedInsuranceList_article"
};

(node as any).hash = "bece9908fffb221f83282d34f5abdbf5";

export default node;
