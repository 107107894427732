/**
 * @generated SignedSource<<6880556d088690c031b6152ba8a54095>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewArticleNotificationBottomSheet_subseries$data = {
  readonly id: string;
  readonly imgUrl: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "NewArticleNotificationBottomSheet_subseries";
};
export type NewArticleNotificationBottomSheet_subseries$key = {
  readonly " $data"?: NewArticleNotificationBottomSheet_subseries$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewArticleNotificationBottomSheet_subseries">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewArticleNotificationBottomSheet_subseries",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imgUrl",
      "storageKey": null
    }
  ],
  "type": "AutoupSubseries",
  "abstractKey": null
};

(node as any).hash = "249d09d0de0aaee16b8066c099786b26";

export default node;
