/**
 * @generated SignedSource<<a9e20fe5ea7f5cf2d70a0120aba77f93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
export type useUpdateArticleStatus_UpdateArticleStatusMutation$variables = {
  articleId: string;
  status: ArticleStatus;
};
export type useUpdateArticleStatus_UpdateArticleStatusMutation$data = {
  readonly result: {
    readonly promotionEndsAt: any | null | undefined;
    readonly status: ArticleStatus;
  };
};
export type useUpdateArticleStatus_UpdateArticleStatusMutation = {
  response: useUpdateArticleStatus_UpdateArticleStatusMutation$data;
  variables: useUpdateArticleStatus_UpdateArticleStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "articleId",
    "variableName": "articleId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promotionEndsAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateArticleStatus_UpdateArticleStatusMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "updateArticleStatus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateArticleStatus_UpdateArticleStatusMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "updateArticleStatus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29b1ea3a33169caccf1f73f3b4f8a6b3",
    "id": null,
    "metadata": {},
    "name": "useUpdateArticleStatus_UpdateArticleStatusMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateArticleStatus_UpdateArticleStatusMutation(\n  $articleId: ID!\n  $status: ArticleStatus!\n) {\n  result: updateArticleStatus(articleId: $articleId, status: $status) {\n    status\n    promotionEndsAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "88684f0d9365cfc0815e87342240b9c9";

export default node;
