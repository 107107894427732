import { FuelType } from '@/types/CarData/FuelType';

import { NUM_1만 } from '../number';
import { getCarSize } from './getCarSize';
import { isElectronic } from './isElectronic';
import { isHybrid } from './isHybrid';

type FirstRegion =
  | '1'
  | '451'
  | '675'
  | '825'
  | '993'
  | '1094'
  | '1179'
  | '1241'
  | '1256'
  | '1873'
  | '2092'
  | '2261'
  | '2488'
  | '2748'
  | '3093'
  | '3464'
  | '3811';

export const getPublicBondPurchaseExpense = ({
  price,
  region1Id,
  displacement,
  fuelType,
}: {
  displacement: number;
  fuelType: FuelType;
  price: number;
  region1Id: string;
}) => {
  const defaultRailroadBondsRate = {
    small: 0.06,
    medium: 0.06,
    large: 0.06,
  };
  const defaultMunicipalBondsRate = {
    small: 0.03,
    medium: 0.04,
    large: 0.06,
  };
  const discountRate = 0.11;
  const carSize = getCarSize(displacement);

  if (carSize === 'compact') {
    return 0;
  }

  const purchaseRateMap: Record<FirstRegion, typeof defaultRailroadBondsRate> = {
    // 서울
    '1': defaultRailroadBondsRate,
    // 부산
    '451': {
      small: 0.04,
      medium: 0.04,
      large: 0.04,
    },
    // 대구
    '675': defaultRailroadBondsRate,
    // 인천
    '825': defaultRailroadBondsRate,
    // 광주
    '993': defaultMunicipalBondsRate,
    // 대전
    '1094': defaultRailroadBondsRate,
    // 울산
    '1179': defaultMunicipalBondsRate,
    // 세종
    '1241': defaultMunicipalBondsRate,
    // 경기
    '1256': defaultMunicipalBondsRate,
    // 강원
    '1873': defaultMunicipalBondsRate,
    // 충북
    '2092': defaultMunicipalBondsRate,
    // 충남
    '2261': {
      small: 0.03,
      medium: 0.03,
      large: 0.04,
    },
    // 전북
    '2488': {
      small: 0.02,
      medium: 0.03,
      large: 0.05,
    },
    // 전남
    '2748': defaultMunicipalBondsRate,
    // 경북
    '3093': defaultMunicipalBondsRate,
    // 경남
    '3464': {
      small: 0,
      medium: 0.04,
      large: 0.05,
    },
    // 제주
    '3811': {
      small: 0.03,
      medium: 0.04,
      large: 0.05,
    },
  };
  const purchaseRate = purchaseRateMap[region1Id as FirstRegion][carSize];
  const deduction = isElectronic(fuelType) ? 250 * NUM_1만 : isHybrid(fuelType) ? 200 * NUM_1만 : 0;

  return Math.floor(Math.max(0, price * purchaseRate - deduction) * discountRate);
};
