import { useCallback } from 'react';

import ErrorScreen from '@/components/Error/ErrorScreen';

type Props = {
  error: Error;
};

const GlobalErrorScreen = ({ error }: Props) => {
  const onClick = useCallback(() => {
    window.location.reload();
  }, []);
  return <ErrorScreen buttonText="새로 고침" error={error} onClick={onClick} />;
};

export default GlobalErrorScreen;
