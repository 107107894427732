export const getKeys = <T extends Record<number, null | number>>(map: T): number[] => {
  return Object.keys(map).map((key) => Number(key));
};

export const getLabeledPoints = <T extends Record<number, null | number>>(map: T): T => {
  const sortedKeys = Object.keys(map)
    .sort((a, b) => Number(a) - Number(b))
    .map((key) => Number(key));
  const filter = (_: number, idx: number) => {
    if (idx === 0 || idx === sortedKeys.length - 1) {
      return true;
    }
    if (sortedKeys.length % 2 === 0) {
      return (
        idx === Math.floor(sortedKeys.length / 3) ||
        idx === sortedKeys.length - Math.floor(sortedKeys.length / 3) - 1
      );
    } else {
      return idx === Math.floor(sortedKeys.length / 2);
    }
  };
  const filteredKeys = sortedKeys.filter(filter);
  const entries = filteredKeys.map((key) => [key, map[key]]);

  return Object.fromEntries(entries) as T;
};
