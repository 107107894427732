import IconCarrot from '@/assets/images/insurance/carrot.png';
import IconDb from '@/assets/images/insurance/db.png';
import IconKb from '@/assets/images/insurance/kb.png';
import IconSamsung from '@/assets/images/insurance/samsung.png';

const DB_DISPLAY_TIME_08_21_16_00 = 1724223600000;

export const insuranceList = [
  {
    id: 'carrot' as const,
    title: '캐롯 퍼마일 자동차 보험',
    reward: '가입은 주유권 혹은 당근머니 최대 3만원 / 계산은 1천원 주유권',
    description: '자동차보험료 계산/가입 시 지급',
    icon: IconCarrot,
  },
  {
    id: 'samsung' as const,
    title: '삼성화재 다이렉트 자동차보험',
    reward: '계산은 스벅 아아 2잔 / 가입은 주유권 2만원',
    description: '자동차보험료 계산 시 / 30만원 이상 가입 시 지급',
    icon: IconSamsung,
  },
  {
    id: 'kb' as const,
    title: 'KB다이렉트 자동차보험',
    reward: '계산: 스벅 아아 2잔, 가입: GS주유권 2만원',
    description: '내 차 보험료 계산 시 / 30만원 이상 가입 시 지급',
    icon: IconKb,
  },
  Date.now() > DB_DISPLAY_TIME_08_21_16_00
    ? {
        id: 'db' as const,
        title: 'DB다이렉트 자동차 보험',
        reward: '신규 가입시 신세계 상품권 최대 2만원 / 보험료 계산시 스벅 아아 2잔',
        description: "당사 오프라인 대비 18.1% 저렴 ('24.2.16 계약부터)",
        icon: IconDb,
      }
    : undefined,
].filter((v) => v != null);
