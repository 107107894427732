import { differenceInDays } from 'date-fns';

import { getTradeDurationFromArticle_article$data } from '@/__generated__/getTradeDurationFromArticle_article.graphql';
import { getTradeDurationFromSanitizedArticle_sanitizedArticle$data } from '@/__generated__/getTradeDurationFromSanitizedArticle_sanitizedArticle.graphql';

import { getFilteredEvents } from './getFilteredEvents';

export const getTradeDuration = (
  events:
    | getTradeDurationFromArticle_article$data['events']
    | getTradeDurationFromSanitizedArticle_sanitizedArticle$data['events']
) => {
  const [lastTradedEvent] = getFilteredEvents(events, 'TRADED');
  const [lastPriceSaleEvent] = getFilteredEvents(events, 'PRICE_SALE').filter(
    (event) => event.t < lastTradedEvent?.t
  );
  const [createdEvent] = getFilteredEvents(events, 'CREATED');

  if (!lastTradedEvent || !(lastPriceSaleEvent || createdEvent)) {
    return Infinity;
  }
  const diff = differenceInDays(
    new Date(lastTradedEvent.t),
    new Date(lastPriceSaleEvent?.t ?? createdEvent.t)
  );

  return diff;
};
