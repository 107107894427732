import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';

const DividerNav = styled.div`
  width: 100%;
  height: ${rem(1)};
  transform: scaleY(0.5);
  background-color: ${vars.$semantic.color.divider3};
`;

export default DividerNav;
