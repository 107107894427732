/**
 * @generated SignedSource<<369c32fbcae333e80fe0d5df5129fa97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notification_newArticleNotificationCondition$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getNewArticleNotificationConditionInput_newArticleNotificationCondition">;
  readonly " $fragmentType": "notification_newArticleNotificationCondition";
};
export type notification_newArticleNotificationCondition$key = {
  readonly " $data"?: notification_newArticleNotificationCondition$data;
  readonly " $fragmentSpreads": FragmentRefs<"notification_newArticleNotificationCondition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "notification_newArticleNotificationCondition"
};

(node as any).hash = "c595bfc9c6ae48c3e9b852cff38aa1ac";

export default node;
