import { rem } from 'polished';
import React from 'react';

import { Props } from './types';

const NoSmoking: React.FC<React.PropsWithChildren<Props>> = ({
  width = 16,
  height = 16,
  ...props
}) => {
  return (
    <svg
      fill="none"
      height={rem(height)}
      viewBox="0 0 16 16"
      width={rem(width)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4879_142281)">
        <path
          d="M4.12891 6.66016H11.5989C11.7889 6.66016 11.9389 6.81016 11.9389 7.00016V8.99016C11.9389 9.18016 11.7889 9.33016 11.5989 9.33016H4.12891V6.66016Z"
          fill="#D1D3D8"
        />
        <path d="M5.2889 6.66016H4.12891V9.33016H5.2889V6.66016Z" fill="#F85D52" />
        <path
          d="M7.99859 14.1901C11.4186 14.1901 14.1886 11.4201 14.1886 8.00006C14.1886 4.58006 11.4186 1.81006 7.99859 1.81006C4.57859 1.81006 1.80859 4.58006 1.80859 8.00006C1.80859 11.4201 4.57859 14.1901 7.99859 14.1901Z"
          stroke="#F85D52"
          strokeMiterlimit="10"
          strokeWidth="1.87"
        />
        <path
          d="M12.4298 3.58008L3.58984 12.4201"
          stroke="#F85D52"
          strokeMiterlimit="10"
          strokeWidth="1.87"
        />
      </g>
      <defs>
        <clipPath id="clip0_4879_142281">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoSmoking;
