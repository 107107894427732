import { BoxButton } from '@daangn/sprout-components-button';
import { rem } from 'polished';
import React, { useState } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { PromotionPaymentLayer_article$key } from '@/__generated__/PromotionPaymentLayer_article.graphql';
import { PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation } from '@/__generated__/PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation.graphql';
import { PromotionPaymentLayer_OpenPromotionPaymentMutation } from '@/__generated__/PromotionPaymentLayer_OpenPromotionPaymentMutation.graphql';
import {
  PromotionPaymentLayerQuery,
  PromotionPricing,
} from '@/__generated__/PromotionPaymentLayerQuery.graphql';
import VStack from '@/components/Base/VStack';
import CONFIG from '@/constants/config';
import useKarrotPayWebview from '@/hooks/useKarrotPayWebview';
import useMutationAsync from '@/hooks/useMutationAsync';
import { useToast } from '@/hooks/useToast';
import useTrack from '@/hooks/useTrack';
import { handleError } from '@/utils/error';
import { getViews } from '@/utils/promotion';

import PromotionItem from './PromotionItem';

export const DEFAULT_PROMOTION_PRICING = 'CLICK_600';

type Props = {
  articleRef: PromotionPaymentLayer_article$key;
  onPromoteComplete: () => void;
};

const PromotionPaymentLayer: React.FC<React.PropsWithChildren<Props>> = ({
  articleRef,
  onPromoteComplete,
}) => {
  const { promotionPricings } = useLazyLoadQuery<PromotionPaymentLayerQuery>(
    graphql`
      query PromotionPaymentLayerQuery {
        promotionPricings {
          type
          amount
          ...PromotionItem_promotionPricings
        }
      }
    `,
    {}
  );
  const { id } = useFragment(
    graphql`
      fragment PromotionPaymentLayer_article on Article {
        id
      }
    `,
    articleRef
  );
  const [openPromotionPayment] =
    useMutationAsync<PromotionPaymentLayer_OpenPromotionPaymentMutation>(graphql`
      mutation PromotionPaymentLayer_OpenPromotionPaymentMutation(
        $articleId: ID!
        $pricing: PromotionPricing
      ) {
        payId: openPromotionPayment(articleId: $articleId, pricing: $pricing)
      }
    `);
  const [closePromotionPaymentAndPromoteArticle] =
    useMutationAsync<PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation>(graphql`
      mutation PromotionPaymentLayer_ClosePromotionPaymentAndPromoteArticleMutation(
        $payId: ID!
        $articleId: ID!
      ) {
        closePromotionPaymentAndPromoteArticle(payId: $payId, articleId: $articleId) {
          nextPullUpAt
          promotionEndsAt
          ...ArticleChart_article
        }
      }
    `);

  const { trackWithActivityName } = useTrack();
  const [openKarrotPayWebview, loadingNode, closePayment] = useKarrotPayWebview();
  const [selectedType, setSelectedType] = useState<PromotionPricing>(DEFAULT_PROMOTION_PRICING);
  const { setToast } = useToast();

  const sortedPricings = promotionPricings
    .map((pricing) => {
      const { amount } = pricing;
      const views = getViews(pricing.type);

      return {
        ...pricing,
        costPerView: amount / views,
      };
    })
    .sort((a, b) => b.costPerView - a.costPerView);
  const [mostExpensivePricing] = sortedPricings;

  const loggerPayload = {
    article_id: id,
    pricing: selectedType,
  };

  const handleClickPromote = async () => {
    trackWithActivityName('click_promotion_payment_start', loggerPayload);

    try {
      const { payId } = await openPromotionPayment({
        variables: { articleId: id, pricing: selectedType },
      });
      await openKarrotPayWebview({
        payId,
        partnerAppSecret: CONFIG.PAYMENT_CLIENT_SECRET,
      });
      trackWithActivityName('promotion_payment_webview_success', loggerPayload);
      await closePromotionPaymentAndPromoteArticle({ variables: { articleId: id, payId } });
      onPromoteComplete();
      trackWithActivityName('promotion_payment_end', loggerPayload);
    } catch (e: any) {
      const error = handleError(e, { reportToSentry: true });
      setToast(error.message);
    } finally {
      closePayment();
    }
  };

  return (
    <VStack gap={12} px={16} py={12}>
      {promotionPricings.map((pricing) => {
        const { type, amount } = pricing;
        const views = getViews(type);
        const discount = mostExpensivePricing.costPerView * views - amount;

        const handleClick = () => {
          setSelectedType(type);
          trackWithActivityName('click_promotion_item', { type });
        };

        return (
          <PromotionItem
            discount={discount}
            key={type}
            onClick={handleClick}
            promotionRef={pricing}
            recommended={type === 'CLICK_600'}
            selected={selectedType === type}
          />
        );
      })}
      <BoxButton
        marginTop={rem(24)}
        onClick={handleClickPromote}
        size="xlarge"
        variant="primary"
        width="100%"
      >
        결제하기
      </BoxButton>
      {loadingNode}
    </VStack>
  );
};

export default PromotionPaymentLayer;
