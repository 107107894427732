/**
 * @generated SignedSource<<3c2f8e686b795798760dac747a2c6c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Footer_article$data = {
  readonly carData: {
    readonly inspection: {
      readonly id: string;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ArticleProxyInspectionButton_article" | "ChatButton_article" | "LikeButton_article" | "WarrantyButton_article" | "useIsWarrantable_article">;
  readonly " $fragmentType": "Footer_article";
};
export type Footer_article$key = {
  readonly " $data"?: Footer_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"Footer_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Footer_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Inspection",
          "kind": "LinkedField",
          "name": "inspection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LikeButton_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleProxyInspectionButton_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WarrantyButton_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatButton_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWarrantable_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "d42f13f2cef8de7c956094f858118fb9";

export default node;
