/**
 * @generated SignedSource<<afdd4594fd237f8c16add7e8166b35ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyOrigin = "FOREIGN" | "KOREAN";
import { FragmentRefs } from "relay-runtime";
export type CompanyIdsFilter_autoupCompanies$data = {
  readonly autoupCompanies: ReadonlyArray<{
    readonly companyOrigin: CompanyOrigin;
    readonly count: number;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CompanyIcon_autoupCompany">;
  }>;
  readonly " $fragmentType": "CompanyIdsFilter_autoupCompanies";
};
export type CompanyIdsFilter_autoupCompanies$key = {
  readonly " $data"?: CompanyIdsFilter_autoupCompanies$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyIdsFilter_autoupCompanies">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyIdsFilter_autoupCompanies",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoupCompany",
      "kind": "LinkedField",
      "name": "autoupCompanies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyOrigin",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CompanyIcon_autoupCompany"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f3503b5186503bf2d1b6ebcffa1054e4";

export default node;
