import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { MouseEventHandler } from 'react';
import { graphql, useFragment } from 'react-relay';

import { User_article$key } from '@/__generated__/User_article.graphql';
import DefaultUserProfileImage from '@/components/Base/DefaultUserProfileImage';
import HStack from '@/components/Base/HStack';
import Scheme from '@/constants/scheme';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import useTrack from '@/hooks/useTrack';
import { getImageUrl } from '@/utils/image';
import { formatFloat } from '@/utils/number';

import MannerTemperature from './MannerTemperature';

interface Props {
  articleRef: User_article$key;
}

const User: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, ...props }) => {
  const {
    id: articleId,
    distanceKmFromMe,
    region,
    user,
  } = useFragment<User_article$key>(
    graphql`
      fragment User_article on Article {
        id
        distanceKmFromMe
        region {
          id
          displayName
        }
        user {
          id
          nickname
          temperature
          profileImage
        }
      }
    `,
    articleRef
  );
  const { trackWithActivityName } = useTrack();
  const redirect = useRedirectAppScheme();
  const handleClickUser: MouseEventHandler<HTMLDivElement> = (e) => {
    const params = { article_id: articleId, target_user_id: user.id };

    e.stopPropagation();
    trackWithActivityName('click_user_profile', params);
    redirect(Scheme.User(user.id));
  };

  return (
    <HStack justify="space-between" w="100%" {...props}>
      <HStack align="center" onClick={handleClickUser}>
        <UserImageContainer>
          {user.profileImage ? (
            <UserImage alt="" src={getImageUrl(user.profileImage, { width: 80, height: 80 })} />
          ) : (
            <DefaultUserProfileImage />
          )}
        </UserImageContainer>
        <div>
          <NickName>{user.nickname}</NickName>
          <SubText>
            {region.displayName}
            {distanceKmFromMe ? ` ${formatFloat(distanceKmFromMe)}km` : ''}
          </SubText>
        </div>
      </HStack>
      <MannerTemperature temperature={user.temperature} />
    </HStack>
  );
};

export default User;

export const UserImageContainer = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
  overflow: hidden;
  border-radius: ${rem(20)};
  margin-right: ${rem(12)};
`;

export const UserImage = styled.img`
  width: 100%;
  display: block;
`;

const NickName = styled.p`
  margin-top: ${rem(-3)};
  ${vars.$semantic.typography.subtitle1Bold}
`;

const SubText = styled.p`
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.caption1Regular}
`;
