/**
 * @generated SignedSource<<17c49c32e1195fce6156fe85b1ec704c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PromotionPricing = "CLICK_1000" | "CLICK_250" | "CLICK_600";
export type PromotionPaymentLayer_OpenPromotionPaymentMutation$variables = {
  articleId: string;
  pricing?: PromotionPricing | null | undefined;
};
export type PromotionPaymentLayer_OpenPromotionPaymentMutation$data = {
  readonly payId: string;
};
export type PromotionPaymentLayer_OpenPromotionPaymentMutation = {
  response: PromotionPaymentLayer_OpenPromotionPaymentMutation$data;
  variables: PromotionPaymentLayer_OpenPromotionPaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pricing"
  }
],
v1 = [
  {
    "alias": "payId",
    "args": [
      {
        "kind": "Variable",
        "name": "articleId",
        "variableName": "articleId"
      },
      {
        "kind": "Variable",
        "name": "pricing",
        "variableName": "pricing"
      }
    ],
    "kind": "ScalarField",
    "name": "openPromotionPayment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionPaymentLayer_OpenPromotionPaymentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromotionPaymentLayer_OpenPromotionPaymentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1e73feb08ce0589b4ca77dcb0147d79",
    "id": null,
    "metadata": {},
    "name": "PromotionPaymentLayer_OpenPromotionPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation PromotionPaymentLayer_OpenPromotionPaymentMutation(\n  $articleId: ID!\n  $pricing: PromotionPricing\n) {\n  payId: openPromotionPayment(articleId: $articleId, pricing: $pricing)\n}\n"
  }
};
})();

(node as any).hash = "35a64071d89e436cb16b397350f9eb6c";

export default node;
