import { IconCheckFill } from '@seed-design/icon';
import React, { useCallback } from 'react';

import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { BodyL1Bold, BodyL1Regular } from '@/components/Base/Text';
import { getSizeProps } from '@/utils/style';

type Props<T> = {
  active: boolean;
  onClick?: (value: T) => void;
  value: T;
};

const BottomSheetItem: React.FC<React.PropsWithChildren<Props<any>>> = ({
  active,
  onClick,
  value,
  children,
  ...props
}) => {
  const handleClick = useCallback(() => {
    onClick?.(value);
  }, [value, onClick]);
  return (
    <HStack justify="space-between" onClick={handleClick} py={11} {...props}>
      {active ? (
        <BodyL1Bold color="primary">{children}</BodyL1Bold>
      ) : (
        <BodyL1Regular>{children}</BodyL1Regular>
      )}
      {active && (
        <IconCheckFill {...getSizeProps(18)} color={getSeedColor(active ? 'primary' : 'gray900')} />
      )}
    </HStack>
  );
};

export default BottomSheetItem;
