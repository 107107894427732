import { useActivity } from '@stackflow/react';
import React, { memo } from 'react';
import { match } from 'ts-pattern';

import type { ActivityName } from '@/stackflow';

import LoadingScreen from '@/components/Base/Loading/LoadingScreen';
import SkeletonPageHotArticles from '@/components/Skeleton/SkeletonPageHotArticles';
import SkeletonPageLikedArticles from '@/components/Skeleton/SkeletonPageLikedArticles';
import SkeletonPageNotificationArticles from '@/components/Skeleton/SkeletonPageNotificationArticles';
import SkeletonPageSalesHistory from '@/components/Skeleton/SkeletonPageSalesHistory';
import SkeletonPageSearchResult from '@/components/Skeleton/SkeletonPageSearchResult';

import SkeletonPageArticle from './SkeletonPageArticle';
import SkeletonPageInspectedArticles from './SkeletonPageInspectedArticles';
import SkeletonPageMain from './SkeletonPageMain';

const SkeletonInitialApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const activity = useActivity();

  return match(activity.name as ActivityName)
    .with('main', () => <SkeletonPageMain />)
    .with('article', () => <SkeletonPageArticle />)
    .with('inspected_articles', () => <SkeletonPageInspectedArticles />)
    .with('hot_articles', () => <SkeletonPageHotArticles />)
    .with('sales_history', () => <SkeletonPageSalesHistory />)
    .with('search_result', () => <SkeletonPageSearchResult />)
    .with('liked_articles', () => <SkeletonPageLikedArticles />)
    .with('notification_articles', () => <SkeletonPageNotificationArticles />)
    .otherwise(() => <LoadingScreen />);
};

export default memo(SkeletonInitialApp);
