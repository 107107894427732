/**
 * @generated SignedSource<<d4fac97f65cd9ccd140321e09d654a3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionPaymentLayer_article$data = {
  readonly id: string;
  readonly " $fragmentType": "PromotionPaymentLayer_article";
};
export type PromotionPaymentLayer_article$key = {
  readonly " $data"?: PromotionPaymentLayer_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionPaymentLayer_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionPaymentLayer_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "7f1a1a2a3bb3c06ad21794be32872446";

export default node;
