import { VIEWER_ID, VIEWER_TYPE } from 'relay-runtime';
import { GetDataID } from 'relay-runtime/lib/store/RelayResponseNormalizer';

const getDataID: GetDataID = (fieldValue, typeName) => {
  if (typeName === VIEWER_TYPE) {
    return fieldValue.id == null ? VIEWER_ID : fieldValue.id;
  }
  return fieldValue.id ? `${typeName}:${fieldValue.id}` : fieldValue.id;
};

export default getDataID;
