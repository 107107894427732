import type { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

export const RatioVariant = {
  '1/1': 1 / 1,
  '16/9': 9 / 16,
  '4/3': 3 / 4,
} as const;

export type RatioVariant = keyof typeof RatioVariant;
type Props = { noCover?: boolean; ratio: RatioVariant };

const AspectRatio = ({ children, noCover, ...props }: PropsWithChildren<Props>) => (
  <AspectRatioContainer {...props}>
    {noCover ? children : <AspectRatioContent>{children}</AspectRatioContent>}
  </AspectRatioContainer>
);

const AspectRatioContainer = styled.div<{ ratio: RatioVariant }>((props) => ({
  position: 'relative',
  height: 0,
  paddingBottom: `calc(${RatioVariant[props.ratio] * 100}%)`,
  overflow: 'hidden',
}));

const AspectRatioContent = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
});

export default AspectRatio;
