import React, { PropsWithChildren } from 'react';

import HStack from '@/components/Base/HStack';
import MoreButton from '@/components/Base/MoreButton';
import { Title3Bold } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';

type Props = {
  onClickMore?: () => void;
  title: React.ReactNode;
};

const TitledSection: React.FC<PropsWithChildren<Props>> = ({ title, onClickMore, children }) => {
  return (
    <VStack>
      <HStack align="flex-start" bg="paperDefault" justify="space-between" mb={8} px={16} w="100%">
        <Title3Bold>{title}</Title3Bold>
        {onClickMore && <MoreButton onClick={onClickMore} />}
      </HStack>
      {children}
    </VStack>
  );
};

export default TitledSection;
