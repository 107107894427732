/**
 * @generated SignedSource<<4fff6f9f4f96233f759bbcee2b62b610>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarouselSection_query$data = {
  readonly inspectedArticles: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"HotArticlesSection_query" | "InspectedArticlesSection_query">;
  readonly " $fragmentType": "CarouselSection_query";
};
export type CarouselSection_query$key = {
  readonly " $data"?: CarouselSection_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CarouselSection_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "count",
    "variableName": "count"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CarouselSection_query",
  "selections": [
    {
      "alias": "inspectedArticles",
      "args": [
        {
          "kind": "Variable",
          "name": "n",
          "variableName": "count"
        }
      ],
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "articlesForInspectionSection",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "InspectedArticlesSection_query"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "HotArticlesSection_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "743b0ac9c262817b07e251eef3b2616c";

export default node;
