import styled from '@emotion/styled';
import { IconMoreVertRegular, IconReportRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { HeaderMenu_article$key } from '@/__generated__/HeaderMenu_article.graphql';
import Share from '@/components/icon/Share';
import CONFIG from '@/constants/config';
import useTrack from '@/hooks/useTrack';
import useUser from '@/hooks/useUser';
import { karrotBridge } from '@/sdk/bridge';
import { getSizeProps } from '@/utils/style';

type Props = {
  articleRef: HeaderMenu_article$key;
  onClickMore: () => void;
  onClickReport: () => void;
  onClickShare: () => void;
};

const HeaderMenu: React.FC<React.PropsWithChildren<Props>> = ({
  onClickShare,
  onClickMore,
  onClickReport,
  articleRef,
}) => {
  const article = useFragment(
    graphql`
      fragment HeaderMenu_article on Article {
        id
        shareUrl
        user {
          id
        }
      }
    `,
    articleRef
  );
  const { user, checkLogin } = useUser();
  const { track } = useTrack();
  // HeaderMenu가 ArticleContextProvider보다 상위에 렌더링되기 때문에 useArticleContext를 사용할 수 없음.
  const isMyArticle = `${user?.id}` === article.user.id;

  const handleReportClick = () => {
    if (!checkLogin() || !article.id) {
      return;
    }
    const url = new URL(CONFIG.REPORT_HOST);
    url.searchParams.set('kind', 'car');
    url.searchParams.set('article_id', article.id);

    karrotBridge.pushRouter({
      router: {
        remote: url.toString(),
      },
    });

    track('article_click_report');
    onClickReport();
  };

  const handleShareClick = () => {
    if (!article.shareUrl || !article.id) {
      return;
    }
    karrotBridge.share({ share: { url: article.shareUrl, text: '[당근 중고차 직거래]' } });
    track('article_click_share');
    onClickShare();
  };

  return (
    <>
      <IconWrapper>
        <Share height={24} onClick={handleShareClick} width={24} />
      </IconWrapper>
      {isMyArticle ? (
        <IconWrapper>
          <IconMoreVertRegular {...iconSizeProps} onClick={onClickMore} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <IconReportRegular {...iconSizeProps} onClick={handleReportClick} />
        </IconWrapper>
      )}
    </>
  );
};

const IconWrapper = styled.div`
  display: flex;
  padding: ${rem(6)};

  & + & {
    margin-left: ${rem(6)};
  }
`;

const iconSizeProps = getSizeProps(24);

export default HeaderMenu;
