import styled from '@emotion/styled';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleStorySection_article$key } from '@/__generated__/ArticleStorySection_article.graphql';
import MultilineText from '@/components/Base/MultilineText';
import Spacer from '@/components/Base/Spacer';
import Text from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import ArticleStories from '@/pages/Article/components/ArticleStories';
import { maskPhoneNumber } from '@/utils/string';

import SectionTitle from './SectionTitle';

type Props = {
  articleRef: ArticleStorySection_article$key;
};

const ArticleStorySection: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(
    graphql`
      fragment ArticleStorySection_article on Article {
        content
        ...ArticleStories_article
      }
    `,
    articleRef
  );
  const { content } = article;

  return (
    <VStack>
      <SectionTitle>차량 소개</SectionTitle>
      <ArticleStories articleRef={article} />
      <Content color="gray900" variant="bodyL1Regular">
        <MultilineText text={maskPhoneNumber(content)} />
      </Content>
      <Spacer axis="vertical" size={20} />
    </VStack>
  );
};

const Content = styled(Text)`
  word-break: break-word;
  overflow-wrap: break-word;
`;

export default ArticleStorySection;
