import type { ValueOf } from '@/types/global';

export const ImageType = {
  UNKNOWN: 'UNKNOWN',
  EXTERIOR: 'EXTERIOR',
  INTERIOR: 'INTERIOR',
  DOCUMENT: 'DOCUMENT',
} as const;

export type ImageType = ValueOf<typeof ImageType>;
