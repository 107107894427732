import { ArticleEventType } from '@/__generated__/getTradeDurationFromArticle_article.graphql';
import { Nullish } from '@/types/global';

export const getFilteredEvents = (
  events: readonly { t: number; type: Nullish<ArticleEventType> }[] | undefined,
  type: ArticleEventType | ArticleEventType[]
) => {
  const types = Array.isArray(type) ? type : [type];

  return (events ?? [])
    .filter((event) => event.type && types.includes(event.type))
    .sort((a, b) => b.t - a.t);
};
