/**
 * @generated SignedSource<<87d4af741bd747b300ab849843198702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useNotificationCreatable_article$data = {
  readonly carData: {
    readonly autoupModel: {
      readonly subseries: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "useNotificationCreatable_article";
};
export type useNotificationCreatable_article$key = {
  readonly " $data"?: useNotificationCreatable_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationCreatable_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useNotificationCreatable_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoupModel",
          "kind": "LinkedField",
          "name": "autoupModel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutoupSubseries",
              "kind": "LinkedField",
              "name": "subseries",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "002549265f3dddc2395681e1043d9986";

export default node;
