import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { CarouselSection_query$key } from '@/__generated__/CarouselSection_query.graphql';
import Spacer from '@/components/Base/Spacer';
import HotArticlesSection from '@/pages/Main/components/HotArticlesSection';
import InspectedArticlesSection from '@/pages/Main/components/InspectedArticlesSection';

type Props = {
  queryRef: CarouselSection_query$key;
};

const CarouselSection: React.FC<Props> = ({ queryRef }) => {
  const query = useFragment(
    graphql`
      fragment CarouselSection_query on Query
      @argumentDefinitions(count: { type: "Int", defaultValue: 20 }) {
        inspectedArticles: articlesForInspectionSection(n: $count) {
          id
        }
        ...InspectedArticlesSection_query @arguments(count: $count)
        ...HotArticlesSection_query @arguments(count: $count)
      }
    `,
    queryRef
  );
  const { inspectedArticles } = query;

  return (
    <div>
      <Spacer axis="vertical" size={16} />
      {inspectedArticles.length > 0 ? (
        <>
          <InspectedArticlesSection queryRef={query} />
          <Spacer axis="vertical" size={32} />
        </>
      ) : null}
      <HotArticlesSection queryRef={query} />
      <Spacer axis="vertical" size={32} />
    </div>
  );
};

export default CarouselSection;
