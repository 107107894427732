import styled from '@emotion/styled';

const BoldDot = () => {
  return (
    <>
      &nbsp;
      <SuperBold>⸱</SuperBold>
      &nbsp;
    </>
  );
};

export default BoldDot;

const SuperBold = styled.b({
  fontWeight: 900,
});
