import { Checkbox } from '@daangn/sprout-components-checkbox';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { graphql, useFragment } from 'react-relay';

import { CompanyIdsFilter_autoupCompanies$key } from '@/__generated__/CompanyIdsFilter_autoupCompanies.graphql';
import BottomFixedLayer from '@/components/Base/BottomFixedLayer';
import Spacer from '@/components/Base/Spacer';
import CompanyIcon from '@/components/CompanyIcon';
import useTrack from '@/hooks/useTrack';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import { pressed, safeAreaInset } from '@/styles/mixins';
import { CompanyOrigin } from '@/types/CarData/CompanyOrigin';

import FilterButtonFooter from './FilterButtonFooter';
import { useFilterContext, useFilterUpdaterContext } from './FilterContextProvider';

type Props = {
  defaultCompanyOrigin?: CompanyOrigin;
  queryRef: CompanyIdsFilter_autoupCompanies$key;
};

const CompanyIdsFilter: React.FC<React.PropsWithChildren<Props>> = ({
  queryRef,
  defaultCompanyOrigin,
}) => {
  const {
    filter: { companyIds },
    isPending,
  } = useFilterContext();
  const { trackWithActivityName } = useTrack();

  const [countElement, setCountElement] = useState<HTMLDivElement | null>(null);

  const { updateOpenedFilter, startTransition } = useFilterUpdaterContext();
  const updateFilter = useUpdateFilter();
  const { autoupCompanies } = useFragment(
    graphql`
      fragment CompanyIdsFilter_autoupCompanies on Query {
        autoupCompanies {
          id
          name
          count
          companyOrigin
          ...CompanyIcon_autoupCompany
        }
      }
    `,
    queryRef
  );

  const [availableIds, setAvailableIds] = useState<string[]>([]);

  const toggleAvailableId = (id: string, value: boolean) => {
    setAvailableIds((pv) => (value ? pv.concat([id]) : pv.filter((item) => item !== id)));
  };

  const unselectAll = () => {
    setAvailableIds([]);
    trackWithActivityName('click_reset_filter_company');
  };

  const applyFilter = () => {
    trackWithActivityName('click_change_filter_company', {
      companyIds: availableIds,
      companyNames: availableIds.map(
        (id) => autoupCompanies.find((company) => company.id === id)!.name
      ),
    });
    updateOpenedFilter(null);
    startTransition(() => {
      updateFilter({ companyIds: availableIds });
    });
  };

  useEffect(() => {
    setCountElement(document.getElementById('manufacturer-count') as HTMLDivElement);
  }, [setCountElement]);

  useEffect(() => {
    if (companyIds) {
      setAvailableIds(companyIds);
    }
  }, [companyIds, setAvailableIds]);

  return (
    <Container>
      {autoupCompanies
        .filter((company) =>
          !defaultCompanyOrigin ? true : company.companyOrigin === defaultCompanyOrigin
        )
        .map((company) => {
          const isSelected = availableIds.includes(company.id) === true;
          return (
            <CompanyItem
              key={company.id}
              onClick={() => toggleAvailableId(company.id, !isSelected)}
            >
              <CompanyIcon active={false} companyRef={company} size={36} />
              <Spacer axis="horizontal" size={12} />
              <CompanyName>{company.name}</CompanyName>
              <Checkbox isSelected={isSelected} />
            </CompanyItem>
          );
        })}
      <BottomLayer>
        <FilterButtonFooter
          isLoading={isPending}
          onClickConfirm={applyFilter}
          onClickRefresh={unselectAll}
        />
      </BottomLayer>
      {countElement &&
        createPortal(
          <Fragment>{availableIds.length > 0 ? ` ${availableIds.length}` : ''}</Fragment>,
          countElement
        )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: ${rem(389)};
  overflow: auto;
  ${safeAreaInset({ paddingBottom: 76 })}
`;

const CompanyName = styled.div`
  flex-grow: 1;
  ${vars.$semantic.typography.bodyM1Regular}
`;

const CompanyItem = styled.div`
  display: flex;
  padding: ${rem(12)} ${rem(16)};
  align-items: center;
  ${pressed()}
`;

const BottomLayer = styled(BottomFixedLayer)`
  padding: 0;
  ${safeAreaInset({ paddingBottom: 0 })}
`;

export default CompanyIdsFilter;
