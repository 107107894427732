import { match } from 'ts-pattern';

import { IS_PROD } from '@/constants/config';
import { karrotBridge } from '@/sdk/bridge';
import { BaseEventParams, formatParams } from '@/utils/track';

import { TrackerImpl } from './TrackerImpl';

function logFirebaseEvent(
  type: 'click' | 'impression' | 'show',
  name: string,
  params?: BaseEventParams
) {
  if (!IS_PROD) {
    return;
  }
  const mergedParams = formatParams({ name, ...params });
  const v2EventType = match(type)
    .with('click', () => 'clicked')
    .with('show', () => 'shown_page')
    .with('impression', () => 'shown_element')
    .exhaustive();

  [type, v2EventType].forEach((eventName) => {
    karrotBridge.logEvent({
      analytics: { target: 'FIREBASE', name: `${eventName}_car`, params: mergedParams },
    });
  });
}

const FirebaseTracker = (): TrackerImpl => {
  let initialized = false;
  return {
    initialized,
    init: () => {
      initialized = true;
    },
    trackEvent: (name, params) => {
      if (!initialized) {
        throw new Error('FirebaseTracker is not initialized');
      }
      logFirebaseEvent(name.includes('impression_') ? 'impression' : 'click', name, params);
    },
    trackPageView: (name, params) => {
      if (!initialized) {
        throw new Error('FirebaseTracker is not initialized');
      }
      logFirebaseEvent('show', name, params);
    },
  };
};

export const firebaseTracker = FirebaseTracker();
