/**
 * @generated SignedSource<<c6d36870a01aaefd0c956c5048af0cd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticleStatusChangeSection_article$data = {
  readonly chatRooms: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly id: string;
  readonly promotionEndsAt: any | null | undefined;
  readonly status: ArticleStatus;
  readonly " $fragmentType": "ArticleStatusChangeSection_article";
};
export type ArticleStatusChangeSection_article$key = {
  readonly " $data"?: ArticleStatusChangeSection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleStatusChangeSection_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleStatusChangeSection_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promotionEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatRoom",
      "kind": "LinkedField",
      "name": "chatRooms",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "9d9228c6e71041a2d4cff6852f971f26";

export default node;
