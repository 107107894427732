/**
 * @generated SignedSource<<0ef7dcb44892cbd12abbe20009bb0ede>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type carHistory_summaryCarHistoryAccident$data = {
  readonly accidentAt: any;
  readonly claim: number;
  readonly expenseEstimate: number;
  readonly expensePainting: number;
  readonly expensePart: number;
  readonly expenseWage: number;
  readonly repaired: ReadonlyArray<string>;
  readonly reserve: number;
  readonly " $fragmentType": "carHistory_summaryCarHistoryAccident";
};
export type carHistory_summaryCarHistoryAccident$key = {
  readonly " $data"?: carHistory_summaryCarHistoryAccident$data;
  readonly " $fragmentSpreads": FragmentRefs<"carHistory_summaryCarHistoryAccident">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carHistory_summaryCarHistoryAccident"
};

(node as any).hash = "f352059910c1c56a21d2b94c14f33d4a";

export default node;
