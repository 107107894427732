import { isAfter, isBefore, isValid } from 'date-fns';
import { PropsWithChildren } from 'react';

type Props = {
  end: Date;
  start?: Date;
};

const ScheduledDisplay: React.FC<PropsWithChildren<Props>> = ({ start, end, children }) => {
  const now = new Date();

  if (start && isValid(start) && isBefore(now, start)) {
    return null;
  }

  if (isValid(end) && isAfter(now, end)) {
    return null;
  }

  return <>{children}</>;
};

export default ScheduledDisplay;
