/**
 * @generated SignedSource<<c1f6b606a8479cee0ec989f6c1d81070>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatus = "NONE" | "ON_SALE" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type InspectedArticlesSection_query$data = {
  readonly inspectedArticles: ReadonlyArray<{
    readonly id: string;
    readonly status: ArticleStatus;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleCard_article" | "VerticalArticleCard_article">;
  }>;
  readonly " $fragmentType": "InspectedArticlesSection_query";
};
export type InspectedArticlesSection_query$key = {
  readonly " $data"?: InspectedArticlesSection_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectedArticlesSection_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InspectedArticlesSection_query",
  "selections": [
    {
      "alias": "inspectedArticles",
      "args": [
        {
          "kind": "Variable",
          "name": "n",
          "variableName": "count"
        }
      ],
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "articlesForInspectionSection",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleCard_article"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VerticalArticleCard_article"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7c0ddbaa3a1929bedb52f1589c23fb21";

export default node;
