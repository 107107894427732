import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import React, { PropsWithChildren } from 'react';

import Box, { Props as BoxProps } from '@/components/Base/Box';

export type Props = {
  onClick: () => void;
} & BoxProps;

const TouchResponsiveButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  ...props
}) => {
  return (
    <Button onClick={onClick} px={20} py={14} {...props}>
      {children}
    </Button>
  );
};

const Button = styled(Box)({
  backgroundColor: vars.$semantic.color.paperDefault,
  '&:active': {
    backgroundColor: vars.$semantic.color.grayPressed,
  },
});

export default TouchResponsiveButton;
