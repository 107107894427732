/**
 * @generated SignedSource<<0003472810fd8e15b748281659010ec6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleStorySection_article$data = {
  readonly content: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleStories_article">;
  readonly " $fragmentType": "ArticleStorySection_article";
};
export type ArticleStorySection_article$key = {
  readonly " $data"?: ArticleStorySection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleStorySection_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleStorySection_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleStories_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "eba71874c3098f3010f1e710d70c7191";

export default node;
