import { objectValues } from '@toss/utils';

import type { ValueOf } from '@/types/global';

export const AdditionalOption = {
  NUM_OF_KEY_2: 'NUM_OF_KEY_2',
  DASHBOARD_CAMERA: 'DASHBOARD_CAMERA',
  SMOKING_FREE: 'SMOKING_FREE',
} as const;
export const AdditionalOptionNames = objectValues(AdditionalOption);
export type AdditionalOption = ValueOf<typeof AdditionalOption>;
