export const scrollToTop = (
  element: HTMLElement,
  options: ScrollToOptions = { behavior: 'smooth' }
) => {
  element.scroll({ top: 0, ...options });
};

export function getActiveActivityElement() {
  return document.querySelector(`[data-stackflow-activity-is-active="true"]`);
}

export const querySelectorInActiveActivity = (selector: string) => {
  const target = getActiveActivityElement()?.querySelector(selector) ?? undefined;
  return target;
};

export const scrollIntoView = (
  element: HTMLElement | string,
  options: ScrollIntoViewOptions = {
    block: 'center',
    inline: 'center',
    behavior: 'smooth',
  }
) => {
  requestAnimationFrame(() => {
    const target =
      typeof element === 'string'
        ? (querySelectorInActiveActivity(element) as HTMLElement | undefined)
        : element;
    target?.scrollIntoView(options);
  });
};

export const scrollToErrorMessage = () => {
  scrollIntoView(`[data-part='error-message']`);
};
