import { graphql, readInlineData } from 'react-relay';

import type { getNewArticleNotificationConditionInput_newArticleNotificationCondition$key } from '@/__generated__/getNewArticleNotificationConditionInput_newArticleNotificationCondition.graphql';
import type { CreateNewArticleNotificationConditionV2Input } from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';

export const getNewArticleNotificationConditionInput = (
  notificationRef: getNewArticleNotificationConditionInput_newArticleNotificationCondition$key
) => {
  const {
    companies,
    series,
    subseries,
    carTypes,
    fuelTypes,
    saleTypes,
    gears,
    modelYearMin,
    modelYearMax,
    driveDistanceMin,
    driveDistanceMax,
    priceMin,
    priceMax,
    companyOrigin,
    baseDistanceKm,
    baseRegion,
    h3IndexList,
  } = readInlineData(
    graphql`
      fragment getNewArticleNotificationConditionInput_newArticleNotificationCondition on NewArticleNotificationCondition
      @inline {
        baseDistanceKm
        baseRegion {
          name3
        }
        companies {
          id
          name
        }
        series {
          id
          name
        }
        subseries {
          id
          name
        }
        carTypes
        fuelTypes
        saleTypes
        gears
        modelYearMin
        modelYearMax
        driveDistanceMin
        driveDistanceMax
        priceMin
        priceMax
        companyOrigin
        baseDistanceKm
        baseRegion {
          id
        }
        h3IndexList {
          h3Index
          resolution
        }
      }
    `,
    notificationRef
  );

  const condition: CreateNewArticleNotificationConditionV2Input = {
    companyIds: companies?.map((item) => item.id),
    seriesIds: series?.map((item) => item.id),
    subseriesIds: subseries?.map((item) => item.id),
    carTypes,
    fuelTypes,
    saleTypes,
    gears,
    modelYearMin,
    modelYearMax,
    driveDistanceMin,
    driveDistanceMax,
    priceMin,
    priceMax,
    companyOrigin,
    baseDistanceKm,
    baseRegionId: baseRegion?.id ? Number(baseRegion.id) : undefined,
    h3IndexList,
  };

  return condition;
};
