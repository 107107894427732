/* eslint-disable perfectionist/sort-imports */
import React from 'react';

import { Stack } from '@/stackflow';
import { environment } from '@/sdk/bridge';
import Providers from '@/bootstrap/Providers';
import ServiceMaintenanceDialog from '@/components/ServiceMaintenanceDialog';
import { Dialog } from '@daangn/transfer-identification-sdk';
import InitApp from '@/bootstrap/InitApp';

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Providers environment={environment}>
      <Stack />
      <Dialog />
      <React.Suspense fallback={null}>
        <InitApp />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <ServiceMaintenanceDialog />
      </React.Suspense>
    </Providers>
  );
};

export default App;
