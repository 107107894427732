import { ErrorBoundary, type ErrorBoundaryProps } from '@sentry/react';
import React, { type PropsWithChildren, Suspense } from 'react';

import type { PropsOf } from '@/types/React/PropsOf';

const defaultErrorFallback = () => <></>;
const defaultFallback = <></>;

type Props = {
  errorFallback?: ErrorBoundaryProps['fallback'];
  fallback?: PropsOf<typeof Suspense>['fallback'];
};
const AsyncBoundary = ({
  children,
  errorFallback = defaultErrorFallback,
  fallback = defaultFallback,
}: PropsWithChildren<Props>) => (
  <ErrorBoundary fallback={errorFallback}>
    <Suspense fallback={fallback}>{children}</Suspense>
  </ErrorBoundary>
);

export default AsyncBoundary;
