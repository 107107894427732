import { getShortenYear } from '../number';

export const getRegistrationText = ({
  carRegistrationYear,
  carRegistrationMonth,
  modelYear,
}: any) => {
  return `${getShortenYear(modelYear)}년식 / ${getShortenYear(
    carRegistrationYear
  )}년 ${carRegistrationMonth}월 등록`;
};
