/**
 * @generated SignedSource<<c1d6a20f7c44b5907811f77e6d266022>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleActionCount_article$data = {
  readonly chatRoomCount: number;
  readonly voteCount: number;
  readonly " $fragmentType": "ArticleActionCount_article";
};
export type ArticleActionCount_article$key = {
  readonly " $data"?: ArticleActionCount_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleActionCount_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleActionCount_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voteCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chatRoomCount",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "f23222a449542e3ea607bed5ad4cd2d1";

export default node;
