import { graphql } from 'relay-runtime';

import { useMeQuery } from '@/__generated__/useMeQuery.graphql';

import useFetchQuery from './useFetchQuery';

const useMe = () => {
  return useFetchQuery<useMeQuery>(graphql`
    query useMeQuery {
      me {
        selfVerified
        karrotPayKycCompleted
      }
    }
  `);
};

export default useMe;
