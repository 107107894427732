import { vars } from '@seed-design/design-token';
import { IconSearchFill } from '@seed-design/icon';

import HStack from '@/components/Base/HStack';
import { Label2Regular } from '@/components/Base/Text';
import { getSizeProps } from '@/utils/style';

type Props = {
  onClick: () => void;
};

const SearchButton = (props: Props) => {
  return (
    <HStack
      align="center"
      aria-label="검색"
      bg="gray100"
      br={6}
      gap={6}
      h={36}
      px={12}
      role="button"
      tabIndex={0}
      {...props}
    >
      <IconSearchFill {...getSizeProps(16)} color={vars.$scale.color.gray500} />
      <Label2Regular color="gray500">어떤 차를 찾으시나요?</Label2Regular>
    </HStack>
  );
};

export default SearchButton;
