import type { ValueOf } from '@/types/global';

export const Manufacturer = {
  Hyundai: 'HYUNDAI',
  Kia: 'KIA',
  Genesis: 'GENESIS',
  ChevroletGmDaewoo: 'CHEVROLET_GM_DAEWOO',
  Renault: 'RENAULT_SAMSUNG',
  Ssangyoung: 'SSANGYOUNG',
  DaewooBus: 'DAEWOO_BUS',
  Benz: 'BENZ',
  Bmw: 'BMW',
  Audi: 'AUDI',
  Fox: 'FOX',
  Volkswagen: 'VOLKSWAGEN',
  Toyota: 'TOYOTA',
  Lexus: 'LEXUS',
  Honda: 'HONDA',
  Mini: 'MINI',
  Ford: 'FORD',
  Peugeot: 'PEUGEOT',
  Volvo: 'VOLVO',
  Porsche: 'PORSCHE',
  Cadillac: 'CADILLAC',
  Jeep: 'JEEP',
  Lincoln: 'LINCOLN',
  Chrysler: 'CHRYSLER',
  Nissan: 'NISSAN',
  Infiniti: 'INFINITI',
  Jaguar: 'JAGUAR',
  LandRover: 'LAND_ROVER',
  Mazda: 'MAZDA',
  McRaren: 'MC_LAREN',
  Mitsubishi: 'MITSUBISHI',
  Bentley: 'BENTLEY',
  Saab: 'SAAB',
  Subaru: 'SUBARU',
  Suzuki: 'SUZUKI',
  Citroen: 'CITROEN',
  AstonMartin: 'ASTON_MARTIN',
  Ferrari: 'FERRARI',
  Fiat: 'FIAT',
  Hummer: 'HUMMER',
  Tesla: 'TESLA',
  Dodge: 'DODGE',
  Chevrolet: 'CHEVROLET',
  Gmc: 'GMC',
  Lamborghini: 'LAMBORGHINI',
  Rolls: 'ROLLS',
  Maserati: 'MASERATI',
  Maybach: 'MAYBACH',
  Cyan: 'CYAN',
  Acura: 'ACURA',
  Dfsk: 'DFSK',
  Ds: 'DS',
  DaechangMotors: 'DAECHANG_MOTORS',
  Lotus: 'LOTUS',
  Maiv: 'MAIV',
  Baic: 'BAIC',
  Oullim: 'OULLIM',
  Isuzu: 'ISUZU',
  Zhidou: 'ZHIDOU',
  Cammsys: 'CAMMSYS',
  PowerPlaza: 'POWER_PLAZA',
  Foton: 'FOTON',
  Etc: 'ETC',
} as const;

export const ManufacturerText: Partial<Record<Manufacturer, string>> = {
  [Manufacturer.Hyundai]: '현대',
  [Manufacturer.Kia]: '기아',
  [Manufacturer.Genesis]: '제네시스',
  [Manufacturer.ChevroletGmDaewoo]: '쉐보레(GM 대우)',
  [Manufacturer.Renault]: '르노(삼성)',
  [Manufacturer.Ssangyoung]: '쌍용',
  [Manufacturer.DaewooBus]: '자일대우버스',
  [Manufacturer.Benz]: '벤츠',
  [Manufacturer.Bmw]: 'BMW',
  [Manufacturer.Audi]: '아우디',
  [Manufacturer.Volkswagen]: '폭스바겐',
  [Manufacturer.Toyota]: '도요타',
  [Manufacturer.Lexus]: '렉서스',
  [Manufacturer.Honda]: '혼다',
  [Manufacturer.Mini]: '미니',
  [Manufacturer.Ford]: '포드',
  [Manufacturer.Peugeot]: '푸조',
  [Manufacturer.Volvo]: '볼보',
  [Manufacturer.Porsche]: '포르쉐',
  [Manufacturer.Cadillac]: '캐딜락',
  [Manufacturer.Jeep]: '지프',
  [Manufacturer.Lincoln]: '링컨',
  [Manufacturer.Chrysler]: '크라이슬러',
  [Manufacturer.Nissan]: '닛산',
  [Manufacturer.Infiniti]: '인피니티',
  [Manufacturer.Jaguar]: '재규어',
  [Manufacturer.LandRover]: '랜드로버',
  [Manufacturer.Mazda]: '마쯔다',
  [Manufacturer.McRaren]: '멕라렌',
  [Manufacturer.Mitsubishi]: '미쯔비시',
  [Manufacturer.Bentley]: '벤틀리',
  [Manufacturer.Saab]: '사브',
  [Manufacturer.Subaru]: '스바루',
  [Manufacturer.Suzuki]: '스즈키',
  [Manufacturer.Citroen]: '시트로엥',
  [Manufacturer.AstonMartin]: '애스턴 마틴',
  [Manufacturer.Ferrari]: '페라리',
  [Manufacturer.Fiat]: '피아트',
  [Manufacturer.Hummer]: '허머',
  [Manufacturer.Tesla]: '테슬라',
  [Manufacturer.Dodge]: '닷지',
  [Manufacturer.Chevrolet]: '쉐보레',
  [Manufacturer.Gmc]: 'GMC',
  [Manufacturer.Lamborghini]: '람보르기니',
  [Manufacturer.Rolls]: '롤스로이스',
  [Manufacturer.Maserati]: '마세라티',
  [Manufacturer.Maybach]: '마이바흐',
  [Manufacturer.Acura]: '아큐라',
  [Manufacturer.Dfsk]: 'DFSK',
  [Manufacturer.Ds]: 'DS',
  [Manufacturer.DaechangMotors]: '대창모터스',
  [Manufacturer.Lotus]: '로터스',
  [Manufacturer.Maiv]: '마이브',
  [Manufacturer.Baic]: 'BAIC',
  [Manufacturer.Oullim]: '어울림모터스',
  [Manufacturer.Isuzu]: '이스즈',
  [Manufacturer.Zhidou]: 'ZD',
  [Manufacturer.PowerPlaza]: '파워플라자',
  [Manufacturer.Foton]: '포톤',
  [Manufacturer.Etc]: '기타',
};
export type Manufacturer = ValueOf<typeof Manufacturer>;
