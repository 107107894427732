/**
 * @generated SignedSource<<a677016a5a7c94e5dbc388d861a0e8a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CarHistoryAccidentType = "MINE_INSURED_BY_MINE" | "MINE_INSURED_BY_OTHER" | "OTHERS";
import { FragmentRefs } from "relay-runtime";
export type carHistory_summaryCarHistoryAccidents$data = {
  readonly accidents: ReadonlyArray<{
    readonly accidentAt: any;
    readonly claim: number;
    readonly expenseEstimate: number;
    readonly type: CarHistoryAccidentType;
    readonly " $fragmentSpreads": FragmentRefs<"carHistory_summaryCarHistoryAccident">;
  }>;
  readonly " $fragmentType": "carHistory_summaryCarHistoryAccidents";
};
export type carHistory_summaryCarHistoryAccidents$key = {
  readonly " $data"?: carHistory_summaryCarHistoryAccidents$data;
  readonly " $fragmentSpreads": FragmentRefs<"carHistory_summaryCarHistoryAccidents">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "carHistory_summaryCarHistoryAccidents"
};

(node as any).hash = "bf77c78200e8814a81d177cf080c1c31";

export default node;
