import { AvoidSnackbarOverlap } from '@daangn/sprout-components-snackbar';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { PropsWithChildren, type ReactNode } from 'react';

import BaseDivider from '@/components/Base/Divider';
import { safeAreaInset } from '@/styles/mixins';

type Props = {
  animate?: boolean;
  hasDivider?: boolean;
  topArea?: ReactNode;
};

const BottomFixedLayer: React.FC<PropsWithChildren<Props>> = ({
  children,
  animate,
  topArea,
  hasDivider = true,
  ...props
}) => {
  return (
    <AvoidSnackbarOverlap>
      <Container data-animate={animate}>
        {hasDivider && <Divider backgroundColor={vars.$semantic.color.divider3} />}
        {topArea}
        <Wrapper {...props}>{children}</Wrapper>
      </Container>
    </AvoidSnackbarOverlap>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform cubic-bezier(0.41, 0.41, 0.51, 1) 300ms;
  bottom: 0;
  background: ${vars.$semantic.color.paperDefault};
  z-index: 1;
  &[data-animate='false'] {
    transform: translate3d(0, 100%, 0);
  }
`;

const Divider = styled(BaseDivider)`
  transform: scaleY(0.5);
`;

const Wrapper = styled.div`
  padding: ${rem(12)} ${rem(16)} ${rem(8)};
  ${safeAreaInset({ paddingBottom: 8 })}
`;

export default BottomFixedLayer;
