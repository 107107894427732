import { graphql, readInlineData } from 'relay-runtime';

import type { getArticleLeaseRentalPriceInfo_article$key } from '@/__generated__/getArticleLeaseRentalPriceInfo_article.graphql';

import { assertNonNullish } from '@/types/Assert/assertNonNullish';

import { formatMonthDistance } from './formatMonthDistance';

export const getArticleLeaseRentalPriceInfo = (
  articleRef: getArticleLeaseRentalPriceInfo_article$key,
  postContractOption: 'BUY' | 'RETURN'
) => {
  const { leaseInfo, rentalInfo } = readInlineData(
    graphql`
      fragment getArticleLeaseRentalPriceInfo_article on Article @inline {
        leaseInfo {
          contractStartedAt
          contractEndedAt
          monthlyFee
          acquisitionPrice
          residualValue
          deposit
          postContractOption
        }
        rentalInfo {
          contractStartedAt
          contractEndedAt
          monthlyFee
          acquisitionPrice
          residualValue
          deposit
          postContractOption
        }
      }
    `,
    articleRef
  );
  const info = leaseInfo ?? rentalInfo;
  assertNonNullish(info);

  const articleTypeText = leaseInfo ? '리스' : '렌트';
  const now = new Date();
  const {
    contractStartedAt, // 계약 시작
    contractEndedAt, // 계약 종료
    monthlyFee, // 월 이용료
    acquisitionPrice, // 인수금
    residualValue, // 잔존 가치
    deposit, // 보증금
  } = info;

  const startDate = new Date(contractStartedAt);
  const endDate = new Date(contractEndedAt);

  const remainingContractPeriod = formatMonthDistance(endDate, now); // 남은 계약 개월 수
  const totalContractPeriod = formatMonthDistance(endDate, startDate); // 총 계약 개월 수
  const remainingInstallment = monthlyFee * remainingContractPeriod; // 남은 할부금
  const postContractPrice = postContractOption === 'BUY' ? residualValue - deposit : deposit; // 구매 시 차량 가액 (지불), 반납 시 보증금 (지원)
  const totalAcquisitionPrice =
    remainingInstallment +
    acquisitionPrice +
    postContractPrice * (postContractOption === 'RETURN' ? -1 : 1); // 남은 할부금 + 인수금 +- (반납시 보증금 뺴기, 구매시 잔존가치 - 보증금 더하기)
  const acquisitionText = acquisitionPrice >= 0 ? '인수금' : '승계 지원금';

  return {
    articleTypeText,
    totalAcquisitionPrice,
    totalContractPeriod,
    acquisitionText,
    postContractPrice,
    remainingInstallment,
    remainingContractPeriod,
    acquisitionPrice,
    postContractOption,
    monthlyFee,
  };
};
