import React from 'react';

import BulletedItem from './Base/BulletedItem';
import KeywordHighlighter from './Base/KeywordHighlighter';
import Quote from './Base/Quote';
import Text from './Base/Text';
import VStack from './Base/VStack';

const NoAccident: React.FC<React.PropsWithChildren<unknown>> = ({ ...props }) => {
  return (
    <VStack gap={12} {...props}>
      <Text color="gray900" variant="bodyL1Regular">
        <KeywordHighlighter
          keywords={['성능에 영향이 없는 단순 수리']}
          render={(matchedKeyword) => (
            <Text color="accent" inline variant="bodyL1Bold">
              {matchedKeyword}
            </Text>
          )}
          text="성능에 영향이 없는 단순 수리는 무사고 차량으로 정의해요."
        />
      </Text>
      <Quote>
        <Text color="gray700" variant="bodyL2Regular">
          자동차 관리법 시행 규칙에 따르면 수리가 되었어도 안전 및 성능에 문제가 생길 수 있는 경우를
          사고차량으로 정의하고 있어요.
        </Text>
        <BulletedItem color="gray700" variant="bodyL2Regular">
          차량 주요 골격에 교환 또는 용접 수리가 행해진 경우
        </BulletedItem>
        <BulletedItem color="gray700" variant="bodyL2Regular">
          외판의 사이드실 패널, 루프 패널, 쿼터 패널에 교환 또는 용접 수리가 행해진 경우
        </BulletedItem>
      </Quote>
    </VStack>
  );
};

export default NoAccident;
