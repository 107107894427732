/**
 * @generated SignedSource<<117f711f15b3f3e8c852da01c7a33f26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NiceDnrHistoryType = "INSPECTION" | "Registration" | "TRANSFER";
import { FragmentRefs } from "relay-runtime";
export type getLastTransferInspection_article$data = {
  readonly carData: {
    readonly niceDnrHistory: ReadonlyArray<{
      readonly date: any;
      readonly driveDistance: number | null | undefined;
      readonly type: NiceDnrHistoryType;
    }>;
  };
  readonly " $fragmentType": "getLastTransferInspection_article";
};
export type getLastTransferInspection_article$key = {
  readonly " $data"?: getLastTransferInspection_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getLastTransferInspection_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getLastTransferInspection_article"
};

(node as any).hash = "8f68cf1e74b2a413fb441d94d019e273";

export default node;
