import { ActivityComponentType } from '@stackflow/react';
import { ComponentType } from 'react';

import {
  LazyPageAgreement,
  LazyPageArticleCarHistory,
  LazyPageArticleChangePrice,
  LazyPageArticleChannelList,
  LazyPageArticleChecklist,
  LazyPageArticleEdit,
  LazyPageArticleEditImages,
  LazyPageArticleImages,
  LazyPageArticleLeaseRental,
  LazyPageArticleOptions,
  LazyPageArticleProxyInspectionGuide,
  LazyPageArticleProxyInspectionPayment,
  LazyPageArticleSafeTrade,
  LazyPageArticleSafeTradeBuyerCompletePayment,
  LazyPageArticleSafeTradeBuyerConfirmPrice,
  LazyPageArticleSafeTradeBuyerConfirmTrade,
  LazyPageArticleSafeTradeBuyerInputPrice,
  LazyPageArticleSafeTradeRegisterAccount,
  LazyPageArticleSafeTradeSellerCancelTrade,
  LazyPageArticleSafeTradeSellerConfirmPrice,
  LazyPageArticleSelectBuyer,
  LazyPageArticleSelectReserver,
  LazyPageArticleSummary,
  LazyPageCuration,
  LazyPageDevLaboratory,
  LazyPageFetchCarInfo,
  LazyPageHotArticles,
  LazyPageHyundaiCapital,
  LazyPageInputAddresses,
  LazyPageInputDisplacement,
  LazyPageInspectedArticles,
  LazyPageInspection,
  LazyPageInspectionAttachDealerProposal,
  LazyPageInspectionCancel,
  LazyPageInspectionCarHistory,
  LazyPageInspectionEstimationReport,
  LazyPageInspectionFaqs,
  LazyPageInspectionFinalizeArticle,
  LazyPageInspectionGuide,
  LazyPageInspectionListForReview,
  LazyPageInspectionMemo,
  LazyPageInspectionPriceEstimation,
  LazyPageInspectionReply,
  LazyPageInspectionReport,
  LazyPageInspectionReportImages,
  LazyPageInspectionReschedule,
  LazyPageLikedArticles,
  LazyPageMechanic,
  LazyPageMechanicTimeTable,
  LazyPageMoonJinPyo,
  LazyPageMyInspections,
  LazyPageNoAccident,
  LazyPageNotification,
  LazyPageNotificationArticles,
  LazyPagePaymentSmokeTest,
  LazyPagePullUp,
  LazyPageRegionRange,
  LazyPageRegisterMechanic,
  LazyPageRegisterMechanicInputRegions,
  LazyPageRegisterMechanicSuccess,
  LazyPageRegisterWarranty,
  LazyPageReserveAgainInspection,
  LazyPageReserveInspection,
  LazyPageSafeTradeGuide,
  LazyPageSalesHistory,
  LazyPageSearch,
  LazyPageSearchResult,
  LazyPageSelectCompany,
  LazyPageSelectGrade,
  LazyPageSelectSeries,
  LazyPageSelectSubgrade,
  LazyPageSelectSubseries,
  LazyPageSubmergedCar,
  LazyPageSubseriesMetadata,
  LazyPageSubseriesTransactionHistory,
  LazyPageTermsOfAgreement,
  LazyPageTradeGuide,
  LazyPageTradeReview,
  LazyPageTradedCars,
  LazyPageWarranty,
  LazyPageWarrantyCoverageExclusion,
  LazyPageWarrantyExclusionGuide,
  LazyPageWarrantyGuide,
  LazyPageWarrantyPrivacyConsent,
  LazyPageWarrantyTermsAgreement,
  LazyPageWriteArticle,
  LazyPageWriteMechanicReview,
  LazyPageWriteReport,
  LazyPageWriteReportApproval,
  LazyPageWriteReportBaseInfo,
  LazyPageWriteReportCarData,
  LazyPageWriteReportConfirm,
  LazyPageWriteReportContent,
  LazyPageWriteReportItem,
  LazyPageWriteReportObdCode,
  LazyPageWriteReportOptions,
  LazyPageWriteReportReject,
  LazyPageWriteReportView,
  LazyPageWriteTradeReview,
} from '@/bootstrap/pages';
import { IS_ALPHA, IS_LOCAL } from '@/constants/config';
import withActivityErrorScreen from '@/hocs/withActivityErrorScreen';
import PageArticle from '@/pages/Article';
import PageMain from '@/pages/Main';
import PageNotFound from '@/pages/NotFound';
import { ActivityName, activityDescription } from '@/stackflow/Activity';

type Route = {
  component: ActivityComponentType;
  decode: (params: Record<string, string>) => any;
  description: string;
  name: ActivityName;
  path: string;
};

const makeRoute = (name: ActivityName, component: ComponentType): Route => {
  return {
    name,
    path: activityDescription[name].url,
    description: activityDescription[name].description,
    decode: activityDescription[name].schema.parse,
    component: component as ActivityComponentType,
  };
};

const routes: Route[] = [
  makeRoute('main', () => <PageMain />),
  makeRoute('hot_articles', LazyPageHotArticles),
  makeRoute('inspected_articles', LazyPageInspectedArticles),
  makeRoute('curation', LazyPageCuration),
  makeRoute('write_article', LazyPageWriteArticle),
  makeRoute('reserve_inspection', LazyPageReserveInspection),
  makeRoute('reserve_inspection_again', LazyPageReserveAgainInspection),
  makeRoute('inspection_guide', LazyPageInspectionGuide),
  makeRoute('write_trade_review', LazyPageWriteTradeReview),
  makeRoute('edit_article', LazyPageArticleEdit),
  makeRoute('edit_article_images', LazyPageArticleEditImages),
  makeRoute('pullup_article', LazyPagePullUp),
  makeRoute('article_change_price', LazyPageArticleChangePrice),
  makeRoute('fetch_car_info', LazyPageFetchCarInfo),
  makeRoute('article', () => <PageArticle />),
  makeRoute('article_summary', LazyPageArticleSummary),
  makeRoute('article_proxy_inspection_guide', LazyPageArticleProxyInspectionGuide),
  makeRoute('article_proxy_inspection_payment', LazyPageArticleProxyInspectionPayment),
  makeRoute('article_car_history', LazyPageArticleCarHistory),
  makeRoute('article_lease_rental', LazyPageArticleLeaseRental),
  makeRoute('article_channel_list', LazyPageArticleChannelList),
  makeRoute('article_options_list', LazyPageArticleOptions),
  makeRoute('article_checklist', LazyPageArticleChecklist),
  makeRoute('article_select_buyer', LazyPageArticleSelectBuyer),
  makeRoute('article_select_reserver', LazyPageArticleSelectReserver),
  makeRoute('register_warranty', LazyPageRegisterWarranty),
  makeRoute('warranty_privacy_consent', LazyPageWarrantyPrivacyConsent),
  makeRoute('warranty_terms_agreement', LazyPageWarrantyTermsAgreement),
  makeRoute('warranty_coverage_exclusion', LazyPageWarrantyCoverageExclusion),
  makeRoute('subseries_transaction_history', LazyPageSubseriesTransactionHistory),
  makeRoute('article_images', LazyPageArticleImages),
  makeRoute('trade_review', LazyPageTradeReview),
  makeRoute('subseries_metadata', LazyPageSubseriesMetadata),
  makeRoute('search', LazyPageSearch),
  makeRoute('search_result', LazyPageSearchResult),
  makeRoute('select_company', LazyPageSelectCompany),
  makeRoute('select_series', LazyPageSelectSeries),
  makeRoute('select_subseries', LazyPageSelectSubseries),
  makeRoute('select_grade', LazyPageSelectGrade),
  makeRoute('select_subgrade', LazyPageSelectSubgrade),
  makeRoute('input_displacement', LazyPageInputDisplacement),
  makeRoute('liked_articles', LazyPageLikedArticles),
  makeRoute('select_company', LazyPageSelectCompany),
  makeRoute('sales_history', LazyPageSalesHistory),
  makeRoute('my_sales_history', LazyPageSalesHistory),
  makeRoute('notification', LazyPageNotification),
  makeRoute('notification_articles', LazyPageNotificationArticles),
  makeRoute('no_accident', LazyPageNoAccident),
  makeRoute('trade_guide', LazyPageTradeGuide),
  makeRoute('submerged_car', LazyPageSubmergedCar),
  makeRoute('hyundai_capital', LazyPageHyundaiCapital),
  makeRoute('traded_cars', LazyPageTradedCars),
  makeRoute('region_range', LazyPageRegionRange),
  makeRoute('my_inspections', LazyPageMyInspections),
  makeRoute('register_mechanic', LazyPageRegisterMechanic),
  makeRoute('register_mechanic_success', LazyPageRegisterMechanicSuccess),
  makeRoute('register_mechanic_input_regions', LazyPageRegisterMechanicInputRegions),
  makeRoute('input_addresses', LazyPageInputAddresses),
  makeRoute('inspection', LazyPageInspection),
  makeRoute('inspection_faqs', LazyPageInspectionFaqs),
  makeRoute('inspection_memo', LazyPageInspectionMemo),
  makeRoute('inspection_reschedule', LazyPageInspectionReschedule),
  makeRoute('inspection_cancel', LazyPageInspectionCancel),
  makeRoute('terms_of_agreement', LazyPageTermsOfAgreement),
  ...(IS_LOCAL || IS_ALPHA ? [makeRoute('dev_laboratory', LazyPageDevLaboratory)] : []),
  makeRoute('write_report_content', LazyPageWriteReportContent),
  makeRoute('write_report_item', LazyPageWriteReportItem),
  makeRoute('write_report_confirm', LazyPageWriteReportConfirm),
  makeRoute('write_report_reject', LazyPageWriteReportReject),
  makeRoute('write_report_obd_code', LazyPageWriteReportObdCode),
  makeRoute('write_report_car_data', LazyPageWriteReportCarData),
  makeRoute('write_report_options', LazyPageWriteReportOptions),
  makeRoute('write_report_base_info', LazyPageWriteReportBaseInfo),
  makeRoute('write_report_view', LazyPageWriteReportView),
  makeRoute('write_report_approval', LazyPageWriteReportApproval),
  makeRoute('write_report', LazyPageWriteReport),
  makeRoute('inspection_report', LazyPageInspectionReport),
  makeRoute('inspection_report_images', LazyPageInspectionReportImages),
  makeRoute('moon_jin_pyo', LazyPageMoonJinPyo),
  makeRoute('write_mechanic_review', LazyPageWriteMechanicReview),
  makeRoute('warranty', LazyPageWarranty),
  makeRoute('warranty_guide', LazyPageWarrantyGuide),
  makeRoute('warranty_exclusion_guide', LazyPageWarrantyExclusionGuide),
  makeRoute('mechanic_time_table', LazyPageMechanicTimeTable),
  makeRoute('mechanic_my_profile', LazyPageMechanic),
  makeRoute('payment_smoke_test', LazyPagePaymentSmokeTest),
  makeRoute('inspections_for_review', LazyPageInspectionListForReview),
  makeRoute('inspection_price_estimation', LazyPageInspectionPriceEstimation),
  makeRoute('inspection_estimation_report', LazyPageInspectionEstimationReport),
  makeRoute('inspection_finalize_article', LazyPageInspectionFinalizeArticle),
  makeRoute('inspection_attach_dealer_proposal', LazyPageInspectionAttachDealerProposal),
  makeRoute('inspection_car_history', LazyPageInspectionCarHistory),
  makeRoute('article_safe_trade', LazyPageArticleSafeTrade),
  makeRoute('article_safe_trade_buyer_confirm_trade', LazyPageArticleSafeTradeBuyerConfirmTrade),
  makeRoute(
    'article_safe_trade_buyer_complete_payment',
    LazyPageArticleSafeTradeBuyerCompletePayment
  ),
  makeRoute('article_safe_trade_buyer_confirm_price', LazyPageArticleSafeTradeBuyerConfirmPrice),
  makeRoute('article_safe_trade_buyer_input_price', LazyPageArticleSafeTradeBuyerInputPrice),
  makeRoute('article_safe_trade_register_account', LazyPageArticleSafeTradeRegisterAccount),
  makeRoute('article_safe_trade_seller_cancel_trade', LazyPageArticleSafeTradeSellerCancelTrade),
  makeRoute('article_safe_trade_seller_confirm_price', LazyPageArticleSafeTradeSellerConfirmPrice),
  makeRoute('safe_trade_guide', LazyPageSafeTradeGuide),
  makeRoute('agreement', LazyPageAgreement),
  makeRoute('not_found', PageNotFound),
  makeRoute('inspection_reply', LazyPageInspectionReply),
].map((route) => {
  return {
    ...route,
    component: withActivityErrorScreen(route.component),
  };
});

export default routes;
