import React, { memo } from 'react';

import VStack from '@/components/Base/VStack';
import SkeletonHorizontalArticleCard from '@/components/Skeleton/SkeletonHorizontalArticleCard';

const SkeletonHorizontalArticleCardList: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <VStack>
      {[...Array(3)].map((_, i: number) => (
        <SkeletonHorizontalArticleCard key={i} />
      ))}
    </VStack>
  );
};

export default memo(SkeletonHorizontalArticleCardList);
