import { BoxButton } from '@daangn/sprout-components-button';
import { PropsWithChildren } from 'react';

import { PropsOf } from '@/types/React/PropsOf';

type Props = PropsOf<typeof BoxButton>;

const LoadingButton: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return (
    <BoxButton {...props} isDisabled={props.isDisabled || props.isLoading}>
      {children}
    </BoxButton>
  );
};

export default LoadingButton;
