import { differenceInDays } from 'date-fns';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { ArticleStories_article$key } from '@/__generated__/ArticleStories_article.graphql';
import ChatBubble from '@/components/Base/ChatBubble';
import HStack from '@/components/Base/HStack';
import { BodyL2Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import Camera from '@/components/icon/Camera';
import Handle from '@/components/icon/Handle';
import NoSmoking from '@/components/icon/NoSmoking';
import OneOwner from '@/components/icon/OneOwner';
import TwoKeys from '@/components/icon/TwoKeys';
import { getLastTransferInspection } from '@/utils/article/getLastTransferInspection';
import { parseOptions } from '@/utils/article/parseOptions';
import { getCommaKmFormat } from '@/utils/number';

type Props = {
  articleRef: ArticleStories_article$key;
};

const Fragment = graphql`
  fragment ArticleStories_article on Article {
    createdAt
    driveDistance
    carData {
      carHistory {
        changes {
          date
          type
        }
      }
    }
    ...getLastTransferInspection_article
    ...parseOptions_article
  }
`;

const ArticleStories: React.FC<Props> = ({ articleRef }) => {
  const article = useFragment(Fragment, articleRef);
  const {
    createdAt,
    driveDistance,
    carData: { carHistory },
  } = article;

  const { additionalOptions } = parseOptions(article);
  const lastTransferInspection = getLastTransferInspection(article);
  const registeredChange = (carHistory?.changes ?? []).find((change) => change.type === 'REGISTER');
  const isOnlyOneOwner = !lastTransferInspection;
  const days =
    registeredChange || !!lastTransferInspection
      ? differenceInDays(
          new Date(createdAt),
          new Date(isOnlyOneOwner ? registeredChange?.date : lastTransferInspection?.date)
        )
      : 0;
  const yearsSinceLatestChange = Math.floor(days / 365);
  const monthsSinceLatestChange = Math.max(Math.floor((days % 365) / 30), 1);
  const periodText =
    (yearsSinceLatestChange > 0 ? `${yearsSinceLatestChange}년 ` : '') +
    `${monthsSinceLatestChange}개월`;

  const myDroveDistanceStart = lastTransferInspection?.driveDistance ?? 0;
  const myDroveDistance = isOnlyOneOwner ? driveDistance : driveDistance - myDroveDistanceStart;
  const isMyDroveDistanceAvailable =
    isOnlyOneOwner || (myDroveDistanceStart > 0 && myDroveDistance >= 0);

  const isSmokingFree = additionalOptions.includes('SMOKING_FREE');
  const hasTwoKeys = additionalOptions.includes('NUM_OF_KEY_2');
  const hasDashCam = additionalOptions.includes('DASHBOARD_CAMERA');

  const stories = [];
  const hStackProps = { gap: 4, align: 'center' as const };

  if (isOnlyOneOwner) {
    stories.push(
      <HStack {...hStackProps}>
        <OneOwner />
        <BodyL2Regular>혼자서 소유했던 차예요.</BodyL2Regular>
      </HStack>
    );
  }
  if (isSmokingFree) {
    stories.push(
      <HStack {...hStackProps}>
        <NoSmoking />
        <BodyL2Regular>실내에서 금연했어요.</BodyL2Regular>
      </HStack>
    );
  }
  if (isMyDroveDistanceAvailable) {
    stories.push(
      <HStack {...hStackProps}>
        <Handle />
        <BodyL2Regular>
          인수 후 {periodText}동안 {getCommaKmFormat(myDroveDistance)} 주행했어요.
        </BodyL2Regular>
      </HStack>
    );
  }
  if (hasTwoKeys && hasDashCam) {
    stories.push(
      <HStack {...hStackProps}>
        <TwoKeys />
        <BodyL2Regular>키 2개와</BodyL2Regular>
        <Camera />
        <BodyL2Regular>블랙박스를 같이 드려요.</BodyL2Regular>
      </HStack>
    );
  } else if (hasTwoKeys) {
    stories.push(
      <HStack {...hStackProps}>
        <TwoKeys />
        <BodyL2Regular>키 2개를 같이 드려요.</BodyL2Regular>
      </HStack>
    );
  } else if (hasDashCam) {
    stories.push(
      <HStack {...hStackProps}>
        <Camera />
        <BodyL2Regular>블랙박스를 같이 드려요.</BodyL2Regular>
      </HStack>
    );
  }

  if (stories.length === 0) {
    return null;
  }

  return (
    <VStack gap={10} mb={20}>
      {stories.map((story, index) => (
        <ChatBubble key={index}>{story}</ChatBubble>
      ))}
    </VStack>
  );
};

export default ArticleStories;
