import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { rem } from 'polished';
import React, { useMemo, useState } from 'react';

import Dim from '@/components/Base/Dim';
import ManuallyPositionedTooltip from '@/components/Base/ManuallyPositionedTooltip';
import Manner1 from '@/components/icon/MannerTemperature/Manner1';
import Manner2 from '@/components/icon/MannerTemperature/Manner2';
import Manner3 from '@/components/icon/MannerTemperature/Manner3';
import Manner4 from '@/components/icon/MannerTemperature/Manner4';
import Manner5 from '@/components/icon/MannerTemperature/Manner5';
import Manner6 from '@/components/icon/MannerTemperature/Manner6';

const mannerTemperatureBreakpoints = [12.5, 30, 40, 50.5, 65.5] as const;
interface Props {
  temperature: number;
}

const temperatureIcons = [
  {
    component: Manner1,
    color: '#777F87',
  },
  {
    component: Manner2,
    color: '#4D8FCE',
    barColor: '#3C77B0',
  },
  {
    component: Manner3,
    color: '#58B9ED',
  },
  {
    component: Manner4,
    color: '#66D06B',
  },
  {
    component: Manner5,
    color: '#FFB938',
  },
  {
    component: Manner6,
    color: vars.$scale.color.carrot500,
  },
];

const MannerTemperature: React.FC<React.PropsWithChildren<Props>> = ({ temperature, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    color,
    barColor,
    component: MannerComponent,
  } = useMemo(() => {
    for (let index = 0; index < mannerTemperatureBreakpoints.length; index++) {
      if (temperature <= mannerTemperatureBreakpoints[index]) {
        return temperatureIcons[index];
      }
    }
    return temperatureIcons[temperatureIcons.length - 1];
  }, [temperature]);

  return (
    <MannerTemperatureContainer {...props}>
      <TemperatureWrap>
        <div>
          <Temperature color={color}>{(+temperature).toFixed(1)}°C</Temperature>
          <TemperatureBarBackground>
            <TemperatureBar color={barColor ?? color} temperature={temperature} />
          </TemperatureBarBackground>
        </div>
        <MannerFaceWrap>
          <MannerComponent
            css={css`
              vertical-align: middle;
            `}
          />
        </MannerFaceWrap>
      </TemperatureWrap>
      <MannerTextBtn onClick={() => setIsVisible(true)}>매너온도</MannerTextBtn>
      <Dim
        css={css`
          position: fixed;
        `}
        onClick={() => setIsVisible(false)}
        visible={isVisible}
      />
      <ManuallyPositionedTooltip
        css={css`
          z-index: 12;
          position: absolute;
          top: ${rem(32)};
          right: 0;
          width: ${rem(236)};
        `}
        maxWidth={236}
        tipStyle={css`
          z-index: 12;
          top: ${rem(24)};
          right: ${rem(14)};
        `}
        visible={isVisible}
      >
        매너온도는 당근 사용자로부터 받은 칭찬, 후기, 비매너 평가, 운영자 제재 등을 종합해서 만든
        매너 지표예요.
      </ManuallyPositionedTooltip>
    </MannerTemperatureContainer>
  );
};

export default MannerTemperature;

const MannerTemperatureContainer = styled.div`
  margin-top: ${rem(-6)};
  position: relative;
`;

const TemperatureWrap = styled.div`
  display: flex;
`;

const Temperature = styled.p<{ color: string }>`
  color: ${({ color }) => `${color}`};
  text-align: right;
  ${vars.$semantic.typography.subtitle1Bold}
`;

const TemperatureBarBackground = styled.div`
  width: ${rem(50)};
  background-color: ${vars.$scale.color.gray200};
  height: ${rem(4)};
  border-radius: ${rem(100)};
  position: relative;
  margin-top: ${rem(1)};
`;

const TemperatureBar = styled.div<{ color: string; temperature: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: ${rem(4)};
  border-radius: ${rem(100)};
  background: ${({ color }) => `${color}`};
  width: ${({ temperature }) => `${temperature}%`};
`;

const MannerFaceWrap = styled.div`
  margin: auto 0 auto ${rem(8)};
`;

const MannerTextBtn = styled.div`
  position: absolute;
  bottom: ${rem(-4)};
  right: 0;
  text-decoration: underline;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption2Regular};
`;
