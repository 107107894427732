import '@daangn/error-component/index.css';
import '@daangn/karrot-clothes/lib/index.css';
import '@daangn/sprout-components-avatar/index.css';
import '@daangn/sprout-components-button/index.css';
import '@daangn/sprout-components-callout/index.css';
import '@daangn/sprout-components-checkbox/index.css';
import '@daangn/sprout-components-chips/index.css';
import '@daangn/sprout-components-dialog/index.css';
import '@daangn/sprout-components-divider/index.css';
import '@daangn/sprout-components-radio-group/index.css';
import '@daangn/sprout-components-slider/index.css';
import '@daangn/sprout-components-spinner/index.css';
import '@daangn/sprout-components-tabs/index.css';
import '@daangn/sprout-components-text-field/index.css';
import '@daangn/sprout-components-snackbar/index.css';
import '@daangn/transfer-identification-sdk/index.css';
import '@seed-design/stylesheet/global.css';
import { ErrorBoundary } from '@sentry/react';
import '@stackflow/plugin-basic-ui/index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

import GlobalErrorScreen from '@/components/Error/GlobalErrorScreen';
import { IS_ALPHA, IS_LOCAL } from '@/constants/config';
import { environment } from '@/sdk/bridge';
import '@/utils/datadog';
import '@/utils/sentry';

import App from './bootstrap/App';
import './bootstrap/setup';
import './bootstrap/shim';
import './index.css';
import './utils/zodI18n';

if (IS_ALPHA || IS_LOCAL) {
  import('./bootstrap/v-console').then((vConsole) => vConsole.default());
}

const container = document.getElementById('root');
const root = createRoot(container!);

if (IS_LOCAL || IS_ALPHA || environment !== 'Web') {
  root.render(
    <React.StrictMode>
      <ErrorBoundary
        beforeCapture={(scope) => scope.setLevel('fatal')}
        fallback={GlobalErrorScreen}
      >
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  );
}
