import React from 'react';

import BottomSheet, { Props as BottomSheetProps } from '@/components/Base/BottomSheet/BottomSheet';
import LoadingButton from '@/components/Base/LoadingButton';
import { BodyL1Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useToast } from '@/hooks/useToast';
import useGetCarHistory from '@/pages/Article/hooks/useGetCarHistory';
import { useCamelCaseActivityParams } from '@/utils/url';

type Props = BottomSheetProps;

const CarHistoryExpiredBottomSheet: React.FC<Props> = (props) => {
  const { articleId } = useCamelCaseActivityParams<'article'>();
  const [getCarHistory, isGetCarHistoryInFlight] = useGetCarHistory();
  const { setToast } = useToast();

  const handleClickGetCarHistory = async () => {
    try {
      const carHistory = await getCarHistory({ id: articleId });

      if (carHistory) {
        setToast('보험이력 조회가 완료되었어요.');
      }
      props.onClickClose?.();
    } catch (e) {
      setToast('보험이력 정보 등록에 실패했어요. 잠시 후 다시 시도해 주세요.');
    }
  };

  return (
    <BottomSheet {...props} title="보험이력 조회">
      <VStack gap={24} px={16} py={8}>
        <BodyL1Regular>보험이력 정보가 만료되었거나 등록되지 않았어요.</BodyL1Regular>
        <LoadingButton
          isLoading={isGetCarHistoryInFlight}
          onClick={handleClickGetCarHistory}
          size="xlarge"
          variant="primary"
          width="100%"
        >
          조회하기
        </LoadingButton>
      </VStack>
    </BottomSheet>
  );
};

export default CarHistoryExpiredBottomSheet;
