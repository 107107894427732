import { CSSObject } from '@emotion/styled';
import {
  Children,
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  isValidElement,
  memo,
} from 'react';

import { PropsOfHtmlTag } from '@/types/React/PropsOfHtmlTag';

import Box, { Props as BoxProps } from './Box';
import Spacer from './Spacer';

export type Props = {
  align?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'space-between';
  children: ReactNode;
  direction?: 'column' | 'row';
  divider?: ReactNode;
  gap?: number;
  justify?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'space-between';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
} & BoxProps &
  PropsOfHtmlTag<'div'>;

const Stack = (
  { direction = 'row', align, justify, gap, children, divider, wrap, ...props }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const style: CSSObject = {
    ...(align
      ? {
          alignItems: align,
        }
      : {}),
    ...(justify
      ? {
          justifyContent: justify,
        }
      : {}),
    ...(wrap
      ? {
          flexWrap: wrap,
        }
      : {}),
    display: 'flex',
    flexDirection: direction,
  };

  return (
    <Box css={style} {...props} ref={ref}>
      {Children.toArray(children)
        .filter((child) => child != null)
        .map((child, index, children) => {
          const key = isValidElement(child) ? child.key : index;
          return (
            <Fragment key={key}>
              {child}
              {index !== children.length - 1 && gap && (
                <Spacer axis={direction === 'row' ? 'horizontal' : 'vertical'} size={gap}>
                  {divider}
                </Spacer>
              )}
            </Fragment>
          );
        })}
    </Box>
  );
};

export default memo(forwardRef<HTMLDivElement, Props>(Stack));
