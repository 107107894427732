import { Callout, CalloutDescription } from '@daangn/sprout-components-callout';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ArticleNoticeSection_article$key } from '@/__generated__/ArticleNoticeSection_article.graphql';
import { ArticleNoticeSection_query$key } from '@/__generated__/ArticleNoticeSection_query.graphql';
import BannerButton from '@/components/Base/BannerButton';
import { CsCenterScheme } from '@/constants/scheme';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import useTrack from '@/hooks/useTrack';

import { useArticleContext } from './ArticleContext';
import ResaledArticleBanner from './components/ResaledArticleBanner';

type Props = {
  articleRef: ArticleNoticeSection_article$key;
  queryRef: ArticleNoticeSection_query$key;
};

const ArticleNoticeSection: React.FC<Props> = ({ queryRef, articleRef }) => {
  const { isMyArticle } = useArticleContext();
  const redirect = useRedirectAppScheme();
  const { track } = useTrack();
  const { blockedReason } = useFragment(
    graphql`
      fragment ArticleNoticeSection_query on Query @argumentDefinitions(userId: { type: "ID!" }) {
        blockedReason(userId: $userId)
      }
    `,
    queryRef
  );
  const { isReviewed, resaleOriginArticle } = useFragment(
    graphql`
      fragment ArticleNoticeSection_article on Article {
        isReviewed
        resaleOriginArticle {
          ...ResaledArticleBanner_sanitizedArticle
        }
      }
    `,
    articleRef
  );

  const blockedReasonText = (() => {
    switch (blockedReason) {
      case 'CHAT_ABUSE':
        return '채팅 오남용 사유로';
      // case 'DEALER':
      //   return '중고차 전문 업자로 확인되어';
      case 'DUPLICATED_ARTICLE':
        return '중복 매물 게시 사유로';
      // case 'ILLEGAL_BUSINESS':
      //   return '전문업자 / 업체 홍보 행위 사유로';
      case 'PERSONAL_INFO':
        return '개인정보 유출 사유로';
      case 'UNKNOWN':
      default:
        return '비정상적 활동으로';
    }
  })();

  const handleClickReview = () => {
    track('article_click_move_to_cs');
    redirect(CsCenterScheme.Home);
  };

  return (
    <Container>
      {isMyArticle && (
        <BannerWrapper>
          {blockedReason && blockedReason !== 'DEALER' && blockedReason !== 'ILLEGAL_BUSINESS' ? (
            <Callout variant="warning">
              <CalloutDescription>
                {blockedReasonText} 게시글이 미노출 처리되었어요.
              </CalloutDescription>
            </Callout>
          ) : (
            !isReviewed && (
              <BannerButton
                actionName="고객센터에 문의하기"
                description="운영상의 이유로 게시글이 미노출되었어요."
                onClick={handleClickReview}
              />
            )
          )}
        </BannerWrapper>
      )}
      {resaleOriginArticle && (
        <ResaledArticleBanner
          css={css`
            margin-bottom: ${rem(16)};
          `}
          sanitizedArticleRef={resaleOriginArticle}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerWrapper = styled.div`
  margin: ${rem(4)} 0 ${rem(16)} 0;
`;

export default ArticleNoticeSection;
