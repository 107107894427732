import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ConfettiLayer_article$key } from '@/__generated__/ConfettiLayer_article.graphql';
import confetti from '@/assets/confetti.json';
import Lottie from '@/components/Lottie/Lottie';

type Props = {
  articleRef: ConfettiLayer_article$key;
  onClickClose: () => void;
};

const ConfettiLayer: React.FC<React.PropsWithChildren<Props>> = ({ articleRef, onClickClose }) => {
  const article = useFragment(
    graphql`
      fragment ConfettiLayer_article on Article {
        promotionEndsAt
      }
    `,
    articleRef
  );

  return (
    <Container>
      {article.promotionEndsAt && (
        <Description>
          {format(new Date(article.promotionEndsAt), 'M월 d일 aaa h시 m분', { locale: ko })}까지
          노출돼요.
        </Description>
      )}
      <LottieWrapper>
        <Lottie animationData={confetti} />
      </LottieWrapper>
      <BoxButton onClick={onClickClose} size="xlarge" variant="primary" width="100%">
        확인
      </BoxButton>
    </Container>
  );
};

export default ConfettiLayer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(16)} ${rem(24)};
`;

const LottieWrapper = styled.div`
  padding: ${rem(24)} 0 ${rem(40)} 0;
`;

const Description = styled.p`
  color: ${vars.$scale.color.gray700};
`;
