import { IconInterestListFill } from '@seed-design/icon';
import { range } from 'lodash-es';

import { getSeedColor } from '@/components/Base/Box';
import HStack from '@/components/Base/HStack';
import { getSizeProps } from '@/utils/style';

type Props = {
  onChange?: (value: number) => void;
  value?: number;
};

const StarRatings = ({ onChange, value = 0, ...props }: Props) => {
  const handleChange = (nextValue: number) => {
    onChange?.(nextValue);
  };
  return (
    <HStack align="center" gap={12} {...props}>
      {range(0, 5).map((i) => {
        const pressed = value >= i + 1;
        return (
          <IconInterestListFill
            color={pressed ? getSeedColor('primary') : getSeedColor('gray500')}
            key={i}
            {...getSizeProps(32)}
            onClick={() => handleChange(i + 1)}
          />
        );
      })}
    </HStack>
  );
};

export default StarRatings;
