/**
 * @generated SignedSource<<591256f016e884a9bd37842a37377263>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ArticleImageType = "DOCUMENT" | "EXTERIOR" | "INTERIOR" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type getMergedImages_article$data = {
  readonly images: ReadonlyArray<{
    readonly images: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly type: ArticleImageType | null | undefined;
  }>;
  readonly " $fragmentType": "getMergedImages_article";
};
export type getMergedImages_article$key = {
  readonly " $data"?: getMergedImages_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"getMergedImages_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getMergedImages_article"
};

(node as any).hash = "11d830d282d927d9ab1ca92f65b3392d";

export default node;
