import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronLeftThin, IconChevronRightThin } from '@seed-design/icon';
import { add, addWeeks, differenceInWeeks, format, isSameDay, set } from 'date-fns';
import { groupBy } from 'lodash-es';
import { rem } from 'polished';
import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import useTrack from '@/hooks/useTrack';
import { isInRange, sum } from '@/utils/number';
import { getSizeProps } from '@/utils/style';

import Base from './Base';
import CustomizedAxisTick from './CustomizedAxisTick';
import PromotionLabel from './PromotionLabel';
import { EventItem, ExtendedEventItem, ExtendedStatisticsItem, StatisticsItem } from './types';
import {
  MILLISECOND_DAY,
  getAdjustedTicks,
  getDateStart,
  getSummaryText,
  getTicks,
  isInPromotion,
  mergeStatGroups,
} from './utils';

type Props = {
  estimations: StatisticsItem[];
  events: EventItem[];
  expectedIncrease: number;
  statistics: StatisticsItem[];
};
type DailyItem = {
  estimatedView: number;
  isToday: boolean;
  notPromoted: number;
  promoted: number;
  promotion?: ExtendedEventItem;
  t: number;
  total: number;
};

const dayFormat = 'yyyy.MM.dd';
const MIN_ESTIMATION_RATIO = 0.12;

const ArticleDailyChart: React.FC<React.PropsWithChildren<Props>> = ({
  statistics,
  estimations,
  events,
  expectedIncrease,
}) => {
  const { track } = useTrack();
  const now = new Date();
  const firstRecordedDate = set(new Date(statistics[0]?.t ?? now.getTime()), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const lastRecordedDate = new Date(statistics[statistics.length - 1]?.t ?? now.getTime());
  const maxWeekIndex = differenceInWeeks(lastRecordedDate, firstRecordedDate);
  const [weekIndex, setWeekIndex] = useState(maxWeekIndex);
  const currentWeekStart = addWeeks(firstRecordedDate, weekIndex);
  const currentWeekEnd = add(currentWeekStart, { weeks: 1, seconds: -1 });

  const diffCount = statistics.map((stat, idx): ExtendedStatisticsItem => {
    if (idx === 0) {
      return { ...stat };
    }

    return {
      t: stat.t,
      view: Math.max(stat.view - statistics[idx - 1]?.view, 0),
    };
  });
  const diffEstimatedCount = estimations.map((estimation, idx): ExtendedStatisticsItem => {
    return {
      t: estimation.t,
      estimatedView: idx === 0 ? 0 : Math.max(estimation.view - estimations[idx - 1]?.view ?? 0, 0),
    };
  });

  const countGroupByDaily = groupBy(diffCount, (stat) => getDateStart(stat.t));
  const estimatedCountGroupByDaily = groupBy(diffEstimatedCount, (stat) => getDateStart(stat.t));
  const mergedCountGroupByDaily = mergeStatGroups(countGroupByDaily, estimatedCountGroupByDaily);

  const extendedEvents = events.reduce<ExtendedEventItem[]>((acc, event) => {
    const eventEnd = event.t + MILLISECOND_DAY;

    if (eventEnd > now.getTime()) {
      return [...acc, event];
    }
    return [...acc, event, { t: eventEnd, type: 'PROMOTION_ENDED' }];
  }, []);
  const lastEvent = extendedEvents[extendedEvents.length - 1];

  const diffByDaily = Object.entries(mergedCountGroupByDaily).map(
    ([timestamp, dailyStats]: [string, ExtendedStatisticsItem[]]) => {
      const extract = (stats: ExtendedStatisticsItem[], field: 'estimatedView' | 'view') =>
        stats.map((stat) => stat?.[field] ?? 0);
      const t = Number(timestamp);

      return {
        t,
        isToday: isSameDay(new Date(t), now),
        estimatedView: sum(extract(dailyStats, 'estimatedView')),
        ...(isInRange(lastEvent?.t, t, t + MILLISECOND_DAY) && { promotion: lastEvent }),
        total: sum(extract(dailyStats, 'view')),
        promoted: sum(extract(dailyStats.filter(isInPromotion(events)), 'view')),
        notPromoted: sum(
          extract(
            dailyStats.filter((stat) => !isInPromotion(events)(stat)),
            'view'
          )
        ),
      } as DailyItem;
    }
  );
  const currentWeekDaily = diffByDaily.filter((daily) =>
    isInRange(daily.t, currentWeekStart.getTime(), currentWeekEnd.getTime())
  );

  const weeklyTotal = currentWeekDaily.reduce(
    (sum, curr) => ({
      ...sum,
      promoted: sum.promoted + curr.promoted,
      total: sum.total + curr.total,
    }),
    { promoted: 0, total: 0 }
  );
  const filledWeekDaily =
    currentWeekDaily.length >= 7
      ? currentWeekDaily
      : [
          ...currentWeekDaily,
          ...[...Array(7 - currentWeekDaily.length).keys()].map((diff) => {
            const lastAvailableDate =
              currentWeekDaily[currentWeekDaily.length - 1]?.t ?? getDateStart(now.getTime());
            const filledDate = add(lastAvailableDate, { days: diff + 1 });
            const emptyDailyItem = {
              total: 0,
              promoted: 0,
              notPromoted: 0,
              estimatedView: 0,
            };

            return {
              t: filledDate.getTime(),
              isToday: isSameDay(filledDate, new Date()),
              ...emptyDailyItem,
            } as DailyItem;
          }),
        ];
  const todayStart = getDateStart(now.getTime());
  const today = filledWeekDaily.find((daily) => daily.t === todayStart);
  const isPromotionOngoing = events.some((event) =>
    isInRange(event.t, todayStart, todayStart + MILLISECOND_DAY)
  );
  const isEstimationVisible = !isPromotionOngoing && !today?.promoted && weekIndex === maxWeekIndex;
  const totalCounts = currentWeekDaily.map(
    (daily) => daily.total + (isEstimationVisible ? daily.estimatedView : 0)
  );
  const maxCount = Math.max(...totalCounts);
  const digits = `${maxCount}`.length;
  const ticks = getTicks(0, maxCount);
  const { adjustedTicks, adjustedAlternativeTicks } = getAdjustedTicks(ticks);
  const adjustedWeekDaily = filledWeekDaily.map((daily) => ({
    ...daily,
    estimatedView:
      daily.estimatedView > 0
        ? Math.max(Math.floor(maxCount * MIN_ESTIMATION_RATIO), daily.estimatedView)
        : 0,
  }));

  return (
    <>
      <TitleWrapper>
        <WeekMoveButton
          css={css`
            padding-left: 0;
          `}
          disabled={weekIndex <= 0}
          onClick={() => {
            setWeekIndex((index) => (index <= 0 ? index : index - 1));
            track('article_click_chart_week_prev', { weekIndex });
          }}
        >
          <IconChevronLeftThin
            {...iconSizeProps}
            color={weekIndex > 0 ? vars.$scale.color.gray900 : vars.$scale.color.gray400}
          />
        </WeekMoveButton>
        <Base.TextWrapper
          css={css`
            padding: 0;
          `}
        >
          <Base.Summary>
            하루 평균{' '}
            {getSummaryText(
              currentWeekDaily.length > 0
                ? Math.round(weeklyTotal.total / currentWeekDaily.length)
                : 0
            )}
          </Base.Summary>
          {weekIndex === maxWeekIndex ? (
            <Base.PromotionDescription
              count={weeklyTotal.promoted}
              events={events}
              expectedIncrease={expectedIncrease}
              statistics={statistics}
            />
          ) : (
            <Base.Description>
              {format(currentWeekStart, dayFormat)} ~ {format(currentWeekEnd, dayFormat)}
            </Base.Description>
          )}
        </Base.TextWrapper>
        <WeekMoveButton
          css={css`
            padding-right: 0;
          `}
          disabled={weekIndex >= maxWeekIndex}
          onClick={() => {
            setWeekIndex((index) => (index >= maxWeekIndex ? index : index + 1));
            track('article_click_chart_week_next', { weekIndex });
          }}
        >
          <IconChevronRightThin
            {...iconSizeProps}
            color={weekIndex < maxWeekIndex ? vars.$scale.color.gray900 : vars.$scale.color.gray400}
          />
        </WeekMoveButton>
      </TitleWrapper>
      <ChartContainer>
        <ResponsiveContainer aspect={1.363} width="100%">
          <BarChart
            barSize={8}
            data={adjustedWeekDaily}
            margin={{
              top: 33,
              left: 16,
              right: -8 + (digits >= 5 ? (digits - 4) * 6 : 0),
              bottom: 10,
            }}
          >
            <XAxis
              axisLine={false}
              dataKey="t"
              domain={['dataMin', 'dataMax']}
              height={18}
              interval={0}
              minTickGap={0}
              padding={{ left: 16 }}
              scale="time"
              tick={(props) => {
                if (props?.payload?.index == null) {
                  return <></>;
                }
                const daily = filledWeekDaily[props.payload.index];

                return (
                  <CustomizedAxisTick
                    {...props}
                    highlighted={daily.isToday}
                    text={daily.isToday ? '오늘' : format(new Date(daily.t), 'MM.dd')}
                  />
                );
              }}
              tickLine={false}
              tickMargin={12}
              type="number"
            />
            <YAxis
              axisLine={false}
              dataKey="total"
              domain={['dataMin', 'dataMax']}
              orientation="right"
              tickFormatter={(value: number, index) => {
                if (maxCount >= 30) {
                  return `${Math.floor(Math.round(value) / 10) * 10}`;
                }
                if (new Set(adjustedAlternativeTicks).size <= 2 && index === 1) {
                  return '';
                }
                return `${Math.floor(value)}`;
              }}
              tickLine={false}
              tickMargin={4}
              ticks={adjustedTicks}
              type="number"
            />
            <CartesianGrid vertical={false} />
            <Bar dataKey="notPromoted" stackId="count">
              {filledWeekDaily.map((entry, index) => (
                <Cell
                  fill={vars.$scale.color.gray800}
                  key={`cell-${index}`}
                  radius={!entry.promoted && !entry.estimatedView ? ([4, 4, 0, 0] as any) : 0}
                  stroke="none"
                />
              ))}
            </Bar>
            <Bar
              dataKey="promoted"
              label={{
                position: 'top',
                content: (props) => {
                  if (props.index == null) {
                    return <></>;
                  }
                  const promotion = filledWeekDaily[props.index].promotion;

                  if (!promotion || isEstimationVisible) {
                    return <></>;
                  }
                  return (
                    <PromotionLabel {...props} marginX={4} marginY={-25} type={promotion.type} />
                  );
                },
              }}
              stackId="count"
            >
              {filledWeekDaily.map((entry, index) => (
                <Cell
                  fill={vars.$semantic.color.primary}
                  key={`cell-${index}`}
                  radius={entry.promoted > 0 ? ([4, 4, 0, 0] as any) : 0}
                  stroke="none"
                />
              ))}
            </Bar>
            {isEstimationVisible && (
              <Bar
                dataKey="estimatedView"
                label={{
                  position: 'top',
                  content: (props) => {
                    if (props.index == null || !filledWeekDaily[props.index]?.isToday) {
                      return <></>;
                    }

                    return (
                      <PromotionLabel {...props} marginX={4} marginY={-25} type="ESTIMATION" />
                    );
                  },
                }}
                stackId="count"
              >
                {filledWeekDaily.map((entry, index) => (
                  <Cell
                    fill={vars.$semantic.color.paperDefault}
                    key={`cell-${index}`}
                    radius={entry.estimatedView > 0 ? ([4, 4, 0, 0] as any) : 0}
                    stroke={vars.$semantic.color.primary}
                    strokeDasharray={[6, 3] as any}
                    strokeWidth={1}
                  />
                ))}
              </Bar>
            )}
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(24)} 0 ${rem(20)};
`;

const WeekMoveButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 ${rem(8)};
  background: transparent;
  border: none;
`;

const ChartContainer = styled.div`
  width: 100%;

  & .recharts-cartesian-axis-tick text {
    ${vars.$semantic.typography.label5Regular};
  }

  & .recharts-cartesian-axis-tick-value {
    fill: ${vars.$scale.color.gray500};
  }

  & .recharts-cartesian-grid-horizontal line {
    stroke: ${vars.$scale.color.gray100};
  }
`;

const iconSizeProps = getSizeProps(18);

export default ArticleDailyChart;
