import { IconInfoThin } from '@seed-design/icon';
import { shuffle } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  InspectedArticlesSection_query$data,
  InspectedArticlesSection_query$key,
} from '@/__generated__/InspectedArticlesSection_query.graphql';
import ArticleCarousel from '@/components/Article/ArticleCarousel';
import { getSeedColor } from '@/components/Base/Box';
import { ColoredSpan } from '@/components/Base/ColoredText';
import HStack from '@/components/Base/HStack';
import { Title3Bold } from '@/components/Base/Text';
import TitledSection from '@/components/TitledSection';
import useInspectionAdvantages from '@/hooks/useInspectionAdvantages';
import useTrack from '@/hooks/useTrack';
import { useFlow } from '@/stackflow';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getSizeProps } from '@/utils/style';

type Props = {
  queryRef: InspectedArticlesSection_query$key;
};

const cache = new Map<string, InspectedArticlesSection_query$data['inspectedArticles']>();

const InspectedArticlesSection: React.FC<Props> = ({ queryRef }) => {
  const { push } = useFlow();
  const { track } = useTrack();
  const [openInspectionAdvantages, inspectionAdvantagesNode] = useInspectionAdvantages();
  const { inspectedArticles } = useFragment(
    graphql`
      fragment InspectedArticlesSection_query on Query
      @argumentDefinitions(count: { type: "Int", defaultValue: 20 }) {
        inspectedArticles: articlesForInspectionSection(n: $count) {
          id
          status
          ...ArticleCard_article
          ...VerticalArticleCard_article
        }
      }
    `,
    queryRef
  );

  const handleClickMore = useCallback(() => {
    push('inspected_articles', {});
    track('main_click_more_from_carousel', {
      carouselType: 'INSPECTION',
    });
  }, [push, track]);

  const handleClickArticleCard = useCallback(
    (data: ArticleEventProperties): void => {
      track('main_click_article_from_carousel', {
        carouselType: 'INSPECTION',
        ...data,
      });
    },
    [track]
  );

  const onSaleArticles = inspectedArticles.filter((article) => article.status === 'ON_SALE');
  const shuffledArticles = useMemo(() => {
    const ids = onSaleArticles.map((article) => article.id).join(',');

    if (cache.has(ids)) {
      return cache.get(ids) ?? [];
    }
    const shuffled = shuffle(onSaleArticles);
    cache.set(ids, shuffled);

    return shuffled;
  }, [onSaleArticles]);

  return (
    <TitledSection
      onClickMore={inspectedArticles.length > 2 ? handleClickMore : undefined}
      title={
        <HStack align="center" gap={4}>
          <Title3Bold color="gray900">
            안심하고 타는 <ColoredSpan color="accent">진단 중고차</ColoredSpan>
          </Title3Bold>
          <IconInfoThin
            {...getSizeProps(20)}
            color={getSeedColor('gray500')}
            onClick={() => {
              openInspectionAdvantages();
              track('main_click_tooltip_from_carousel', {
                carouselType: 'INSPECTION',
              });
            }}
          />
        </HStack>
      }
    >
      {
        <ArticleCarousel
          articles={shuffledArticles}
          itemSize="medium"
          onClick={handleClickArticleCard}
          supportSnap
          variant="verticalC"
        />
      }
      {inspectionAdvantagesNode}
    </TitledSection>
  );
};

export default InspectedArticlesSection;
