/**
 * @generated SignedSource<<ce292305c3f36a9ad41fafd6bdf61dc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_article$data = {
  readonly carData: {
    readonly carNo: string;
    readonly ownerVerified: boolean | null | undefined;
  };
  readonly id: string;
  readonly isHide: boolean;
  readonly promotionEndsAt: any | null | undefined;
  readonly shareUrl: string;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderMenu_article">;
  readonly " $fragmentType": "Navigation_article";
};
export type Navigation_article$key = {
  readonly " $data"?: Navigation_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHide",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promotionEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarData",
      "kind": "LinkedField",
      "name": "carData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carNo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerVerified",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderMenu_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "fce3a2f597b164ce135f360c38af5814";

export default node;
