import React, { useEffect } from 'react';

import { useFilterContext } from '@/components/Filter/FilterContextProvider';
import { carStorage } from '@/utils/storage';

const SyncFilterToStorage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { filter } = useFilterContext();

  useEffect(() => {
    carStorage.setFilter(filter);
  }, [filter]);

  return null;
};

export default SyncFilterToStorage;
