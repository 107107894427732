import styled, { CSSObject } from '@emotion/styled';

type Props = {
  axis?: 'horizontal' | 'vertical';
  size?: number;
};

const propsToStyle = ({ axis, size }: Props): CSSObject[] => {
  return [
    { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    axis === 'horizontal' && size
      ? {
          width: `${size}px`,
          minWidth: `${size}px`,
        }
      : {},
    axis === 'vertical' && size
      ? {
          height: `${size}px`,
          minHeight: `${size}px`,
        }
      : {},
  ];
};

const Spacer = styled.span<Props>(propsToStyle);
export default Spacer;
