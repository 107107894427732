import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog';
import { useAtomValue } from 'jotai';
import React from 'react';

import { karrotBridge } from '@/sdk/bridge';
import { serviceMaintenanceAtom } from '@/store/serviceMaintenance';

const ServiceMaintenanceDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
  const serviceMaintenance = useAtomValue(serviceMaintenanceAtom);

  const handleClick = () => {
    karrotBridge.closeRouter({});
  };

  if (!serviceMaintenance) {
    return null;
  }

  return (
    <DialogContainer>
      <AlertDialog
        description="불편을 드려 죄송해요. 조금 뒤에 다시 이용해 주세요."
        onPrimaryAction={handleClick}
        primaryActionLabel="확인"
        title="중고차 서비스 점검중이예요"
      />
    </DialogContainer>
  );
};

export default ServiceMaintenanceDialog;
