export const WriteErrorMessage: Record<string, string> = {
  ARTICLE_LIMIT_REACHED: '월 최대 1대, 연 최대 4대의 차량만 등록할 수 있어요.',
  ABUSE_SUSPICIOUS: '반복적인 작성으로 제한되어있어요.',
  USER_BLOCKED: '활동이 제한되었어요.',
  BLOCKED: '운영 상의 이유로 작성이 제한되었어요.',
};

export const ChatErrorMessage: Record<string, string> = {
  BLOCKED: '운영 상의 이유로 제재된 매물이에요.',
  USER_BLOCKED: '서비스 이용이 제한되어 이 기능을 사용할 수 없어요.',
  HOIAN_BLOCKED_SENDER_BLOCKED_RECEIVER: '차단한 사용자에요.',
  HOIAN_BLOCKED_RECEIVER_BLOCKED_SENDER: '메시지를 보낼 수 없는 사용자예요.',
  TOO_MANY_CHAT: '채팅은 하루에 10회까지만 보낼 수 있어요.',
  REGION_NOT_VERIFIED: '채팅하려면 동네 인증이 필요해요.',
};

export const ArticleErrorMessage: Record<string, string> = {
  DELETED: '게시글이 삭제되어 볼 수 없어요.',
  HIDDEN: '숨김 처리된 게시물이에요.',
  NOT_FOUND: '매물 정보를 찾을 수 없어요.',
  BLOCKED: '운영 상의 이유로 제재된 매물이에요.',
};
