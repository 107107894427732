import { RefObject, useEffect, useRef } from 'react';

import useLatest from '@/hooks/useLatest';

import useIntersectionObserver from './useIntersectionObserver';

const useImpression = (ref: RefObject<HTMLElement>, callback: () => void) => {
  const isIntersecting = useIntersectionObserver(ref, {
    threshold: 0.5,
  });
  const isCalled = useRef(false);
  const callbackRef = useLatest(callback);
  useEffect(() => {
    if (isIntersecting && !isCalled.current) {
      callbackRef.current();
      isCalled.current = true;
    }
  }, [callbackRef, isIntersecting]);
};

export default useImpression;
