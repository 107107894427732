import { isInRange } from '@/utils/number';

import { EventItem, ExtendedStatisticsItem, StatisticsItem } from './types';

export const getSummaryText = (count: number) => {
  return `${count}회 조회됐어요`;
};

export const getDiff = (arr: StatisticsItem[]) => {
  if (!arr.length) {
    return 0;
  }
  return arr[arr.length - 1].view - arr[0].view;
};

export const isInPromotion = (promotionEvents: EventItem[]) => (stat: ExtendedStatisticsItem) => {
  return promotionEvents.some((event) => isInRange(stat.t, event.t, event.t + MILLISECOND_DAY));
};

export const MILLISECOND_DAY = 24 * 60 * 60 * 1000;

export const getTicks = (min: number, max: number) => {
  const diff = max - min;

  return [min, min + diff / 3, min + (diff * 2) / 3, max];
};

export const getAdjustedTicks = (ticks: number[]) => {
  const min = Math.min(...ticks);
  const max = Math.max(...ticks);
  const avg = (min + max) / 2;
  const diff = max - min;

  const alternativeTicks = [min, avg, max];
  const adjustedAlternativeTicks = [min, Math.floor(avg), max];

  let adjustedTicks: number[] = [];

  if (diff >= 30) {
    adjustedTicks = ticks;
  } else if (new Set(adjustedAlternativeTicks).size <= 2) {
    adjustedTicks = [min, min + 0.5, min + 1];
  } else {
    adjustedTicks = alternativeTicks;
  }

  return { adjustedTicks, adjustedAlternativeTicks };
};

type StatsGroup = {
  [key: string]: ExtendedStatisticsItem[];
};
export const mergeStatGroups = (statsGroupA: StatsGroup, statsGroupB: StatsGroup) => {
  const merged: StatsGroup = { ...statsGroupA };

  Object.entries(statsGroupB).forEach(([timestamp, stats]) => {
    const t = Number(timestamp);

    if (!(timestamp in merged)) {
      merged[t] = [...stats];
      return;
    }
    stats.forEach((stat) => {
      const index = merged[t].findIndex((s) => s.t === stat.t);
      if (index <= -1) {
        merged[t] = [...merged[t], { ...stat }];
        return;
      }
      merged[t][index] = { ...merged[t][index], ...stat };
    });
  });
  Object.values(merged).forEach((stats) => {
    stats.sort((statA, statB) => statA.t - statB.t);
  });

  return merged;
};

export const getDateStart = (timestamp: number) => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);

  return date.getTime();
};
