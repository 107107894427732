import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import { rem } from 'polished';
import React from 'react';

import { ArticleCard_article$key } from '@/__generated__/ArticleCard_article.graphql';
import ArticleCard from '@/components/Article/ArticleCard';
import Box from '@/components/Base/Box';
import BaseDivider from '@/components/Base/Divider';
import CONFIG from '@/constants/config';
import useTrack from '@/hooks/useTrack';
import { ArticleEventProperties } from '@/utils/article/ArticleEventProperties';
import { getSizeProps } from '@/utils/style';

type Props = {
  items: ({ id: string } & ArticleCard_article$key)[];
  onClickMore?: () => void;
  showMoreButton?: boolean;
  title: string;
};

const RecommendedArticles: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  items,
  showMoreButton = false,
  onClickMore,
}) => {
  const { track } = useTrack();

  return (
    <>
      <Title>{title}</Title>
      <List>
        {items.map((article, index, list) => {
          const handleClick = ({ articleId }: ArticleEventProperties) => {
            track('article_click_recommended_article', { articleId });
          };

          return (
            <React.Fragment key={article.id}>
              <ArticleCard articleRef={article} key={article.id} onClick={handleClick} />
              {index < list.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
      {showMoreButton && (
        <Box px={16} py={16}>
          <BoxButton
            onClick={onClickMore}
            size="small"
            suffix={<Icon {...getSizeProps(16)} color={vars.$scale.color.gray900} />}
            variant="secondary"
            width="100%"
          >
            {CONFIG.SERVICE_NAME} 게시글 더보기
          </BoxButton>
        </Box>
      )}
    </>
  );
};

const Title = styled.div`
  ${vars.$semantic.typography.title3Bold}
  padding: 0 ${rem(16)};
  color: ${vars.$scale.color.gray900};
`;

const Icon = styled(IconChevronRightRegular)`
  margin-left: ${rem(4)};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled(BaseDivider)`
  margin: 0 ${rem(16)};
  width: calc(100% - 2 * ${rem(16)});
`;

export default RecommendedArticles;
