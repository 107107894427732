import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';

type Props = {
  size?: number;
};

export const DEFAULT_PROFILE_IMAGE_URL =
  'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';

const DefaultUserProfileImage: React.FC<React.PropsWithChildren<Props>> = ({
  size = 40,
  ...props
}) => {
  return (
    <Container {...props} size={size}>
      <Image src={DEFAULT_PROFILE_IMAGE_URL} />
    </Container>
  );
};

const Container = styled.div<Required<Pick<Props, 'size'>>>`
  ${({ size }) => {
    const sizeInRem = rem(size);
    return `
      width: ${sizeInRem};
      height: ${sizeInRem};
    `;
  }}
  border-radius: 50%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export default DefaultUserProfileImage;
